<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="preferenceWidgetAccessKeySearchQuery"
			search-query-label="Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changePreferenceWidgetAccessKeySearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
		>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="$emit('create-access-key', accessKeyType)"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Embedded Consent Collection Access Keys
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredAccessKeys"
					sort-by="accessKeyId"
					sort-desc
					@click:row="editAccessKey"
				>
					<template #item.action="{ item }">
						<IconButton
							tooltip-text="Copy Key To Clipboard"
							@click.stop.prevent="copyAccessKey(item.accessKey)"
						>
							mdi-content-copy
						</IconButton>
						<IconButton
							:tooltip-text="userFullPermissions && userHasBrand(item.brandId) ? 'Edit Access Key' : 'View Access Key'"
							@click="editAccessKey(item)"
						>
							{{ userFullPermissions && userHasBrand(item.brandId) ? 'mdi-pencil' : 'mdi-eye' }}
						</IconButton>
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandId)"
							tooltip-text="Delete Access Key"
							@click.stop.prevent="preferenceWidgetAccessKeyToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="preferenceWidgetAccessKeyToRemove"
			:entity-name="preferenceWidgetAccessKeyToRemove.name"
			entity-type="Embedded Consent Collection Access Key"
			@close="preferenceWidgetAccessKeyToRemove = null"
			@delete="removeKey"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { deletePreferenceWidgetAccessKey } from '../../../../../../shared/utils/api/access-keys.js'
import { ACCESS_KEYS_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'
import { changePreferenceWidgetAccessKeySearchQuery, preferenceWidgetAccessKeySearchQuery } from '../../../../../../shared/state/access-keys.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId, userHasBrand } from '../../../../../../shared/state/brands.js'

export default {
	components: { FilterBar, PrimaryActionButton, SectionCard, DataTable, ConfirmDeleteModal, IconButton },
	props: {
		accessKeyType: {
			type: Number,
			required: true
		},
		accessKeys: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			userHasBrand,
			changePreferenceWidgetAccessKeySearchQuery,
			preferenceWidgetAccessKeySearchQuery,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId
		}
	},
	data () {
		return {
			searchQuery: null,
			selectedBrandId: null,
			preferenceWidgetAccessKeyToRemove: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		tableHeaders () {
			const tableHeaders = [
				{
					text: 'ID',
					value: 'accessKeyId',
					width: '5%'
				},
				{
					text: useLabels('Brand'),
					value: 'brandName',
					width: '10%'
				},
				{
					text: 'Name',
					value: 'name',
					width: '15%'
				},
				{
					text: 'Access Key',
					value: 'accessKey',
					width: '20%'
				},
				{
					text: 'Description',
					value: 'description',
					width: '20%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '10%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '10%',
					sortable: false
				}
			]
			return tableHeaders
		},
		filteredAccessKeys () {
			return this.accessKeys.filter(({ name, brandId }) => {
				let check = true
				if (preferenceWidgetAccessKeySearchQuery.value) check = name.toLowerCase().includes(preferenceWidgetAccessKeySearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				return check
			})
		},
		userFullPermissions () {
			return this.productAreaPermission(ACCESS_KEYS_MODULE_FULL_PERMISSIONS)
		}
	},
	methods: {
		editAccessKey (accessKeyToEdit) {
			const accessKeyData = {
				accessKeyToEdit,
				accessKeyType: this.accessKeyType
			}
			this.$emit('edit-access-key', accessKeyData)
		},
		async removeKey () {
			await deletePreferenceWidgetAccessKey(this.preferenceWidgetAccessKeyToRemove.accessKey)
			this.preferenceWidgetAccessKeyToRemove = null
			this.$emit('load-access-keys')
			showSnackbar('This Access Key has been removed')
		},
		copyAccessKey (accessKey) {
			navigator.clipboard.writeText(accessKey)
			showSnackbar({ color: 'grey darken-1', text: 'Access Key copied to clipboard' })
		}
	}
}
</script>
