<template>
	<SectionCard>
		<template #title>
			{{ elementType.cardTitle }}
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						label="Default Channel"
						disabled
						:test-id="`${element.name}_default_channelName`"
						:value="element.channelName"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Channel"
						disabled
						:test-id="`${element.name}_translated_channelName`"
						tooltip-text="This can be translated in Components, Channels"
						:value.sync="translatedChannelName"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextField
						label="Default Statement"
						disabled
						:test-id="`${element.name}_default_statementText`"
						:value="element.statementText"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Statement"
						disabled
						:test-id="`${element.name}_translated_statementText`"
						tooltip-text="This can be translated in Components, Statements & Groups, Statements"
						:value.sync="translatedStatementText"
					/>
				</v-col>
			</v-row>
			<v-row
				v-for="radio in radioOptions"
				:key="radio.value"
				dense
			>
				<v-col cols="6">
					<TextField
						:label="fieldLabel(radio.value)"
						disabled
						:test-id="`${element.name}_default_${radio.value}_label`"
						:value="fieldValue(radio.value)"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						:label="fieldLabel(radio.value, true)"
						:value="fieldValue(radio.value, true)"
						:disabled="!userFullPermissions"
						:test-id="`${element.name}_translated_${radio.value}_label`"
						@input="updateRadioOptionLabel(radio.value, $event)"
					/>
				</v-col>
			</v-row>
			<ExtendedPreferencesSection
				v-if="hasExtendedPreferences"
				:user-full-permissions="userFullPermissions"
				:extended-preferences.sync="element.extendedPreferences"
				:translated-extended-preferences.sync="translatedExtendedPreferences"
				@update-extended-preferences="updateTranslation(element.name, 'extendedPreferences', $event)"
			/>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import ExtendedPreferencesSection from './extended-preferences-section.vue'
export default {
	components: {
		SectionCard,
		TextField,
		ExtendedPreferencesSection
	},
	props: {
		element: {
			type: Object,
			required: true
		},
		elementType: {
			type: Object,
			required: true
		},
		translatedSchema: {
			type: Object,
			required: true
		},
		userFullPermissions: {
			type: Boolean,
			required: true
		}
	},
	data () {
		return {
			OPT_IN_VALUE: 1,
			OPT_OUT_VALUE: 0,
			NO_CHANGE_VALUE: 3
		}
	},
	computed: {
		translatedChannelName () {
			return this.translatedSchema[this.element?.name]?.channelName
		},
		translatedStatementText () {
			return this.translatedSchema[this.element?.name]?.statementText
		},
		radioOptions () {
			return this.element?.items
		},
		optInLabel () {
			return this.element.items.find(({ value }) => value === this.OPT_IN_VALUE).label
		},
		translatedOptInLabel () {
			return this.translatedSchema[this.element?.name]?.items.find(({ value }) => value === this.OPT_IN_VALUE).label
		},
		optOutLabel () {
			return this.element.items.find(({ value }) => value === this.OPT_OUT_VALUE).label
		},
		translatedOptOutLabel () {
			return this.translatedSchema[this.element?.name]?.items.find(({ value }) => value === this.OPT_OUT_VALUE).label
		},
		noChangeLabel () {
			return this.element.items.find(({ value }) => value === this.NO_CHANGE_VALUE).label
		},
		translatedNoChangeLabel () {
			return this.translatedSchema[this.element?.name]?.items.find(({ value }) => value === this.NO_CHANGE_VALUE).label
		},
		translatedExtendedPreferences () {
			return this.translatedSchema[this.element?.name]?.extendedPreferences ?? []
		},
		hasExtendedPreferences () {
			return this.element.extendedPreferences?.length > 0 && this.translatedExtendedPreferences?.length > 0
		}
	},
	methods: {
		updateTranslation (key, property, value) {
			this.$emit('update-translation', key, property, value)
		},
		fieldLabel (value, translation = false) {
			switch (value) {
				case this.OPT_IN_VALUE:
					return `${translation ? 'Translated' : 'Default'} Opt In Label`
				case this.OPT_OUT_VALUE:
					return `${translation ? 'Translated' : 'Default'} Opt Out Label`
				case this.NO_CHANGE_VALUE:
					return `${translation ? 'Translated' : 'Default'} No Change Label`
				default:
					return ''
			}
		},
		fieldValue (value, translation = false) {
			switch (value) {
				case this.OPT_IN_VALUE:
					return !translation ? this.optInLabel : this.translatedOptInLabel
				case this.OPT_OUT_VALUE:
					return !translation ? this.optOutLabel : this.translatedOptOutLabel
				case this.NO_CHANGE_VALUE:
					return !translation ? this.noChangeLabel : this.translatedNoChangeLabel
				default:
					return null
			}
		},
		updateRadioOptionLabel (labelToUpdate, value) {
			const items = this.translatedSchema[this.element.name].items
			const radioOption = items.find(({ value }) => value === labelToUpdate)
			radioOption.label = value
			this.updateTranslation(this.element.name, 'items', items)
		}
	}
}
</script>
