export const elementFieldsMap = {
	cassieHeader: {
		cardTitle: 'Header',
		componentName: 'HeaderFooterElement'
	},
	cassieFooter: {
		cardTitle: 'Footer',
		componentName: 'HeaderFooterElement'
	},
	radioPreference: {
		cardTitle: 'Radio Preference',
		componentName: 'RadioPreferenceElement'
	},
	checkboxPreference: {
		cardTitle: 'Checkbox Preference',
		componentName: 'SimplePreferenceElement'
	},
	togglePreference: {
		cardTitle: 'Toggle Preference',
		componentName: 'SimplePreferenceElement'
	},
	implicitOptInPreference: {
		cardTitle: 'Implicit Opt In Preference',
		componentName: 'SimplePreferenceElement'
	},
	title: {
		cardTitle: 'Title',
		componentName: 'PiiElement'
	},
	forename: {
		cardTitle: 'Forename',
		componentName: 'PiiElement'
	},
	middleName: {
		cardTitle: 'Middle Name',
		componentName: 'PiiElement'
	},
	surname: {
		cardTitle: 'Surname',
		componentName: 'PiiElement'
	},
	organisationName: {
		cardTitle: 'Organisation Name',
		componentName: 'PiiElement'
	},
	jobTitle: {
		cardTitle: 'Job Title',
		componentName: 'PiiElement'
	},
	email1: {
		cardTitle: 'Email 1',
		componentName: 'PiiElement'
	},
	email2: {
		cardTitle: 'Email 2',
		componentName: 'PiiElement'
	},
	telephone1: {
		cardTitle: 'Telephone 1',
		componentName: 'PiiElement'
	},
	telephone2: {
		cardTitle: 'Telephone 2',
		componentName: 'PiiElement'
	},
	mobile: {
		cardTitle: 'Cell Phone/Mobile',
		componentName: 'PiiElement'
	},
	address1: {
		cardTitle: 'Address 1',
		componentName: 'PiiElement'
	},
	address2: {
		cardTitle: 'Address 2',
		componentName: 'PiiElement'
	},
	address3: {
		cardTitle: 'Address 3',
		componentName: 'PiiElement'
	},
	address4: {
		cardTitle: 'Address 4',
		componentName: 'PiiElement'
	},
	address5: {
		cardTitle: 'Address 5',
		componentName: 'PiiElement'
	},
	town: {
		cardTitle: 'Town',
		componentName: 'PiiElement'
	},
	county: {
		cardTitle: 'County',
		componentName: 'PiiElement'
	},
	postcode: {
		cardTitle: 'Zip Code/Postcode ',
		componentName: 'PiiElement'
	},
	country: {
		cardTitle: 'Country',
		componentName: 'PiiElement'
	},
	urn1: {
		cardTitle: 'URN 1',
		componentName: 'PiiElement'
	},
	urn2: {
		cardTitle: 'URN 2',
		componentName: 'PiiElement'
	},
	urn3: {
		cardTitle: 'URN 3',
		componentName: 'PiiElement'
	},
	custom1: {
		cardTitle: 'Custom 1',
		componentName: 'PiiElement'
	},
	custom2: {
		cardTitle: 'Custom 2',
		componentName: 'PiiElement'
	},
	custom3: {
		cardTitle: 'Custom 3',
		componentName: 'PiiElement'
	},
	custom4: {
		cardTitle: 'Custom 4',
		componentName: 'PiiElement'
	},
	custom5: {
		cardTitle: 'Custom 5',
		componentName: 'PiiElement'
	},
	custom6: {
		cardTitle: 'Custom 6',
		componentName: 'PiiElement'
	},
	custom7: {
		cardTitle: 'Custom 7',
		componentName: 'PiiElement'
	},
	custom8: {
		cardTitle: 'Custom 8',
		componentName: 'PiiElement'
	},
	custom9: {
		cardTitle: 'Custom 9',
		componentName: 'PiiElement'
	},
	custom10: {
		cardTitle: 'Custom 10',
		componentName: 'PiiElement'
	},
	savePreferencesButton: {
		cardTitle: 'Save Preferences Button',
		componentName: 'ButtonElement'
	},
	optInAllButton: {
		cardTitle: 'Opt In All Button',
		componentName: 'ButtonElement'
	},
	optOutAllButton: {
		cardTitle: 'Opt Out All Button',
		componentName: 'ButtonElement'
	},
	h1: {
		cardTitle: 'H1',
		componentName: 'StaticTextElement'
	},
	h2: {
		cardTitle: 'H2',
		componentName: 'StaticTextElement'
	},
	h3: {
		cardTitle: 'H3',
		componentName: 'StaticTextElement'
	},
	h4: {
		cardTitle: 'H4',
		componentName: 'StaticTextElement'
	},
	p: {
		cardTitle: 'Paragraph',
		componentName: 'StaticTextElement'
	},
	img: {
		cardTitle: 'Image',
		componentName: 'StaticImageElement'
	}
}
