<template>
	<SectionCard>
		<template #title>
			{{ elementType.cardTitle }}
		</template>
		<template #body>
			<v-row
				dense
			>
				<v-col cols="6">
					<TextField
						label="Default Image Src"
						disabled
						:test-id="`${element.name}_default_image_src`"
						:value="element.src"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Image Src"
						:value.sync="translatedImageSrc"
						:disabled="!userFullPermissions"
						:test-id="`${element.name}_translated_image_src`"
						@input="updateTranslation(element.name, 'src', $event)"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="element.title"
				dense
			>
				<v-col cols="6">
					<TextField
						label="Default Title"
						disabled
						:test-id="`${element.name}_default_title`"
						:value="element.title"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Title"
						:value.sync="translatedImageTitle"
						:disabled="!userFullPermissions"
						:test-id="`${element.name}_translated_title`"
						@input="updateTranslation(element.name, 'title', $event)"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="element.alt"
				dense
			>
				<v-col cols="6">
					<TextField
						label="Default Alt Text"
						disabled
						:test-id="`${element.name}_default_alt_text`"
						:value="element.alt"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Alt Text"
						:value.sync="translatedImageAlt"
						:disabled="!userFullPermissions"
						:test-id="`${element.name}_translated_alt_text`"
						@input="updateTranslation(element.name, 'alt', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
export default {
	components: {
		SectionCard,
		TextField
	},
	props: {
		element: {
			type: Object,
			required: true
		},
		elementType: {
			type: Object,
			required: true
		},
		translatedSchema: {
			type: Object,
			required: true
		},
		userFullPermissions: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		translatedImageSrc () {
			return this.translatedSchema[this.element?.name]?.src
		},
		translatedImageTitle () {
			return this.translatedSchema[this.element?.name]?.title
		},
		translatedImageAlt () {
			return this.translatedSchema[this.element?.name]?.alt
		}
	},
	methods: {
		updateTranslation (key, property, value) {
			this.$emit('update-translation', key, property, value)
		}
	}
}
</script>
