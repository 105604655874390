<template>
	<div>
		<SectionCard
			collapsible
			:collapsed="showExtendedPreferences"
		>
			<template #title>
				Extended Preferences
			</template>
			<template #subtitle>
				Extended Preference Keys can be assigned a single text value which will be passed in with a Data Subjects Preference update.
			</template>
			<template #body>
				<v-row dense>
					<v-col
						cols="7"
						class="cassie-horizontal-sm d-flex flex-row"
					>
						<Dropdown
							:value="selectedChannel"
							:items="channelOptions"
							searchable
							label="Channel *"
							disabled
						/>
						<PrimaryActionButton
							@click="showManageModal = true"
						>
							Add
						</PrimaryActionButton>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<DataTable
							:headers="tableHeaders"
							:items="extendedPreferences"
						>
							<template #item.extendedPreferenceName="{ item }">
								{{ displayExtendedPreferenceName(item.keyId) }}
							</template>
							<template #item.action="{ item }">
								<IconButton
									@click="editExtendedPreference(item)"
								>
									mdi-pencil
								</IconButton>
								<IconButton
									@click="deleteExtendedPreference(item)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<ActionLinkExtendedPreferencesManageModal
			v-if="showManageModal"
			:extended-preference-to-edit="extendedPreferenceToEdit"
			:extended-preference-keys="extendedPreferenceKeys"
			:action-link-extended-preferences="extendedPreferences"
			:selected-channel-item="selectedChannelItem"
			@close="closeManageModal"
			@submit="submitExtendedPreference"
		/>
	</div>
</template>
<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import ActionLinkExtendedPreferencesManageModal from './action-link-extended-preferences-manage-modal.vue'
import { getExtendedPreferences } from '../../../../../shared/utils/api/extended-preferences.js'
export default {
	components: {
		SectionCard,
		DataTable,
		Dropdown,
		PrimaryActionButton,
		IconButton,
		ActionLinkExtendedPreferencesManageModal
	},
	props: {
		selectedChannel: Number,
		channels: Array,
		extendedPreferences: Array,
		userFullPermissions: Boolean
	},
	data () {
		return {
			showExtendedPreferences: false,
			extendedPreferenceKeys: [],
			extendedPreferenceFieldTypes: [],
			showManageModal: false,
			extendedPreferenceToEdit: null
		}
	},
	computed: {
		tableHeaders () {
			let tableHeaders = [
				{ text: 'Channel', value: 'channelName', width: '15%' },
				{ text: 'Extended Preference', value: 'extendedPreferenceName', width: '15%' },
				{ text: 'Value', value: 'defaultFreeTextValue', width: '10%' },
				{ text: 'Date Created', value: 'formattedDateCreated', width: '10%' },
				{ text: 'Action', value: 'action', width: '10%', sortable: false }
			]

			if (!this.userFullPermissions) {
				tableHeaders = tableHeaders.filter(header => header.value !== 'action')
			}

			return tableHeaders
		},
		selectedChannelItem () {
			return this.channels.find(channel => channel.channelId === this.selectedChannel)
		},
		channelOptions () {
			return this.channels.map(channel => ({
				...channel,
				value: channel.channelId,
				text: channel.channelName
			}))
		}
	},
	created () {
		this.loadExtendedPreferences()
		if (this.extendedPreferences.length >= 1) this.showExtendedPreferences = true
	},
	methods: {
		async loadExtendedPreferences () {
			const { extendedPreferences, lookupData } = await getExtendedPreferences()
			this.extendedPreferenceKeys = extendedPreferences
			this.extendedPreferenceFieldTypes = lookupData.extendedPreferenceFieldTypes
		},
		closeManageModal () {
			this.showManageModal = false
			this.extendedPreferenceToEdit = null
		},
		editExtendedPreference (extendedPreference) {
			this.extendedPreferenceToEdit = extendedPreference
			this.showManageModal = true
		},
		deleteExtendedPreference (extendedPreference) {
			const extendedPreferences = [...this.extendedPreferences]
			this.$delete(extendedPreferences, extendedPreference.index)
			this.$emit('update-extended-preferences', extendedPreferences)
		},
		submitExtendedPreference (extendedPreferences) {
			this.$emit('update-extended-preferences', extendedPreferences)
			this.closeManageModal()
		},
		displayExtendedPreferenceName (keyId) {
			const extendedPreference = this.extendedPreferenceKeys.find(extendedPreference => extendedPreference.extendedPreferenceId === keyId)
			return extendedPreference?.name
		}
	}
}
</script>
