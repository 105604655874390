<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Preference Page Configuration
			</template>
			<template #body>
				<v-row dense>
					<v-col
						cols="6"
					>
						<TextField
							:value="pageTitle"
							label="Page Title *"
							:tooltip-text="`
								<p>The Title of the Preference Page</p>
								<p>This text populates the 'page-title' div on the Preference Page</p>
								`
							"
							:disabled="!userFullPermissions"
							:rules="{required: true, max: 150}"
							@input="updatePageContents({
								...(pageContents),
								pageTitle: $event
							})"
						/>
					</v-col>
					<v-col
						cols="6"
					>
						<Dropdown
							:value="dataSubjectIdentifierDisplayField"
							label="Data Subject Identifier Display Field *"
							:tooltip-text="`
								<p>Select a field from the Cassie database to display to the Data Subject to show the page is unique to that user</p>
								<p>This dynamic field populates the 'custom-identifier' div on the Preference Page</p>
								`
							"
							:disabled="!userFullPermissions"
							:items="availableFields"
							custom-sort
							searchable
							rules="required"
							@input="updatePageContents({
								...(pageContents),
								dataSubjectIdentifierDisplayField: $event
							})"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col
						cols="6"
					>
						<Dropdown
							:value="headerId"
							:items="filteredHeaderOptions"
							:disabled="!userFullPermissions"
							clearable
							custom-sort
							searchable
							label="Header"
							:tooltip-text="`
								<p>An optional Header on the Preference Page</p>
								<p>This text populates the 'page-header' div on the Preference Page</p>
								`
							"
							@input="updatePageContents({
								...(pageContents),
								headerId: $event
							})"
						/>
					</v-col>
					<v-col
						v-if="userFullPermissions"
						cols="6"
						class="cassie-horizontal-sm"
					>
						<TextButton @click="openCreateHeaderFooter('Header')">
							<v-icon>
								mdi-plus
							</v-icon>
							Create Header
						</TextButton>
						<TextButton
							v-if="headerId"
							@click="openEditHeaderFooterForm('header', headerId)"
						>
							<v-icon>
								mdi-pencil
							</v-icon>
							Edit Selected Header
						</TextButton>
					</v-col>
				</v-row>
				<TextArea
					:value="pageText"
					label="Page Text"
					:tooltip-text="`
								<p>The Page Text to be displayed at the top of the Preference Page by default.  This can be used to give context to the data displayed on the page</p>
								<p>This text populates the 'page-freetext' div on the Preference Page</p>
								`
					"
					:rules="{max: 1000}"
					:disabled="!userFullPermissions"
					@input="updatePageContents({
						...(pageContents),
						pageText: $event
					})"
				/>
				<v-row dense>
					<v-col
						cols="6"
					>
						<TextField
							:value="mainPreferencesAreaTitle"
							label="Preferences Area Title *"
							:tooltip-text="`
								<p>The Title of the 'Main' Preference area on the Preference Page</p>
								<p>This text populates the 'main-group__name' div on the Preference Page</p>
								`
							"
							:disabled="!userFullPermissions"
							:rules="{ required: true, max: 50 }"
							@input="updatePageContents({
								...(pageContents),
								mainPreferencesAreaTitle: $event
							})"
						/>
					</v-col>
				</v-row>
				<TextArea
					:value="mainPreferencesAreaText"
					label="Preferences Area Text"
					:tooltip-text="`
								<p> The Preferences Area Text to be displayed at the top of the 'Main' Preference Area on the Preference Page by default.</p>
								<p>This text populates the 'main-group__description' div on the Preference Page</p>
								`
					"
					:disabled="!userFullPermissions"
					:rules="{max: 150}"
					@input="updatePageContents({
						...(pageContents),
						mainPreferencesAreaText: $event
					})"
				/>
				<v-row
					dense
					no-gutters
					class="align-center"
				>
					<v-col
						cols="6"
					>
						<Toggle
							:value="enableAdditionalSubArea1 || subPreferenceArea1title"
							:disabled="additionalSubAreaUsedInPreferences(SUB_AREA_1_ID) || !userFullPermissions"
							label="Enable Additional Sub Area 1"
							:tooltip-text="`
								<p>Enable an Additional Sub Area on the Preference Page so that Statements can be displayed in separate sections</p>
								<p>This will create a new div on the page named 'sub-group'</p>
								`
							"
							hide-details
							dense
							class="ma-0"
							@update:value="value => {
								enableAdditionalSubArea1 = value
								if (!value) {
									updatePageContents({
										...(pageContents),
										subPreferenceArea1title: null
									})
								}
							}"
						/>
					</v-col>
					<v-col
						cols="6"
					>
						<TextField
							v-if="enableAdditionalSubArea1 || subPreferenceArea1title"
							:value="subPreferenceArea1title"
							:disabled="!userFullPermissions"
							label="Sub Area 1 Title *"
							:tooltip-text="`
								<p>The title of the first additional Sub Area on the Preference Page</p>
								<p>This text populates the 'sub-group__title' div on the Preference Page</p>
								`
							"
							:rules="{required: true, max: 50}"
							@input="updatePageContents({
								...(pageContents),
								subPreferenceArea1title: $event
							})"
						/>
					</v-col>
				</v-row>
				<v-row
					dense
					no-gutters
					class="align-center"
				>
					<v-col
						cols="6"
					>
						<Toggle
							:value="enableAdditionalSubArea2 || subPreferenceArea2title"
							:disabled="additionalSubAreaUsedInPreferences(SUB_AREA_2_ID) || !userFullPermissions"
							label="Enable Additional Sub Area 2"
							:tooltip-text="`
								<p>The title of the second additional Sub Area on the Preference Page.</p>
								<p>This text populates the 'sub-group__title' div on the Preference Page</p>
								`
							"
							hide-details
							dense
							class="ma-0"
							@update:value="value => {
								enableAdditionalSubArea2 = value
								if (!value) {
									updatePageContents({
										...(pageContents),
										subPreferenceArea2title: null
									})
								}
							}"
						/>
					</v-col>
					<v-col
						cols="6"
					>
						<TextField
							v-if="enableAdditionalSubArea2 || subPreferenceArea2title"
							:value="subPreferenceArea2title"
							:disabled="!userFullPermissions"
							label="Sub Area 2 Title *"
							:tooltip-text="`
								<p>The title of the second additional Sub Area on the Preference Page.</p>
								<p>This text populates the 'sub-group__title' div on the Preference Page</p>
								`
							"
							:rules="{required: true, max: 50}"
							@input="updatePageContents({
								...(pageContents),
								subPreferenceArea2title: $event
							})"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col
						cols="6"
					>
						<Dropdown
							:value="footerId"
							:items="filteredFooterOptions"
							custom-sort
							clearable
							searchable
							:disabled="!userFullPermissions"
							label="Preferences Section Footer"
							:tooltip-text="`
								<p>The footer which sits at the bottom of the Preferences section.</p>
								<p>This text populates the 'footer-text' div on the Preference Page</p>
								`
							"
							@input="updatePageContents({
								...(pageContents),
								footerId: $event
							})"
						/>
					</v-col>
					<v-col
						v-if="userFullPermissions"
						cols="6"
						class="cassie-horizontal-sm"
					>
						<TextButton @click="openCreateHeaderFooter('Footer')">
							<v-icon>
								mdi-plus
							</v-icon>
							Create Footer
						</TextButton>
						<TextButton
							v-if="footerId"
							@click="openEditHeaderFooterForm('footer', footerId)"
						>
							<v-icon>
								mdi-pencil
							</v-icon>
							Edit Selected Footer
						</TextButton>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Behavior
			</template>
			<template #body>
				<Toggle
					:value="preferenceOptionsAreTrinary"
					:disabled="!userFullPermissions"
					hide-details
					dense
					label="Preference answer options are trinary (i.e. Opt-In, Opt-Out & No Change)"
					tooltip-text="The Standard 'Opt-In' & 'Opt-Out' preference choices will have a 3rd option named 'No Change' if the Data Subject has made a previous Preference update"
					class="ma-0"
					@update:value="updatePageSettings({
						...(pageSettings),
						preferenceOptionsAreTrinary: $event
					})"
				/>
				<Toggle
					:value="hideCurrentPrefData"
					:disabled="!userFullPermissions"
					hide-details
					dense
					class="ma-0"
					:label="`
						Don't load the data subject's current preferences i.e. default all Preferences to <b class='ml-1'>${preferenceOptionsAreTrinary ? ' No Change' : ' Opt-Out'}</b>
					`"
					:tooltip-text="`
								<p>By default, if a Data Subject accesses this Preference Page, their existing Preferences are displayed.  </p>
								<p>If enabled, this option will default to 'Opt-Out', but will default to 'No Change' if the trinary options are also enabled</p>
								`
					"
					@update:value="updatePageSettings({
						...(pageSettings),
						hideCurrentPrefData: $event
					})"
				/>
				<Toggle
					:value="onlyAllowPreferenceOptOuts"
					:disabled="!userFullPermissions"
					hide-details
					dense
					label="Only allow data subjects to Opt-Out of preferences"
					tooltip-text="This option restricts the Data Subject so that they can only 'Opt-Out' of Preferences on this Preference Page"
					class="ma-0"
					@update:value="updatePageSettings({
						...(pageSettings),
						onlyAllowPreferenceOptOuts: $event
					})"
				/>
				<Toggle
					:value="showChannelName"
					:disabled="!userFullPermissions"
					hide-details
					dense
					:label="'Show Channel Name above Statements' | useLabels"
					tooltip-text="As well as the configured Statement, enabling this option will display the name of the Channel that the Data Subject is making a Preference against"
					class="ma-0"
					@update:value="updatePageSettings({
						...(pageSettings),
						showChannelName: $event
					})"
				/>
			</template>
		</SectionCard>
		<LinkPreferencePageStepPreferenceCard
			:page-preferences="pagePreferences"
			:page-contents="pageContents"
			:brand-id="brandId"
			@update:pageContents="updatePageContents"
			@update:pagePreferences="$emit('update:pagePreferences', $event)"
		/>
		<ExtendedPreferencesCard
			:preferences="pagePreferences"
			:extended-preferences="extendedPrefs"
			parent-type="Link"
			@update:extendedPreferences="$emit('update:extendedPrefs', $event)"
			@extended-prefs-changed="$emit('extended-prefs-changed')"
		/>
		<HeaderFooterFormModal
			v-if="showHeaderFooterForm"
			:type-id="headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS"
			:form-to-edit="headerFooterFormData"
			:selected-brand-id="brandId"
			:form-type="selectedFormType"
			@updated="getPreferencePageData"
			@set-selected-header:headerId="setSelectedHeaderFooter('header', $event)"
			@set-selected-footer:footerId="setSelectedHeaderFooter('footer', $event)"
			@close="() => {
				headerFooterFormData = null
				showHeaderFooterForm = false
			}"
		/>
	</div>
</template>

<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextArea from '../../../../../shared/components/textarea.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import { getStandakFields } from '../../../../../shared/utils/api/configuration.js'
import { getFooters, getHeaders } from '../../../../../shared/utils/api/headers-and-footers.js'
import { headersAndFootersTypeIdEnum } from '../../../../../shared/enums/headers-and-footers.js'
import LinkPreferencePageStepPreferenceCard from './link-preference-page-step-preference-card.vue'
import ExtendedPreferencesCard from '../../shared/extended-preferences-card.vue'
import HeaderFooterFormModal from '../../shared/header-footer-form-modal.vue'

const UNIVERSAL_BRAND = 0
const SUB_AREA_1_ID = 1
const SUB_AREA_2_ID = 2
export default {
	components: {
		LinkPreferencePageStepPreferenceCard,
		TextArea,
		Dropdown,
		TextField,
		SectionCard,
		TextButton,
		HeaderFooterFormModal,
		ExtendedPreferencesCard,
		Toggle
	},
	props: {
		pageContents: Object,
		pageSettings: Object,
		pagePreferences: Array,
		extendedPrefs: Array,
		brandId: Number,
		userFullPermissions: Boolean
	},
	data () {
		return {
			headersAndFootersTypeIdEnum,
			availableFields: [],
			enableAdditionalSubArea1: false,
			enableAdditionalSubArea2: false,
			showHeaderFooterForm: false,
			headerFooterFormData: null,
			UNIVERSAL_BRAND,
			headers: [],
			footers: [],
			selectedFormType: null,
			SUB_AREA_1_ID,
			SUB_AREA_2_ID
		}
	},
	computed: {
		pageText () {
			return this.pageContents?.pageText
		},
		mainPreferencesAreaText () {
			return this.pageContents?.mainPreferencesAreaText
		},
		mainPreferencesAreaTitle () {
			return this.pageContents?.mainPreferencesAreaTitle
		},
		headerId () {
			return this.pageContents?.headerId
		},
		footerId () {
			return this.pageContents?.footerId
		},
		dataSubjectIdentifierDisplayField () {
			return this.pageContents?.dataSubjectIdentifierDisplayField
		},
		pageTitle () {
			return this.pageContents?.pageTitle
		},
		subPreferenceArea1title () {
			return this.pageContents?.subPreferenceArea1title
		},
		subPreferenceArea2title () {
			return this.pageContents?.subPreferenceArea2title
		},
		preferenceOptionsAreTrinary () {
			return this.pageSettings?.preferenceOptionsAreTrinary
		},
		hideCurrentPrefData () {
			return this.pageSettings?.hideCurrentPrefData
		},
		onlyAllowPreferenceOptOuts () {
			return this.pageSettings?.onlyAllowPreferenceOptOuts
		},
		showChannelName () {
			return this.pageSettings?.showChannelName
		},
		filteredHeaderOptions () {
			if (this.headers) {
				const headers = this.headers.map(({ id, name, brandId }) => ({ value: id, text: name, brandId })).sort((a, b) => a.text.localeCompare(b.text))
				let filteredHeaders = []
				if (this.brandId === UNIVERSAL_BRAND) {
					filteredHeaders = headers.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
					return filteredHeaders
				} else {
					filteredHeaders = headers.filter(({ brandId }) => brandId === this.brandId || brandId === UNIVERSAL_BRAND)
					return filteredHeaders
				}
			} else {
				return []
			}
		},
		filteredFooterOptions () {
			if (this.footers) {
				const footers = this.footers.map(({ id, name, brandId }) => ({ value: id, text: name, brandId })).sort((a, b) => a.text.localeCompare(b.text))
				let filteredFooters = []
				if (this.brandId === UNIVERSAL_BRAND) {
					filteredFooters = footers.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
					return filteredFooters
				} else {
					filteredFooters = footers.filter(({ brandId }) => brandId === this.brandId || brandId === UNIVERSAL_BRAND)
					return filteredFooters
				}
			} else {
				return []
			}
		}
	},
	created () {
		this.getPreferencePageData()
	},
	methods: {
		async getPreferencePageData () {
			const [{ data: availableFields }, { data: headers }, { data: footers }] = await Promise.all([
				getStandakFields(),
				getHeaders(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS),
				getFooters(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS)
			])
			this.availableFields = availableFields.map(({ columnName }) => ({ value: columnName, text: columnName }))
			this.headers = headers
			this.footers = footers
		},
		additionalSubAreaUsedInPreferences (subAreaId) {
			return this.pagePreferences.find(({ preferencesAreaId }) => preferencesAreaId === subAreaId)
		},
		updatePageContents (pageContents) {
			this.$emit('update:pageContents', pageContents)
		},
		updatePageSettings (pageSettings) {
			this.$emit('update:pageSettings', pageSettings)
		},
		close () {
			this.$emit('close')
		},
		openCreateHeaderFooter (formType) {
			this.showHeaderFooterForm = true
			this.selectedFormType = formType
		},
		openEditHeaderFooterForm (type, headerFooterId) {
			this.showHeaderFooterForm = true
			if (type === 'header') {
				const matchingHeader = this.headers.find(({ id }) => id === headerFooterId)
				matchingHeader.brand = matchingHeader.brandId
				this.headerFooterFormData = matchingHeader
			} else if (type === 'footer') {
				const matchingFooter = this.footers.find(({ id }) => id === headerFooterId)
				matchingFooter.brand = matchingFooter.brandId
				this.headerFooterFormData = matchingFooter
			}
		},
		setSelectedHeaderFooter (type, id) {
			if (type === 'header') {
				this.updatePageContents({
					...this.pageContents,
					headerId: id
				})
			} else if (type === 'footer') {
				this.updatePageContents({
					...this.pageContents,
					footerId: id
				})
			}
		}
	}
}
</script>
