<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title />
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<TextField
							:value.sync="cookieBanner.name"
							label="Name *"
							:rules="{required: true, max: 255}"
							:disabled="!userCanCreateUpdate"
							@input="updateBanner('name', $event)"
						/>
					</v-col>
					<v-col cols="6">
						<Dropdown
							:value.sync="cookieBanner.brandId"
							:disabled="isEdit || cloneMode || !userCanCreateUpdate || !clientMultiBranded || singleBrand"
							:items="brandItems"
							custom-sort
							label="Brand*"
							searchable
							:rules="{ required: clientMultiBranded}"
							@input="updateBanner('brandId', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							:value.sync="cookieBanner.accessKeyId"
							:items="accessKeyItems"
							label="Access Key *"
							rules="required"
							:disabled="!userCanCreateUpdate"
							@input="updateBanner('accessKeyId', $event)"
						/>
					</v-col>
					<v-col
						v-if="userCanCreateUpdate"
						cols="6"
					>
						<TextButton @click="showAccessKeyModal = true">
							<v-icon>
								mdi-plus
							</v-icon>
							Create
						</TextButton>
						<TextButton
							:disabled="!cookieBanner.accessKeyId"
							@click="openEditAccessKeyModal"
						>
							<v-icon>
								mdi-pencil
							</v-icon>
							Edit Selected Access Key
						</TextButton>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							:value.sync="cookieBanner.privacyPolicyId"
							:items="activePrivacyPolicies"
							searchable
							custom-sort
							label="Privacy Policy *"
							rules="required"
							:disabled="!userCanCreateUpdate"
							@input="updateBanner('privacyPolicyId', $event)"
						/>
					</v-col>
					<v-col
						v-if="userCanCreateUpdate"
						cols="6"
					>
						<TextButton @click="showPrivacyPolicyModal = true">
							<v-icon>
								mdi-plus
							</v-icon>
							Create
						</TextButton>

						<TextButton
							:disabled="!cookieBanner.privacyPolicyId"
							@click="openEditPrivacyPolicyModal"
						>
							<v-icon>
								mdi-pencil
							</v-icon>
							Edit Selected Privacy Policy
						</TextButton>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="3">
						<TextField
							:value.sync="cookieBanner.cassieCookiesExpiryInDays"
							label="Cassie Cookies Expiry (in days) *"
							tooltip-text="Cookie expiry times are not guaranteed as they are dependent on specific browser behaviour."
							type="number"
							:rules="{required: true, numeric: true}"
							:disabled="!userCanCreateUpdate"
							@input="updateBanner('cassieCookiesExpiryInDays', $event)"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<CookieBannerTemplateSelectorCards
			:cookie-banner="cookieBanner"
			:cookie-appearance-templates="cookieAppearanceTemplates"
			:cookie-text-templates="cookieTextTemplates"
			:cookie-collection-templates="cookieCollectionTemplates"
			:update-banner="updateBanner"
			:is-edit="isEdit"
			:clone-mode="cloneMode"
			:user-can-create-update="userCanCreateUpdate"
			@show-cookie-text-manage-screen="$emit('show-cookie-text-manage-screen')"
			@open-edit-text-template-screen="$emit('open-edit-text-template-screen', $event)"
			@show-cookie-collection-manage-screen="$emit('show-cookie-collection-manage-screen')"
			@open-edit-collection-template-screen="$emit('open-edit-collection-template-screen', $event)"
			@show-cookie-appearance-manage-screen="$emit('show-cookie-appearance-manage-screen')"
			@open-edit-appearance-template-screen="$emit('open-edit-appearance-template-screen', $event)"
		/>
		<SectionCard>
			<template #title>
				Allowed Domains
			</template>
			<template #subtitle>
				Specify whether this Cookie Banner can be used on all
				domains (potential security risk) or only on specific
				ones
			</template>
			<template #title-action>
				<Toggle
					:value.sync="cookieBanner.allowedDomainsValue"
					label-left
					:toggle-labels="['Custom List', 'Allow All']"
					:disabled="!userCanCreateUpdate || cdcCookieCollectionSelected"
					@update:value="updateBanner('allowedDomainsValue', $event)"
				/>
			</template>
			<template
				v-if="cookieBanner.allowedDomainsValue === false"
				#body
			>
				<OptionSelector
					v-if="showAddDomainSelector"
					:selected="cookieBanner.allowedDomains"
					entity-type="Domain"
					is-text
					:disabled="!userCanCreateUpdate"
					:required="cookieBanner.allowedDomains.length === 0"
					@update:selected="updateBanner('allowedDomains', $event)"
				/>
			</template>
		</SectionCard>
		<CookieBannerCisCard
			v-if="cassieIdentityServiceEnabled"
			:cookie-banner="cookieBanner"
			:cookie-banner-scripts="cookieBannerScripts"
			:update-banner="updateBanner"
			:cdc-cookie-collection-selected="cdcCookieCollectionSelected"
			:allowed-domains="cookieBanner.allowedDomains"
			:user-can-create-update="userCanCreateUpdate"
		/>
		<SectionCard v-if="showPreview">
			<template #title>
				Preview
			</template>
			<template #body>
				<CookieBannerPreview
					:cookie-appearance-template="selectedAppearanceTemplate"
					:cookie-text-template="selectedTextTemplate"
					:cookie-collection-template="selectedCollectionTemplate"
				/>
			</template>
		</SectionCard>
		<CookieBannerAccessKeyModal
			v-if="showAccessKeyModal"
			:brand-items="brandItems"
			:access-key-to-edit="accessKeyToEdit"
			@close="closeAccessKeyModal"
			@submit="submitAccessKey"
			@get-new-access-key-id="addNewAccessKeyToBanner"
		/>
		<CookieBannerPrivacyPolicyModal
			v-if="showPrivacyPolicyModal"
			:brand-items="brandItems"
			:privacy-policy-to-edit="privacyPolicyToEdit"
			@close="closePrivacyPolicyModal"
			@submit="submitPrivacyPolicy"
			@get-new-privacy-policy-id="addNewPrivacyPolicyToBanner"
		/>
	</div>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import OptionSelector from '../../../../../../../shared/components/option-selector.vue'
import TextButton from '../../../../../../../shared/components/text-button.vue'
import CookieBannerCisCard from './cookie-banner-cis-card.vue'
import CookieBannerTemplateSelectorCards from './cookie-banner-template-selector-cards.vue'
import CookieBannerPreview from '../cookie-banner-preview/cookie-banner-preview.vue'
import CookieBannerAccessKeyModal from './cookie-banner-access-key-modal.vue'
import CookieBannerPrivacyPolicyModal from './cookie-banner-privacy-policy-modal.vue'
import { clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM, singleBrand } from '../../../../../../../shared/state/brands.js'
import { cassieIdentityServiceEnabled } from '../../../../../../../shared/state/config-keys.js'
import { activePrivacyPolicies, updatePrivacyPolicies } from '../../../../../../../shared/state/privacy-policies.js'
import { getConsentBannerAccessKeys } from '../../../../../../../shared/utils/api/access-keys.js'
import { getCookieScripts } from '../../../../../../../shared/utils/api/cookies/cookie-scripts.js'
import { getCookieCategories } from '../../../../../../../shared/utils/api/cookies/cookie-categories.js'
import { getFooters, getHeaders } from '../../../../../../../shared/utils/api/headers-and-footers.js'
import { headersAndFootersTypeIdEnum } from '../../../../../../../shared/enums/headers-and-footers.js'
import { COOKIE_COLLECTION_TEMPLATE } from '../cookie-appearances-templates/cookie-banner-preview-default-values.js'
export default {
	components: {
		SectionCard,
		TextField,
		Dropdown,
		Toggle,
		OptionSelector,
		TextButton,
		CookieBannerCisCard,
		CookieBannerTemplateSelectorCards,
		CookieBannerPreview,
		CookieBannerAccessKeyModal,
		CookieBannerPrivacyPolicyModal
	},
	props: {
		cookieBanner: Object,
		isEdit: {
			type: Boolean,
			default: false
		},
		cloneMode: {
			type: Boolean,
			default: false
		},
		cookieTextTemplates: Array,
		cookieAppearanceTemplates: Array,
		cookieCollectionTemplates: Array,
		cdcCookieCollectionSelected: Boolean,
		userCanCreateUpdate: Boolean
	},
	setup () {
		return {
			brandOptions,
			activePrivacyPolicies,
			cassieIdentityServiceEnabled,
			headersAndFootersTypeIdEnum,
			COOKIE_COLLECTION_TEMPLATE,
			updatePrivacyPolicies,
			clientMultiBranded,
			singleBrand
		}
	},
	data () {
		return {
			cookieAccessKeys: [],
			cookieScripts: [],
			cookieCategories: [],
			headers: [],
			footers: [],
			showAccessKeyModal: false,
			accessKeyToEdit: null,
			showPrivacyPolicyModal: false,
			privacyPolicyToEdit: null,
			showManageCollectionTemplateScreen: false,
			showManageTextTemplateScreen: false,
			cookieTextTemplateToEdit: null,
			showManageAppearanceTemplateScreen: false
		}
	},
	computed: {
		brandItems () {
			// ensures when viewing universal object it displays in dropdown
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		},
		accessKeyItems () {
			return this.cookieAccessKeys.map(accessKey => {
				return {
					text: accessKey.accessKeyName,
					value: accessKey.accessKeyId
				}
			}).sort((a, b) => a.text.localeCompare(b.text))
		},
		allowedDomainItems () {
			return [
				{ text: 'Allow All', value: true },
				{ text: 'Custom List', value: false }
			]
		},
		showAddDomainSelector () {
			return this.cookieBanner.allowedDomainsValue === false
		},
		cookieBannerScripts () {
			const cookieBannerScripts = this.cookieCollectionTemplates.find(({ id }) => id === this.cookieBanner.cookieCollectionTemplateId)?.cookieScripts
			if (cookieBannerScripts) {
				return cookieBannerScripts.map(script => {
					const matchingCookieScript = this.cookieScripts.find(({ id }) => id === script.id)
					return {
						...matchingCookieScript
					}
				})
			} else return []
		},
		showPreview () {
			return this.selectedCollectionTemplate?.cookieCategories && this.selectedTextTemplate && this.selectedAppearanceTemplate
		},
		selectedAppearanceTemplate () {
			return this.cookieAppearanceTemplates?.find(({ id }) => id === this.cookieBanner?.cookieAppearanceTemplateId)
		},
		selectedTextTemplate () {
			const textTemplate = this.cookieTextTemplates?.find(({ id }) => id === this.cookieBanner.cookieTextTemplateId)
			if (textTemplate) {
				if (textTemplate.headerId) textTemplate.headerHtml = this.headers?.find(({ id }) => id === textTemplate?.headerId)?.html
				if (textTemplate.footerId) textTemplate.footerHtml = this.footers?.find(({ id }) => id === textTemplate?.footerId)?.html
			}
			return textTemplate
		},
		selectedCollectionTemplate () {
			const collectionTemplate = this.cookieCollectionTemplates.find(({ id }) => id === this.cookieBanner.cookieCollectionTemplateId)
			const cookieCategories = collectionTemplate?.cookieCategories.map(category => ({
				name: this.cookieCategories?.find(({ id }) => id === category.id)?.categoryName,
				isStrictlyNecessary: this.cookieCategories?.find(({ id }) => id === category.id)?.isStrictlyCategory,
				description: this.cookieCategories?.find(({ id }) => id === category.id)?.displayText,
				cookies: collectionTemplate?.cookieScripts.map(script => ({
					name: this.cookieScripts?.find(({ id }) => id === script.id)?.cookieScriptName,
					cookieScriptType: this.cookieScripts?.find(({ id }) => id === script.id)?.cookieScriptType,
					description: this.cookieScripts?.find(({ id }) => id === script.id)?.displayText,
					gpcEnabled: this.cookieScripts?.find(({ id }) => id === script.id)?.gpcEnabled,
					categoryId: this.cookieScripts?.find(({ id }) => id === script.id)?.cookieCategoryId
				})).filter(({ categoryId }) => categoryId === category.id)
			}))
			return {
				cookieCategories: cookieCategories
			}
		},
		showManageCookieBannerScreen () {
			return !this.showManageCollectionTemplateScreen && !this.showManageTextTemplateScreen && !this.showManageAppearanceTemplateScreen
		}
	},
	watch: {
		cdcCookieCollectionSelected (cdcCollection) {
			if (cdcCollection) this.updateBanner('allowedDomainsValue', false)
		}
	},
	async created () {
		await Promise.all([
			this.loadAccessKeys(),
			this.getCookieScripts(),
			this.getCookieCategories(),
			this.loadHeadersFooters()
		])
	},
	methods: {
		async getCookieScripts () {
			const { cookieScripts } = await getCookieScripts()
			this.cookieScripts = cookieScripts
		},
		async getCookieCategories () {
			const { cookieCategories } = await getCookieCategories()
			this.cookieCategories = cookieCategories
		},
		async loadHeadersFooters () {
			const { data: headers } = await getHeaders(headersAndFootersTypeIdEnum.CONSENT_BANNERS)
			const { data: footers } = await getFooters(headersAndFootersTypeIdEnum.CONSENT_BANNERS)
			this.headers = headers ?? []
			this.footers = footers ?? []
		},
		updateBanner (property, value) {
			this.$emit('update:cookieBanner', {
				...this.cookieBanner,
				[property]: value
			})
		},
		async loadAccessKeys () {
			const { cookieAccessKeys } = await getConsentBannerAccessKeys()
			this.cookieAccessKeys = cookieAccessKeys
		},
		loadPrivacyPolicies () {
			updatePrivacyPolicies()
		},
		openEditAccessKeyModal () {
			this.showAccessKeyModal = true
			this.accessKeyToEdit = this.cookieAccessKeys.find(({ accessKeyId }) => accessKeyId === this.cookieBanner.accessKeyId)
		},
		closeAccessKeyModal () {
			this.showAccessKeyModal = false
			this.accessKeyToEdit = null
		},
		submitAccessKey () {
			this.closeAccessKeyModal()
			this.loadAccessKeys()
		},
		addNewAccessKeyToBanner (newAccessKeyId) {
			this.updateBanner('accessKeyId', newAccessKeyId)
		},
		openEditPrivacyPolicyModal () {
			this.showPrivacyPolicyModal = true
			const selectedPrivacyPolicy = activePrivacyPolicies.value.find(({ value }) => value === this.cookieBanner.privacyPolicyId)
			this.privacyPolicyToEdit = {
				privacyPolicyId: selectedPrivacyPolicy.value,
				privacyPolicyName: selectedPrivacyPolicy.text,
				privacyPolicyUrl: selectedPrivacyPolicy.policyUrl,
				externalVersionRef: selectedPrivacyPolicy.activeVersionName,
				isVisibleInCSPortal: selectedPrivacyPolicy.isVisibleInCSPortal,
				brandId: selectedPrivacyPolicy.brandId
			}
		},
		closePrivacyPolicyModal () {
			this.showPrivacyPolicyModal = false
			this.privacyPolicyToEdit = null
		},
		submitPrivacyPolicy () {
			this.closePrivacyPolicyModal()
			this.loadPrivacyPolicies()
		},
		addNewPrivacyPolicyToBanner (newPrivacyPolicyId) {
			this.updateBanner('privacyPolicyId', newPrivacyPolicyId)
		}
	}
}
</script>
