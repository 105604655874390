<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="apiAccessKeySearchQuery"
			search-query-label="Name"
			hide-brand-filter
			@persistSearchQuery="changeApiAccessKeySearchQuery"
		>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="$emit('create-access-key', accessKeyType)"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				API Access Keys
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredApiKeys"
					sort-by="apiKeyId"
					sort-desc
					@click:row="editAccessKey"
				>
					<template #item.action="{ item }">
						<IconButton
							tooltip-text="Copy Key To Clipboard"
							@click.stop.prevent="copyAccessKey(item.accessKey)"
						>
							mdi-content-copy
						</IconButton>
						<IconButton
							:tooltip-text="userFullPermissions ? 'Edit Access Key' : 'View Access Key'"
							@click="editAccessKey(item)"
						>
							{{ userFullPermissions && userHasBrand(item.brandId) ? 'mdi-pencil' : 'mdi-eye' }}
						</IconButton>
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandId)"
							tooltip-text="Delete Access Key"
							@click.stop.prevent="apiKeyToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="apiKeyToRemove"
			:entity-name="apiKeyToRemove.apiKeyName"
			entity-type="API Key"
			@close="apiKeyToRemove = null"
			@delete="removeKey"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { deleteApiKey } from '../../../../../../shared/utils/api/access-keys.js'
import { ACCESS_KEYS_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { brandOptions, userHasBrand } from '../../../../../../shared/state/brands.js'
import { changeApiAccessKeySearchQuery, apiAccessKeySearchQuery } from '../../../../../../shared/state/access-keys.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'
export default {
	components: { FilterBar, PrimaryActionButton, SectionCard, DataTable, ConfirmDeleteModal, IconButton },
	props: {
		accessKeyType: {
			type: Number,
			required: true
		},
		accessKeys: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			userHasBrand,
			showSnackbar,
			brandOptions,
			changeApiAccessKeySearchQuery,
			apiAccessKeySearchQuery
		}
	},
	data () {
		return {
			searchQuery: null,
			apiKeyToRemove: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		tableHeaders () {
			const tableHeaders = [
				{
					text: 'ID',
					value: 'apiKeyId',
					width: '5%'
				},
				{
					text: useLabels('Brand'),
					value: 'brandName',
					width: '10%'
				},
				{
					text: 'Name',
					value: 'apiKeyName',
					width: '15%'
				},
				{
					text: 'Access Key',
					value: 'apiKey',
					width: '20%'
				},
				{
					text: 'Description',
					value: 'apiKeyDescription',
					width: '20%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '10%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '10%',
					sortable: false
				}
			]
			return tableHeaders
		},
		filteredApiKeys () {
			return this.accessKeys.filter(({ apiKeyName }) => {
				let check = true
				if (apiAccessKeySearchQuery.value) check = apiKeyName.toLowerCase().includes(apiAccessKeySearchQuery.value.toLowerCase())
				return check
			}).map(apiKey => ({
				...apiKey,
				brandName: brandOptions.value.find(({ value }) => value === apiKey.brandId)?.text
			}))
		},
		userFullPermissions () {
			return this.productAreaPermission(ACCESS_KEYS_MODULE_FULL_PERMISSIONS)
		}
	},
	methods: {
		editAccessKey (accessKeyToEdit) {
			const accessKeyData = {
				accessKeyToEdit,
				accessKeyType: this.accessKeyType
			}
			this.$emit('edit-access-key', accessKeyData)
		},
		async removeKey () {
			await deleteApiKey(this.apiKeyToRemove.apiKey)
			this.apiKeyToRemove = null
			this.$emit('load-access-keys')
			showSnackbar('This API Key has been removed')
		},
		copyAccessKey (accessKey) {
			navigator.clipboard.writeText(accessKey)
			showSnackbar({ color: 'grey darken-1', text: 'Access Key copied to clipboard' })
		}
	}
}
</script>
