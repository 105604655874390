<template>
	<ValidationForm
		#default="{ handleSubmit }"
		style="width: 100%; max-width: 100%;"
		tag="div"
	>
		<ViewLayout>
			<template #header-title>
				Progressive Profiling
			</template>
			<template #header-caption>
				This module allows the configuration of a chain of Embedded Consent Collections to obtain first party data.
			</template>
			<template #header-after>
				<Stepper
					:step.sync="currentStep"
					:steps="steps"
					:in-progress="!isEdit"
				/>
			</template>
			<template
				v-if="displayScreen"
				#content
			>
				<StepperItems
					:step="currentStep"
					:steps="steps"
				>
					<template #0>
						<ProgressiveProfilePrimaryWidgetConfigurationStep
							:progressive-profile.sync="progressiveProfile"
							:user-can-create-and-update="userCanCreateAndUpdate"
							:is-edit="isEdit"
							:headers="headers"
							:footers="footers"
							:channels="channels"
							:preference-widgets="preferenceWidgetItems"
							:primary-preference-widget="primaryPreferenceWidget"
						/>
					</template>
					<template #1>
						<ProgressiveProfileSecondaryActionsStep
							:progressive-profile.sync="progressiveProfile"
							:user-can-create-and-update="userCanCreateAndUpdate"
							:headers="headers"
							:footers="footers"
							:channels="channels"
							:preference-widgets="preferenceWidgetItems"
							:primary-preference-widget="primaryPreferenceWidget"
						/>
					</template>
					<template #2>
						<ProgressiveProfileFinalActionsStep
							:progressive-profile.sync="progressiveProfile"
							:user-can-create-and-update="userCanCreateAndUpdate"
							:headers="headers"
							:footers="footers"
							:channels="channels"
							:preference-widgets="preferenceWidgetItems"
							:primary-preference-widget="primaryPreferenceWidget"
						/>
					</template>
					<template #3>
						<ProgressiveProfileAppearanceStep
							:progressive-profile.sync="progressiveProfile"
							:user-can-create-and-update="userCanCreateAndUpdate"
							:is-edit="isEdit"
							:headers="headers"
							:footers="footers"
							:channels="channels"
							:preference-widgets="preferenceWidgetItems"
							:primary-preference-widget="primaryPreferenceWidget"
							:banner-locations="bannerLocations"
							:execution-options="executionOptions"
							:display-types="displayTypes"
						/>
					</template>
					<template #4>
						<ProgressiveProfileEmbedCodeStep
							:progressive-profile.sync="progressiveProfile"
							:enable-cassie-cloud-embed-code="enableCassieCloudEmbedCode"
						/>
					</template>
				</StepperItems>
			</template>
			<template
				v-if="displayScreen"
				#footer
			>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="navigateToOverview">
							Back to {{ accessedViaHub ? 'Consent Collection' : 'Overview' }}
						</SecondaryActionButton>
						<v-spacer />
						<SecondaryActionButton
							v-if="currentStep !== 0"
							@click="previousStep"
						>
							Previous
						</SecondaryActionButton>
						<PrimaryActionButton
							v-if="userCanCreateAndUpdate && !isEdit && currentStep === 3"
							class="ml-2"
							@click="handleSubmit(createProgressiveProfile)"
						>
							Create
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="userCanCreateAndUpdate && isEdit && currentStep !== 4"
							class="ml-2"
							@click="handleSubmit(updateProgressiveProfile)"
						>
							Save Changes
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="currentStep !== 3 && currentStep !== 4"
							class="ml-2"
							@click="nextStep"
						>
							Next
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
	</ValidationForm>
</template>
<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import Stepper from '../../../../../../shared/components/stepper.vue'
import StepperItems from '../../../../../../shared/components/stepper-items.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import ProgressiveProfilePrimaryWidgetConfigurationStep from './progressive-profile-primary-widget-configuration-step.vue'
import ProgressiveProfileSecondaryActionsStep from './progressive-profile-secondary-actions-step.vue'
import ProgressiveProfileFinalActionsStep from './progressive-profile-final-actions-step.vue'
import ProgressiveProfileAppearanceStep from './progressive-profile-appearance-step.vue'
import ProgressiveProfileEmbedCodeStep from './progressive-profile-embed-code-step.vue'
import { PROGRESSIVE_PROFILING, CONSENT_COLLECTION } from '../../../../router/route-names.js'
import { getPreferenceWidgets } from '../../../../../../shared/utils/api/preference-widgets.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import { getPreferenceChannels } from '../../../../../../shared/utils/api/channels.js'
import { getFooters, getHeaders } from '../../../../../../shared/utils/api/headers-and-footers.js'
import { defaultBrandInSelect, UNIVERSAL_BRAND } from '../../../../../../shared/state/brands.js'
import { headersAndFootersTypeIdEnum } from '../../../../../../shared/enums/headers-and-footers.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { postProgressiveProfile, putProgressiveProfile } from '../../../../../../shared/utils/api/progressive-profiling.js'
import { COLOUR_SCHEMES } from './profile-appearance.js'
import { enableCassieCloudEmbedCode } from '../../../../../../shared/state/config-keys.js'
export default {
	components: {
		ValidationForm,
		ViewLayout,
		Stepper,
		StepperItems,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		ProgressiveProfilePrimaryWidgetConfigurationStep,
		ProgressiveProfileSecondaryActionsStep,
		ProgressiveProfileFinalActionsStep,
		ProgressiveProfileAppearanceStep,
		ProgressiveProfileEmbedCodeStep
	},
	props: {
		progressiveProfileToEdit: Object,
		userCanCreateAndUpdate: Boolean,
		accessedViaHub: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			defaultBrandInSelect,
			UNIVERSAL_BRAND,
			COLOUR_SCHEMES,
			showSnackbar,
			enableCassieCloudEmbedCode
		}
	},
	data () {
		return {
			currentStep: this.initialStep || 0,
			steps: [
				{ slot: 0, title: 'Primary Collection Configuration', stepNumber: '01', stepComplete: false },
				{ slot: 1, title: 'Secondary Actions', stepNumber: '02', stepComplete: false },
				{ slot: 2, title: 'Final Actions', stepNumber: '03', stepComplete: false },
				{ slot: 3, title: 'Appearance', stepNumber: '04', stepComplete: false },
				{ slot: 4, title: 'Embed Code', stepNumber: '05', stepComplete: false }
			],
			progressiveProfile: JSON.parse(JSON.stringify(this.progressiveProfileToEdit || {
				profileName: null,
				brandId: defaultBrandInSelect.value,
				description: null,
				primaryWidgetId: null,
				executionOptionId: null,
				profileBannerLocationId: null,
				timeOnPageInSeconds: 0,
				restrictToOneResponsePerWidget: true,
				displayTypeId: null,
				choices: [],
				colourSchemeJson: COLOUR_SCHEMES.LIGHT,
				cssSelector: '',
				restrictToOneWidgetPerVisit: true
			})),
			preferenceWidgets: [],
			channels: [],
			languages: [],
			headers: [],
			footers: [],
			displayScreen: false
		}
	},
	computed: {
		...mapGetters('lookupData', ['progressiveProfileLookupData']),
		initialStep () {
			return this.isEdit ? 1 : 4
		},
		isEdit () {
			return !!this.progressiveProfileToEdit
		},
		preferenceWidgetItems () {
			const preferenceWidgets = this.preferenceWidgets.map(prefWidget => ({
				...prefWidget,
				text: `${prefWidget.preferenceWidgetName} - ${prefWidget.brandName}`,
				value: prefWidget.preferenceWidgetId
			}))

			if (this.progressiveProfile.brandId === UNIVERSAL_BRAND) {
				return preferenceWidgets
			} else {
				return preferenceWidgets.filter(({ brandId }) => brandId === this.progressiveProfile.brandId || brandId === UNIVERSAL_BRAND)
			}
		},
		primaryPreferenceWidget () {
			return this.preferenceWidgets.find(({ preferenceWidgetId }) => preferenceWidgetId === this.progressiveProfile.primaryWidgetId)
		},
		defaultBehaviours () {
			return {
				displayTypeId: 1,
				executionOptionId: 1,
				profileBannerLocationId: 1
			}
		},
		bannerLocations () {
			return this.progressiveProfileLookupData.bannerLocations.map(location => ({
				text: location.bannerLocationName,
				value: location.bannerLocationId
			}))
		},
		executionOptions () {
			return this.progressiveProfileLookupData.executionOptions.map(option => ({
				text: option.executionOptionName,
				value: option.executionOptionId
			}))
		},
		displayTypes () {
			return this.progressiveProfileLookupData.displayTypes.map(option => ({
				text: option.displayTypeName,
				value: option.displayTypeId
			}))
		}
	},
	async created () {
		await this.loadPreferenceWidgets()
		await Promise.all([
			this.loadChannels(),
			this.loadLanguages(),
			this.loadHeadersAndFooters()
		])
		if (!this.isEdit) this.setDefaultBehaviours()
		if (!this.progressiveProfile.colourSchemeJson) this.progressiveProfile.colourSchemeJson = COLOUR_SCHEMES.LIGHT
		this.displayScreen = true
	},
	methods: {
		navigateToOverview () {
			if (this.accessedViaHub) {
				this.$router.push(CONSENT_COLLECTION)
			} else {
				this.$router.push({ name: PROGRESSIVE_PROFILING })
			}
		},
		setDefaultBehaviours () {
			this.progressiveProfile = {
				...this.progressiveProfile,
				...this.defaultBehaviours
			}
		},
		async loadPreferenceWidgets () {
			const { preferenceWidgets } = await getPreferenceWidgets()
			this.preferenceWidgets = preferenceWidgets
		},
		async loadChannels () {
			const { data: { channels } } = await getPreferenceChannels()
			this.channels = channels
		},
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.languages = assignedLanguages
		},
		async loadHeadersAndFooters () {
			const [
				{ data: headers },
				{ data: footers }
			] = await Promise.all([
				getHeaders(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS),
				getFooters(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS)
			])
			this.headers = headers
			this.footers = footers
		},
		async createProgressiveProfile () {
			await postProgressiveProfile(this.formatPayload(this.progressiveProfile)).then(response => {
				this.progressiveProfile.id = response.data.newProgressiveProfileId
			})
			showSnackbar('Progressive Profile created successfully.')
			this.navigateToEmbedCode()
		},
		async updateProgressiveProfile () {
			await putProgressiveProfile(this.formatPayload(this.progressiveProfile))
			showSnackbar('Progressive Profile updated successfully.')
			this.navigateToEmbedCode()
		},
		navigateToEmbedCode () {
			this.currentStep = 4
		},
		previousStep () {
			this.currentStep--
		},
		nextStep () {
			this.currentStep++
		},
		formatPayload (profile) {
			const formattedPayload = {
				...profile,
				restrictToOneResponsePerWidget: profile.restrictToOneResponsePerWidget,
				choices: profile.choices.map(choice => ({
					channelId: choice.channelId,
					widgetId: choice.widgetId,
					choices: choice.choices.map(subChoice => ({
						channelId: subChoice.channelId,
						widgetId: subChoice.widgetId,
						choices: []
					}))
				})),
				colourSchemeJson: JSON.stringify(profile.colourSchemeJson)
			}
			if (this.isEdit) {
				delete formattedPayload.brandId
				delete formattedPayload.brandName
				delete formattedPayload.createdBy
				delete formattedPayload.createdByName
				delete formattedPayload.createdDate
				delete formattedPayload.editedBy
				delete formattedPayload.editedByName
				delete formattedPayload.lastEditedDate
			}
			return formattedPayload
		}
	}
}
</script>
