<template>
	<ValidationForm
		#default="{ handleSubmit, invalid }"
	>
		<Modal
			width="850px"
		>
			<template #modal-title>
				<div class="cassie-vertical-sm">
					{{ modalTitle }}
				</div>
				<v-spacer />
				<IconButton
					@click="$emit('close')"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<Message
					v-if="isEdit && theme.cassieDefault"
					type="warning"
				>
					You cannot change the name or upload files for default themes
				</Message>
				<v-row>
					<v-col cols="6">
						<TextField
							v-model="theme.themeName"
							:disabled="readOnly"
							class="cassie-input-width-xl"
							label="Theme Name *"
							:rules="{required: true}"
						/>
					</v-col>
					<v-col cols="6">
						<TextField
							v-model="theme.logoUrl"
							:disabled="readOnly"
							label="Logo URL"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6">
						<TextField
							v-model="theme.logoLink"
							:disabled="readOnly"
							label="Logo Link"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6">
						<div class="font-weight-bold">
							Javascript<v-tooltip
								slot="append"
								top
							>
								<template #activator="{ on }">
									<v-icon
										light
										v-on="on"
									>
										mdi-information
									</v-icon>
								</template>
								<span>
									Upload your script.js file
								</span>
							</v-tooltip>
						</div>
						<validation-provider
							#default="{ errors }"
							rules="isValidScript:js"
						>
							<v-file-input
								v-model="theme.script"
								:disabled="readOnly"
								counter
								show-size
								label="Javascript"
								accept="text/javascript"
								truncate-length="50"
								:error-messages="errors.length ? [errors[0]] : []"
							/>
						</validation-provider>
					</v-col>
					<v-col cols="6">
						<div class="font-weight-bold">
							Style<v-tooltip
								slot="append"
								top
							>
								<template #activator="{ on }">
									<v-icon
										light
										v-on="on"
									>
										mdi-information
									</v-icon>
								</template>
								<span>
									Upload your style.css file
								</span>
							</v-tooltip>
						</div>
						<validation-provider
							#default="{ errors }"
							rules="isValidCss:css"
						>
							<v-file-input
								v-model="theme.style"
								:disabled="readOnly"
								counter
								show-size
								label="CSS (style.css)"
								accept=".css"
								truncate-length="50"
								:error-messages="errors.length ? [errors[0]] : []"
							/>
						</validation-provider>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6">
						<div class="font-weight-bold">
							Resources<v-tooltip
								slot="append"
								top
							>
								<template #activator="{ on }">
									<v-icon
										light
										v-on="on"
									>
										mdi-information
									</v-icon>
								</template>
								<span>
									Resource files must be less than 5MB in size. Allowed file formats: jpg, jpeg, png, gif, svg, tiff and ttf
								</span>
							</v-tooltip>
						</div>
						<validation-provider
							#default="{ errors }"
							rules="isValidResource:jpg,jpeg,png,gif,svg,tiff,ttf"
						>
							<v-file-input
								v-model="selectedResources"
								:disabled="readOnly"
								multiple
								counter
								show-size
								label="Resources"
								accept=".png, .jpg, .jpeg, .gif, .svg, .tiff, .ttf"
								:error-messages="errors.length ? [errors[0]] : []"
								truncate-length="50"
							/>
						</validation-provider>
						<v-col />
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<h4>
							Assets
						</h4>
						<p>
							Note files will be uploaded to your public portal folders in the following structure
						</p>
						<template v-if="!isEdit || readOnly">
							<p>
								Javascript: <br>
								/Themes/[ClientID]/[ThemeID]/js/script.js
								<a
									v-if="isEdit && theme.cassieDefault"
									@click="downloadExampleFile('js')"
								>
									Default Example
								</a>
							</p>
							<p>
								CSS: <br>
								/Themes/[ClientID]/[ThemeID]/css/style.css
								<a
									v-if="isEdit && theme.cassieDefault"
									@click="downloadExampleFile('css')"
								>
									Default Example
								</a>
							</p>
							<p>
								Resources: <br>
								/Themes/[ClientID]/[ThemeID]/resources/[file.ext]
							</p>
						</template>
						<DataTable
							v-else
							:headers="tableHeaders"
							:items="filteredResources"
							@click:row="downloadAsset"
						>
							<template #item.name="{ item }">
								<a
									:href="item.url"
									target="_blank"
									rel="noopener noreferrer"
								>
									{{ item.name }}
								</a>
							</template>
							<template #item.action="{ item }">
								<IconButton
									tooltip-text="Download File"
									@click.stop="downloadAsset(item)"
								>
									mdi-file-download
								</IconButton>
								<IconButton
									tooltip-text="Delete File"
									@click.stop.prevent="deleteAsset(item)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</v-col>
				</v-row>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton
					@click="$emit('close')"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="!isEdit || !readOnly"
					:disabled="invalid"
					@click="
						handleSubmit(submit)
					"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>
<script>
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Modal from '../../../../../shared/components/modal.vue'
import Message from '../../../../../shared/components/message.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import downloadFile from '../../../../../shared/mixins/download-file.js'
import { scriptExample, styleExample } from './samples.js'
import { deleteThemeAsset } from '../../../../../shared/utils/api/themes.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
export default {
	components: {
		ValidationForm,
		TextField,
		Modal,
		Message,
		PrimaryActionButton,
		SecondaryActionButton,
		IconButton,
		DataTable
	},
	mixins: [downloadFile],
	props: {
		themeToEdit: Object,
		canCreateAndUpdate: Boolean
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			theme: JSON.parse(JSON.stringify(this.themeToEdit)) || {
				themeName: '',
				logoUrl: '',
				logoLink: '',
				script: null,
				style: null,
				resources: []
			},
			selectedResources: []
		}
	},
	computed: {
		isEdit () {
			return !!this.themeToEdit
		},
		readOnly () {
			return this.themeToEdit?.cassieDefault || !this.canCreateAndUpdate
		},
		modalTitle () {
			if (this.isEdit) {
				return this.readOnly || this.themeToEdit?.cassieDefault ? 'View Theme' : 'Edit Theme'
			} else {
				return 'Create Theme'
			}
		},
		filteredResources () {
			const allowedFileTypes = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'tiff', 'ttf', 'js', 'css']
			const filteredResources = this.theme.resources.filter(resource => allowedFileTypes.includes(resource.name.split('.').pop().toLowerCase()))
			return [
				...filteredResources
			]
		},
		tableHeaders () {
			const headers = [
				{ value: 'name', text: 'File Name', width: '30%' },
				{ value: 'url', text: 'File Path', width: '55%' },
				{ value: 'action', text: 'Action', width: '15%' }
			]
			return this.canCreateAndUpdate ? headers : headers.filter(h => h.value !== 'action')
		}
	},
	methods: {
		submit () {
			const formData = new FormData()
			formData.append('themeName', this.theme.themeName)
			formData.append('brandId', 0)
			formData.append('script', this.theme.script)
			formData.append('style', this.theme.style)
			formData.append('logoLink', this.theme.logoLink)
			formData.append('logoUrl', this.theme.logoUrl)
			const resources = [
				...this.theme.resources,
				...this.selectedResources
			]
			resources?.forEach(resource => {
				formData.append('resources', resource)
			})
			if (!this.isEdit) {
				this.$emit('create', formData)
			} else {
				formData.append('themeId', this.theme.themeId)
				this.$emit('update', formData)
			}
		},
		downloadExampleFile (fileType) {
			const js = 'js'
			if (fileType === js) {
				const file = new File([scriptExample], 'script.js')
				const link = document.createElement('a')
				link.href = URL.createObjectURL(file)
				link.setAttribute('download', 'script.js')
				link.click()
			} else {
				const file = new File([styleExample], 'styles.css')
				const link = document.createElement('a')
				link.href = URL.createObjectURL(file)
				link.setAttribute('download', 'styles.css')
				link.click()
			}
		},
		async deleteAsset (item) {
			const formData = new FormData()
			formData.append('ThemeId', this.theme.themeId)
			formData.append('FileName', item.name)
			formData.append('Type', item.type)
			await deleteThemeAsset(formData).then(() => {
				showSnackbar('Asset deleted successfully')
				this.theme.resources = this.theme.resources.filter(resource => resource.name !== item.name)
			})
		},
		downloadAsset (item) {
			this.downloadFile(item.url, item.name)
		}
	}
}
</script>
