<!-- eslint-disable max-lines -->
<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="d-flex flex-column overflow-y-hidden"
		style="width: 100%;"
	>
		<ConsentCollectionLayout
			v-if="displayPage"
			:title="`${isEdit ? 'Edit' : 'Create'} Consent Collection Widget`"
			:caption="stepCaption"
		>
			<template #header-after>
				<Stepper
					:steps="steps"
					:step="step"
					:edit-mode="isEdit"
					@update:step="step = $event"
				/>
			</template>
			<template #content>
				<StepperItems
					:steps="steps"
					:step="step"
				>
					<template #0>
						<div class="cassie-vertical-md">
							<ConsentCollectionDetailsCard
								:details.sync="details"
								:is-edit="isEdit"
								:user-full-permissions="userFullPermissions"
								:languages="mappedAssignedLanguages"
								parent-type="Widget"
								@update-details="onUpdateDetails"
							/>
							<MetadataCard
								module-type="Widget"
								:component-type-id="COMPONENT_TYPE.CONSENT_COLLECTION_WIDGETS"
								:assigned-meta-data="consentCollectionWidget.externalValues"
								:read-only="!userFullPermissions"
								@update:metadata="updateMetadata($event)"
							/>
						</div>
					</template>
					<template #1>
						<BuildStep
							v-if="displayBuilder"
							:channels="channels"
							:headers="headers"
							:footers="footers"
							:brand-id="consentCollectionWidget.brandId"
							:extended-preferences="extendedPreferences"
							:user-full-permissions="userFullPermissions"
							type="consentCollectionWidget"
						/>
					</template>
					<template #2>
						<ThemeStep
							v-if="displayBuilder"
							:user-full-permissions="userFullPermissions"
							:all-themes="themes"
							:channels="channels"
							:brand-id="consentCollectionWidget.brandId"
							:extended-preferences="extendedPreferences"
							:create-style-mode="!consentCollectionWidget.formBuilderThemeGUID"
							type="consentCollectionWidget"
						/>
					</template>
					<template #3>
						<ConsentCollectionWidgetPositionAndTriggerStep
							:consent-collection-widget.sync="consentCollectionWidget"
							:user-full-permissions="userFullPermissions"
						/>
					</template>
					<template #4>
						<ConsentCollectionWidgetOutcomesStep
							:consent-collection-widget.sync="consentCollectionWidget"
							:user-full-permissions="userFullPermissions"
							:assigned-languages="mappedAssignedLanguages"
							:confirmation-emails="brandFilteredSystemEmails"
						/>
					</template>
					<template #5>
						<ConsentCollectionWidgetDeployStep
							:consent-collection-widget.sync="consentCollectionWidget"
							:user-full-permissions="userFullPermissions"
							:assigned-languages="mappedAvailableLanguages"
						/>
					</template>
				</StepperItems>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton
							test-id="manage_consent_collection_widget_back_to_overview_button"
							@click="backToOverview"
						>
							Back To {{ accessedViaHub ? 'Consent Collection' : 'Overview' }}
						</SecondaryActionButton>
						<v-spacer />
						<SecondaryActionButton
							v-if="step > 0"
							test-id="manage_consent_collection_widget_previous_button"
							@click="handleSubmit(previousStep)"
						>
							Previous
						</SecondaryActionButton>
						<PrimaryActionButton
							v-if="step < steps.length - 1 && isEdit && userFullPermissions"
							class="ml-2"
							test-id="manage_consent_collection_widget_save_button"
							@click="handleSubmit(saveConsentCollectionWidget)"
						>
							Save Changes
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="!isEdit && userFullPermissions && step !== steps.length - 1 && step !== steps.length - 2"
							class="ml-2"
							test-id="manage_consent_collection_widget_save_progress_button"
							@click="handleSubmit(saveConsentCollectionWidget)"
						>
							Save
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="step === steps.length - 2 && !isEdit && userFullPermissions"
							class="ml-2"
							test-id="manage_consent_collection_widget_finish_button"
							@click="handleSubmit(nextStep)"
						>
							Finish
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="showNextButton"
							test-id="manage_consent_collection_widget_next_button"
							class="ml-2"
							@click="handleSubmit(nextStep)"
						>
							Next
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ConsentCollectionLayout>
		<ConfirmThemeManageModal
			v-if="themeToManage"
			:theme-to-manage="themeToManage"
			@close="themeToManage = null"
			@manage-theme="manageTheme"
		/>
		<NonFormUnsavedChangesModal
			v-if="showUnsavedChangesModal"
			:next="nextFunction"
			@cancel="showUnsavedChangesModal = false"
			@proceed="proceedFromUnsavedChanges"
		/>
	</ValidationForm>
</template>

<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import isEqual from 'lodash/isEqual'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import ConsentCollectionLayout from '../consent-collection-layout.vue'
import Stepper from '../../../../../../shared/components/stepper-v2.vue'
import StepperItems from '../../../../../../shared/components/stepper-v2-items.vue'
import ConsentCollectionDetailsCard from '../consent-collection-details-card.vue'
import MetadataCard from '../../../shared/metadata-card.vue'
import BuildStep from '../build-step.vue'
import ThemeStep from '../theme-step.vue'
import ConsentCollectionWidgetPositionAndTriggerStep from './consent-collection-widget-position-and-trigger-step.vue'
import ConsentCollectionWidgetOutcomesStep from './consent-collection-widget-outcomes-step.vue'
import ConsentCollectionWidgetDeployStep from './consent-collection-widget-deploy-step.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import ConfirmThemeManageModal from '../confirm-theme-manage-modal.vue'
import NonFormUnsavedChangesModal from '../../../../../../shared/components/non-form-unsaved-changes-modal.vue'
import { defaultBrandInSelect, UNIVERSAL_BRAND, brandOptions } from '../../../../../../shared/state/brands.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { getConsentCollectionWidget, createConsentCollectionWidget, updateConsentCollectionWidget } from '../../../../../../shared/utils/api/consent-collection-widgets.js'
import { getSchema,	getTheme, getAllThemes, postSchema, putSchema } from '../../../../../../shared/utils/api/form-builder.js'
import { getSystemEmails } from '../../../../../../shared/utils/api/system-emails.js'
import { getPreferenceChannels } from '../../../../../../shared/utils/api/channels.js'
import { headersAndFootersTypeIdEnum } from '../../../../../../shared/enums/headers-and-footers.js'
import { getFooters, getHeaders } from '../../../../../../shared/utils/api/headers-and-footers.js'
import { getExtendedPreferences } from '../../../../../../shared/utils/api/extended-preferences.js'
import { CONSENT_COLLECTION_WIDGETS, CONSENT_COLLECTION } from '../../../../router/route-names.js'
import { getEnv } from '../../../../../../shared/utils/runtime-settings.js'
import {
	defaultVueformBuilderFormat,
	schemaHasPreferencesWithUnassignedChannelOrStatement,
	schemaHasUnassignedHeaderOrFooter
} from '../../../../../../shared/utils/vueform-builder-utils.js'
import { COMPONENT_TYPE } from '../../../../../../shared/state/meta-data.js'
import { PREF_WIDGETS_AREA_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import parseJsonMixin from '../../../../../../shared/mixins/parse-json.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
export default {
	components: {
		ValidationForm,
		ConsentCollectionLayout,
		Stepper,
		StepperItems,
		ConsentCollectionDetailsCard,
		MetadataCard,
		BuildStep,
		ThemeStep,
		ConsentCollectionWidgetPositionAndTriggerStep,
		ConsentCollectionWidgetOutcomesStep,
		ConsentCollectionWidgetDeployStep,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		ConfirmThemeManageModal,
		NonFormUnsavedChangesModal
	},
	mixins: [parseJsonMixin],
	beforeRouteLeave (to, from, next) {
		if (this.showUnsavedModal) {
			this.nextFunction = next
			this.showUnsavedChangesModal = true
			return
		}
		next()
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		accessedViaHub: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		const isFormBuilderScriptLoaded = Array.from(document.getElementsByTagName('script')).some(script => script.src === getEnv('VUE_APP_FORMBUILDER_URL'))
		if (!isFormBuilderScriptLoaded) {
			const formBuilderScript = document.createElement('script')
			formBuilderScript.src = getEnv('VUE_APP_FORMBUILDER_URL')
			document.head.appendChild(formBuilderScript)
		}
		return {
			defaultBrandInSelect,
			showSnackbar,
			COMPONENT_TYPE,
			UNIVERSAL_BRAND
		}
	},
	data () {
		const DETAILS_STEP = 0
		const BUILDER_STEP = 1
		const STYLE_STEP = 2
		const COMPLETE_STATUS = 5
		return {
			consentCollectionWidget: {
				name: '',
				consentCollectionWidgetGUID: '',
				description: '',
				brandId: defaultBrandInSelect.value,
				externalValues: [],
				formBuilderSchemaGUID: null,
				formBuilderThemeGUID: null,
				executionTypeId: 1,
				cssSelector: null,
				executionDelaySeconds: 0,
				positionId: 1,
				restrictByDomain: false,
				allowedDomains: [],
				outcomeSuccessInlineHeadline: '',
				outcomeSuccessInlineMessage: '',
				confirmationEmailId: null,
				customPrivacyPolicies: []
			},
			step: 0,
			steps: [
				{
					title: 'Details',
					slot: 0,
					stepNumber: 0,
					stepComplete: false,
					complete: true
				},
				{
					title: 'Build',
					slot: 1,
					stepNumber: 1,
					stepComplete: false,
					complete: false
				},
				{
					title: 'Style',
					slot: 2,
					stepNumber: 2,
					stepComplete: false,
					complete: false
				},
				{
					title: 'Position',
					slot: 3,
					stepNumber: 3,
					stepComplete: false,
					complete: false
				},
				{
					title: 'Outcomes',
					slot: 4,
					stepNumber: 4,
					stepComplete: false,
					complete: false
				},
				{
					title: 'Deploy',
					slot: 5,
					stepNumber: 5,
					stepComplete: false,
					complete: false
				}
			],
			DETAILS_STEP,
			BUILDER_STEP,
			STYLE_STEP,
			COMPLETE_STATUS,
			details: {
				name: '',
				description: '',
				brandId: null,
				customDefaultLanguageId: null,
				allowedDomains: []
			},
			headers: [],
			footers: [],
			extendedPreferences: [],
			channels: [],
			systemEmails: [],
			assignedLanguages: [],
			themes: [],
			themeToManage: null,
			formSaved: false,
			showUnsavedChangesModal: false,
			nextFunction: null,
			displayBuilder: false,
			displayPage: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		isEdit () {
			return !!this.id && this.completeConsentCollectionWidget
		},
		isPartialOrCompleteConsentCollectionWidget () {
			return !!this.widgetId || !!this.id
		},
		completeConsentCollectionWidget () {
			return this.consentCollectionWidget.status === 5
		},
		userFullPermissions () {
			if (!this.isEdit) return true
			return this.productAreaPermission(PREF_WIDGETS_AREA_FULL_PERMISSIONS) && this.userHasBrand
		},
		userHasBrand () {
			const brands = brandOptions.value.map(({ value }) => value)
			return brands.includes(this.consentCollectionWidget.brandId)
		},
		stepCaption () {
			switch (this.step) {
				case 0:
					return 'The administration details for your widget'
				case 1:
					return 'Build your widget'
				case 2:
					return 'Style your widget'
				case 3:
					return 'Position your widget and how it appears'
				case 4:
					return 'Set the success messages for your customers'
				case 5:
					return 'Final settings and collecting the code for your widget'
				default:
					return ''
			}
		},
		availableLanguages () {
			return this.consentCollectionWidget.availableTranslations
		},
		mappedAvailableLanguages () {
			return this.assignedLanguages?.map(language => ({
				...language,
				value: language.languageID,
				text: language.languageName,
				languageCode: language.languageCode
			})) ?? []
		},
		mappedAssignedLanguages () {
			return this.assignedLanguages?.map(language => ({
				...language,
				value: language.languageID,
				text: language.languageName,
				languageCode: language.languageCode
			})) ?? []
		},
		brandFilteredSystemEmails () {
			if (!this.systemEmails) {
				return []
			}
			const emailTypeFilter = emailTypeId => emailTypeId === 3 || emailTypeId === null
			const brandsFilter = brandId => brandId === UNIVERSAL_BRAND || brandId === this.consentCollectionWidget.brandId
			const filteredSystemEmails = this.systemEmails.filter(({ brandId, emailTypeId }) => brandsFilter(brandId) && emailTypeFilter(emailTypeId))

			return filteredSystemEmails.map(systemEmail => ({
				...systemEmail,
				text: systemEmail.systemEmailName,
				value: systemEmail.systemEmailId
			}))
		},
		showNextButton () {
			if (this.isEdit) {
				return this.step < this.steps.length - 1
			} else {
				return this.step < this.steps.length - 2
			}
		},
		schemaChanged () {
			const formHistory = JSON.parse(localStorage.getItem('vueform-history'))
			return formHistory?.length > 1
		},
		themeChanged () {
			const formHistory = JSON.parse(localStorage.getItem('vueform-history'))
			const mostRecentChange = formHistory?.[formHistory.length - 1]?.theme
			const secondToLastChange = formHistory?.[formHistory.length - 2]?.theme
			const themeChanged = !isEqual(mostRecentChange, secondToLastChange)
			return themeChanged
		},
		showUnsavedModal () {
			if (this.step === this.BUILDER_STEP) {
				return this.schemaChanged && !this.formSaved
			} else if (this.step === this.STYLE_STEP) {
				return this.themeChanged && !this.formSaved
			} else return false
		}
	},
	async mounted () {
		await this.loadSystemEmails()
		await this.loadAssignedLanguages()
		await this.getAllThemes()
		if (this.isPartialOrCompleteConsentCollectionWidget) {
			await this.fetchExistingData()
		} else {
			delete defaultVueformBuilderFormat.builder.themeName
			localStorage.setItem('vueform-builder', JSON.stringify(defaultVueformBuilderFormat))
		}
		this.setDetails()
		await this.getBuilderData()
		this.displayPage = true
		this.displayBuilder = true
	},
	methods: {
		async loadAssignedLanguages () {
			const { data } = await getAssignedLanguages()
			this.assignedLanguages = data
		},
		async loadSystemEmails () {
			const { data } = await getSystemEmails()
			this.systemEmails = data.systemEmails
		},
		async fetchExistingData () {
			const { consentCollectionWidget } = await getConsentCollectionWidget(this.id)
			this.consentCollectionWidget = consentCollectionWidget
			this.widgetId = this.id
			const builderConfig = {
				...defaultVueformBuilderFormat
			}
			if (consentCollectionWidget.formBuilderSchemaGUID) {
				const { data } = await getSchema(consentCollectionWidget.formBuilderSchemaGUID)
				builderConfig.schema = JSON.parse(data.json)
			}
			if (consentCollectionWidget.formBuilderThemeGUID) {
				const { data } = await getTheme(consentCollectionWidget.formBuilderThemeGUID)
				builderConfig.theme = JSON.parse(data.json)
				builderConfig.builder.themeName = data.themeName
			}
			if (this.consentCollectionWidget.status === this.COMPLETE_STATUS) {
				this.setAllStepsToComplete()
			} else {
				this.step = this.consentCollectionWidget.status
				this.steps.forEach((step, index) => {
					if (index <= this.consentCollectionWidget.status) {
						step.stepComplete = true
					}
				})
			}
			localStorage.setItem('vueform-builder', JSON.stringify(builderConfig))
		},
		setDetails () {
			this.details = {
				name: this.consentCollectionWidget.name,
				description: this.consentCollectionWidget.description,
				brandId: this.consentCollectionWidget.brandId ?? defaultBrandInSelect.value,
				customDefaultLanguageId: this.consentCollectionWidget.customDefaultLanguageId,
				allowedDomains: this.consentCollectionWidget.allowedDomains
			}
		},
		async getBuilderData () {
			const [
				{ data: headers },
				{ data: footers },
				{ data: { channels } },
				{ extendedPreferences }
			] = await Promise.all([
				getHeaders(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS),
				getFooters(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS),
				getPreferenceChannels(),
				getExtendedPreferences()
			])
			this.headers = headers
			this.footers = footers
			this.channels = channels
			this.extendedPreferences = extendedPreferences
		},
		async getAllThemes () {
			const { themes } = await getAllThemes()
			this.themes = themes.sort((a, b) => a.themeName.localeCompare(b.themeName))
			const themesObject = {}
			this.themes.forEach(theme => {
				const themeProperties = {
					...this.parsedJSON(theme.json)
				}
				delete themeProperties.themeId
				themesObject[theme.themeName] = {
					themeId: theme.themeId,
					...themeProperties
				}
			})
			localStorage.setItem('vueform-themes', JSON.stringify(themesObject))
		},
		onUpdateDetails (property, value) {
			this.details = {
				...this.details,
				[property]: value
			}
			this.consentCollectionWidget = {
				...this.consentCollectionWidget,
				[property]: value
			}
		},
		updateMetadata (assignedMetadata) {
			this.consentCollectionWidget.externalValues = assignedMetadata
		},
		setAllStepsToComplete () {
			this.steps.forEach(step => {
				step.stepComplete = true
			})
		},
		async nextStep () {
			let progressToNextStep = true
			if (this.userFullPermissions) {
				progressToNextStep = await this.saveConsentCollectionWidget(true)
			}
			if (!this.themeToManage && progressToNextStep !== false) {
				this.steps[this.step].stepComplete = true
				this.step++
			}
		},
		previousStep () {
			this.step--
		},
		manageThemeFunctionality (accessedViaNext = false) {
			const builderConfig = JSON.parse(localStorage.getItem('vueform-builder'))
			const currentTheme = JSON.parse(JSON.stringify(builderConfig.theme))
			const themeName = builderConfig.builder.themeName
			const newTheme = !this.isEdit && !themeName
			const themeToManage = {
				brandId: this.consentCollectionWidget.brandId,
				accessedViaNext,
				variables: JSON.stringify(currentTheme)
			}
			if (newTheme) {
				this.themeToManage = {
					...themeToManage,
					createFromExisting: false
				}
			} else {
				this.handleDirtyTheme(themeToManage, currentTheme, themeName, accessedViaNext)
			}
		},
		handleDirtyTheme (themeToManage, currentTheme, themeName, accessedViaNext) {
			const existingTheme = JSON.parse(localStorage.getItem('vueform-themes'))[themeName]
			const themeId = existingTheme?.themeId
			delete existingTheme.themeId
			delete currentTheme.themeId

			const borderColorSignatureHr = 'border-color-signature-hr'
			delete existingTheme[borderColorSignatureHr]
			delete currentTheme[borderColorSignatureHr]

			const dirtyTheme = !isEqual(existingTheme, currentTheme)
			if (dirtyTheme) {
				this.themeToManage = {
					...themeToManage,
					createFromExisting: true,
					themeName: themeName,
					themeId: themeId
				}
			} else {
				this.consentCollectionWidget.formBuilderThemeGUID = themeId
				this.updateConsentCollectionWidget(accessedViaNext)
			}
		},
		async manageTheme (themeGuid, accessedViaNext, themeName) {
			this.consentCollectionWidget.formBuilderThemeGUID = themeGuid
			this.themeToManage = null
			const builderConfig = JSON.parse(localStorage.getItem('vueform-builder'))
			builderConfig.builder.themeName = themeName
			localStorage.setItem('vueform-builder', JSON.stringify(builderConfig))
			this.getAllThemes()

			this.updateConsentCollectionWidget()
			if (accessedViaNext) {
				this.steps[this.step].stepComplete = true
				this.step++
			}
		},
		async saveSchema () {
			const builderConfig = JSON.parse(localStorage.getItem('vueform-builder'))

			const schema = {
				brandId: this.consentCollectionWidget.brandId,
				isTemplate: false,
				json: JSON.stringify(builderConfig.schema)
			}
			if (!this.consentCollectionWidget.formBuilderSchemaGUID) {
				await postSchema(schema).then(response => {
					this.consentCollectionWidget.formBuilderSchemaGUID = response.data.id
				})
			} else {
				delete schema.brandId
				await putSchema(this.consentCollectionWidget.formBuilderSchemaGUID, schema)
			}
			return true
		},

		async saveConsentCollectionWidget (accessedViaNext = false) {
			if (this.step === this.DETAILS_STEP && !this.isPartialOrCompleteConsentCollectionWidget) {
				await this.postConsentCollectionWidget(accessedViaNext)
			} else {
				const builderConfig = JSON.parse(localStorage.getItem('vueform-builder'))
				if (this.step === this.BUILDER_STEP && this.userFullPermissions) {
					const schema = builderConfig.schema
					if (Object.keys(schema).length === 0) {
						this.showSnackbar({ text: 'Please build the form', color: 'red' })
						return false
					}
					const hasPrefsWithUnassignedChannelOrStatement = schemaHasPreferencesWithUnassignedChannelOrStatement(schema)

					if (hasPrefsWithUnassignedChannelOrStatement) {
						this.showSnackbar({ text: 'One or more Preference components have unassigned Channels/Statements.', color: 'red' })
						return false
					}

					const hasUnassignedHeaderOrFooter = schemaHasUnassignedHeaderOrFooter(schema)
					if (hasUnassignedHeaderOrFooter) {
						this.showSnackbar({ text: 'One or more Header/Footer components have not been assigned.', color: 'red' })
						return false
					}

					await this.saveSchema()
				}
				if (this.step === this.STYLE_STEP && this.userFullPermissions) {
					await this.manageThemeFunctionality(accessedViaNext)
				} else if (this.step !== this.STYLE_STEP && this.userFullPermissions) {
					await this.updateConsentCollectionWidget(accessedViaNext)
				}
				this.formSaved = true
				return true
			}
		},
		async postConsentCollectionWidget (accessedViaNext) {
			const payload = {
				consentCollectionWidgetName: this.consentCollectionWidget.name,
				consentCollectionWidgetDescription: this.consentCollectionWidget.description,
				brandId: this.consentCollectionWidget.brandId,
				defaultLanguageId: this.consentCollectionWidget.customDefaultLanguageId,
				externalValues: this.consentCollectionWidget.externalValues.map(({ metaDataKeyId, metaDataValue }) => ({
					externalMetaDataId: metaDataKeyId,
					externalDataValue: metaDataValue
				})),
				allowedDomains: this.consentCollectionWidget.allowedDomains,
				status: accessedViaNext ? 1 : 0
			}
			await createConsentCollectionWidget(payload).then(response => {
				this.widgetId = response.data.consentCollectionWidgetId
				this.consentCollectionWidget.consentCollectionWidgetGUID = response.data.consentCollectionWidgetGUID
				this.showSnackbar('Consent Collection Widget created successfully')
			})
		},
		async updateConsentCollectionWidget (accessedViaNext) {
			await updateConsentCollectionWidget(this.widgetId, this.formatPayload(accessedViaNext))
			this.showSnackbar('Consent Collection Widget updated successfully')
		},
		formatPayload (accessedViaNext) {
			if (!this.consentCollectionWidget.customPrivacyPoliciesEnabled) {
				this.consentCollectionWidget.customPrivacyPolicies = []
			}
			const consentCollectionWidget = {
				...this.consentCollectionWidget,
				consentCollectionWidgetName: this.consentCollectionWidget.name,
				consentCollectionWidgetDescription: this.consentCollectionWidget.description,
				defaultLanguageId: this.consentCollectionWidget.customDefaultLanguageId,
				externalValues: this.consentCollectionWidget.externalValues.map(({ metaDataKeyId, metaDataValue }) => ({
					externalMetaDataId: metaDataKeyId,
					externalDataValue: metaDataValue
				})),
				status: this.setPutStatus(accessedViaNext),
				executionTypeId: this.consentCollectionWidget.executionTypeId === 0 ? null : this.consentCollectionWidget.executionTypeId
			}
			delete consentCollectionWidget.name
			delete consentCollectionWidget.description
			delete consentCollectionWidget.customPrivacyPoliciesEnabled
			delete consentCollectionWidget.consentCollectionWidgetGUID
			delete consentCollectionWidget.customDefaultLanguageId
			if (this.isEdit) {
				delete consentCollectionWidget.consentCollectionWidgetId
				delete consentCollectionWidget.brandId
				delete consentCollectionWidget.createdBy
				delete consentCollectionWidget.createdByName
				delete consentCollectionWidget.createdDate
				delete consentCollectionWidget.lastUpdatedBy
				delete consentCollectionWidget.lastUpdatedByName
				delete consentCollectionWidget.lastUpdatedDate
				delete consentCollectionWidget.defaultLanguageCode
			}	else {
				delete consentCollectionWidget.restrictByDomain
			}
			return consentCollectionWidget
		},
		setPutStatus (accessedViaNext) {
			if (accessedViaNext) {
				return this.step === 4 || this.consentCollectionWidget.status === this.COMPLETE_STATUS ? this.COMPLETE_STATUS : this.step + 1
			} else {
				return this.step === 4 || this.consentCollectionWidget.status === this.COMPLETE_STATUS ? this.COMPLETE_STATUS : this.step
			}
		},
		backToOverview () {
			if (this.accessedViaHub) {
				this.$router.push({ name: CONSENT_COLLECTION })
			} else {
				this.$router.push({ name: CONSENT_COLLECTION_WIDGETS })
			}
			localStorage.removeItem('vueform-builder')
			localStorage.removeItem('vueform-history')
			localStorage.removeItem('vueform-themes')
		},
		proceedFromUnsavedChanges () {
			localStorage.removeItem('vueform-builder')
			localStorage.removeItem('vueform-history')
			localStorage.removeItem('vueform-themes')
			this.showUnsavedChangesModal = false
		}
	}
}
</script>
