<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="cassie-vertical-md"
	>
		<ConnectorServiceLayout>
			<template #content>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<v-row>
							<v-col
								cols="6"
							>
								<TextField
									v-model="systemIntegration.integrationSystemName"
									class="cassie-input-width-xl"
									label="System Name *"
									rules="required"
									tooltip-text="Enter a name for this system integration"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col
								cols="6"
							>
								<Dropdown
									v-model="systemIntegration.brandId"
									:label="'Brand*' | useLabels"
									searchable
									:items="filteredBrandOptions"
									custom-sort
									:rules="{ required: clientMultiBranded}"
									tooltip-text="Select a Brand for this system integration"
									:disabled="!userFullPermissions || !clientMultiBranded || singleBrand"
								/>
							</v-col>
							<v-col cols="12">
								<TextArea
									v-model="systemIntegration.notes"
									label="Description"
									tooltip-text="Enter any explanatory notes to help describe this system integration (internal use only)"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col cols="6">
								<TextField
									v-model="systemIntegration.endpointAddress"
									class="cassie-input-width-xl"
									label="Endpoint Address *"
									rules="required"
									tooltip-text="Enter the full domain address (URL) of the API endpoint e.g. https://www.yourAPIaddress.com"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col cols="6">
								<TextField
									v-model="systemIntegration.frequencyInSeconds"
									label="Connection Frequency *"
									type="number"
									rules="required"
									tooltip-text="Choose the desired frequency for transfer of information to the target system. This period is specified in seconds."
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col cols="6">
								<Toggle
									:value.sync="systemIntegration.enabled"
									tooltip-text="Once enabled, all services you have set up for this system integration will commence processing actions (if they are also enabled)"
									label="Enable System Integration"
									:disabled="!userFullPermissions"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						Authentication
					</template>
					<template #body>
						<v-row>
							<v-col cols="4">
								<Dropdown
									v-model="systemIntegration.authenticationTypeId"
									:items="authenticationOptions"
									label="Authentication Type *"
									rules="required"
									:disabled="!userFullPermissions"
								/>
							</v-col>
						</v-row>
						<p>
							{{ authDescription }}
						</p>
						<Toggle
							v-if="(isEdit || cloneMode) && additionalAuthRequired"
							:value.sync="editConnectionDetails"
							tooltip-text="Enable this toggle to amend Integration specific connection credentials. All connection details need to be populated before saving any changes."
							label="Edit Connection Details"
							:disabled="!userFullPermissions"
						/>
						<v-row
							v-if="additionalAuthRequired && editConnectionDetails"
						>
							<v-col
								v-if="
									systemIntegration.authenticationTypeId === OAUTH2_USERNAME_AND_PASSWORD ||
										systemIntegration.authenticationTypeId === JWT
								"
								cols="3"
							>
								<TextField
									v-model="systemIntegration.username"
									label="Username *"
									rules="required"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col
								v-if="systemIntegration.authenticationTypeId === OAUTH2_USERNAME_AND_PASSWORD"
								cols="3"
							>
								<PasswordField
									v-model="systemIntegration.password"
									label="Password *"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col
								v-if="
									systemIntegration.authenticationTypeId === OAUTH2_USERNAME_AND_PASSWORD ||
										systemIntegration.authenticationTypeId === OAUTH2_CLIENT_CREDENTIALS ||
										systemIntegration.authenticationTypeId === JWT
								"
								cols="3"
							>
								<TextField
									v-model="systemIntegration.clientId"
									label="Client ID *"
									rules="required"
									tooltip-text="Enter the Client ID for the target system"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col
								v-if="
									systemIntegration.authenticationTypeId === OAUTH2_USERNAME_AND_PASSWORD ||
										systemIntegration.authenticationTypeId === OAUTH2_CLIENT_CREDENTIALS
								"
								cols="3"
							>
								<TextField
									v-model="systemIntegration.clientSecret"
									label="Client Secret *"
									rules="required"
									tooltip-text="Enter the Client Secret for the target system"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col
								v-if="systemIntegration.authenticationTypeId === OAUTH2_USERNAME_AND_PASSWORD"
								cols="3"
							>
								<TextField
									v-model="systemIntegration.token"
									label="Token *"
									rules="required"
									tooltip-text="Enter the token for the target system"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col
								v-if="systemIntegration.authenticationTypeId === OAUTH2_CLIENT_CREDENTIALS"
								cols="3"
							>
								<TextField
									v-model="systemIntegration.auth2Scope"
									label="Auth2 Scope"
									tooltip-text="Enter the Scope for the target system"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col
								v-if="systemIntegration.authenticationTypeId === OAUTH2_CLIENT_CREDENTIALS
									|| systemIntegration.authenticationTypeId === OAUTH2_USERNAME_AND_PASSWORD
									|| systemIntegration.authenticationTypeId === JWT"
								cols="3"
							>
								<TextField
									v-model="systemIntegration.authenticationUrl"
									label="Authentication URL *"
									rules="required"
									tooltip-text="Enter the URL for the target system"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col
								v-if="systemIntegration.authenticationTypeId === JWT"
								cols="3"
							>
								<TextField
									v-model="systemIntegration.jwtCertKey"
									label="JWT Cert Key *"
									tooltip-text="Enter the JWT Cert Key for the target system"
									rules="required"
									:disabled="!userFullPermissions"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="navigateToOverview">
							Cancel
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userFullPermissions"
							@click="handleSubmit(submit)"
						>
							{{ !!systemIntegrationToEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ConnectorServiceLayout>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import ConnectorServiceLayout from '../connector-service-layout.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import PasswordField from '../../../../../../shared/components/password-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextArea from '../../../../../../shared/components/textarea.vue'
import Toggle from '../../../../../../shared/components/toggle.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM, userHasBrand } from '../../../../../../shared/state/brands.js'
import { postSystemIntegration, putSystemIntegration } from '../../../../../../shared/utils/api/connector-service.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		TextArea,
		Dropdown,
		TextField,
		PasswordField,
		ConnectorServiceLayout,
		PrimaryActionButton,
		SecondaryActionButton,
		PageActionRow,
		SectionCard,
		Toggle,
		ValidationForm
	},
	props: {
		systemIntegrationToEdit: Object,
		lookupData: Object,
		cloneMode: Boolean
	},
	setup () {
		return {
			brandOptions,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect
		}
	},
	data () {
		const OAUTH2_USERNAME_AND_PASSWORD = 1
		const OAUTH2_CLIENT_CREDENTIALS = 2
		const JWT = 4

		return {
			systemIntegration: JSON.parse(JSON.stringify(this.systemIntegrationToEdit || {
				integrationSystemName: null,
				endpointAddress: null,
				authenticationTypeId: null,
				clientId: null,
				clientSecret: null,
				username: null,
				password: null,
				validFrom: null,
				validTo: null,
				token: null,
				notes: null,
				enabled: false,
				brandId: defaultBrandInSelect.value,
				frequencyInSeconds: null,
				auth2Scope: null,
				authenticationUrl: null,
				jwtCertKey: null,
				resource: null
			})),
			editConnectionDetails: true,
			OAUTH2_USERNAME_AND_PASSWORD,
			OAUTH2_CLIENT_CREDENTIALS,
			JWT
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			if (this.isEdit) {
				return this.productAreaPermission(CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS) && this.systemIntegrationToEdit.isEditable && userHasBrand(this.systemIntegration.brandId)
			} else {
				return this.productAreaPermission(CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS)
			}
		},
		isEdit () {
			return !!this.systemIntegrationToEdit && !this.cloneMode
		},
		authenticationOptions () {
			return this.lookupData.authTypes.map(authType => ({
				text: authType.displayName,
				value: authType.id,
				displayOrder: authType.displayOrder,
				description: authType.description
			})).sort((a, b) => a.displayOrder - b.displayOrder)
		},
		authDescription () {
			return this.authenticationOptions.find(({ value }) => value === this.systemIntegration.authenticationTypeId)?.description
		},
		additionalAuthRequired () {
			return this.systemIntegration.authenticationTypeId === this.OAUTH2_USERNAME_AND_PASSWORD ||
				this.systemIntegration.authenticationTypeId === this.OAUTH2_CLIENT_CREDENTIALS ||
				this.systemIntegration.authenticationTypeId === this.JWT
		},
		filteredBrandOptions () {
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit || this.cloneMode) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		}
	},
	created () {
		if (this.isEdit || this.cloneMode) {
			this.editConnectionDetails = false
		}
		if (this.cloneMode) {
			this.systemIntegration.integrationSystemName += ' (clone)'
			this.systemIntegration.isEditable = true
			this.systemIntegration.cloneFromId = this.systemIntegration.id
			delete this.systemIntegration.id
			this.systemIntegration.enabled = false
		}
	},
	methods: {
		async submit () {
			if (!this.isEdit || this.cloneMode) {
				if (this.cloneMode) {
					this.systemIntegration.cloneAuthFields = !this.editConnectionDetails
				}
				await postSystemIntegration(this.systemIntegration)
				showSnackbar('System integration created successfully')
			} else {
				this.systemIntegration.editAuthFields = this.editConnectionDetails
				await putSystemIntegration(this.systemIntegration)
				showSnackbar('System integration successfully updated')
			}

			this.navigateToOverview()
		},
		updateSystemIntegration (property, value) {
			this.systemIntegration[property] = value
		},
		getValue (property) {
			return this.systemIntegration[property]
		},
		navigateToOverview () {
			this.$router.back()
			this.$emit('loadSystemIntegrations')
		}
	}
}
</script>
