<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="systemIntegrationSearchQuery"
			search-query-label="System Integration Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persist-search-query="changeSystemIntegrationSearchQuery"
			@persist-selected-brand-id="changeAdminPortalBrandFilter"
		>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="onSystemIntegrationCreate"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				System Integrations
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredSystemIntegrations"
					sort-by="id"
					sort-desc
					@click:row="onSystemIntegrationEdit"
				>
					<template #item.enabled="{ item }">
						{{ item.enabled ? 'Enabled' : 'Disabled' }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							class="mr-2"
							:tooltip-text="userFullPermissions && userHasBrand(item.brandId) && item.isEditable ? 'Edit System Integration' : 'View System Integration'"
							@click="onSystemIntegrationEdit(item)"
						>
							{{ userFullPermissions && userHasBrand(item.brandId) ? 'mdi-pencil' : 'mdi-eye' }}
						</IconButton>
						<IconButton
							v-if="item.enabled && userFullPermissions && userHasBrand(item.brandId)"
							tooltip-text="Disable System Integration"
							class="mr-2"
							@click.stop.prevent="$emit('change:active', item)"
						>
							mdi-pause-circle
						</IconButton>
						<IconButton
							v-else-if="!item.enabled && userFullPermissions && userHasBrand(item.brandId)"
							class="mr-2"
							tooltip-text="Enable System Integration"
							@click.stop.prevent="$emit('change:active', item)"
						>
							mdi-play-circle
						</IconButton>
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandId)"
							tooltip-text="Clone System Integration"
							@click.stop.prevent="onSystemIntegrationClone(item)"
						>
							mdi-content-duplicate
						</IconButton>
						<IconButton
							v-if="item.isEditable && userFullPermissions && userHasBrand(item.brandId)"
							class="mr-2"
							tooltip-text="Delete System Integration"
							@click.stop.prevent="$emit('delete', item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import DataTable from '../../../../../shared/components/data-table.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import { MANAGE_SYSTEM_INTEGRATION } from '../../../router/route-names.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { brandOptions, changeAdminPortalBrandFilter, selectedAdminPortalBrandId, userHasBrand } from '../../../../../shared/state/brands.js'
import { CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { changeSystemIntegrationSearchQuery, systemIntegrationSearchQuery } from '../../../../../shared/state/connector-service.js'
export default {
	components: { SectionCard, DataTable, FilterBar, PrimaryActionButton, IconButton },
	props: {
		systemIntegrations: {
			type: Array,
			default: () => []
		},
		lookupData: Object
	},
	setup () {
		return {
			userHasBrand,
			brandOptions,
			changeSystemIntegrationSearchQuery,
			systemIntegrationSearchQuery,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS)
		},
		tableHeaders () {
			return [
				{
					text: 'ID',
					value: 'id',
					width: '5%'
				},
				{
					text: useLabels('Brand'),
					value: 'brandName',
					width: '20%'
				},
				{
					text: 'System Integration Name',
					value: 'integrationSystemName',
					width: '20%'
				},
				{
					text: 'Frequency',
					value: 'frequencyInSeconds',
					width: '10%'
				},
				{
					text: 'Enabled',
					value: 'enabled',
					width: '10%'
				},
				{
					text: 'Status',
					value: 'status',
					width: '10%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '10%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '10%',
					sortable: false
				}

			]
		},
		filteredSystemIntegrations () {
			return this.systemIntegrations.filter(({ integrationSystemName, brandId }) => {
				let check = true
				if (systemIntegrationSearchQuery.value) check = integrationSystemName.toLowerCase().includes(systemIntegrationSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && selectedAdminPortalBrandId.value === brandId
				return check
			}).map(systemIntegration => ({
				...systemIntegration,
				brandName: brandOptions.value.find(({ value }) => systemIntegration.brandId === value)?.text
			}))
		}
	},
	methods: {
		onSystemIntegrationCreate () {
			this.$router.push({ name: MANAGE_SYSTEM_INTEGRATION, params: { lookupData: this.lookupData } })
		},
		onSystemIntegrationEdit (systemIntegrationToEdit) {
			this.$router.push({ name: MANAGE_SYSTEM_INTEGRATION, params: { systemIntegrationToEdit, lookupData: this.lookupData } })
		},
		onSystemIntegrationClone (systemIntegrationToEdit) {
			this.$router.push({ name: MANAGE_SYSTEM_INTEGRATION, params: { systemIntegrationToEdit, lookupData: this.lookupData, cloneMode: true } })
		}
	}
}
</script>
