<template>
	<Modal
		width="80%"
	>
		<template #modal-title>
			Preview {{ name }} Translation - {{ languageName }}
			<v-spacer />
			<IconButton @click="$emit('close')">
				mdi-close
			</IconButton>
		</template>
		<template #modal-content>
			<cassie-form-builder
				:read-only="true"
				mode="preview"
				:channels="mappedChannels"
				:extended-preferences="mappedExtendedPreferences"
				class="cassie-builder"
			/>
		</template>
	</Modal>
</template>
<script>
import Modal from '../../../../../shared/components/modal.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'

export default {
	components: {
		Modal,
		IconButton
	},
	props: {
		name: {
			type: String,
			required: true
		},
		languageName: {
			type: String,
			required: true
		},
		languageId: {
			type: Number,
			required: true
		},
		channels: {
			type: Array,
			required: true
		},
		extendedPreferences: {
			type: Array,
			required: false
		}
	},
	computed: {
		translatedChannels () {
			const formSchema = JSON.parse(localStorage.getItem('vueform-builder'))?.schema
			const preferenceElements = Object?.keys(formSchema)?.filter(key => key?.includes('checkboxPreference') || key?.includes('radioPreference') || key?.includes('togglePreference'))
			const channelsUsed = []
			preferenceElements.forEach(elementKey => {
				const element = formSchema[elementKey]
				const channel = this.channels.find(channel => channel.channelId === element.channel)
				if (channel) {
					const translatedChannel = channel.channelTranslations.find(({ languageID }) => languageID === this.languageId)
					channel.channelName = translatedChannel?.translatedChannelName ?? channel.channelName
					const statement = channel?.statements?.find(statement => statement.statementID === element.statement)
					const statementTranslation = statement?.liveStatements.find(({ languageId }) => languageId === this.languageId)
					statement.statementText = statementTranslation?.statementText ?? statement.statementText
					channel.statements = [statement]
					channelsUsed.push(channel)
				}
			})
			return channelsUsed
		},
		mappedChannels () {
			const channelsWithStatements = this.translatedChannels.filter(channel => channel.statements?.length)
			const channelsAndStatements = channelsWithStatements.map(channel => ({
				value: channel.channelId,
				label: channel.channelName,
				statements: channel.statements?.map(statement => ({
					value: statement.statementID,
					label: statement.statementText
				})).sort((a, b) => a.label.localeCompare(b.label))
			})).sort((a, b) => a.label.localeCompare(b.label))
			return JSON.stringify(channelsAndStatements)
		},
		mappedExtendedPreferences () {
			const mappedExtendedPreferences = this.extendedPreferences?.map(extendedPreference => ({
				extendedPreferenceId: extendedPreference.extendedPreferenceId,
				name: extendedPreference.name,
				dropdownValues: extendedPreference.dropdownValues.map(dropdownValue => ({
					value: this.languageId === 0 ? dropdownValue.value : this.mapExtendedPreferenceDropdownValues(extendedPreference, dropdownValue),
					valueId: dropdownValue.valueId,
					displayOrder: dropdownValue.displayOrder
				})).sort((a, b) => a.displayOrder - b.displayOrder)
			}))
			return JSON.stringify(mappedExtendedPreferences)
		}
	},
	methods: {
		mapExtendedPreferenceDropdownValues (extendedPreference, dropdownValue) {
			const translatedValue = extendedPreference.translations.find(translation =>
				translation.languageId === this.languageId && translation.valueId === dropdownValue.valueId)
			return translatedValue?.value ? translatedValue.value : dropdownValue.value
		}
	}
}
</script>

<style>
.cassie-builder {
	height: calc(100vh - 298px);
	max-height: calc(100vh - 298px);
}

</style>
