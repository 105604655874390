import { render, staticRenderFns } from "./_action-links.vue?vue&type=template&id=813d5a5a&"
import script from "./_action-links.vue?vue&type=script&lang=js&"
export * from "./_action-links.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports