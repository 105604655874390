<template>
	<SectionCard>
		<template #title>
			Deploy Preference Page
		</template>
		<template #body>
			<v-row
				dense
			>
				<v-col cols="4">
					<Dropdown
						v-model="selectedLanguage"
						custom-sort
						label="Language"
						searchable
						:items="filteredLanguageOptions"
						@change="updateEmbedCodeWithLanguage"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<LinkViewer
						:show-preview-button="showPreviewButton"
						:inner-value="embedCode"
						:preference-page-id="preferencePageId"
						:selected-language-code="selectedLanguageCode"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import LinkViewer from './link-viewer.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'

export default {
	components: {
		SectionCard,
		LinkViewer,
		Dropdown
	},
	props: {
		showPreviewButton: {
			type: Boolean,
			default: false
		},
		embedCode: String,
		preferencePageId: Number,
		assignedLanguages: Array
	},
	data: () => ({
		selectedLanguage: 0,
		selectedLanguageCode: 'default'
	}),
	computed: {
		filteredLanguageOptions () {
			const noneOption = {
				text: 'None',
				value: 0
			}
			let filteredLanguages = []
			if (this.assignedLanguages) {
				filteredLanguages = this.assignedLanguages.filter(({ languageID }) => languageID !== 0)
				return [noneOption, ...filteredLanguages]
			} else {
				return []
			}
		}
	},
	methods: {
		updateEmbedCodeWithLanguage (value) {
			const languageCode = this.assignedLanguages.find(({ value: languageID }) => languageID === value).languageCode
			this.selectedLanguageCode = languageCode ?? 'default'
		}
	}
}
</script>
