<template>
	<ViewLayout>
		<template #header-title>
			Custom Preference Pages
		</template>
		<template #header-caption>
			Add, edit and manage your custom preference pages
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<PreferenceLinkTab
						:preference-page-links="preferencePageLinks"
						:user-full-permissions="userFullPermissions"
						:user-read-only="userReadOnly"
						:link-auth-types="linkAuthTypes"
						:assigned-languages-items="assignedLanguagesItems"
						:status-filter-items="statusFilterItems"
						@create-link="createLink"
						@edit-link="onEditLink"
						@clone-link="onCloneLink"
						@view-link="onViewLink"
						@remove-link="onRemoveLink"
						@load-links="loadLinks"
						@preview-link="onPreviewLink"
					/>
				</template>
				<template #1>
					<LinkTranslationsTab
						:user-full-permissions="userFullPermissions"
						:user-read-only="userReadOnly"
						:preference-page-links="preferencePageLinks"
						:link-types="lookUpData.linkTypes"
						@load-links="loadLinks"
					/>
				</template>
			</TabItems>
			<RemoveLinkModal
				v-if="linkToBeRemoved"
				v-bind="linkToBeRemoved"
				@close="linkToBeRemoved = null"
				@deleted="loadLinks"
			/>
			<ViewLinkModal
				v-if="linkToView"
				v-bind="linkToView"
				:assigned-language-items="assignedLanguagesItems"
				@close="linkToView = null"
			/>
		</template>
		<template #footer>
			<PageActionRow>
				<template #actions>
					<SecondaryActionButton @click="backToConsentCollection">
						Back to Consent Collection
					</SecondaryActionButton>
				</template>
			</PageActionRow>
		</template>
	</ViewLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import PreferenceLinkTab from './preference-link-tab.vue'
import LinkTranslationsTab from '../shared/link-translations-tab.vue'
import { getLinks, getLinkButtonTranslations, encryptParameters } from '../../../../../../shared/utils/api/admin-portal/links/links.js'
import RemoveLinkModal from '../remove-link-modal.vue'
import { MANAGE_PREFERENCE_LINK, CONSENT_COLLECTION } from '../../../../router/route-names.js'
import ViewLinkModal from '../view-link-modal.vue'
import { SEE_LINKS_AREA, LINKS_AREA_FULL_PERMISSIONS, LINKS_PAGE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import previewLinkMixin from '../../../../../../shared/mixins/preview-link.js'
export default {
	components: {
		ViewLayout,
		Tabs,
		TabItems,
		PreferenceLinkTab,
		ViewLinkModal,
		RemoveLinkModal,
		LinkTranslationsTab,
		PageActionRow,
		SecondaryActionButton
	},
	mixins: [previewLinkMixin],
	props: {
		accessedViaTranslations: Boolean
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			tabs: [
				{ title: 'Preference Pages', slot: '0' },
				{ title: 'Preference Page Translations', slot: '1' }
			],
			tab: '0',
			actionLinks: [],
			preferencePageLinks: [],
			lookUpData: [],
			linkToBeRemoved: null,
			linkToView: null,
			selectedBrandId: null,
			assignedLanguages: [],
			linksButtons: null,
			changeMadeToGeneralTranslations: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		userFullPermissions () {
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)
		},
		userReadOnly () {
			return this.productAreaPermission(SEE_LINKS_AREA)
		},
		linkAuthTypes () {
			return this.lookUpData.linkAuthTypes?.map(
				({ linkAuthTypeId, linkAuthTypeDescription }) => ({ value: linkAuthTypeId, text: linkAuthTypeDescription })
			)
		},
		assignedLanguagesItems () {
			return this.assignedLanguages.map(language => ({
				...language,
				languageCode: language.languageID === 0 ? 'default' : language.languageCode,
				value: language.languageID,
				text: language.languageName
			}))
		},
		statusFilterItems () {
			return [
				{ value: null, text: 'View All' },
				{ value: true, text: 'Enabled' },
				{ value: false, text: 'Disabled' }
			]
		},
		generalTranslationsTab () {
			return '2'
		}
	},
	created () {
		this.loadLanguages()
		this.loadLinks()
		this.loadLinksButtonTranslations()
		if (this.accessedViaTranslations) {
			this.tab = '1'
		} else {
			this.tab = '0'
		}
	},
	methods: {
		async loadLinks () {
			const mapAuthenticationType = links => links.map(link => ({ ...link, authenticationType: link.authenticationDetails?.authTypeDescription }))
			const { data: { actionLinks, preferencePageLinks, lookUpData } } = await getLinks()
			this.lookUpData = lookUpData
			this.actionLinks = mapAuthenticationType(actionLinks)
			this.preferencePageLinks = mapAuthenticationType(preferencePageLinks.map(preferencePageLink => ({
				...preferencePageLink,
				preferencePageLinkConfig: {
					extendedPrefs: preferencePageLink.preferencePageLinkConfig.extendedPrefs,
					internalPageDescription: preferencePageLink.preferencePageLinkConfig.internalPageDescription,
					pageContents: preferencePageLink.preferencePageLinkConfig.pageContents,
					pagePreferences: preferencePageLink.preferencePageLinkConfig.pagePreferences.sort((a, b) => a.displayOrderInPreferenceArea - b.displayOrderInPreferenceArea),
					pageSettings: preferencePageLink.preferencePageLinkConfig.pageSettings,
					preferencePageId: preferencePageLink.preferencePageLinkConfig.preferencePageId,
					showChannelName: preferencePageLink.preferencePageLinkConfig.showChannelName
				}
			})))
		},
		onEditLink (link, linkType) {
			if (!link.linkFields.length) {
				showSnackbar({ color: 'red', text: 'this link cannot be edited within this portal as it has no StandDak parameters' })
			} else {
				this.$router.push({
					name: MANAGE_PREFERENCE_LINK,
					params: {
						linkToEdit: link,
						assignedLanguageItems: this.assignedLanguagesItems
					},
					query: {
						linkType
					}
				})
			}
		},
		onCloneLink (link, linkType) {
			const linkToClone = {
				...link,
				linkName: `${link.linkName} (clone)`
			}
			delete linkToClone.linkId
			if (!link.linkFields.length) {
				showSnackbar({ color: 'red', text: 'this link cannot be cloned within this portal as it has no StandDak parameters' })
			} else {
				this.$router.push({
					name: MANAGE_PREFERENCE_LINK,
					params: {
						linkToEdit: linkToClone,
						assignedLanguageItems: this.assignedLanguagesItems,
						cloneMode: true
					},
					query: {
						linkType
					}
				})
			}
		},
		onViewLink (link) {
			this.linkToView = link
		},
		onRemoveLink (link) {
			this.linkToBeRemoved = link
		},
		createLink (linkType) {
			this.$router.push({
				name: MANAGE_PREFERENCE_LINK,
				params: {
					assignedLanguageItems: this.assignedLanguagesItems
				},
				query: {
					linkType
				}
			})
		},
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		async loadLinksButtonTranslations () {
			// const { data: { linkButtonTranslations } } = await getLinkButtonTranslations()
			const linksButtons = await getLinkButtonTranslations()
			this.linksButtons = linksButtons.data
		},
		generalTranslationsChanged (changeMade) {
			this.changeMadeToGeneralTranslations = changeMade
		},
		saveGeneralTranslations () {
			this.$refs.generalTranslationsTab.saveTranslations()
		},
		cancelGeneralTranslationChanges () {
			this.$refs.generalTranslationsTab.cancelTranslationChanges()
		},
		async onPreviewLink (link) {
			const tempString = this.getLinkHref(link)
			await this.previewLink(tempString, link)
		},
		async getEncryptedData (encryptedParams, link) {
			return await encryptParameters({
				encryptionKeyId: link.configurationOptions.encryption.encryptionKeyId,
				linkParams: encryptedParams
			})
		},
		getLinkHref (link) {
			return new DOMParser()
				.parseFromString(link.embedLink, 'text/html')
				.getElementsByTagName('a')[0].href
		},
		backToConsentCollection () {
			this.$router.push({
				name: CONSENT_COLLECTION
			})
		}
	}
}
</script>
