<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="cassie-vertical-md"
	>
		<CookieTemplateLayout
			:header-title="'Translate Cookie Text Template' | useLabels('Translate Cookie Text Template')"
			:header-caption="'translate a cookie text template' | useLabels('translate a cookie text template')"
		>
			<template #content>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="4">
								<TextField
									:value="cookieTextTemplate.name"
									label="Name"
									disabled
								/>
							</v-col>
							<v-col cols="4">
								<Dropdown
									:value="cookieTextTemplate.brandId"
									disabled
									:items="brandItems"
									custom-sort
									label="Brand"
								/>
							</v-col>
							<v-col cols="4">
								<TextField
									:value="cookieTextTemplate.languageName"
									label="Language"
									disabled
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<Textarea
									:value="cookieTextTemplate.description"
									label="Description"
									no-resize
									disabled
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<PreBannerTextCard
					:pre-banner.sync="textTemplateTranslation.preBanner"
					:default-language-pre-banner.sync="cookieTextTemplate.preBanner"
					translation-mode
					:user-can-create-update="userCanCreateUpdate"
				/>
				<GpcBannerTextCard
					:gpc-banner.sync="textTemplateTranslation.gpcBanner"
					:default-language-gpc-banner.sync="cookieTextTemplate.gpcBanner"
					translation-mode
					:user-can-create-update="userCanCreateUpdate"
				/>
				<BannerTextCard
					:banner.sync="textTemplateTranslation.banner"
					:default-language-banner.sync="cookieTextTemplate.banner"
					translation-mode
					:user-can-create-update="userCanCreateUpdate"
				/>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							Back to Overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userCanCreateUpdate"
							@click="handleSubmit(submit)"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</CookieTemplateLayout>
	</ValidationForm>
</template>
<script>
import { mapGetters } from 'vuex'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import Textarea from '../../../../../../../shared/components/textarea.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import PreBannerTextCard from './pre-banner-text-card.vue'
import GpcBannerTextCard from './gpc-banner-text-card.vue'
import BannerTextCard from './banner-text-card.vue'
import PageActionRow from '../../../../../../../shared/components/page-action-row.vue'
import { brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM, userHasBrand } from '../../../../../../../shared/state/brands.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { COOKIE_TEXT_TEMPLATES } from '../../../../../router/route-names.js'
import { putCookieTextTemplateTranslation, postCookieTextTemplateTranslation } from '../../../../../../../shared/utils/api/cookies/cookie-text-templates.js'
import { CAN_CREATE_UPDATE_COOKIE_TEXT_TEMPLATES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		CookieTemplateLayout,
		ValidationForm,
		SectionCard,
		TextField,
		Dropdown,
		Textarea,
		PrimaryActionButton,
		SecondaryActionButton,
		PreBannerTextCard,
		GpcBannerTextCard,
		BannerTextCard,
		PageActionRow
	},
	props: {
		cookieTextTemplate: {
			type: Object,
			required: true
		},
		accessedVia: String,
		cloneMode: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			brandOptions,
			showSnackbar
		}
	},
	data () {
		return {
			textTemplateTranslation: JSON.parse(JSON.stringify(this.cookieTextTemplate?.cookieTextTemplateTranslation || {
				preBanner: {
					preBannerText: '',
					acceptAllButtonText: '',
					rejectAllButtonText: '',
					cookieSettingsButtonText: ''
				},
				gpcBanner: {
					gpcBannerTitle: '',
					gpcBannerText: '',
					acceptAllButtonText: '',
					acceptAllExcGpcButtonText: '',
					backButtonText: ''
				},
				banner: {
					submitPreferencesButtonText: '',
					userConsentTabLabelText: '',
					legitimateInterestTabLabelText: '',
					acceptAllButtonText: '',
					rejectAllButtonText: '',
					optInLabel: '',
					optOutLabel: ''
				}
			}))
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_TEXT_TEMPLATES) && userHasBrand(this.cookieTextTemplate.brandId)
		},
		isEdit () {
			return !!this.cookieTextTemplate.cookieTextTemplateTranslationID
		},
		brandItems () {
			// ensures when viewing universal object it displays in dropdown
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		}
	},
	methods: {
		backToOverview () {
			this.$router.push({
				name: COOKIE_TEXT_TEMPLATES,
				params: {
					accessedVia: 'cookie-text-template-translations',
					previouslySelectedLanguage: this.cookieTextTemplate.languageId
				}
			})
		},
		async submit () {
			const translation = {
				textTemplateId: this.cookieTextTemplate.textTemplateId,
				languageId: this.cookieTextTemplate.languageId,
				brandId: this.cookieTextTemplate.brandId,
				...this.textTemplateTranslation
			}
			if (this.isEdit) {
				await putCookieTextTemplateTranslation(translation)
				this.showSnackbar('Cookie text template translation updated')
				this.backToOverview()
			} else {
				await postCookieTextTemplateTranslation(translation)
				this.showSnackbar('Cookie text template translation created')
				this.backToOverview()
			}
		}
	}
}
</script>
