<template>
	<validation-form
		#default="{ handleSubmit }"
		tag="div"
		class="d-flex flex-column overflow-y-hidden"
		style="width: 100%;"
	>
		<ViewLayout>
			<template #header-title>
				{{ pageTitle }}
			</template>
			<template #header-caption>
				{{ pageCaption }}
			</template>
			<template #header-after>
				<Stepper
					:key="currentStepKey"
					:step="currentStep"
					:steps="steps"
					:in-progress="!isEdit"
					@update:step="value => {
						handleSubmit(() => {
							updateCurrentStep(value)
						})
						currentStepKey++
					}"
				/>
			</template>
			<template #content>
				<slot name="content" />
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backButtonAction">
							Back to {{ accessedViaHub ? 'Consent Collection' : 'Overview' }}
						</SecondaryActionButton>
						<v-spacer />
						<div class="cassie-horizontal-md d-flex flex-row">
							<SecondaryActionButton
								v-if="currentStep && (currentStep !== steps.length - 1 || !userFullPermissions)"
								@click="handleSubmit(previous)"
							>
								Previous
							</SecondaryActionButton>
							<PrimaryActionButton
								v-if="currentStep === steps.length - 2 && userFullPermissions"
								:disabled="!linkHasChanged || !userFullPermissions"
								@click="handleSubmit(goToLastStep)"
							>
								{{ !isEdit ? 'Finish' : 'Save Changes' }}
							</PrimaryActionButton>
							<template v-else-if="currentStep !== steps.length - 1">
								<PrimaryActionButton
									v-if="isEdit && userFullPermissions"
									:disabled="!linkHasChanged || !userFullPermissions"
									@click="handleSubmit(goToLastStep)"
								>
									Save Changes
								</PrimaryActionButton>
								<PrimaryActionButton
									@click="handleSubmit(next)"
								>
									Next
								</PrimaryActionButton>
							</template>
							<PrimaryActionButton
								v-else-if="currentStep === steps.length - 2 && !userFullPermissions"
								@click="handleSubmit(next)"
							>
								Next
							</PrimaryActionButton>
						</div>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
	</validation-form>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import Stepper from '../../../../../shared/components/stepper.vue'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import { LINKS_AREA_FULL_PERMISSIONS, LINKS_PAGE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'

export default {
	components: { ValidationForm, PrimaryActionButton, SecondaryActionButton, PageActionRow, Stepper, ViewLayout },
	props: {
		backButtonAction: Function,
		brandId: Number,
		finishButtonAction: {
			type: Function,
			default: () => {}
		},
		linkTypes: {
			type: Array,
			default: () => []
		},
		linkType: {
			type: Number,
			required: true
		},
		isEdit: Boolean,
		currentStep: Number,
		steps: Array,
		linkHasChanged: Boolean,
		linkName: String,
		link: Object,
		accessedViaHub: Boolean
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		const ACTION_LINK = 1
		const PUBLIC_PORTAL_LINK = 2
		const PREFERENCE_PAGE_LINK = 3
		const PREFERENCES_STEP = 2
		return {
			// Needed due to Vuetify using internal state management for isActive property on the tab
			// Otherwise validation check is ignored and the tab selected is changed
			currentStepKey: 0,
			ACTION_LINK,
			PUBLIC_PORTAL_LINK,
			PREFERENCE_PAGE_LINK,
			PREFERENCES_STEP
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		linkTypeName () {
			switch (this.linkType) {
				case this.ACTION_LINK:
					return 'Action Link'
				case this.PUBLIC_PORTAL_LINK:
					return 'Public Portal Link'
				case this.PREFERENCE_PAGE_LINK:
					return 'Preference Page Link'
				default:
					return 'Link'
			}
		},
		linkNameTitle () {
			return `- ${this.linkName}`
		},
		pageTitle () {
			return `${this.isEdit ? 'Edit' : 'Create'} ${this.linkTypeName} ${this.isEdit ? this.linkNameTitle : ''}`
		},
		pageCaption () {
			if (this.linkType === this.PREFERENCE_PAGE_LINK) {
				return this.isEdit ? 'Edit an existing custom preference page' : 'Add a new custom preference page'
			} else {
				return this.isEdit ? 'Edit an existing ' + this.linkTypeName.toLowerCase() : 'Add a new ' + this.linkTypeName.toLowerCase()
			}
		},
		userFullPermissions () {
			if (this.brandId === 0 && !this.userHasUniversalBrand) {
				return false
			}
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)
		},
		pagePreferences () {
			return this.link?.preferencePageLinkConfig?.pagePreferences
		}
	},
	methods: {
		async goToLastStep () {
			if (this.linkType === this.PREFERENCE_PAGE_LINK && this.pagePreferences.length === 0) {
				showSnackbar({ text: 'Please add at least one preference in the preferences tab', color: 'red' })
				return
			}
			await this.finishButtonAction()
			this.$root.$emit('show-link-view-link-step')
			const steps = JSON.parse(JSON.stringify(this.steps))
			steps[this.currentStep].stepComplete = true
			this.updateSteps(steps)
			this.updateCurrentStep(this.steps.length - 1)
		},
		updateCurrentStep (currentStep) {
			this.$emit('update:currentStep', currentStep)
		},
		updateSteps (steps) {
			this.$emit('update:steps', steps)
		},
		previous () {
			this.updateCurrentStep(this.currentStep - 1)
		},
		next () {
			if (this.linkType === this.PREFERENCE_PAGE_LINK && this.currentStep === this.PREFERENCES_STEP && this.pagePreferences.length === 0) {
				showSnackbar({ text: 'Please add at least one preference', color: 'red' })
				return
			}
			const steps = JSON.parse(JSON.stringify(this.steps))
			steps.forEach((step, index) => {
				if (index <= this.currentStep) step.stepComplete = true
			})
			this.updateSteps(steps)
			this.updateCurrentStep(this.currentStep + 1)
		}
	}
}
</script>
