<template>
	<div class="cassie-vertical-md pt-4">
		<FilterBar
			:search-query.sync="consentCollectionWidgetSearchQuery"
			search-query-label="Widget Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changeConsentCollectionWidgetSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataSearchQuery="changeMetaDataValueSearchQuery"
			@persistSelectedMetaDataKeyId="changeMetaDataKey"
		>
			<template #after-filters>
				<Dropdown
					v-model="consentCollectionWidgetTranslationStatusId"
					:items="translationStatusItems"
					custom-sort
					label="Status"
					@input="changeConsentCollectionWidgetTranslationStatusId($event)"
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Widget Translations
			</template>
			<template #title-action>
				<Dropdown
					:value="consentCollectionWidgetLanguageSelected"
					label="Language"
					searchable
					:items="assignedLanguagesItems"
					@input="changeLanguage($event)"
				/>
			</template>
			<template #body>
				<DataTable
					sort-desc
					sort-by="consentCollectionWidgetID"
					:headers="tableHeaders"
					:items="filteredTranslations"
					@click:row="onEditClick"
				>
					<template #item.translated="{ item }">
						{{ item.translated ? TRANSLATED : NO_TRANSLATION }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandID)"
							tooltip-text="Edit Consent Collection Widget Translation"
							@click="onEditClick(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Consent Collection Widget Translation"
							@click="onEditClick(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandID) && item.translated"
							tooltip-text="Delete Consent Collection Widget Translation"
							@click.stop.prevent="consentCollectionWidgetTranslationToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="consentCollectionWidgetTranslationToRemove"
			:entity-name="consentCollectionWidgetTranslationToRemove.name"
			entity-type="Consent Collection Widget Translation"
			@close="consentCollectionWidgetTranslationToRemove = null"
			@delete="deleteConsentCollectionWidgetTranslation"
		/>
	</div>
</template>

<script>
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import { getConsentCollectionWidgetTranslations, deleteConsentCollectionWidgetTranslation } from '../../../../../../shared/utils/api/consent-collection-widgets.js'
import { metaDataValueSearchQuery, selectedMetaDataKey, changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../../shared/state/meta-data.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId, brandOptions, UNIVERSAL_BRAND } from '../../../../../../shared/state/brands.js'
import {
	changeConsentCollectionWidgetSearchQuery,
	consentCollectionWidgetSearchQuery,
	consentCollectionWidgetLanguageSelected,
	changeConsentCollectionWidgetLanguageSelected,
	changeConsentCollectionWidgetTranslationStatusId,
	consentCollectionWidgetTranslationStatusId
} from '../../../../../../shared/state/consent-collection-widgets.js'
import { MANAGE_CONSENT_COLLECTION_WIDGET_TRANSLATION } from '../../../../router/route-names.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
export default {
	components: {
		SectionCard,
		FilterBar,
		DataTable,
		IconButton,
		Dropdown,
		ConfirmDeleteModal
	},
	props: {
		userFullPermissions: Boolean,
		userHasBrand: Function,
		assignedLanguages: Array
	},
	setup () {
		return {
			metaDataValueSearchQuery,
			selectedMetaDataKey,
			changeMetaDataKey,
			changeMetaDataValueSearchQuery,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeConsentCollectionWidgetSearchQuery,
			consentCollectionWidgetSearchQuery,
			consentCollectionWidgetLanguageSelected,
			changeConsentCollectionWidgetLanguageSelected,
			changeConsentCollectionWidgetTranslationStatusId,
			consentCollectionWidgetTranslationStatusId,
			brandOptions,
			UNIVERSAL_BRAND,
			MANAGE_CONSENT_COLLECTION_WIDGET_TRANSLATION
		}
	},
	data () {
		return {
			NO_TRANSLATION: 'Untranslated',
			TRANSLATED: 'Translated',
			consentCollectionWidgetTranslationToRemove: null,
			consentCollectionWidgetTranslations: []
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'ID', value: 'consentCollectionWidgetID', width: '10%' },
				{ text: 'Brand', value: 'brandName', width: '20%' },
				{ text: 'Widget Name', value: 'name', width: '20%' },
				{ text: 'Status', value: 'translated', width: '20%' },
				{ text: 'Last Updated', value: 'lastUpdated', width: '20%' },
				{ text: 'Action', value: 'action', sortable: false, width: '10%' }
			]
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		filteredTranslations () {
			return this.consentCollectionWidgetTranslations.filter(({ name, translated, brandID, assignedMetadata }) => {
				let check = true
				if (consentCollectionWidgetSearchQuery.value) check = name.toLowerCase().includes(consentCollectionWidgetSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandID === selectedAdminPortalBrandId.value
				if (consentCollectionWidgetTranslationStatusId.value) check = check && translated === consentCollectionWidgetTranslationStatusId.value
				if (this.selectedMetaDataKey !== null) check = check && assignedMetadata?.some(meta => meta.externalMetaDataId === this.selectedMetaDataKey)
				if (this.metaDataValueSearchQuery) {
					check = check && assignedMetadata?.some(meta => meta.externalDataValue.toLowerCase().includes(this.metaDataValueSearchQuery.toLowerCase()))
				}
				return check
			})
		},
		translationStatusItems () {
			return [
				{ value: null, text: 'View All' },
				{ value: true, text: this.TRANSLATED },
				{ value: false, text: this.NO_TRANSLATION }
			]
		}
	},
	async created () {
		this.loadConsentCollectionWidgetTranslations(consentCollectionWidgetLanguageSelected.value
			? consentCollectionWidgetLanguageSelected.value
			: this.assignedLanguagesItems[0]?.value ?? 0)
	},
	methods: {
		clearFilters () {
			changeConsentCollectionWidgetTranslationStatusId(null)
		},
		async changeLanguage (languageId) {
			await this.loadConsentCollectionWidgetTranslations(languageId)
		},
		async loadConsentCollectionWidgetTranslations (languageId) {
			changeConsentCollectionWidgetLanguageSelected(languageId)
			const { consentCollectionWidgetTranslations } = await getConsentCollectionWidgetTranslations(languageId)
			this.consentCollectionWidgetTranslations = consentCollectionWidgetTranslations
		},
		onEditClick (item) {
			this.$router.push({
				name: MANAGE_CONSENT_COLLECTION_WIDGET_TRANSLATION,
				query: {
					id: item.consentCollectionWidgetID,
					languageId: consentCollectionWidgetLanguageSelected.value
				}
			})
		},
		async deleteConsentCollectionWidgetTranslation () {
			await deleteConsentCollectionWidgetTranslation(this.consentCollectionWidgetTranslationToRemove.consentCollectionWidgetID, consentCollectionWidgetLanguageSelected.value)
			this.consentCollectionWidgetTranslationToRemove = null
			this.loadConsentCollectionWidgetTranslations(consentCollectionWidgetLanguageSelected.value)
			showSnackbar('Consent Collection Widget Translation Deleted')
		}
	}
}
</script>
