<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="cookieScriptSearchQuery"
			search-query-label="Cookie Script Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changeCookieScriptSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedCookieScriptType"
					label="Cookie Script Type"
					:items="scriptTypeFilterItems"
					custom-sort
					@change="changeCookieScriptType"
				/>
				<Dropdown
					v-model="selectedCookieCategory"
					label="Cookie Category"
					:items="categoryFilterItems"
					custom-sort
					@change="changeCookieCategory"
				/>
				<Dropdown
					v-model="selectedTranslationStatus"
					label="Translation Status"
					:items="translationStatusItems"
					custom-sort
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ 'Cookie Script Translations' | useLabels }}
			</template>
			<template #title-action>
				<Dropdown
					:value="selectedLanguageIdOrDefault"
					label="Language"
					searchable
					:items="assignedLanguagesItems"
					@input="selectedLanguageId = $event"
				/>
			</template>
			<template #body>
				<DataTable
					:items="filteredScriptTranslations"
					:headers="tableHeaders"
					sort-by="id"
					:sort-desc="true"
					@click:row="cookieScriptTranslationToEdit = $event"
				>
					<template #item.type="{ item }">
						{{ item.isStrictlyCategory ? 'Strictly Necessary' : 'User Consent' }}
					</template>
					<template #item.cookieScriptTranslated="{ item }">
						{{ getTranslationStatus(item) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userCanCreateUpdate && userHasBrand(item.brandId)"
							tooltip-text="Edit Script Translation"
							@click="cookieScriptTranslationToEdit = item"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else-if="item.scriptTranslationId"
							tooltip-text="View Script Translation"
							@click="cookieScriptTranslationToEdit = item"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userCanDelete && item.scriptTranslationId && userHasBrand(item.brandId)"
							tooltip-text="Delete Script Translation"
							@click.stop.prevent="scriptTranslationToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<CookieScriptTranslationModal
			v-if="cookieScriptTranslationToEdit"
			:cookie-script-translation="cookieScriptTranslationToEdit"
			:user-can-create-update="userCanCreateUpdate && userHasBrand(cookieScriptTranslationToEdit.brandId)"
			@close="cookieScriptTranslationToEdit = null"
			@submit="submitCookieScriptTranslation"
		/>
		<ConfirmDeleteModal
			v-if="scriptTranslationToRemove"
			:entity-name="scriptTranslationToRemove.cookieScriptName"
			entity-type="Cookie Script Translation"
			@close="scriptTranslationToRemove = null"
			@delete="removeScriptTranslation"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import ConfirmDeleteModal from '../../../../../../../shared/components/confirm-delete-modal.vue'
import CookieScriptTranslationModal from './cookie-script-translation-modal.vue'
import { getAssignedLanguages } from '../../../../../../../shared/utils/api/languages.js'
import { useLabels } from '../../../../../../../shared/state/admin-portal-navigation.js'
import { cookieScriptSearchQuery, changeCookieScriptSearchQuery, changeCookieScriptType, selectedCookieScriptType, changeCookieCategory, selectedCookieCategory } from '../../../../../../../shared/state/cookie-scripts.js'
import { selectedAdminPortalBrandId, changeAdminPortalBrandFilter, userHasBrand } from '../../../../../../../shared/state/brands.js'
import { deleteCookieScriptTranslation } from '../../../../../../../shared/utils/api/cookies/cookie-scripts.js'
import { COOKIE_SCRIPTS_FULL_PERMISSIONS, CAN_CREATE_UPDATE_COOKIE_SCRIPTS, CAN_DELETE_COOKIE_SCRIPTS } from '../../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		FilterBar,
		SectionCard,
		Dropdown,
		DataTable,
		IconButton,
		CookieScriptTranslationModal,
		ConfirmDeleteModal
	},
	props: {
		cookieScripts: Array,
		cookieCategories: Array
	},
	setup () {
		return {
			userHasBrand,
			useLabels,
			cookieScriptSearchQuery,
			changeCookieScriptSearchQuery,
			changeCookieScriptType,
			selectedCookieScriptType,
			changeCookieCategory,
			selectedCookieCategory,
			selectedAdminPortalBrandId,
			changeAdminPortalBrandFilter
		}
	},
	data () {
		return {
			selectedTranslationStatus: null,
			selectedLanguageId: null,
			assignedLanguages: [],
			cookieScriptTranslationToEdit: null,
			scriptTranslationToRemove: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		translationStatusItems () {
			return [
				{ value: null, text: 'View All' },
				{ value: true, text: 'Translated Only' },
				{ value: false, text: 'Untranslated Only' }
			]
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		selectedLanguageIdOrDefault () {
			if (this.selectedLanguageId !== null) return this.selectedLanguageId
			return this.assignedLanguagesItems[0]?.value
		},
		scriptTypeFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Essential',
					value: 'Essential'
				},
				{
					text: 'Legitimate Interest',
					value: 'Legitimate Interest'
				},
				{
					text: 'User Consent',
					value: 'User Consent'
				}
			]
		},
		categoryFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				...this.cookieCategories.map(({ id, categoryName }) => ({
					text: categoryName,
					value: id
				})).sort((a, b) => a.text.localeCompare(b.text))
			]
		},
		tableHeaders () {
			return [
				{ value: 'id', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'cookieScriptName', text: 'Cookie Script Name', width: '20%' },
				{ value: 'cookieScriptType', text: 'Cookie Script Type', width: '15%' },
				{ value: 'cookieScriptTranslated', text: 'Translation Status', width: '15%' },
				{ value: 'createdDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '15%', sortable: false }
			]
		},
		cookieScriptTranslations () {
			return this.cookieScripts.map(script => {
				const scriptTranslation = script.translations?.find(({ languageId }) => languageId === this.selectedLanguageIdOrDefault)
				const language = this.assignedLanguagesItems.find(({ value }) => value === this.selectedLanguageIdOrDefault)
				return {
					...script,
					scriptTranslationId: scriptTranslation?.scriptTranslationId,
					translatedScriptName: scriptTranslation?.cookieScriptName,
					translatedDisplayText: scriptTranslation?.displayText,
					translatedUrl: scriptTranslation?.informationUrl,
					languageName: language?.text,
					languageId: language?.value
				}
			})
		},
		filteredScriptTranslations () {
			return this.cookieScriptTranslations.filter(({ cookieScriptName, brandId, cookieScriptType, cookieCategoryId, scriptTranslationId }) => {
				let check = true
				if (cookieScriptSearchQuery.value) check = cookieScriptName.toLowerCase().includes(cookieScriptSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				if (selectedCookieScriptType.value !== null) check = check && cookieScriptType === selectedCookieScriptType.value
				if (selectedCookieCategory.value !== null) check = check && cookieCategoryId === selectedCookieCategory.value
				if (this.selectedTranslationStatus !== null) check = check && (this.selectedTranslationStatus ? Boolean(scriptTranslationId) : !scriptTranslationId)
				return check
			})
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_SCRIPTS) || this.productAreaPermission(COOKIE_SCRIPTS_FULL_PERMISSIONS)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_COOKIE_SCRIPTS) || this.productAreaPermission(COOKIE_SCRIPTS_FULL_PERMISSIONS)
		}
	},
	created () {
		this.loadLanguages()
	},
	methods: {
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		getTranslationStatus (script) {
			if (script.scriptTranslationId) return 'Translated'
			return 'Untranslated'
		},
		clearFilters () {
			selectedCookieScriptType.value = null
			selectedCookieCategory.value = null
		},
		async removeScriptTranslation () {
			const scriptTranslationToDelete = {
				languageId: this.scriptTranslationToRemove.languageId,
				channelId: this.scriptTranslationToRemove.cookieScriptChannelId
			}
			await deleteCookieScriptTranslation(scriptTranslationToDelete)
			this.scriptTranslationToRemove = null
			this.$emit('load')
		},
		submitCookieScriptTranslation () {
			this.cookieScriptTranslationToEdit = null
			this.$emit('load')
		}
	}
}
</script>
