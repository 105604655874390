<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="850px">
			<template #modal-title>
				{{ isEdit ? 'Edit' : 'Create' }} Transaction Type
				<v-spacer />
				<IconButton @click="$emit('close')">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<Message
					v-if="isEdit && transactionType.isSystem"
					type="warning"
				>
					You cannot change details of a default transaction type
				</Message>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="6">
							<TextField
								v-model="transactionType.name"
								:disabled="!canCreateAndUpdate || transactionType.isSystem"
								label="Name *"
								rules="required"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Textarea
								v-model="transactionType.description"
								label="Description"
								:disabled="!canCreateAndUpdate || transactionType.isSystem"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<Dropdown
								v-model="transactionType.securityLevelId"
								:disabled="!canCreateAndUpdate || transactionType.isSystem"
								:items="securityLevels"
								item-value="id"
								item-text="name"
								label="Security Level *"
								rules="required"
								tooltip-text="An 'Accessible' Consent Category allows any Cookies or Channels to be available to Cassie's Unauthenticated Applications such as Embedded Consent Collections & Cookie Banners.<br><br>
								A 'Private' Consent Category will only surface information on Cookies or Channels to Cassie Applications that require Authentication before obtaining any information such as the Cassie API Suite."
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Toggle
								v-if="isUniversalConsentAllowed"
								v-model="transactionType.enableUniversalConsent"
								label="Enable Universal Consent"
								class="cassie-input-width-md"
								:value.sync="transactionType.enableUniversalConsent"
								tooltip-text="Enabling Universal Consent will allow Cassie to update Consent settings for a known Data Subject when moving between their devices."
								:disabled="!canCreateAndUpdate || transactionType.isSystem"
							/>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="canCreateAndUpdate"
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import Modal from '../../../../../shared/components/modal.vue'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import { createTransactionType, updateTransactionType } from '../../../../../shared/utils/api/transaction-types.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import Textarea from '../../../../../shared/components/textarea.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import Message from '../../../../../shared/components/message.vue'

export default {
	components: {
		Modal,
		ValidationForm,
		IconButton,
		TextField,
		Dropdown,
		SecondaryActionButton,
		PrimaryActionButton,
		Textarea,
		Toggle,
		Message
	},
	props: {
		typeToEdit: Object,
		canCreateAndUpdate: Boolean,
		securityLevels: Array
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			transactionType: JSON.parse(JSON.stringify(this.typeToEdit || {
				name: '',
				description: null,
				securityLevelId: null,
				enableUniversalConsent: false
			}))
		}
	},
	computed: {
		isEdit () {
			return !!this.typeToEdit
		},
		isUniversalConsentAllowed () {
			const selectedLevel = this.securityLevels.find(level => level.id === this.transactionType.securityLevelId)
			return selectedLevel ? selectedLevel.universalConsentAllowed : false
		}
	},
	watch: {
		'transactionType.securityLevelId': function (newVal, oldVal) {
			if (newVal !== oldVal) {
				const selectedLevel = this.securityLevels.find(level => level.id === newVal)
				if (selectedLevel && !selectedLevel.universalConsentAllowed) {
					this.transactionType.enableUniversalConsent = false
				}
			}
		}
	},
	methods: {
		async submit () {
			const transactionType = {}
			transactionType.name = this.transactionType.name
			transactionType.description = this.transactionType.description
			transactionType.securityLevelId = this.transactionType.securityLevelId
			transactionType.enableUniversalConsent = this.transactionType.enableUniversalConsent

			if (!this.isEdit) {
				await createTransactionType(transactionType)
				showSnackbar('Transaction Type created successfully.')
			} else {
				transactionType.transactionTypeId = this.transactionType.id
				await updateTransactionType(transactionType)
				showSnackbar('Transaction Type updated successfully.')
			}
			this.$emit('submit')
		}
	}
}
</script>
