<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Widget Embed Code
			</template>
			<template #body>
				<v-row
					v-if="assignedLanguages && assignedLanguages.length"
					dense
				>
					<v-col cols="4">
						<Dropdown
							v-model="selectedLanguage"
							custom-sort
							label="Language"
							searchable
							:items="assignedLanguages"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="10">
						<p class="mb-0">
							1 - On your web form, you need to paste the below component into the body, as shown below (where we have pre-populated the value). The content of this component must be left blank - it will be loaded with your form {{ 'statements' | useLabels }} from Cassie.
						</p>
					</v-col>
					<v-spacer />
					<TextButton
						@click="copyCode(webComponentBodyEmbedCode)"
					>
						Copy
					</TextButton>
				</v-row>
				<Prism
					language="javascript"
					:code="webComponentBodyEmbedCode"
				/>
				<v-row>
					<v-col cols="10">
						<p class="mb-0">
							2 - Please paste this into the head of your HTML document.
						</p>
					</v-col>
					<v-spacer />
					<TextButton
						@click="copyCode(webComponentHeadEmbedCode)"
					>
						Copy
					</TextButton>
				</v-row>
				<Prism
					:code="webComponentHeadEmbedCode"
					language="javascript"
				/>
			</template>
		</SectionCard>
	</div>
</template>

<script>
import Prism from 'vue-prism-component'
import { getEnv } from '../../../../../../shared/utils/runtime-settings.js'
import TextButton from '../../../../../../shared/components/text-button.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import { licenseId } from '../../../../../../shared/state/config-keys.js'
import { consentCollectionWidgetMinifiedCode } from '../../preference-widgets/widget-embed-code-templates.js'
import { getPreferenceWidgetAccessKeys } from '../../../../../../shared/utils/api/access-keys.js'
import Dropdown from '../../../../../../shared/components/dropdown.vue'

export default {
	components: {
		SectionCard,
		Prism,
		TextButton,
		Dropdown
	},
	props: {
		consentCollectionWidget: Object,
		assignedLanguages: Array
	},
	data () {
		return {
			selectedLanguage: null
		}
	},
	computed: {
		widgetCassieUrl () {
			return getEnv('VUE_APP_WIDGET_URL').replace('widget.js', 'formrenderer')
		},
		widgetUrl () {
			return getEnv('VUE_APP_WIDGET_URL').replace('cassiecloud.com/widget.js', `\${d}/formrenderer`)
		},
		widgetRegionAndEnvironment () {
			return getEnv('VUE_APP_WIDGET_URL').replace('https://wscript-cdn-', '').replace('.cassiecloud.com/widget.js', '')
		},
		widgetEnvironmentParam () {
			if (this.widgetRegionAndEnvironment.indexOf('-') !== -1) {
				return 'env="' + this.widgetRegionAndEnvironment.split('-')[1].toLowerCase() + '"'
			}
			return ''
		},
		widgetLanguageParam () {
			if (this.selectedLanguage !== null) {
				const languageCode = this.assignedLanguages.find(({ value: languageID }) => languageID === this.selectedLanguage).languageCode
				return `language="${languageCode ?? 'default'}" `
			}
			return 'language="default" '
		},
		widgetRegionParam () {
			if (this.widgetRegionAndEnvironment.indexOf('-') !== -1) {
				return 'region="' + this.widgetRegionAndEnvironment.split('-')[0].toLowerCase() + '" '
			}
			return 'region="' + this.widgetRegionAndEnvironment + '"'
		},
		accessKeyItems () {
			if (!this.accessKeys) return []
			const accessKeys = this.accessKeys.map(({ brandId, accessKey, accessKeyName }) => ({
				brandId,
				text: `${accessKeyName} - ${accessKey}`,
				value: accessKey.toUpperCase()
			}))
			return accessKeys.filter(({ brandId }) => brandId === this.consentCollectionWidget.brandId || brandId === 0)
		},
		webComponentHeadEmbedCode () {
			const widgetScriptUrl = this.widgetUrl.concat('/scripts/widget.js')
			const widgetScriptCassieUrl = this.widgetCassieUrl.concat('/scripts/widget.js')
			return consentCollectionWidgetMinifiedCode
				.replace('$widgetScriptUrl', widgetScriptUrl)
				.replaceAll('$widgetScriptCassieUrl', widgetScriptCassieUrl)
		},
		webComponentBodyEmbedCode () {
			if (!this.consentCollectionWidget) {
				return '<inline-modal widget-id="PrefWidget" license-key="license-key"></inline-modal>'
			} else {
				return `<inline-modal widget-id="${this.consentCollectionWidget.consentCollectionWidgetGUID.toUpperCase()}" license-key="${licenseId.value}" ${this.widgetLanguageParam}${this.widgetRegionParam}${this.widgetEnvironmentParam}></inline-modal>`
			}
		}
	},
	async created () {
		await this.getAccessKeys()
	},
	methods: {
		async getAccessKeys () {
			const { preferenceWidgetAccessKeys } = await getPreferenceWidgetAccessKeys()
			this.accessKeys = preferenceWidgetAccessKeys
		},
		copyCode (snippet) {
			navigator.clipboard.writeText(snippet)
		}
	}
}
</script>
