<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="600px">
			<template #modal-title>
				{{ modalTitle }}
			</template>
			<template #modal-content>
				<v-row dense>
					<v-col cols="12">
						<Dropdown
							v-model="extendedPreference.keyId"
							label="Extended Preference *"
							searchable
							:items="extendedPreferencesItemsMapped"
							:disabled="isEdit"
							rules="required"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<TextField
							v-model="extendedPreference.defaultFreeTextValue"
							label="Associated Value *"
							rules="required"
						/>
					</v-col>
				</v-row>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton
					@click="$emit('close')"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					@click="handleSubmit(submitExtendedPreference)"
				>
					{{ isEdit ? 'Update' : 'Add' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import Modal from '../../../../../shared/components/modal.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'

export default {
	components: {
		ValidationForm,
		Modal,
		Dropdown,
		TextField,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		extendedPreferenceToEdit: Object,
		extendedPreferenceKeys: Array,
		actionLinkExtendedPreferences: Array,
		selectedChannelItem: Object
	},
	data () {
		return {
			extendedPreference: JSON.parse(JSON.stringify(this.extendedPreferenceToEdit || {
				channelId: this.selectedChannelItem.channelId,
				channelName: this.selectedChannelItem.channelName,
				keyId: null,
				defaultFreeTextValue: null
			}))
		}
	},
	computed: {
		isEdit () {
			return !!this.extendedPreferenceToEdit
		},
		modalTitle () {
			return `${this.isEdit ? 'Edit' : 'Create'} Extended Preference`
		},
		extendedPreferencesItemsMapped () {
			// filter out extended preferences that are already added apart from the one being edited
			const unavailableExtendedPreferenceIds = this.actionLinkExtendedPreferences.map(
				({ keyId }) => keyId
			).filter(keyId => keyId !== this.extendedPreferenceToEdit?.keyId)
			const availableExtendedPreferences = this.extendedPreferenceKeys.filter(
				extendedPref => !unavailableExtendedPreferenceIds.includes(extendedPref.extendedPreferenceId)
			)
			return availableExtendedPreferences.map(extendedPref => ({
				value: extendedPref.extendedPreferenceId,
				text: extendedPref.name
			}))
		}
	},
	methods: {
		submitExtendedPreference () {
			if (this.isEdit) {
				// find the extended preference and update it
				const existingExtendedPreferences = this.actionLinkExtendedPreferences
				this.$set(existingExtendedPreferences, this.extendedPreference.index, this.extendedPreference)
				this.$emit('submit', existingExtendedPreferences)
			} else {
				this.$emit('submit', [
					...this.actionLinkExtendedPreferences,
					this.extendedPreference
				])
			}
		}
	}
}
</script>
