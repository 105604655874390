<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Outcomes
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<TextField
							:value.sync="consentCollectionWidget.outcomeSuccessInlineHeadline"
							label="Success Headline *"
							rules="required"
							test-id="widget_outcome_success_headline"
							:disabled="!userFullPermissions"
							@input="updateConsentCollectionWidget('outcomeSuccessInlineHeadline', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<Textarea
							:value.sync="consentCollectionWidget.outcomeSuccessInlineMessage"
							label="Success Message *"
							rules="required"
							test-id="widget_outcome_success_message"
							:disabled="!userFullPermissions"
							@input="updateConsentCollectionWidget('outcomeSuccessInlineMessage', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							:value.sync="consentCollectionWidget.confirmationEmailId"
							label="Confirmation Email"
							:items="confirmationEmails"
							searchable
							test-id="widget_outcome_confirmation_email"
							:disabled="!userFullPermissions"
							clearable
							@input="updateConsentCollectionWidget('confirmationEmailId', $event)"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<ConsentCollectionPrivacyPolicyCard
			:assigned-languages="assignedLanguages"
			:user-full-permissions="userFullPermissions"
			:custom-privacy-policies.sync="consentCollectionWidget.customPrivacyPolicies"
			:brand-id.sync="consentCollectionWidget.brandId"
			type="widget"
			@update-privacy-policies="updatePrivacyPolicies($event)"
			@update-privacy-policies-enabled="updatePrivacyPoliciesEnabled($event)"
		/>
	</div>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import Textarea from '../../../../../../shared/components/textarea.vue'
import ConsentCollectionPrivacyPolicyCard from '../consent-collection-privacy-policy-card.vue'

export default {
	components: {
		SectionCard,
		TextField,
		Textarea,
		Dropdown,
		ConsentCollectionPrivacyPolicyCard
	},
	props: {
		consentCollectionWidget: Object,
		assignedLanguages: Array,
		userFullPermissions: Boolean,
		confirmationEmails: Array
	},
	methods: {
		updatePrivacyPolicies (privacyPolicies) {
			this.updateConsentCollectionWidget('customPrivacyPolicies', privacyPolicies)
		},
		updatePrivacyPoliciesEnabled (isEnabled) {
			this.updateConsentCollectionWidget('customPrivacyPoliciesEnabled', isEnabled)
		},
		updateConsentCollectionWidget (property, value) {
			this.$emit('update:consentCollectionWidget', {
				...this.consentCollectionWidget,
				[property]: value
			})
		}
	}
}
</script>
