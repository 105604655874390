<template>
	<ViewLayout>
		<template #header-title>
			Public Portal Links
		</template>
		<template #header-caption>
			Add, edit and manage your public portal links
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<PublicPortalLinkTab
						:public-portal-links="publicPortalLinks"
						:look-up-data="lookUpData"
						:user-full-permissions="userFullPermissions"
						:user-read-only="userReadOnly"
						@load-links="loadLinks"
					/>
				</template>
				<template #1>
					<LinkTranslationsTab
						:user-full-permissions="userFullPermissions"
						:user-read-only="userReadOnly"
						:public-portal-links="publicPortalLinks"
						:link-types="lookUpData.linkTypes"
						@load-links="loadLinks"
					/>
				</template>
			</TabItems>
		</template>
	</ViewLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import { getLinks } from '../../../../../../shared/utils/api/admin-portal/links/links.js'
import { changePublicPortalLinkSearchQuery, publicPortalLinkSearchQuery } from '../../../../../../shared/state/links.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../shared/state/brands.js'
import { MANAGE_PUBLIC_PORTAL_LINK } from '../../../../router/route-names.js'
import { PUBLIC_PORTAL_LINK_TYPE } from '../link-types.js'
import { SEE_LINKS_AREA, LINKS_AREA_FULL_PERMISSIONS, PP_LINKS_PAGE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import PublicPortalLinkTab from './public-portal-links-tab.vue'
import LinkTranslationsTab from '../shared/link-translations-tab.vue'

export default {
	components: { ViewLayout, Tabs, TabItems, PublicPortalLinkTab, LinkTranslationsTab },
	props: {
		accessedViaTranslations: Boolean
	},
	setup () {
		return {
			changePublicPortalLinkSearchQuery,
			publicPortalLinkSearchQuery,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId
		}
	},
	data () {
		return {
			tabs: [
				{ title: 'Public Portal Links', slot: '0' },
				{ title: 'Public Portal Link Translations', slot: '1' }],
			tab: '0',
			publicPortalLinks: [],
			lookUpData: {}
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		userFullPermissions () {
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(PP_LINKS_PAGE_FULL_PERMISSIONS)
		},
		userReadOnly () {
			return this.productAreaPermission(SEE_LINKS_AREA)
		}
	},
	created () {
		this.loadLinks()
		if (this.accessedViaTranslations) {
			this.tab = '1'
		} else {
			this.tab = '0'
		}
	},
	methods: {
		async loadLinks () {
			const mapTableDisplayData = links => links.map(link => ({
				...link,
				authenticationType: link.authenticationDetails?.authTypeDescription,
				publicPortalLandingPage: link.publicPortalLinkConfig?.selectedPublicPortalLandingPageDescription
			}))
			const { data: { publicPortalLinks, lookUpData } } = await getLinks()
			this.lookUpData = lookUpData
			this.publicPortalLinks = mapTableDisplayData(publicPortalLinks)
		},
		onEditLink (link) {
			this.$router.push({
				name: MANAGE_PUBLIC_PORTAL_LINK,
				params: {
					linkToEdit: link
				},
				query: {
					linkType: PUBLIC_PORTAL_LINK_TYPE
				}
			})
		},
		createPublicPortalLink () {
			this.$router.push({
				name: MANAGE_PUBLIC_PORTAL_LINK,
				query: {
					linkType: PUBLIC_PORTAL_LINK_TYPE
				}
			})
		}
	}
}
</script>
