<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="cassie-vertical-md"
	>
		<CookieTemplateLayout
			:header-title="'Configure Cookie Collection Template' | useLabels('Configure Cookie Collection Template')"
			:header-caption="'Configure a collection of cookie categories and scripts' | useLabels('Configure a collection of cookie categories and scripts')"
		>
			<template #content>
				<Message
					v-if="cookieCollectionTemplate.isSystem && !cloneMode"
					type="warning"
				>
					You cannot edit system default Cookie Collection Templates. Please ensure they meet your business needs before use or clone this and edit accordingly.
				</Message>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<TextField
									v-model="cookieCollectionTemplate.name"
									label="Name *"
									:disabled="!userCanCreateUpdate || (cookieCollectionTemplate.isSystem && !cloneMode)"
									:rules="{required: true, max: 255}"
								/>
							</v-col>
							<v-col cols="6">
								<Dropdown
									v-model="cookieCollectionTemplate.brandId"
									:disabled="isEdit || cloneMode || !userCanCreateUpdate || !clientMultiBranded || singleBrand || (cookieCollectionTemplate.isSystem && !cloneMode)"
									:items="brandItems"
									custom-sort
									label="Brand*"
									searchable
									:rules="{ required: clientMultiBranded}"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<Textarea
									v-model="cookieCollectionTemplate.description"
									label="Description *"
									no-resize
									:disabled="!userCanCreateUpdate || (cookieCollectionTemplate.isSystem && !cloneMode)"
									:rules="{required: true, max: 500}"
								/>
							</v-col>
						</v-row>
						<v-row v-if="cassieIdentityServiceEnabled">
							<v-col cols="4">
								<Toggle
									:value.sync="cookieCollectionTemplate.crossDomainConsent"
									label="Enable Cross Domain Consent"
									tooltip-text="This collection, once submitted will enable cookies to be accessed from one of the allowed domains. The specification of this is managed in the Cookie Banner configuration page"
									:disabled="!userCanCreateUpdate || (cookieCollectionTemplate.isSystem && !cloneMode)"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<CookieCategoriesCard
					v-if="cookieCategories.length"
					:cookie-categories="cookieCategories"
					:cookie-scripts="cookieScripts"
					:cookie-collection-template.sync="cookieCollectionTemplate"
					:brand-id.sync="cookieCollectionTemplate.brandId"
					:is-edit="isEdit"
					:user-can-create-update="userCanCreateUpdate && !(cookieCollectionTemplate.isSystem && !cloneMode)"
					@update:cookieCategories="updateCookieCollectionTemplate('cookieCategories', $event)"
					@update:cookieScripts="updateCookieCollectionTemplate('cookieScripts', $event)"
					@update:runOrderScripts="updateCookieCollectionTemplate('runOrderScripts', $event)"
					@load-cookie-categories="loadCookieCategories"
				/>
				<CookieScriptsInCategoryCard
					v-for="cookieCategory in cookieCollectionTemplate.cookieCategories"
					:key="cookieCategory.id"
					:cookie-collection-template.sync="cookieCollectionTemplate"
					:cookie-category="assignedCookieCategory(cookieCategory.id)"
					:cookie-category-scripts="cookieCategoryScripts(cookieCategory.id)"
					:cookie-categories="cookieCategories"
					:transaction-types="transactionTypes"
					:security-levels="securityLevels"
					:channel-device-copy-actions="channelDeviceCopyActions"
					:all-cookie-scripts="cookieScripts"
					:user-can-create-update="userCanCreateUpdate && !(cookieCollectionTemplate.isSystem && !cloneMode)"
					@update:cookieScripts="updateCookieCollectionTemplate('cookieScripts', $event)"
					@update:runOrderScripts="updateCookieCollectionTemplate('runOrderScripts', $event)"
					@load-cookie-scripts="loadCookieScripts"
				/>
				<CookieScriptRunOrderCard
					v-if="cookieCollectionTemplate.cookieScripts.length"
					:cookie-collection-template.sync="cookieCollectionTemplate"
					:all-cookie-scripts="cookieScripts"
					:user-can-create-update="userCanCreateUpdate && !(cookieCollectionTemplate.isSystem && !cloneMode)"
					@update:runOrderScripts="updateCookieCollectionTemplate('runOrderScripts', $event)"
				/>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							{{ backToOverviewText }}
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userCanCreateUpdate && !(cookieCollectionTemplate.isSystem && !cloneMode)"
							@click="handleSubmit(submit)"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</CookieTemplateLayout>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import Textarea from '../../../../../../../shared/components/textarea.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import CookieCategoriesCard from './cookie-categories-card.vue'
import { getTransactionTypes } from '../../../../../../../shared/utils/api/transaction-types.js'
import CookieScriptsInCategoryCard from './cookie-scripts-in-category-card.vue'
import CookieScriptRunOrderCard from './cookie-script-run-order-card.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import PageActionRow from '../../../../../../../shared/components/page-action-row.vue'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM, userHasBrand } from '../../../../../../../shared/state/brands.js'
import { getCookieScripts } from '../../../../../../../shared/utils/api/cookies/cookie-scripts.js'
import { getCookieCategories } from '../../../../../../../shared/utils/api/cookies/cookie-categories.js'
import { postCookieCollectionTemplate, putCookieCollectionTemplate } from '../../../../../../../shared/utils/api/cookies/cookie-collection-templates.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { COOKIE_COLLECTION_TEMPLATES } from '../../../../../router/route-names.js'
import { CAN_CREATE_UPDATE_COOKIE_COLLECTION_TEMPLATES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { cassieIdentityServiceEnabled } from '../../../../../../../shared/state/config-keys.js'
import Message from '../../../../../../../shared/components/message.vue'
export default {
	components: {
		CookieTemplateLayout,
		ValidationForm,
		SectionCard,
		TextField,
		Dropdown,
		Textarea,
		CookieCategoriesCard,
		CookieScriptsInCategoryCard,
		CookieScriptRunOrderCard,
		PrimaryActionButton,
		SecondaryActionButton,
		PageActionRow,
		Toggle,
		Message
	},
	props: {
		cookieCollectionTemplateToEdit: Object,
		userFullPermissions: Boolean,
		accessedVia: String,
		cloneMode: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			userHasBrand,
			brandOptions,
			showSnackbar,
			cassieIdentityServiceEnabled,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect
		}
	},
	data () {
		const ACCESSED_VIA_MANAGE_COOKIE_BANNER = 'manage-cookie-banner'
		const ACCESSED_VIA_COOOKIE_COLLECTION_TEMPLATES = 'cookie-collection-templates'
		return {
			cookieCollectionTemplate: JSON.parse(JSON.stringify(this.cookieCollectionTemplateToEdit || {
				name: '',
				brandId: null,
				description: '',
				cookieCategories: [],
				cookieScripts: [],
				runOrderScripts: [],
				crossDomainConsent: false
			})),
			cookieScripts: [],
			cookieCategories: [],
			transactionTypes: [],
			securityLevels: [],
			channelDeviceCopyActions: [],
			ACCESSED_VIA_MANAGE_COOKIE_BANNER,
			ACCESSED_VIA_COOOKIE_COLLECTION_TEMPLATES
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			if (this.cookieCollectionTemplate.isSystem && !this.cloneMode) return false
			else if (this.isEdit) return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_COLLECTION_TEMPLATES) && userHasBrand(this.cookieCollectionTemplate.brandId)
			else return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_COLLECTION_TEMPLATES)
		},
		isEdit () {
			return !!this.cookieCollectionTemplateToEdit && !this.cloneMode
		},
		backToOverviewText () {
			if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) return 'Back to Cookie Banner'
			else return 'Back to Overview'
		},
		brandItems () {
			// ensures when viewing universal object it displays in dropdown
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		}
	},
	async created () {
		if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) {
			this.scrollToTop()
		}
		await this.loadCookieScripts()
		await this.loadCookieCategories()
		await this.loadTransactionTypes()

		if (!clientMultiBranded.value) {
			this.cookieCollectionTemplate.brandId = UNIVERSAL_BRAND
		}

		if (singleBrand.value) { this.cookieCollectionTemplate.brandId = defaultBrandInSelect.value }
	},
	methods: {
		assignedCookieCategory (cookieCategoryId) {
			return this.cookieCategories?.find(({ id }) => id === cookieCategoryId)
		},
		cookieCategoryScripts (categoryId) {
			let categoryScripts = this.cookieScripts?.filter(({ cookieCategoryId }) => cookieCategoryId === categoryId)
			categoryScripts = categoryScripts.map(({ cookieScriptName, brandId, brandName, id }) => ({
				text: `${cookieScriptName} - ${brandName}`,
				brandId,
				value: id,
				runOrder: null
			}))

			if (this.cookieCollectionTemplate.brandId === UNIVERSAL_BRAND) {
				categoryScripts = categoryScripts.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				categoryScripts = categoryScripts.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.cookieCollectionTemplate.brandId)
			}

			return categoryScripts
		},
		assignedCookieCategoryScripts (categoryId) {
			return this.cookieCollectionTemplate.cookieScripts.filter(({ cookieCategoryId }) => cookieCategoryId === categoryId)
		},
		async loadCookieScripts () {
			const { cookieScripts } = await getCookieScripts()
			this.cookieScripts = cookieScripts
		},
		async loadCookieCategories () {
			const { cookieCategories } = await getCookieCategories()
			this.cookieCategories = cookieCategories
		},
		async loadTransactionTypes () {
			const { data: { transactionTypes, securityLevels, channelDeviceCopyActions } } = await getTransactionTypes()
			this.transactionTypes = transactionTypes
			this.securityLevels = securityLevels
			this.channelDeviceCopyActions = channelDeviceCopyActions
		},
		updateCookieCollectionTemplate (property, value) {
			this.cookieCollectionTemplate = {
				...this.cookieCollectionTemplate,
				[property]: value
			}
		},
		scrollToTop () {
			if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) document.querySelector('#manage_cookie_banner_container').scrollTo(0, 0)
		},
		backToOverview () {
			this.scrollToTop()
			if (this.accessedVia === this.ACCESSED_VIA_MANAGE_COOKIE_BANNER) {
				this.$emit('close')
			} else {
				this.$router.push({ name: COOKIE_COLLECTION_TEMPLATES })
			}
		},
		async submit () {
			const cookieCollectionTemplate = JSON.parse(JSON.stringify(this.cookieCollectionTemplate))
			cookieCollectionTemplate.cookieCategories = cookieCollectionTemplate.cookieCategories.map((category, index) => ({
				cookieCategoryId: category.id,
				displayOrder: index + 1
			}))
			cookieCollectionTemplate.cookieScripts = cookieCollectionTemplate.cookieScripts.map((script, index) => ({
				cookieScriptId: script.id,
				cookieCategoryId: script.categoryId,
				displayOrder: index + 1,
				runOrder: this.setScriptRunOrder(script.id)
			}))
			delete cookieCollectionTemplate.runOrderScripts
			if (!this.isEdit) {
				if (this.cloneMode) delete cookieCollectionTemplate.id
				await postCookieCollectionTemplate(cookieCollectionTemplate).then(response => {
					this.$emit('submit')
					this.$emit('get-new-collection-template-id', response.data.newCookieCollectionTemplateId)
				})
				this.showSnackbar('Cookie collection template created')
			} else {
				delete cookieCollectionTemplate.usage
				delete cookieCollectionTemplate.brandName
				await putCookieCollectionTemplate(cookieCollectionTemplate)
				this.$emit('submit')
				this.showSnackbar('Cookie collection template updated')
			}
			this.backToOverview()
		},
		setScriptRunOrder (id) {
			return this.cookieCollectionTemplate.runOrderScripts.findIndex(script => script.id === id) + 1
		}
	}
}
</script>
