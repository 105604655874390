<template>
	<div>
		<SectionCard>
			<template #title>
				Cookie Categories
			</template>
			<template #title-action>
				<TextButton
					:disabled="cookieCollectionTemplate.brandId === null || !userCanCreateUpdate"
					@click="showManageModal = true"
				>
					<v-icon>
						mdi-plus
					</v-icon>
					Create Category
				</TextButton>
			</template>
			<template #subtitle>
				Please select all of the Cookie Categories that you wish to include in this Cookie Collection Template.
			</template>
			<template #body>
				<v-row
					v-if="userCanCreateUpdate"
					dense
				>
					<v-col cols="6">
						<Dropdown
							v-model="selectedCategoryIds"
							:search-input.sync="searchInput"
							label="Cookie Categories *"
							multiple
							select-all
							searchable
							:items="availableCookieCategoryItems"
							:rules="{ requiredCookieCategories: cookieCollectionTemplate.cookieCategories.length === 0 }"
							@change="updateSelectAllChecked"
						>
							<template
								v-if="showSelectAll"
								#prepend-item
							>
								<v-list-item
									dense
									@click="toggleSelectAll"
								>
									<v-checkbox
										v-model="selectAllChecked"
										dense
										@click="toggleSelectAll"
									/>
									<v-list-item-title class="pb-2">
										Select All
									</v-list-item-title>
								</v-list-item>
								<v-divider />
							</template>
						</Dropdown>
					</v-col>
					<v-col cols="3">
						<!-- add permission & select all -->
						<PrimaryActionButton
							:disabled="!selectedCategoryIds.length"
							@click="addCategories"
						>
							Add
						</PrimaryActionButton>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<DataTable
							:items="cookieCollectionTemplate.cookieCategories"
							:headers="tableHeaders"
						>
							<template #item.categoryName="{ item }">
								{{ cookieCategoryName(item) }}
							</template>
							<template #item.type="{ item }">
								{{ cookieCategoryType(item) }}
							</template>
							<template #item.displayOrder="{ index }">
								{{ index + 1 }}
							</template>
							<template
								v-if="userCanCreateUpdate"
								#item.action="{ item, index }"
							>
								<IconButton
									tooltip-text="Edit Category"
									@click="editCookieCategory(item)"
								>
									mdi-pencil
								</IconButton>
								<IconButton
									v-if="index !== 0 && !isStrictlyCategory(item) && !categoryAfterStrictlyCategory(index)"
									@click="moveCategoryUp(index)"
								>
									mdi-arrow-up
								</IconButton>
								<IconButton
									v-if="index !== cookieCollectionTemplate.cookieCategories.length - 1 && !isStrictlyCategory(item)"
									@click="moveCategoryDown(index)"
								>
									mdi-arrow-down
								</IconButton>
								<IconButton
									v-if="!isStrictlyCategory(item)"
									@click="deleteCategory(index, item)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</v-col>
				</v-row>
			</template>
		</SectionCard>

		<CookieCategoryModal
			v-if="showManageModal || cookieCategoryToEdit && userCanCreateUpdate"
			:cookie-category-to-edit="cookieCategoryToEdit"
			accessed-via-cookie-banner-configuration
			:cookie-collection-brand-id="cookieCollectionTemplate.brandId"
			:user-full-permissions="true"
			@close="closeModal"
			@submit="submitCookieCategory"
			@get-new-category-id="addNewCategoryToCollection"
		/>
	</div>
</template>
<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import TextButton from '../../../../../../../shared/components/text-button.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import CookieCategoryModal from '../cookie-categories/cookie-category-modal.vue'
import { UNIVERSAL_BRAND } from '../../../../../../../shared/state/brands.js'

export default {
	components: {
		SectionCard,
		Dropdown,
		PrimaryActionButton,
		TextButton,
		DataTable,
		IconButton,
		CookieCategoryModal
	},
	props: {
		cookieCategories: Array,
		cookieCollectionTemplate: Object,
		isEdit: Boolean,
		userCanCreateUpdate: Boolean,
		brandId: Number
	},
	data () {
		return {
			selectedCategoryIds: [],
			cookieCategoryToEdit: null,
			showManageModal: false,
			selectAllChecked: false,
			searchInput: null
		}
	},
	computed: {
		availableCookieCategoryItems () {
			const existingCookieCategories = this.cookieCollectionTemplate.cookieCategories.map(({ id }) => id)
			let mappedCategories = this.cookieCategories.map(({ id, brandId, brandName, categoryName, isStrictlyCategory }) => ({
				value: id,
				text: `${categoryName} - ${brandName}`,
				isStrictlyCategory,
				brandId
			}))
			mappedCategories = mappedCategories.filter(({ value, isStrictlyCategory }) => !existingCookieCategories.includes(value) && !isStrictlyCategory)
			if (this.cookieCollectionTemplate.brandId === UNIVERSAL_BRAND) {
				mappedCategories = mappedCategories.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				mappedCategories = mappedCategories.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.cookieCollectionTemplate.brandId)
			}

			return mappedCategories.sort((a, b) => a.text.localeCompare(b.text))
		},
		tableHeaders () {
			return [
				{ text: 'ID', value: 'id' },
				{ text: 'Cookie Category Name', value: 'categoryName' },
				{ text: 'Type', value: 'type' },
				{ text: 'Display Order', value: 'displayOrder' },
				{ text: 'Action', value: 'action' }
			]
		},
		showSelectAll () {
			return this.availableCookieCategoryItems.length > 1
		}
	},
	watch: {
		brandId: {
			handler (brand) {
				if (!this.isEdit) {
					this.autoAddStrictlyCategory(brand)
				}
			}
		}
	},
	methods: {
		autoAddStrictlyCategory (brand) {
			const strictlyNecessaryCategory = {
				id: this.cookieCategories?.find(({ isStrictlyCategory, brandId }) => isStrictlyCategory === true && brandId === brand)?.id ??
					this.cookieCategories?.find(({ isStrictlyCategory, brandId }) => isStrictlyCategory === true && brandId === UNIVERSAL_BRAND)?.id,
				isStrictlyCategory: true
			}

			const cookieCategories = this.cookieCollectionTemplate.cookieCategories.filter(({ isStrictlyCategory }) => !isStrictlyCategory)
			this.$emit('update:cookieCategories', [
				strictlyNecessaryCategory,
				...cookieCategories
			])
		},
		cookieCategoryName (category) {
			const matchedCategory = this.cookieCategories.find(({ id }) => id === category.id)
			return `${matchedCategory.categoryName} - ${matchedCategory.brandName}`
		},
		cookieCategoryType (category) {
			return this.cookieCategories.find(({ id }) => id === category.id)?.isStrictlyCategory ? 'Strictly Necessary' : 'User Consent'
		},
		isStrictlyCategory (category) {
			return this.cookieCategories.find(({ id }) => id === category.id)?.isStrictlyCategory === true
		},
		categoryAfterStrictlyCategory (index) {
			const secondCategory = index === 1
			const previousCategoryIsStrictlyCategory = this.isStrictlyCategory(this.cookieCollectionTemplate.cookieCategories[index - 1])
			return secondCategory && previousCategoryIsStrictlyCategory
		},
		addCategories () {
			const selectedCategoryIds = this.selectedCategoryIds.map(category => ({
				id: category,
				isStrictlyCategory: this.isStrictlyCategory(this.cookieCategories.find(({ id }) => id === category))
			}))
			this.$emit('update:cookieCategories', [
				...this.cookieCollectionTemplate.cookieCategories,
				...selectedCategoryIds
			])
			this.selectedCategoryIds = []
			this.selectAllChecked = false
			this.searchInput = null
			this.selectAllChecked = false
		},
		moveCategoryDown (index) {
			this.move(this.cookieCollectionTemplate.cookieCategories, index, index + 1)
		},
		moveCategoryUp (index) {
			this.move(this.cookieCollectionTemplate.cookieCategories, index, index - 1)
		},
		applySort (array) {
			this.$emit('update:cookieCategories', array)
		},
		move (array, from, to) {
			const arrayCopy = JSON.parse(JSON.stringify(array))
			arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0])
			return this.applySort(arrayCopy)
		},
		deleteCategory (index, item) {
			// runOrderScripts = Those are cookie scripts that do not have the deleted categoryId. These can be parsed to the update runOrderScripts method.
			const runOrderScripts = this.cookieCollectionTemplate.runOrderScripts.filter(({ categoryId }) => categoryId !== item.id)
			this.$emit('update:runOrderScripts', runOrderScripts)

			const filteredScripts = this.cookieCollectionTemplate.cookieScripts.filter(({ categoryId }) => categoryId !== item.id)
			this.$emit('update:cookieScripts', filteredScripts)

			const cookieCategories = this.cookieCollectionTemplate.cookieCategories
			this.$delete(cookieCategories, index)
			this.$emit('update:cookieCategories', cookieCategories)
		},
		editCookieCategory (category) {
			this.cookieCategoryToEdit = this.cookieCategories.find(({ id }) => id === category.id)
			this.showManageModal = true
		},
		closeModal () {
			this.showManageModal = false
			this.cookieCategoryToEdit = null
		},
		submitCookieCategory () {
			this.closeModal()
			this.$emit('load-cookie-categories')
		},
		addNewCategoryToCollection (newCategoryId) {
			const newCategory = {
				id: newCategoryId,
				isStrictlyCategory: false
			}
			this.$emit('update:cookieCategories', [
				...this.cookieCollectionTemplate.cookieCategories,
				newCategory
			])
		},
		toggleSelectAll () {
			if (this.selectAllChecked) {
				this.selectAllChecked = false
				this.selectedCategoryIds = []
			} else {
				this.selectAllChecked = true
				if (this.searchInput) {
					this.selectedCategoryIds = this.availableCookieCategoryItems.filter(category => category.text.toLowerCase().includes(this.searchInput.toLowerCase()))
						.map(category => category.value)
				} else {
					this.selectedCategoryIds = this.availableCookieCategoryItems.map(category => {
						return category.value
					})
				}
			}
		},
		updateSelectAllChecked () {
			const filteredItems = this.searchInput
				? this.availableCookieCategoryItems.filter(category => category.text.toLowerCase().includes(this.searchInput.toLowerCase()))
				: this.availableCookieCategoryItems

			this.selectAllChecked = this.selectedCategoryIds.length === filteredItems.length
		}
	}
}
</script>
