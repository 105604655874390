var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cassie-vertical-md pt-4"},[_c('FilterBar',{attrs:{"search-query":_vm.consentCollectionWidgetSearchQuery,"search-query-label":"Widget Name","selected-brand-id":_vm.selectedAdminPortalBrandId,"show-meta-data-filters":"","selected-meta-data-key-id":_vm.selectedMetaDataKey,"meta-data-search-query":_vm.metaDataValueSearchQuery},on:{"update:searchQuery":function($event){_vm.consentCollectionWidgetSearchQuery=$event},"update:search-query":function($event){_vm.consentCollectionWidgetSearchQuery=$event},"update:selectedBrandId":function($event){_vm.selectedAdminPortalBrandId=$event},"update:selected-brand-id":function($event){_vm.selectedAdminPortalBrandId=$event},"update:selectedMetaDataKeyId":function($event){_vm.selectedMetaDataKey=$event},"update:selected-meta-data-key-id":function($event){_vm.selectedMetaDataKey=$event},"update:metaDataSearchQuery":function($event){_vm.metaDataValueSearchQuery=$event},"update:meta-data-search-query":function($event){_vm.metaDataValueSearchQuery=$event},"persistSearchQuery":_vm.changeConsentCollectionWidgetSearchQuery,"persistSelectedBrandId":_vm.changeAdminPortalBrandFilter,"persistMetaDataSearchQuery":_vm.changeMetaDataValueSearchQuery,"persistSelectedMetaDataKeyId":_vm.changeMetaDataKey,"clearFilters":_vm.clearFilters},scopedSlots:_vm._u([{key:"after-filters",fn:function(){return [_c('Dropdown',{attrs:{"items":_vm.statusFilterItems,"custom-sort":"","label":"Status"},on:{"input":function($event){return _vm.changeConsentCollectionWidgetStatusType($event)}},model:{value:(_vm.consentCollectionWidgetStatusType),callback:function ($$v) {_vm.consentCollectionWidgetStatusType=$$v},expression:"consentCollectionWidgetStatusType"}})]},proxy:true},{key:"action",fn:function(){return [(_vm.userFullPermissions)?_c('PrimaryActionButton',{on:{"click":function($event){return _vm.$router.push({
					name: _vm.MANAGE_CONSENT_COLLECTION_WIDGET,
					params: {
						userFullPermissions: _vm.userFullPermissions
					}
				})}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create ")],1):_vm._e()]},proxy:true}])}),_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Consent Collection Widgets ")]},proxy:true},{key:"body",fn:function(){return [_c('DataTable',{attrs:{"sort-by":"consentCollectionWidgetId","sort-desc":"","headers":_vm.tableHeaders,"items":_vm.filteredConsentCollectionWidgets},on:{"click:row":_vm.onEditClick},scopedSlots:_vm._u([{key:"item.brandName",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBrandName(item.brandId))+" ")]}},{key:"item.consentCollectionWidgetName",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.consentCollectionWidgetName)+" "),(item.status !== _vm.COMPLETE_STATUS)?_c('v-chip',{staticClass:"ml-2 aqua-blue white--text",attrs:{"x-small":""}},[_vm._v(" In Progress ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
				var item = ref.item;
return [(_vm.userFullPermissions && _vm.userHasBrand(item.brandId))?_c('IconButton',{on:{"click":function($event){return _vm.onEditClick(item)}}},[_vm._v(" mdi-pencil ")]):_c('IconButton',{on:{"click":function($event){return _vm.onEditClick(item)}}},[_vm._v(" mdi-eye ")]),(_vm.userFullPermissions && _vm.userHasBrand(item.brandId))?_c('IconButton',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.consentCollectionWidgetToRemove = item}}},[_vm._v(" mdi-trash-can ")]):_vm._e()]}}])})]},proxy:true}])}),(_vm.consentCollectionWidgetToRemove)?_c('ConfirmDeleteModal',{attrs:{"entity-name":_vm.consentCollectionWidgetToRemove.consentCollectionWidgetName,"entity-type":"Consent Collection Widget"},on:{"close":function($event){_vm.consentCollectionWidgetToRemove = null},"delete":_vm.deleteConsentCollectionWidget}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }