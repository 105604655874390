<template>
	<ValidationForm
		#default="{ handleSubmit }"
		class="cassie-vertical-md"
	>
		<StatementGroupsLayout>
			<template #content>
				<Dropdown
					v-if="clientMultiBranded"
					v-model="selectedBrandId"
					:label="brandFilterLabel"
					searchable
					class="cassie-input-width-sm"
					custom-sort
					:items="[{ value: null, text: 'View All' }, ...brandOptions]"
				/>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<Dropdown
									v-model="statementGroup.channelId"
									:items="filteredChannels"
									searchable
									:disabled="isEdit || !userFullPermissions"
									:label="'Channel *' | useLabels"
									rules="required"
									@change="clearStatements"
								/>
							</v-col>
							<v-col cols="6">
								<TextField
									v-model="statementGroup.statementGroupName"
									:disabled="!userFullPermissions"
									:label="'Statement Group Name *' | useLabels"
									rules="required"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<Textarea
									v-model="statementGroup.statementGroupDescription"
									:disabled="!userFullPermissions"
									:label="'Statement Group Description *'| useLabels"
									:rules="{ max: 1500, required: true }"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						{{ 'Statements' | useLabels }}
					</template>
					<template #body>
						<div class="d-flex flex-column cassie-vertical-md">
							<div
								v-if="!universalStatementGroupNotUser"
								class="d-flex cassie-horizontal-sm"
							>
								<Dropdown
									v-model="selectedStatement"
									:disabled="!userFullPermissions"
									:items="availableStatements"
									searchable
									:label="'Statements *' | useLabels"
									style="flex: auto;"
								/>
								<PrimaryActionButton
									v-if="userFullPermissions"
									:disabled="!selectedStatement"
									@click="addStatement"
								>
									Add
								</PrimaryActionButton>
							</div>
							<v-card
								v-if="!statementGroup.statements.length"
								flat
								style="border: 1px solid currentColor !important; height: 100px;"
								class="background pa-4 d-flex align-center justify-center"
							>
								<span>
									No {{ 'Statements' | useLabels }} have been added to this list yet
								</span>
							</v-card>
							<v-simple-table
								v-else
								dense
							>
								<template #default>
									<thead>
										<tr>
											<th
												class="text-left"
												scope="col"
											>
												Statement ID
											</th>
											<th
												class="text-left"
												scope="col"
												style="width: 70%;"
											>
												{{ 'Statement' | useLabels }}
											</th>
											<th
												class="text-left"
												scope="col"
											>
												Is Live
											</th>
											<th
												v-if="!universalStatementGroupNotUser"
												class="text-left"
												scope="col"
												style="width: 80px;"
											>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="statement in statementGroup.statements"
											:key="statement.statementId"
										>
											<td class="text-left">
												{{ statement.statementId }}
											</td>
											<td class="text-left">
												{{ getText(statement.statementId) }}
											</td>
											<td class="text-left">
												<v-switch
													v-model="statement.isLive"
													:disabled="!userFullPermissions"
												/>
											</td>
											<td class="text-left">
												<v-icon
													v-if="userFullPermissions"
													small
													@click="onRemove(statement.statementId)"
												>
													mdi-trash-can
												</v-icon>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</div>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="navigateToOverview">
							Back to overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userFullPermissions"
							@click="statementGroup.statements.length ? handleSubmit(submit) : displayNoStatementError()"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</StatementGroupsLayout>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import StatementGroupsLayout from './statement-groups-layout.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import { getPreferenceChannels } from '../../../../../shared/utils/api/channels.js'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import { STATEMENT_GROUPS } from '../../../router/route-names.js'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import Textarea from '../../../../../shared/components/textarea.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { createStatementGroup, updateStatementGroup, manageAssignedStatements } from '../../../../../shared/utils/api/statement-groups.js'
import { clientMultiBranded, brandOptions, userHasBrand } from '../../../../../shared/state/brands.js'
import { adminPortalBrandFieldLabel } from '../../../../../shared/state/config-keys.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { STATEMENT_GROUPS_PAGE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { getTruncatedText } from '../../../../../shared/utils/utils.js'

export default {
	components: { PrimaryActionButton, PageActionRow, ValidationForm, SecondaryActionButton, Dropdown, TextField, SectionCard, StatementGroupsLayout, Textarea },
	props: {
		statementGroupToEdit: Object
	},
	setup () {
		return {
			clientMultiBranded,
			brandOptions,
			adminPortalBrandFieldLabel,
			getTruncatedText
		}
	},
	data () {
		return {
			statementGroup: JSON.parse(JSON.stringify(this.statementGroupToEdit || {
				statementGroupName: null,
				statementGroupDescription: null,
				channelId: null,
				statements: []
			})),
			channels: [],
			selectedStatement: null,
			selectedBrandId: null
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		isEdit () {
			return Boolean(this.statementGroupToEdit)
		},
		selectedChannelStatements () {
			const channel = this.channels.find(({ channelId }) => channelId === this.statementGroup.channelId)
			return channel?.statements?.map(({ statementID, statementText }) => ({ value: statementID, text: statementText })) || []
		},
		userFullPermissions () {
			if (!this.isEdit) {
				return this.checkPermission(STATEMENT_GROUPS_PAGE_FULL_PERMISSIONS)
			} else {
				return this.checkPermission(STATEMENT_GROUPS_PAGE_FULL_PERMISSIONS) && userHasBrand(this.statementGroup.brandId)
			}
		},
		brandFilterLabel () {
			return `Filter ${useLabels('Channels') ?? 'Channels'} by ${adminPortalBrandFieldLabel.value}`
		},
		availableChannels () {
			const availableChannels = this.channels.filter(({ statements }) => statements.length)

			if (clientMultiBranded.value) {
				return availableChannels.map(({ brandName, channelName, channelId, brandId }) => ({
					text: `${channelName} - ${brandName}`,
					value: channelId,
					brandId: brandId
				}))
			} else {
				return availableChannels.map(({ channelName, channelId, brandId }) => ({
					text: channelName,
					value: channelId,
					brandId: brandId
				}))
			}
		},
		filteredChannels () {
			return this.availableChannels.filter(({ brandId }) => {
				let check = true
				if (this.selectedBrandId !== null) check = check && brandId === this.selectedBrandId
				return check
			})
		},
		availableStatements () {
			const selectedStatements = (this.statementGroup.statements.map(({ statementId }) => {
				return statementId
			}))
			return this.selectedChannelStatements.filter(({ value }) => !selectedStatements.includes(value)).map(statement => ({
				value: statement.value,
				text: this.getTruncatedText(statement.text, 50)
			}))
		},
		universalStatementGroupNotUser () {
			return this.statementGroup.brandId === 0 && !this.userHasUniversalBrand
		}
	},
	async created () {
		const { data: { channels } } = await getPreferenceChannels()
		this.channels = channels
	},
	methods: {
		navigateToOverview () {
			this.$router.push({
				name: STATEMENT_GROUPS
			})
		},
		clearStatements () {
			this.statementGroup.statements = []
		},
		addStatement () {
			this.statementGroup.statements.push({
				statementId: this.selectedStatement,
				isLive: true
			})
			this.selectedStatement = null
		},
		onRemove (selectedStatementId) {
			const statement = this.statementGroup.statements.find(({ statementId }) => statementId === selectedStatementId)
			const index = this.statementGroup.statements.indexOf(statement)
			if (index > -1) {
				this.statementGroup.statements.splice(index, 1)
			}
		},
		getText (value) {
			return this.selectedChannelStatements.find(option => option.value === value)?.text
		},
		async submit () {
			const statementGroup = {
				groupName: this.statementGroup.statementGroupName,
				groupDescription: this.statementGroup.statementGroupDescription
			}
			if (!this.isEdit) {
				statementGroup.channelId = this.statementGroup.channelId
				await createStatementGroup(statementGroup).then(response => {
					this.manageStatementGroupStatements(response.data.newStatementGroupId)
				})
			} else {
				statementGroup.groupId = this.statementGroup.statementGroupID
				await updateStatementGroup(statementGroup)
				this.manageStatementGroupStatements(statementGroup.groupId)
			}
			showSnackbar(useLabels(this.isEdit ? 'This statement group has been updated' : 'You have created a new statement group'))
			this.navigateToOverview()
		},
		async manageStatementGroupStatements (statementGroupId) {
			const statmentGroupStatements = {
				statementGroupId,
				assignedStatements: this.statementGroup.statements
			}
			await manageAssignedStatements(statmentGroupStatements)
		},
		displayNoStatementError () {
			showSnackbar({ color: 'red', text: useLabels('please add statements to this statement group') })
		}
	}
}
</script>
