<template>
	<validation-observer
		tag="div"
		class="cassie-vertical-md"
	>
		<SectionCard>
			<template #title>
				Request
			</template>
			<template #body>
				<div class="d-flex flex-row cassie-horizontal-sm">
					<Dropdown
						label="HTTP Method *"
						:value="httpMethod"
						:items="httpMethodItems"
						rules="required"
						class="cassie-input-width-md"
						tooltip-text="Set the HTTP method being used to transmit data"
						:disabled="!userFullPermissions"
						@input="$emit('update:httpMethod', $event)"
					/>
					<TextField
						label="Endpoint Sub-Address *"
						:value="requestUrl"
						rules="required"
						class="cassie-input-width-md"
						tooltip-text="Specify the API endpoint sub-address for this specific service. e.g. /services/data/Contact/"
						:disabled="!userFullPermissions"
						@input="$emit('update:requestUrl', $event)"
					/>
				</div>
				<Textarea
					:value="requestBody"
					label="Request Body *"
					rules="required"
					tooltip-text="Build the request body template to be used for sending data"
					:disabled="!userFullPermissions"
					@input="$emit('update:requestBody', $event)"
				/>
				<TextField
					:value="requestThrottlingWaitTimeMS"
					label="Request Throttling"
					class="cassie-input-width-md"
					type="number"
					tooltip-text="To avoid any potential request rate limit issues, the length of time between each message can be delayed.  If required, set this value here in milliseconds"
					:disabled="!userFullPermissions"
					@input="$emit('update:requestThrottlingWaitTimeMS', $event)"
				/>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Request - Custom Headers
			</template>
			<template #body>
				<div class="d-flex flex-row cassie-horizontal-sm">
					<TextField
						v-model="customHeaderName"
						class="cassie-input-width-sm"
						label="Header Name"
						tooltip-text="Input the name of the custom header to be sent as part of any outbound message to your integration system"
						:disabled="!userFullPermissions"
					/>
					<TextField
						v-model="customHeaderValue"
						class="cassie-input-width-sm"
						label="Header Value"
						tooltip-text="Input the value to be sent within this custom header"
						:disabled="!userFullPermissions"
					/>
					<PrimaryActionButton
						v-if="userFullPermissions"
						@click="addCustomHeader"
					>
						Add
					</PrimaryActionButton>
				</div>
				<v-simple-table
					v-if="customHeaders"
					dense
					light
				>
					<template #default>
						<thead>
							<tr>
								<th
									class="text-left"
									scope="col"
								>
									Header Name
								</th>
								<th
									class="text-left"
									scope="col"
								>
									Header Value
								</th>
								<th
									class="text-right"
									scope="col"
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(header, index) in customHeaders"
								:key="index"
							>
								<td>
									{{ displayCustomHeaderName(index) }}
								</td>
								<td>
									{{ displayCustomHeaderValue(index) }}
								</td>
								<td class="text-right">
									<IconButton
										v-if="userFullPermissions"
										tooltip-text="Remove Custom Header"
										@click="removeCustomHeader(index)"
									>
										mdi-trash-can
									</IconButton>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</template>
		</SectionCard>
	</validation-observer>
</template>
<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Textarea from '../../../../../../shared/components/textarea.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'

export default {
	components: { Dropdown, TextField, Textarea, SectionCard, PrimaryActionButton, IconButton },
	props: {
		httpMethod: String,
		authenticationUrl: String,
		requestUrl: String,
		requestBody: String,
		requestThrottlingWaitTimeMS: [String, Number],
		customHeaders: {
			type: Array,
			default: () => []
		},
		httpMethodItems: Array,
		userFullPermissions: Boolean
	},
	data () {
		return {
			customHeaderName: null,
			customHeaderValue: null
		}
	},
	methods: {
		updateCustomHeaders (customHeaders) {
			this.$emit('update:customHeaders', customHeaders)
		},
		removeCustomHeader (index) {
			const customHeaders = [...this.customHeaders]
			this.$delete(customHeaders, index)
			this.updateCustomHeaders(customHeaders)
		},
		addCustomHeader () {
			this.updateCustomHeaders([
				...this.customHeaders,
				{
					headerName: this.customHeaderName,
					headerValue: this.customHeaderValue
				}
			])
			this.customHeaderName = null
			this.customHeaderValue = null
		},
		displayCustomHeaderName (index) {
			return this.customHeaders[index]?.headerName
		},
		displayCustomHeaderValue (index) {
			return this.customHeaders[index]?.headerValue
		}
	}
}
</script>
