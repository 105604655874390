<template>
	<SectionCard>
		<template #title>
			<slot name="title" />
		</template>
		<template #title-action>
			<slot name="title-action" />
		</template>
		<template #body>
			<v-row no-gutters>
				<v-col :cols="6">
					<TextField
						:disabled="!canCreateAndEdit"
						:value="user.forename"
						rules="required"
						label="Forename *"
						class="mr-4"
						@input="updateUser('forename', $event)"
					/>
				</v-col>
				<v-col :cols="6">
					<TextField
						:disabled="!canCreateAndEdit"
						:value="user.surname"
						rules="required"
						label="Surname *"
						@input="updateUser('surname', $event)"
					/>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col :cols="6">
					<TextField
						:disabled="!canCreateAndEdit"
						:value="user.username"
						rules="required"
						label="Username *"
						class="mr-4"
						@input="updateUser('username', $event)"
					/>
				</v-col>
				<v-col :cols="6">
					<TextField
						:disabled="!canCreateAndEdit"
						:value="user.email"
						rules="required|email"
						label="Email Address *"
						validate-on-blur
						@input="updateUser('email', $event)"
					/>
				</v-col>
			</v-row>
			<div class="d-flex">
				<Dropdown
					:disabled="!canCreateAndEdit"
					:value="user.roleId"
					:items="productRoleOptions"
					searchable
					rules="required"
					label="User Role *"
					style="width: 300px; flex: none;"
					@input="updateUser('roleId', $event)"
				/>
				<SecondaryActionButton
					v-if="!!user.roleId"
					:input-value="showPermissions"
					class="ml-4"
					@click="showPermissions = !showPermissions"
				>
					Show permissions
				</SecondaryActionButton>
			</div>
			<PermissionsCard v-if="showPermissions">
				<CsPortalPermissions
					:permissions="selectedRolePermissions"
					readonly
				/>
			</PermissionsCard>
			<template v-if="showBrandSelector">
				<div
					v-if="!user.brandIds.includes(0)"
					class="d-flex"
				>
					<Dropdown
						:value="selectedBrand"
						:items="filteredBrandOptions"
						custom-sort
						searchable
						:label="`${brandFieldLabel} *`"
						:disabled="singleBrandOptionExists || !canCreateAndEdit"
						:rules="{ arrayIsNotEmpty: { value: user.brandIds, ignoreVModel: true } }"
						:custom-messages="{ arrayIsNotEmpty: `You must add at least one ${brandFieldLabel}` }"
						validation-mode="passive"
						style="width: 300px; flex: none;"
						class="mr-4"
						@input="changeSelectedBrand"
					/>
					<PrimaryActionButton
						:disabled="singleBrandOptionExists || !selectedBrand && selectedBrand !== 0 || !canCreateAndEdit"
						@click="addBrand"
					>
						Add
					</PrimaryActionButton>
				</div>
				<v-simple-table
					v-if="user.brandIds && user.brandIds.length && onlyShowDataSubjectsAssociatedWithSelectedBrand"
					light
					class="mb-4"
				>
					<template #default>
						<thead>
							<tr>
								<th
									scope="col"
									class="text-left"
									style="width: 100%;"
								>
									Associated {{ brandFieldLabel }}
								</th>
								<th
									scope="col"
									class="text-right"
									style="width: 90px;"
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="brandId in user.brandIds"
								:key="brandId"
							>
								<td>
									{{ getBrandText(brandId) }}
								</td>
								<td class="text-right">
									<v-btn
										:disabled="singleBrandOptionExists || !canCreateAndEdit"
										icon
										color="blue darken-2"
										@click="removeBrandId(brandId)"
									>
										<v-icon>
											mdi-close
										</v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</template>
			<slot name="bottom" />
		</template>
	</SectionCard>
</template>
<script>
import TextField from '../text-field.vue'
import Dropdown from '../dropdown.vue'
import PrimaryActionButton from '../primary-action-button.vue'
import { getProductRoles } from '../../utils/api/product-roles.js'
import SecondaryActionButton from '../secondary-action-button.vue'
import PermissionsCard from '../../../cs-portal/src/components/views/permissions-card.vue'
import {
	brandFieldLabel,
	onlyShowDataSubjectsAssociatedWithSelectedBrand
} from '../../state/configuration.js'
import CsPortalPermissions from '../cs-portal-permissions.vue'
import SectionCard from '../section-card.vue'
import { brandOptions, changeSelectedBrand, selectedBrand, showBrandSelector } from '../../state/brands.js'

export default {
	components: { SectionCard, CsPortalPermissions, PermissionsCard, SecondaryActionButton, PrimaryActionButton, Dropdown, TextField },
	props: {
		user: {
			type: Object,
			default: () => ({})
		},
		resetValidation: {
			type: Function,
			default: () => {}
		},
		canCreateAndEdit: Boolean
	},
	setup () {
		return {
			changeSelectedBrand,
			selectedBrand,
			brandFieldLabel,
			showBrandSelector,
			brandOptions,
			onlyShowDataSubjectsAssociatedWithSelectedBrand
		}
	},
	data () {
		return {
			productRoles: [],
			showPermissions: false
		}
	},
	computed: {
		filteredBrandOptions () {
			const brands = brandOptions.value
			const universal = brands.findIndex(({ value }) => value === 0)
			brands[universal].text = 'Universal'
			return brands.filter(({ value }) => !this.user.brandIds.includes(value))
		},
		productRoleOptions () {
			return this.productRoles.map(({ name, roleId }) => ({
				value: roleId,
				text: name
			}))
		},
		singleBrandOptionExists () {
			return this.brandOptions.length === 1
		},
		selectedRolePermissions () {
			return this.productRoles.find(({ roleId }) => roleId === this.user.roleId)?.permissions || []
		}
	},
	async created () {
		const { data: productRoles } = await getProductRoles(2)
		this.productRoles = productRoles
	},
	methods: {
		removeBrandId (brandId) {
			const brandIds = this.user.brandIds.filter(id => id !== brandId)
			this.updateUser('brandIds', brandIds)
			this.resetValidation()
		},
		getBrandText (brandId) {
			return this.brandOptions.find(({ value }) => value === brandId)?.text || brandId
		},
		addBrand () {
			const brandExists = this.user.brandIds.find(id => id === this.selectedBrand) !== undefined
			if (!brandExists && this.selectedBrand !== 0) {
				this.updateUser('brandIds', [
					...this.user.brandIds,
					this.selectedBrand
				])
				this.resetValidation()
			} else if (!brandExists && this.selectedBrand === 0) {
				this.updateUser('brandIds', [
					this.selectedBrand
				])
				this.resetValidation()
			}
		},
		updateUser (property, value) {
			this.$emit('update:user', {
				...this.user,
				[property]: value
			})
		}
	}
}
</script>
