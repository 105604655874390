<template>
	<ManageLinkLayout
		:current-step.sync="currentStep"
		:steps.sync="steps"
		:is-edit="isEdit"
		:link-types="lookUpData.linkTypes"
		:link-type="linkType"
		:link-has-changed="linkHasChanged"
		:back-button-action="goBackToOverview"
		:finish-button-action="finish"
		:brand-id="isEdit ? linkToEdit.brandId : userBrands[0]"
		:link-name="link.linkName"
		:link="link"
	>
		<template #content>
			<StepperItems
				:step="currentStep"
				:steps="steps"
			>
				<template #0>
					<LinkDetailsStep
						:is-enabled="link.isEnabled"
						:link-name="link.linkName"
						:link-text="link.linkText"
						:authentication-details="link.authenticationDetails"
						:brand-id="link.brandId"
						:link-type="linkType"
						:link-auth-types="lookUpData.linkAuthTypes"
						:prove-confirmation-emails="lookUpData.proveConfirmationEmails"
						:custom-default-language-id="link.customDefaultLanguageId"
						:edit-mode="isEdit"
						:user-full-permissions="userFullPermissions"
						:assigned-language-items="assignedLanguagesItems"
						hide-email-authentication-options
						@update:linkName="updateLink('linkName', $event)"
						@update:linkText="updateLink('linkText', $event)"
						@update:isEnabled="updateLink('isEnabled', $event)"
						@update:authenticationDetails="updateLink('authenticationDetails', $event)"
						@update:brandId="updateLink('brandId', $event)"
						@update:customDefaultLanguageId="updateLink('customDefaultLanguageId', $event)"
					>
						<template #details-card-bottom>
							<div class="d-flex flex-row cassie-horizontal-sm">
								<Dropdown
									:value="link.publicPortalLinkConfig ? link.publicPortalLinkConfig.selectedPublicPortalLandingPageId : null"
									:items="publicPortalLandingPageOptions"
									:disabled="!userFullPermissions || (link.brandId === 0 && !userHasUniversalBrand)"
									label="Public Portal Landing Page *"
									rules="required"
									class="cassie-input-width-md"
									@input="updateLink('publicPortalLinkConfig', {
								...(link.publicPortalLinkConfig),
								selectedPublicPortalLandingPageId: $event
									})"
								/>
							</div>
						</template>
					</LinkDetailsStep>
				</template>
				<template #1>
					<LinkFieldsStep
						:configuration-options="link.configurationOptions"
						:link-fields="link.linkFields"
						:encryption-keys.sync="lookUpData.encryptionKeys"
						:brand-id="link.brandId"
						:user-full-permissions="userFullPermissions"
						:link-type="linkType"
						:contact-fields="lookUpData.contactFields"
						@reload-look-up-data="getLinks"
						@update:configurationOptions="updateLink('configurationOptions', $event)"
						@update:linkFields="updateLink('linkFields', $event)"
					/>
				</template>
				<template #2>
					<LinkViewLinkStep
						:link-id="link.linkId || newLinkId"
						:link-type="linkType"
						:assigned-language-items="assignedLanguagesItems"
					/>
				</template>
			</StepperItems>
		</template>
	</ManageLinkLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import { PUBLIC_PORTAL_LINKS } from '../../../../router/route-names.js'
import { getLinks, upsertPublicPortalLink } from '../../../../../../shared/utils/api/admin-portal/links/links.js'
import ManageLinkLayout from '../manage-link-layout.vue'
import StepperItems from '../../../../../../shared/components/stepper-items.vue'
import LinkDetailsStep from '../link-details-step.vue'
import LinkFieldsStep from '../link-fields-step.vue'
import LinkViewLinkStep from '../link-view-link-step.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { CONFIRM_VALUE_AUTHENTICATION_TYPE, DIRECT_AUTHENTICATION_TYPE } from '../link-authentication-types.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { LINKS_AREA_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { brandOptions } from '../../../../../../shared/state/brands.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
export default {
	components: { Dropdown, LinkViewLinkStep, LinkFieldsStep, LinkDetailsStep, StepperItems, ManageLinkLayout },
	props: {
		linkToEdit: Object,
		linkType: {
			type: Number,
			required: true
		}
	},
	setup () {
		return {
			brandOptions
		}
	},
	data () {
		return {
			currentStep: 0,
			steps: [
				{ slot: 0, title: 'Details', stepNumber: '01', stepComplete: false },
				{ slot: 1, title: 'Fields', stepNumber: '02', stepComplete: false },
				{ slot: 2, title: 'Link', stepNumber: '03', stepComplete: false }
			],
			link: {
				...(this.linkToEdit || { linkFields: [{}] })
			},
			lookUpData: {},
			publicPortalLinks: {},
			embedCode: '',
			newLinkId: null,
			assignedLanguages: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand', 'userBrands']),
		linkHasChanged () {
			return JSON.stringify(this.linkToEdit) !== JSON.stringify(this.link)
		},
		isEdit () {
			return !!this.linkToEdit
		},
		publicPortalLandingPageOptions () {
			return this.lookUpData.publicPortalLandingPages?.map(({ pageId, pageName }) => ({ value: pageId, text: pageName })) || []
		},
		filteredLinkAuthTypes () {
			return this.lookUpData.linkAuthTypes?.filter(({ linkAuthTypeId }) => [DIRECT_AUTHENTICATION_TYPE, CONFIRM_VALUE_AUTHENTICATION_TYPE].includes(linkAuthTypeId)) || []
		},
		userFullPermissions () {
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS)
		},
		assignedLanguagesItems () {
			return this.assignedLanguages.map(language => ({
				...language,
				languageCode: language.languageID === 0 ? 'default' : language.languageCode,
				value: language.languageID,
				text: language.languageName
			}))
		}
	},
	async created () {
		const DEFAULT_BRAND = 0
		this.getLinks()
		this.loadLanguages()
		if (this.linkToEdit) {
			this.embedCode = this.linkToEdit.publicPortalLinkEmbedCode
		} else if (!this.linkToEdit && brandOptions.value.length === 1) {
			this.link.brandId = DEFAULT_BRAND
		}
	},
	methods: {
		async getLinks () {
			const { data: { lookUpData, publicPortalLinks } } = await getLinks()
			this.lookUpData = lookUpData
			this.publicPortalLinks = publicPortalLinks
		},
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		updateLink (property, value) {
			this.$set(this.link, [property], value)
		},
		goBackToOverview () {
			this.$router.push({ name: PUBLIC_PORTAL_LINKS })
		},
		async setNewLinkEmbedCode () {
			await this.getLinks()
			const linkSnippet = this.publicPortalLinks.find(link => link.linkId === this.newLinkId)?.publicPortalLinkEmbedCode
			this.embedCode = linkSnippet
		},
		async finish () {
			if (this.$route.params.linkToEdit) {
				this.link.publicPortalLinkId = this.$route.params.linkToEdit.linkId
			}
			await upsertPublicPortalLink(this.link).then(response => {
				if (!this.linkToEdit) {
					this.newLinkId = response.data.newPublicPortalLinkId
					this.setNewLinkEmbedCode()
				}
			})

			showSnackbar(`This link has been ${this.isEdit ? 'updated' : 'added'}`)
		}
	}
}
</script>
