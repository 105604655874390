export default {
	methods: {
		async previewLink (tempString, link) {
			let encryptedParams = {}
			let hasEncryptedParams = false
			link.linkFields.forEach(field => {
				if (field.isEncrypted && field.previewValue) {
					encryptedParams = { ...encryptedParams, [field.friendlyNameInLink]: encodeURIComponent(field.previewValue) }
					hasEncryptedParams = true
				} else {
					tempString = tempString.replace(field.replaceValueInLink, encodeURIComponent(field.previewValue))
				}
			})
			if (!hasEncryptedParams) {
				window.open(tempString)
			} else {
				const { data } = await this.getEncryptedData(encryptedParams, link)
				window.open(tempString.replace('[EncryptedData]', data.encryptedValue))
			}
		}
	}
}
