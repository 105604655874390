/* eslint-disable no-unmodified-loop-condition */
// https://dev.azure.com/SyrenisLimited/CassieCloud/_wiki/wikis/CassieCloud.wiki/141/UX-Usability-Guidelines?anchor=date-formats
export const DATE_FORMAT = 'yyyy/MM/dd'
export const DATE_FORMAT_MONTH_YEAR = 'MMMM yyyy'
export const DATE_TIME_FORMAT = 'yyyy/MM/dd HH:mm:ss'
export const DATE_TIME_FORMAT_NO_SECONDS = 'yyyy/MM/dd HH:mm'
export const DATE_FORMAT_DATE_PICKER = 'yyyy-MM-dd'
export const DATE_FORMAT_CHARTS = 'yyyy-MM-dd'
export const DATE_FORMAT_DAY = 'dd MMM yyyy'
export const DATE_FORMAT_WEEK = 'dd MMM yyyy'
