<template>
	<SectionCard>
		<template #title>
			IAB Text Translations
		</template>
		<template #title-action>
			<Dropdown
				:value="selectedLanguageCodeOrDefault"
				label="Language"
				searchable
				:items="assignedLanguagesItems"
				:disabled="disableLanguageField"
				@input="changeLanguage"
			/>
		</template>
		<template #body>
			<DataTable
				:headers="tableHeaders"
				:items="iabText"
				hide-default-footer
				:items-per-page="50"
			>
				<template #item.text="{ item, index }">
					<Textarea
						:label="`${item.name} Translation`"
						:value="item.text"
						:readonly="!hasSelectedLanguage"
						auto-grow
						class="my-2"
						rows="1"
						small
						@input="updateIabTextTranslation($event, index)"
					/>
				</template>
			</DataTable>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import Textarea from '../../../../../../../shared/components/textarea.vue'
import { getAssignedLanguages } from '../../../../../../../shared/utils/api/languages.js'
import { getIabText, putIabText } from '../../../../../../../shared/utils/api/cookies/cookie-iab-text.js'
export default {
	components: {
		SectionCard,
		DataTable,
		Dropdown,
		Textarea
	},
	data () {
		return {
			assignedLanguages: [],
			selectedLanguageCode: null,
			disableLanguageField: false,
			iabText: []
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'ID', value: 'id', width: '1%' },
				{ text: 'Description', value: 'name', width: '19%' },
				{ text: 'Default Text', value: 'defaultText', width: '30%' },
				{ text: 'Translated Text', value: 'text', width: '30%' }
			]
		},
		selectedLanguageCodeOrDefault () {
			if (this.selectedLanguageCode !== null) return this.selectedLanguageCode
			return 'en'
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageCode, languageName, languageID }) => ({
				value: languageCode,
				text: languageName,
				languageID: languageID
			})).filter(({ languageID }) => languageID !== DEFAULT_LANGUAGE)
		},
		hasSelectedLanguage () {
			return this.selectedLanguageCode !== null
		}
	},
	async created () {
		await this.loadLanguages()
		await this.loadIabText()
	},
	methods: {
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		changeLanguage (languageCode) {
			this.selectedLanguageCode = languageCode
			this.loadIabText()
		},
		async loadIabText () {
			try {
				const { data: { bannerText } } = await getIabText(this.selectedLanguageCodeOrDefault)
				this.iabText = Object.values(bannerText)
			} catch {
				const { data: { bannerText } } = await getIabText('en')
				this.iabText = Object.values(bannerText)
			}
		},
		iabTranslationText (index) {
			return this.iabText[index]?.text
		},
		updateIabTextTranslation (event, index) {
			this.iabText[index].text = event
			this.$emit('translations-changed', true)
		},
		async saveTranslation () {
			const translations = this.iabText.map(({ id, name, text, defaultText }) => ({ id, name, text, defaultText }))
			const requestObject = {
				languageCode: this.selectedLanguageCodeOrDefault,
				bannerText: {}
			}
			translations.forEach(item => {
				requestObject.bannerText[item.id] = item
			})
			await putIabText(requestObject)
		},
		cancelTranslationChanges () {
			this.$emit('translations-changed', false)
		}
	}
}
</script>
