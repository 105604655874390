<template>
	<SectionCard>
		<template #title>
			Access Key Details
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						v-model="accessKey.name"
						:disabled="!userFullPermissions"
						label="Access Key Name *"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						v-model="accessKey.description"
						:disabled="!userFullPermissions"
						label="Description"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Dropdown
						v-model="accessKey.brandId"
						:disabled="!userFullPermissions || isEdit || !clientMultiBranded || singleBrand"
						:label="'Brand*' | useLabels"
						searchable
						:items="brandItems"
						custom-sort
						:rules="{ required: clientMultiBranded}"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { defaultBrandInSelect, singleBrand, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM } from '../../../../../../shared/state/brands.js'
import { ACCESS_KEY_TYPES } from '../../../../../../shared/enums/access-keys.js'
import { postCookieAccessKey, postPreferenceWidgetAccessKey, putCookieAccessKey, putPreferenceWidgetAccessKey } from '../../../../../../shared/utils/api/access-keys.js'

export default {
	components: { SectionCard, TextField, Dropdown },
	props: {
		accessKeyType: {
			type: Number,
			required: true
		},
		accessKeyToEdit: Object,
		isEdit: Boolean,
		userFullPermissions: Boolean
	},
	setup () {
		return {
			brandOptions,
			ACCESS_KEY_TYPES,
			clientMultiBranded,
			defaultBrandInSelect,
			singleBrand
		}
	},
	data () {
		return {
			accessKey: JSON.parse(JSON.stringify(this.accessKeyToEdit || {
				name: '',
				description: '',
				brandId: defaultBrandInSelect.value
			}))
		}
	},
	computed: {
		brandItems () {
			// ensures when viewing universal object it displays in dropdown
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		}
	},
	methods: {
		saveAccessKey () {
			if (this.accessKeyType === ACCESS_KEY_TYPES.COOKIE_ACCESS_KEY) {
				this.manageCookieAccessKey()
			} else {
				this.managePreferenceWidgetAccessKey()
			}
			this.$emit('back-to-overview')
		},
		async manageCookieAccessKey () {
			if (!clientMultiBranded.value) {
				this.accessKey.brandId = 0
			}

			if (!this.isEdit) {
				await postCookieAccessKey(this.accessKey)
			} else {
				delete this.accessKey.accessKeyId
				delete this.accessKey.accessKeyName
				delete this.accessKey.brandName
				delete this.accessKey.createdDate
				delete this.accessKey.isConsentBannerDefault

				await putCookieAccessKey(this.accessKey)
			}
		},
		async managePreferenceWidgetAccessKey () {
			if (!clientMultiBranded.value) {
				this.accessKey.brandId = 0
			}
			if (!this.isEdit) {
				await postPreferenceWidgetAccessKey(this.accessKey)
			} else {
				delete this.accessKey.accessKeyId
				delete this.accessKey.accessKeyName
				delete this.accessKey.brandName
				delete this.accessKey.createdDate
				delete this.accessKey.isConsentBannerDefault

				await putPreferenceWidgetAccessKey(this.accessKey)
			}
		}
	}
}
</script>
