<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				<slot name="title" />
			</template>
			<template #body>
				<div class="cassie-vertical-md">
					<div class="d-flex cassie-horizontal-md">
						<TextField
							:value="user.forename"
							:view-only="!userCanCreateUpdate"
							:rules="{required: true, max: 60}"
							label="Forename *"
							class="cassie-input-width-md"
							@input="updateUser('forename', $event)"
						/>
						<TextField
							:value="user.surname"
							:view-only="!userCanCreateUpdate"
							:rules="{required: true, max: 60}"
							label="Surname *"
							class="cassie-input-width-md"
							@input="updateUser('surname', $event)"
						/>
					</div>
					<div class="d-flex cassie-horizontal-md">
						<TextField
							:value="user.emailAddress"
							:view-only="!userCanCreateUpdate"
							:rules="{ required: true, email: true, max: 128 }"
							label="Email Address *"
							class="cassie-input-width-md"
							@input="updateUser('emailAddress', $event)"
						/>
						<TextField
							:value="user.telephoneNo"
							:view-only="!userCanCreateUpdate"
							label="Telephone No."
							class="cassie-input-width-md"
							rules="phoneNumber"
							@input="updateUser('telephoneNo', $event)"
						/>
					</div>
					<div class="d-flex cassie-horizontal-md">
						<TextField
							:value="user.mobileNo"
							:view-only="!userCanCreateUpdate"
							label="Mobile No."
							class="cassie-input-width-md"
							rules="phoneNumber"
							@input="updateUser('mobileNo', $event)"
						/>
						<Dropdown
							:value="user.defaultDashboard"
							:view-only="!userCanCreateUpdate"
							:items="dashboardOptions"
							:label="'Default Dashboard *' | useLabels"
							rules="required"
							class="cassie-input-width-md"
							@input="updateUser('defaultDashboard', $event)"
						/>
					</div>
					<div class="d-flex cassie-horizontal-md">
						<Dropdown
							v-if="userCanCreateUpdate"
							v-model="selectedBrand"
							:search-input.sync="brandSearchInput"
							:items="filteredBrandOptions"
							custom-sort
							:label="'Brand*' | useLabels"
							searchable
							multiple
							:rules="{ requiredBrands: user.brandIds.length === 0 }"
							class="cassie-input-width-md"
						/>
						<PrimaryActionButton
							v-if="userCanCreateUpdate"
							:disabled="!selectedBrand && selectedBrand!== 0"
							@click="addBrand"
						>
							Add {{ adminPortalBrandFieldLabel }}
						</PrimaryActionButton>
					</div>
					<v-simple-table
						v-if="user.brandIds && user.brandIds.length"
						light
						class="mb-4"
					>
						<template #default>
							<thead>
								<tr>
									<th
										class="text-left"
										style="width: 100%;"
										scope="col"
									>
										Associated {{ adminPortalBrandFieldLabel }}
									</th>
									<th
										v-if="userCanCreateUpdate"
										class="text-right"
										style="width: 90px;"
										scope="col"
									>
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="brand in user.brandIds"
									:key="brand"
								>
									<td>
										{{ getBrandText(brand) }}
									</td>
									<td
										v-if="userCanCreateUpdate"
										class="text-right"
									>
										<v-btn
											:disabled="singleBrandOptionExists"
											icon
											color="blue darken-2"
											@click="removeBrandId(brand)"
										>
											<v-icon>
												mdi-close
											</v-icon>
										</v-btn>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<div class="d-flex cassie-horizontal-md">
						<Toggle
							:value="user.isActive"
							:disabled="!userCanCreateUpdate"
							label="User Active"
							class="cassie-input-width-md"
							@update:value="updateUser('isActive', $event)"
						/>
					</div>
					<PrimaryActionButton
						v-if="user.userId && userCanCreateUpdate && ssoBypassAllowed"
						@click="resetPassword"
					>
						Reset Password
					</PrimaryActionButton>
				</div>
				<PasswordResetModal
					v-if="showPasswordResetModal"
					@close="showPasswordResetModal = false"
				/>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				User Role
			</template>
			<template #body>
				<div class="cassie-vertical-md">
					<div
						v-if="userCanCreateUpdate"
						class="d-flex flex-row cassie-horizontal-md align-center"
					>
						<Dropdown
							v-model="selectedUserRole"
							label="User Roles"
							:items="userRoleItems"
							searchable
							return-object
							item-disabled="disabled"
							class="cassie-input-width-md"
							@change="updatedUserRole"
						/>
						<Toggle
							:value="showPermissions"
							:disabled="!selectedUserRole"
							label="Show Permissions"
							class="cassie-input-width-md"
							@update:value="updateShowPermissions($event)"
						/>
					</div>
					<DataTable
						v-if="selectedUserRole && showPermissions"
						:items="tableData"
						:headers="tableHeaders"
					/>
				</div>
			</template>
		</SectionCard>
		<SectionCard v-if="showLegacyPermissions">
			<template #title>
				Legacy Permission Groups
			</template>
			<template #subtitle>
				{{ userCanCreateUpdate ? 'Manage' : '' }} Permission Groups used by V1 Admin Portal
			</template>
			<template #body>
				<div class="cassie-vertical-md">
					<div
						v-if="userCanCreateUpdate"
						class="d-flex flex-row cassie-horizontal-md"
					>
						<Dropdown
							v-model="selectedLegacyPermissionGroups"
							:search-input.sync="searchInput"
							label="Permission Groups"
							:items="filteredLegacyPermissionGroupsOptions"
							return-object
							multiple
							select-all
							searchable
							class="cassie-input-width-md"
							@change="updateSelectAllChecked"
						>
							<template
								v-if="legacyPermissionGroupsTableData.length !== legacyPermissionGroups.length"
								#prepend-item
							>
								<v-list-item
									dense
									@click="toggleSelectAll"
								>
									<v-checkbox
										v-model="selectAllChecked"
										dense
										@click="toggleSelectAll"
									/>
									<v-list-item-title class="pb-2">
										Select All
									</v-list-item-title>
								</v-list-item>
								<v-divider />
							</template>
						</Dropdown>
						<PrimaryActionButton
							:disabled="noSelectedLegacyGroups"
							@click="addLegacyPermissionGroup"
						>
							Add
						</PrimaryActionButton>
					</div>
					<DataTable
						:items="legacyPermissionGroupsTableData"
						:headers="legacyPermissionGroupsHeaders"
					>
						<template #item.action="{ item }">
							<v-icon
								v-if="userCanCreateUpdate"
								small
								class="mr-2"
								@click="removeLegacyPermissionGroup(item.permissionGroupId)"
							>
								mdi-trash-can
							</v-icon>
						</template>
					</DataTable>
				</div>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../../shared/components/toggle.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import {
	adminPortalProductRoles, legacyPermissionGroups, adminPortalSsoEnabled, adminPortalSsoBypassDomains, adminPortalPermissions
} from '../../../../../../shared/state/admin-portal-users-and-lookup-data.js'
import { brandOptions } from '../../../../../../shared/state/brands.js'
import { sendResetPasswordEmailApi } from '../../../../../../shared/utils/api/admin-portal-users.js'
import { adminPortalBrandFieldLabel, showLegacyPermissions } from '../../../../../../shared/state/config-keys.js'
import PasswordResetModal from '../../../../../../shared/components/password-reset-modal.vue'
import { CAN_SEE_CONSENT_BANNERS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: {
		PasswordResetModal,
		SectionCard,
		TextField,
		Dropdown,
		Toggle,
		PrimaryActionButton,
		DataTable
	},
	props: {
		user: {
			type: Object,
			default: () => ({})
		},
		resetValidation: {
			type: Function,
			default: () => {}
		},
		userCanCreateUpdate: Boolean,
		ssoEnabled: Boolean
	},
	setup () {
		return {
			brandOptions,
			adminPortalProductRoles,
			adminPortalBrandFieldLabel,
			legacyPermissionGroups,
			showLegacyPermissions,
			adminPortalSsoEnabled,
			adminPortalSsoBypassDomains,
			adminPortalPermissions
		}
	},
	data () {
		return {
			selectedUserRole: null,
			selectedLegacyPermissionGroups: [],
			showPasswordResetModal: false,
			selectedBrand: null,
			selectAllChecked: false,
			originalEmail: null,
			showPermissions: false,
			searchInput: null,
			brandSearchInput: null
		}
	},
	computed: {
		...mapGetters('dashboards', ['dashboards']),
		...mapGetters('auth', ['productAreaPermission', 'userDefaultDashboardId']),
		tableData () {
			const data = this.selectedUserRole && this.adminPortalProductRoles.find(role => role.productRoleId === this.selectedUserRole.value).productRolePermissions
			return data
		},
		userRoleItems () {
			const roleItems = adminPortalProductRoles.value.map(role => (
				{
					value: role.productRoleId,
					text: role.productRoleName
				}
			))
			return roleItems
		},
		legacyPermissionGroupsTableData () {
			return this.user.legacyPermissionsData?.map(datum => {
				const permissionGroupName = legacyPermissionGroups?.value.find(({ permissionGroupId }) => permissionGroupId === datum.permissionGroupId)?.permissionGroupName
				return {
					...datum,
					permissionGroupName
				}
			})
		},
		filteredLegacyPermissionGroupsOptions () {
			const selectedLegacyPermissionGroupIds = this.user.legacyPermissionsData?.map(datum => {
				return legacyPermissionGroups.value.find(({ permissionGroupId }) => permissionGroupId === datum.permissionGroupId)?.permissionGroupId
			})
			return legacyPermissionGroups.value
				.filter(({ permissionGroupId }) => !selectedLegacyPermissionGroupIds.includes(permissionGroupId)).map(permissionGroup => ({
					...permissionGroup,
					text: permissionGroup.permissionGroupName
				})) ?? []
		},
		noSelectedLegacyGroups () {
			return this.selectedLegacyPermissionGroups.length === 0
		},
		filteredBrandOptions () {
			const options = brandOptions.value.filter(({ value }) => !this.user.brandIds?.includes(value)) ?? brandOptions.value
			return options.map(({ value, text }) => ({
				value,
				text,
				disabled: this.disableBrandItem(value)
			}))
		},
		singleBrandOptionExists () {
			return this.brandOptions.length === 1
		},
		dashboardOptions () {
			return this.dashboards.map(({ id, name }) => ({
				value: id,
				text: name
			}))
		},
		tableHeaders () {
			const headers =
				[
					{
						text: 'Module',
						value: 'module',
						width: '30%'
					},
					{
						text: 'Page',
						value: 'page',
						width: '30%'
					},
					{
						text: 'Access Permission',
						value: 'accessPermission',
						width: '40%'
					}
				]

			return headers
		},
		legacyPermissionGroupsHeaders () {
			const headers =
				[
					{
						text: 'Associated Legacy Permission Groups',
						value: 'permissionGroupName',
						width: '90%'
					}
				]
			if (this.userCanCreateUpdate) {
				headers.push(
					{
						text: 'Action',
						value: 'action',
						width: '10%'
					})
			}
			return headers
		},
		ssoBypassAllowed () {
			if (!this.originalEmail) return false
			if (this.originalEmail.toLowerCase() !== this.user.emailAddress.toLowerCase()) {
				return false
			}
			if (adminPortalSsoEnabled.value) {
				return adminPortalSsoBypassDomains.value?.find(m => this.user.emailAddress.toLowerCase().endsWith(m.domain))
			} else return true
		}
	},
	async created () {
		await this.getDashboards(this.productAreaPermission(CAN_SEE_CONSENT_BANNERS))
		this.originalEmail = this.user.emailAddress
		const selectedUserRole = this.adminPortalProductRoles.find(({ productRoleId }) => productRoleId === this.user.userRoleId)
		if (selectedUserRole) {
			this.selectedUserRole = {
				value: selectedUserRole.productRoleId,
				text: selectedUserRole.productRoleName
			}
		}
	},
	methods: {
		...mapActions('dashboards', ['getDashboards']),
		async resetPassword () {
			await sendResetPasswordEmailApi({
				emailAddress: this.user.emailAddress
			})
			this.showPasswordResetModal = true
		},
		updateLegacyPermissionGroupData (data) {
			this.updateUser('legacyPermissionsData', data)
		},
		addLegacyPermissionGroup () {
			this.updateLegacyPermissionGroupData([
				...this.user.legacyPermissionsData,
				...this.selectedLegacyPermissionGroups
			])
			this.resetValidation()
			this.selectedLegacyPermissionGroups = []
			this.selectAllChecked = false
			this.selectAllChecked = false
		},
		async updateUser (property, value) {
			await this.$emit('update:user', {
				...this.user,
				[property]: value
			})
		},
		removeLegacyPermissionGroup (permissionGroupIdToRemove) {
			const filteredPermissionGroupData = this.user.legacyPermissionsData.filter(({ permissionGroupId }) => permissionGroupId !== permissionGroupIdToRemove)
			this.updateLegacyPermissionGroupData(filteredPermissionGroupData)
		},
		getBrandText (brandId) {
			return this.brandOptions.find(({ value }) => value === brandId)?.text || brandId
		},
		removeBrandId (brandId) {
			const brandIds = this.user.brandIds.filter(id => id !== brandId)
			this.updateUser('brandIds', brandIds)
		},
		async addBrand () {
			this.updateUser('brandIds', [
				...this.user.brandIds,
				...this.selectedBrand
			])
			this.selectedBrand = null
		},
		toggleSelectAll () {
			if (this.selectAllChecked) {
				this.selectAllChecked = false
				this.selectedLegacyPermissionGroups = []
			} else {
				this.selectAllChecked = true
				if (this.searchInput) {
					this.selectedLegacyPermissionGroups = this.filteredLegacyPermissionGroupsOptions.filter(field => field.text.toLowerCase().includes(this.searchInput.toLowerCase()))
				} else {
					this.selectedLegacyPermissionGroups = this.filteredLegacyPermissionGroupsOptions
				}
			}
		},
		updateSelectAllChecked () {
			const filteredOptions = this.searchInput
				? this.filteredLegacyPermissionGroupsOptions.filter(field => field.text.toLowerCase().includes(this.searchInput.toLowerCase()))
				: this.filteredLegacyPermissionGroupsOptions

			this.selectAllChecked = this.selectedLegacyPermissionGroups.length === filteredOptions.length
		},
		updatedUserRole () {
			this.updateUser('userRoleId', this.selectedUserRole.value)
			if (!this.showPermissions) this.updateShowPermissions(true)
		},
		updateShowPermissions (value) {
			this.showPermissions = value
		},
		disableBrandItem (brandId) {
			return this.selectedBrand?.includes(0) && brandId !== 0 || this.user.brandIds?.includes(0) && brandId !== 0 ||
				!this.selectedBrand?.includes(0) && this.selectedBrand?.length >= 1 && brandId === 0 ||
				!this.user.brandIds?.includes(0) && this.user.brandIds?.length >= 1 && brandId === 0
		}
	}
}
</script>
