import { computed, ref } from '@vue/composition-api'

const NO_CONSENT_COLLECTION_WIDGET_TEXT_FILTER = ''
const NO_CONSENT_COLLECTION_WIDGET_TRANSLATION_STATUS_SELECTED = null
const NO_CONSENT_COLLECTION_WIDGET_TRANSLATION_LANGUAGE_SELECTED = null
const NO_CONSENT_COLLECTION_WIDGET_STATUS_TYPE = null

const consentCollectionWidgetSearchQueryState = ref(NO_CONSENT_COLLECTION_WIDGET_TEXT_FILTER)
const consentCollectionWidgetTranslationStatusIdState = ref(NO_CONSENT_COLLECTION_WIDGET_TRANSLATION_STATUS_SELECTED)
const consentCollectionWidgetLanguageSelectedState = ref(NO_CONSENT_COLLECTION_WIDGET_TRANSLATION_LANGUAGE_SELECTED)
const consentCollectionWidgetStatusTypeState = ref(NO_CONSENT_COLLECTION_WIDGET_STATUS_TYPE)

export const changeConsentCollectionWidgetSearchQuery = searchQuery => {
	consentCollectionWidgetSearchQueryState.value = searchQuery
}

export const changeConsentCollectionWidgetTranslationStatusId = value => {
	consentCollectionWidgetTranslationStatusIdState.value = value
}

export const changeConsentCollectionWidgetLanguageSelected = language => {
	consentCollectionWidgetLanguageSelectedState.value = language
}

export const changeConsentCollectionWidgetStatusType = statusType => {
	consentCollectionWidgetStatusTypeState.value = statusType
}

export const consentCollectionWidgetSearchQuery = computed({
	get () {
		return consentCollectionWidgetSearchQueryState.value
		// your getter
	},
	set (value) {
		changeConsentCollectionWidgetSearchQuery(value)
		// your setter
	}
})

export const consentCollectionWidgetTranslationStatusId = computed({
	get () {
		return consentCollectionWidgetTranslationStatusIdState.value
		// your getter
	},
	set (value) {
		changeConsentCollectionWidgetTranslationStatusId(value)
		// your setter
	}
})

export const consentCollectionWidgetLanguageSelected = computed({
	get () {
		return consentCollectionWidgetLanguageSelectedState.value
	},
	set (value) {
		changeConsentCollectionWidgetLanguageSelected(value)
	}
})

export const consentCollectionWidgetStatusType = computed({
	get () {
		return consentCollectionWidgetStatusTypeState.value
	},
	set (value) {
		changeConsentCollectionWidgetStatusType(value)
	}
})

export const resetConsentCollectionWidgetFilters = () => {
	changeConsentCollectionWidgetSearchQuery(NO_CONSENT_COLLECTION_WIDGET_TEXT_FILTER)
	changeConsentCollectionWidgetTranslationStatusId(NO_CONSENT_COLLECTION_WIDGET_TRANSLATION_STATUS_SELECTED)
	changeConsentCollectionWidgetLanguageSelected(NO_CONSENT_COLLECTION_WIDGET_TRANSLATION_LANGUAGE_SELECTED)
	changeConsentCollectionWidgetStatusType(NO_CONSENT_COLLECTION_WIDGET_STATUS_TYPE)
}
