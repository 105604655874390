<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="extendedPreferencesSearchQuery"
			search-query-label="Extended Preference Key Name"
			hide-brand-filter
			@persistSearchQuery="changeExtendedPreferencesSearchQuery"
		/>
		<SectionCard>
			<template #title>
				Extended Preference Translations
			</template>
			<template #title-action>
				<Dropdown
					:value="selectedLanguageIdOrDefault"
					label="Language"
					searchable
					:items="assignedLanguagesItems"
					@input="selectedLanguageId = $event"
				/>
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredExtendedPreferenceTranslations"
					sort-by="extendedPreferenceId"
					sort-desc
					@click:row="manageExtendedPreferenceTranslation"
				>
					<template #item.action="{ item }">
						<IconButton
							tooltip-text="Edit Extended Preference Translation"
							@click="manageExtendedPreferenceTranslation(item)"
						>
							{{ readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil' }}
						</IconButton>
						<IconButton
							v-if="!readOnlyPermissions && item.isTranslated"
							tooltip-text="Delete Extended Preference Translation"
							@click.stop.prevent="extendedPreferenceTranslationToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="extendedPreferenceTranslationToRemove"
			:entity-name="`${extendedPreferenceTranslationToRemove.name} Translation`"
			entity-type="Extended Preference Translation"
			@close="extendedPreferenceTranslationToRemove = null"
			@delete="deleteExtendedPreferenceTranslation"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../shared/components/section-card.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import { changeExtendedPreferencesSearchQuery, extendedPreferencesSearchQuery } from '../../../../../shared/state/extended-preferences.js'
import { getAssignedLanguages } from '../../../../../shared/utils/api/languages.js'
import { EXTENDED_PREFERENCES_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { deleteExtendedPreferenceTranslation } from '../../../../../shared/utils/api/extended-preferences.js'
import { MANAGE_EXTENDED_PREFERENCE_TRANSLATION } from '../../../router/route-names.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
export default {
	components: { DataTable, FilterBar, SectionCard, IconButton, Dropdown, ConfirmDeleteModal },
	props: {
		extendedPreferences: Array
	},
	setup () {
		return {
			showSnackbar,
			changeExtendedPreferencesSearchQuery,
			extendedPreferencesSearchQuery
		}
	},
	data () {
		return {
			extendedPreferenceTranslationToEdit: null,
			extendedPreferenceTranslationToRemove: null,
			assignedLanguages: [],
			selectedLanguageId: null,
			showAddModal: false
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		selectedLanguageIdOrDefault () {
			if (this.selectedLanguageId !== null) return this.selectedLanguageId
			return this.assignedLanguagesItems[0]?.value
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		filteredExtendedPreferenceTranslations () {
			return this.extendedPreferences.map(extendedPref => {
				const extendedPrefTranslations = extendedPref.translations?.filter(({ languageId }) => languageId === this.selectedLanguageIdOrDefault)
				const language = this.assignedLanguagesItems.find(({ value }) => value === this.selectedLanguageIdOrDefault)
				return {
					...extendedPref,
					languageName: language?.text,
					languageId: language?.value,
					isTranslated: extendedPrefTranslations.length >= 1,
					translationStatus: extendedPrefTranslations.length >= 1 ? 'Translated' : 'Untranslated',
					translations: extendedPref.dropdownValues.map(dropdownValue => {
						const translation = extendedPrefTranslations.find(({ valueId }) => valueId === dropdownValue.valueId)
						return {
							originalValue: dropdownValue.value,
							valueId: dropdownValue.valueId,
							value: translation?.value ?? null
						}
					})
				}
			}).filter(({ name }) => {
				let check = true
				if (extendedPreferencesSearchQuery.value) check = name.toLowerCase().includes(extendedPreferencesSearchQuery.value.toLowerCase())
				return check
			})
		},
		readOnlyPermissions () {
			return !this.checkPermission(EXTENDED_PREFERENCES_MODULE_FULL_PERMISSIONS)
		},
		tableHeaders () {
			return [
				{
					value: 'extendedPreferenceId',
					text: 'ID',
					width: '10%'
				},
				{
					value: 'name',
					text: 'Extended Preference Key Name',
					width: '30%'
				},
				{
					value: 'translationStatus',
					text: 'Status',
					width: '25%'
				},
				{
					value: 'dateAdded',
					text: 'Created Date',
					width: '25%'
				},
				{
					value: 'action',
					text: 'Action',
					width: '10%',
					sortable: false
				}
			]
		}
	},
	created () {
		this.loadLanguages()
	},
	methods: {
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		closeManageExtendedPreferenceModal () {
			this.extendedPreferenceTranslationToEdit = null
			this.showAddModal = false
		},
		manageExtendedPreferenceTranslation (extendedPreferenceTranslation) {
			this.extendedPreferenceTranslationToEdit = extendedPreferenceTranslation
			this.$router.push({
				name: MANAGE_EXTENDED_PREFERENCE_TRANSLATION,
				params: {
					extendedPreferenceToTranslate: extendedPreferenceTranslation
				}
			})
		},
		async deleteExtendedPreferenceTranslation () {
			await deleteExtendedPreferenceTranslation({
				valueId: this.extendedPreferenceTranslationToRemove.extendedPreferenceId,
				languageId: this.extendedPreferenceTranslationToRemove.languageId
			})
			showSnackbar('Extended Preference Translation deleted successfully')
			this.$emit('load-extended-preferences')
			this.extendedPreferenceTranslationToRemove = null
		}
	}
}
</script>
