<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			hide-brand-filter
			hide-search-query-filter
			@clearFilters="selectedLanguageId = null"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedLanguageId"
					label="Language"
					searchable
					:items="[{ value: null, text: 'View All' }, ...languageItems]"
					custom-sort
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Cookie Policy Table Translations
			</template>
			<template #body>
				<DataTable
					:items="filteredPolicyTableHeaderTranslations"
					:headers="tableHeaders"
					@click:row="openManageModal"
				>
					<template #item.usageCount="{ item }">
						{{ getTableHeaderUsage(item) }}
					</template>
					<template #item.action="{ item }">
						<IconButton @click="openManageModal(item)">
							{{ userCanCreateUpdate ? 'mdi-pencil' : 'mdi-eye' }}
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ManagePolicyTableHeaderTextModal
			v-if="languageHeadersToEdit"
			:language-headers-to-edit="languageHeadersToEdit"
			:user-can-create-update="userCanCreateUpdate"
			@close="languageHeadersToEdit = null"
			@submit="getPolicyTableHeaderTranslations"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ManagePolicyTableHeaderTextModal from './manage-policy-table-header-text-modal.vue'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import { getPolicyTableHeaderTranslations } from '../../../../../../shared/utils/api/cookies/cookie-policy-table-header-translations.js'
import { CAN_CREATE_UPDATE_POLICY_TABLES } from '../../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: { FilterBar, Dropdown, SectionCard, DataTable, IconButton, ManagePolicyTableHeaderTextModal },
	data () {
		return {
			assignedLanguages: [],
			policyTableHeaderTranslations: [],
			selectedLanguageId: null,
			languageHeadersToEdit: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_POLICY_TABLES)
		},
		tableHeaders () {
			return [
				{ text: 'ID', value: 'languageID' },
				{ text: 'Language Name', value: 'languageName' },
				{ text: 'Header Fields Translated', value: 'usageCount' },
				{ text: 'Action', value: 'action', sortable: false }
			]
		},
		languageItems () {
			return this.assignedLanguages.map(language => ({ value: language.languageID, text: language.languageName }))
		},
		filteredPolicyTableHeaderTranslations () {
			const policyTableHeaderTranslations = this.policyTableHeaderTranslations.map(tableHeader => {
				return {
					...tableHeader,
					languageName: this.assignedLanguages.find(({ languageID }) => languageID === tableHeader.languageID)?.languageName
				}
			})

			// sort policyTableHeaderTranslations with languageID 0 first and the rest sorted by languageName
			policyTableHeaderTranslations.sort((a, b) => {
				if (a.languageID === 0) return -1
				if (b.languageID === 0) return 1
				return a.languageName.localeCompare(b.languageName)
			})

			return policyTableHeaderTranslations.filter(({ languageID }) => {
				let check = true
				if (this.selectedLanguageId !== null) check = languageID === this.selectedLanguageId
				return check
			})
		}
	},
	created () {
		this.getAssignedLanguages()
		this.getPolicyTableHeaderTranslations()
	},
	methods: {
		async getAssignedLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		async getPolicyTableHeaderTranslations () {
			const { policyTableHeaderTranslations } = await getPolicyTableHeaderTranslations()
			this.policyTableHeaderTranslations = policyTableHeaderTranslations
		},
		getTableHeaderUsage (tableHeader) {
			const totalNumberOfFields = Object.keys(tableHeader).filter(key => key !== 'languageID' && key !== 'languageName').length
			const numberOfFieldsTranslated = Object.keys(tableHeader).filter(
				key => key !== 'languageID' &&
				key !== 'languageName' &&
				tableHeader[key]?.length !== 0 &&
				tableHeader[key] !== null).length
			return `${numberOfFieldsTranslated}/${totalNumberOfFields}`
		},
		openManageModal (languageHeadersToEdit) {
			this.languageHeadersToEdit = languageHeadersToEdit
		}
	}
}
</script>
