var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CookieTemplateLayout',{attrs:{"header-title":_vm._f("useLabels")('Cookie Appearance Templates','Cookie Appearance Templates'),"header-caption":_vm._f("useLabels")('Add, edit and manage cookie appearance templates','Add, edit and manage cookie appearance templates')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('FilterBar',{attrs:{"search-query":_vm.cookieAppearanceTemplateSearchQuery,"search-query-label":"Cookie Appearance Template Name","selected-brand-id":_vm.selectedAdminPortalBrandId},on:{"update:searchQuery":function($event){_vm.cookieAppearanceTemplateSearchQuery=$event},"update:search-query":function($event){_vm.cookieAppearanceTemplateSearchQuery=$event},"update:selectedBrandId":function($event){_vm.selectedAdminPortalBrandId=$event},"update:selected-brand-id":function($event){_vm.selectedAdminPortalBrandId=$event},"persistSearchQuery":_vm.changeCookieAppearanceTemplateSearchQuery,"persistSelectedBrandId":_vm.changeAdminPortalBrandFilter},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.userCanCreateUpdate)?_c('PrimaryActionButton',{on:{"click":function($event){return _vm.onCreateClick()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create ")],1):_vm._e()]},proxy:true}])}),_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Cookie Appearance Templates ")]},proxy:true},{key:"body",fn:function(){return [_c('DataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredCookieAppearanceTemplates,"sort-by":"id","sort-desc":true},on:{"click:row":_vm.onEditClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.isSystem)?_c('Chip',{staticClass:"ml-2 coral-red white--text"},[_vm._v(" Cassie Template ")]):_vm._e()]}},{key:"item.forceBannerInteraction",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.forceBannerInteraction ? 'mdi-check' : 'mdi-close')+" ")])]}},{key:"item.preBannerEnabled",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(!item.preBanner.preBannerEnabled ? 'mdi-check' : 'mdi-close')+" ")])]}},{key:"item.gpcBannerEnabled",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.gpcBanner.gpcBannerEnabled ? 'mdi-check' : 'mdi-close')+" ")])]}},{key:"item.autoShowModal",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.banner.autoShowModal ? 'mdi-check' : 'mdi-close')+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!item.isSystem && _vm.userCanCreateUpdate && _vm.userHasBrand(item.brandId))?_c('IconButton',{attrs:{"tooltip-text":"Edit Cookie Appearance Template"},on:{"click":function($event){return _vm.onEditClick(item)}}},[_vm._v(" mdi-pencil ")]):_c('IconButton',{attrs:{"tooltip-text":"View Cookie Appearance Template"},on:{"click":function($event){return _vm.onEditClick(item)}}},[_vm._v(" mdi-eye ")]),((_vm.userCanCreateUpdate && _vm.userHasBrand(item.brandId)) || item.isSystem)?_c('IconButton',{attrs:{"tooltip-text":"Clone Cookie Appearance Template"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCloneClick(item, true)}}},[_vm._v(" mdi-content-duplicate ")]):_vm._e(),(_vm.userCanDelete && item.usage === 0 && !item.isSystem && _vm.userHasBrand(item.brandId))?_c('IconButton',{attrs:{"tooltip-text":"Delete Cookie Appearance Template"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.cookieAppearanceTemplateToDelete = item}}},[_vm._v(" mdi-trash-can ")]):_vm._e()]}}])})]},proxy:true}])}),(_vm.cookieAppearanceTemplateToDelete && _vm.userCanDelete)?_c('ConfirmDeleteModal',{attrs:{"entity-name":_vm.cookieAppearanceTemplateToDelete.name,"entity-type":"Cookie Appearance Template"},on:{"close":function($event){_vm.cookieAppearanceTemplateToDelete = null},"delete":_vm.deleteCookieAppearanceTemplate}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }