<template>
	<ValidationForm
		#default="{ handleSubmit }"
	>
		<Modal width="650px">
			<template #modal-title>
				{{ modalTitle }} Pass
				<v-spacer />
				<IconButton
					@click="$emit('close')"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="groupingRule.passName"
								label="Name *"
								:rules="{required: true, max: 255}"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="9">
							<Dropdown
								v-model="selectedPassFields"
								:search-input.sync="searchInput"
								:items="dataSubjectContactFieldItems"
								custom-sort
								multiple
								searchable
								select-all
								label="Pass Field *"
								@change="updateSelectAllChecked"
							>
								<template
									v-if="showSelectAll"
									#prepend-item
								>
									<v-list-item
										dense
										@click="toggleSelectAll"
									>
										<v-checkbox
											v-model="selectAllChecked"
											dense
											@click="toggleSelectAll"
										/>
										<v-list-item-title class="pb-2">
											Select All
										</v-list-item-title>
									</v-list-item>
									<v-divider />
								</template>
							</Dropdown>
						</v-col>
						<v-col cols="3">
							<v-spacer />
							<PrimaryActionButton @click="addPassFields">
								Add
							</PrimaryActionButton>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<v-simple-table>
								<template #default>
									<thead>
										<tr>
											<th
												scope="col"
												class="text-left"
											>
												Pass Field
											</th>
											<th
												scope="col"
												class="text-right"
											>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item, index) in groupingRule.passFields"
											:key="index"
										>
											<td>
												{{ item }}
											</td>
											<td class="text-right">
												<IconButton @click="deletePassField(index)">
													mdi-trash-can
												</IconButton>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton
					@click="$emit('close')"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					@click="handleSubmit(submit)"
				>
					{{ isEdit ? 'Save' : 'Create' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>
<script>
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { getDataSubjectContactFields } from '../../../../../../shared/utils/api/data-subject-rules.js'
import { upsertGroupingRules } from '../../../../../../shared/utils/api/data-subject-grouping-rules.js'

export default {
	components: {
		ValidationForm,
		Modal,
		IconButton,
		TextField,
		Dropdown,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		groupingRuleToEdit: Object,
		groupingRules: Array
	},
	data () {
		return {
			groupingRule: JSON.parse(JSON.stringify(this.groupingRuleToEdit || {
				passName: null,
				passFields: []
			})),
			selectedPassFields: [],
			dataSubjectContactFields: null,
			selectAllChecked: false,
			searchInput: null
		}
	},
	computed: {
		isEdit () {
			return !!this.groupingRuleToEdit
		},
		modalTitle () {
			return this.isEdit ? 'Edit' : 'Create'
		},
		dataSubjectContactFieldItems () {
			return this.dataSubjectContactFields?.map(field => {
				return field.columnName
			}).filter(field => !this.groupingRule.passFields.includes(field) && field !== 'SyrenisID')
		},
		showSelectAll () {
			return this.groupingRule.passFields?.length !== this.dataSubjectContactFields?.length
		}
	},
	async created () {
		await this.loadDataSubjectContactFields()
	},
	methods: {
		async loadDataSubjectContactFields () {
			const data = await getDataSubjectContactFields()
			this.dataSubjectContactFields = data.standakFields
		},
		addPassFields () {
			this.groupingRule.passFields = [
				...this.groupingRule.passFields,
				...this.selectedPassFields
			]
			this.selectedPassFields = []
			this.selectAllChecked = false
			this.searchInput = null
			this.selectAllChecked = false
		},
		deletePassField (index) {
			this.$delete(this.groupingRule.passFields, index)
		},
		async submit () {
			let groupingRules = this.groupingRules
			if (!this.isEdit) {
				groupingRules = [
					...groupingRules,
					{
						...this.groupingRule,
						executionOrder: groupingRules.length + 1
					}
				]
			} else {
				this.$set(groupingRules, this.groupingRule.index, this.groupingRule)
			}
			groupingRules = groupingRules.map(function (rule) {
				delete rule.index
				return rule
			})
			await upsertGroupingRules(groupingRules)
			this.$emit('close')
		},
		toggleSelectAll () {
			if (this.selectAllChecked) {
				this.selectAllChecked = false
				this.selectedPassFields = []
			} else {
				this.selectAllChecked = true
				if (this.searchInput) {
					this.selectedPassFields = this.dataSubjectContactFieldItems.filter(field => field.toLowerCase().includes(this.searchInput.toLowerCase()))
				} else {
					this.selectedPassFields = this.dataSubjectContactFieldItems
				}
			}
		},
		updateSelectAllChecked () {
			const filteredItems = this.searchInput
				? this.dataSubjectContactFieldItems.filter(field => field.toLowerCase().includes(this.searchInput.toLowerCase()))
				: this.dataSubjectContactFieldItems

			this.selectAllChecked = this.selectedPassFields.length === filteredItems.length
		}
	}
}
</script>
