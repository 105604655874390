<template>
	<SectionCard>
		<template #title>
			Link
		</template>
		<template #title-action>
			<Dropdown
				v-model="selectedLanguage"
				custom-sort
				label="Language"
				searchable
				:items="filteredLanguageOptions"
				@change="updateEmbedCodeWithLanguage"
			/>
		</template>
		<template #body>
			<p>
				The following Link can be embedded within a Webpage Link or Email.  Select a Language to update the Link and set the required 'languagecode'.
			</p>
			<p>
				Each of the Link Fields specified in Step 2 and the Properties in Step 5 will need dynamically replacing accordingly so that the Link matches to the correct Data Subject.
			</p>
			<v-row>
				<v-col
					cols="12"
					class="text-right"
				>
					<TextButton @click="copyToClipboard(true)">
						Copy with tags
					</TextButton>
					<TextButton @click="copyToClipboard(false)">
						Copy with URL only
					</TextButton>
					<TextButton
						v-if="shouldShowPreview"
						@click="openPreview(link)"
					>
						Preview link
					</TextButton>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<Prism>
						{{ embedCode }}
					</Prism>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>
<script>
import Prism from 'vue-prism-component'
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import { getLinkByID, encryptParameters } from '../../../../../shared/utils/api/admin-portal/links/links.js'
import { ACTION_LINK_TYPE, PUBLIC_PORTAL_LINK_TYPE, PREFERENCE_PAGE_LINK_TYPE } from '../links/link-types.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import previewLinkMixin from '../../../../../shared/mixins/preview-link.js'

export default {
	components: { TextButton, SectionCard, Prism, Dropdown },
	mixins: [previewLinkMixin],
	props: {
		linkId: {
			type: Number,
			required: true
		},
		linkType: {
			type: Number,
			required: true
		},
		assignedLanguageItems: {
			type: Array,
			default: () => []
		}
	},
	setup: () => ({
		PUBLIC_PORTAL_LINK_TYPE,
		showSnackbar
	}),
	data: () => ({
		embedCode: '',
		selectedLanguage: 0,
		selectedLanguageCode: 'default',
		link: {},
		lookupData: {}
	}),
	computed: {
		filteredLanguageOptions () {
			const noneOption = {
				text: 'None',
				value: 0
			}
			let filteredLanguages = []
			if (this.assignedLanguageItems) {
				filteredLanguages = this.assignedLanguageItems.filter(({ languageID }) => languageID !== 0)
				return [noneOption, ...filteredLanguages]
			} else {
				return []
			}
		},
		embedHref () {
			return new DOMParser()
				.parseFromString(this.embedCode, 'text/html')
				.getElementsByTagName('a')[0].href
		},
		shouldShowPreview () {
			return !(this.link.linkFields?.find(
				field => !field.previewValue || field.previewValue === ''
			) ?? null)
		}
	},
	async mounted () {
		await this.fetchLinkData()
		this.$root.$on('show-link-view-link-step', this.fetchLinkData)
	},
	beforeDestroy () {
		this.$root.$off('show-link-view-link-step', this.fetchLinkData)
	},
	methods: {
		async fetchLinkData () {
			// fetch the link again to get the embed code
			await getLinkByID(this.linkId).then(response => {
				this.lookupData = response.data.lookUpData
				const links = response.data
				switch (this.linkType) {
					case ACTION_LINK_TYPE:
						this.link = links.actionLinks[0]
						this.embedCode = links.actionLinks[0].actionLinkEmbedCode
						break
					case PUBLIC_PORTAL_LINK_TYPE:
						this.link = links.publicPortalLinks[0]
						this.embedCode = links.publicPortalLinks[0].publicPortalLinkEmbedCode
						break
					case PREFERENCE_PAGE_LINK_TYPE:
						this.link = links.preferencePageLinks[0]
						this.embedCode = links.preferencePageLinks[0].preferencePageLinkEmbedCode
						break
					default:
						this.embedCode = 'Error retrieving embed code'
				}
			})
		},
		copyToClipboard (includeTags) {
			if (includeTags) {
				navigator.clipboard.writeText(this.embedCode)
			} else {
				const parser = new DOMParser()
				const link = parser.parseFromString(this.embedCode, 'text/html')
				const href = link.body.getElementsByTagName('a')[0].href
				navigator.clipboard.writeText(href)
					.catch(() => {
						showSnackbar({
							text: 'Error copying to clipboard',
							color: 'red'
						})
					})
			}
		},
		updateEmbedCodeWithLanguage (value) {
			const languageCode = this.assignedLanguageItems.find(({ value: languageID }) => languageID === value).languageCode
			this.embedCode = this.embedCode.replace(`languagecode=${this.selectedLanguageCode}`, `languagecode=${languageCode}`)
			this.selectedLanguageCode = languageCode
		},
		async openPreview (link) {
			const tempString = this.embedHref
			await this.previewLink(tempString, link)
		},
		async getEncryptedData (encryptedParams, link) {
			return await encryptParameters({
				encryptionKeyId: link.configurationOptions.encryption.encryptionKeyId,
				linkParams: encryptedParams
			})
		}
	}
}
</script>
