<template>
	<ConsentCollectionLayout
		title="Consent Collection Widgets"
		caption="Add, edit and manage your consent collection widgets"
	>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<ConsentCollectionWidgetsTab
						:consent-collection-widgets="consentCollectionWidgets"
						:user-full-permissions="userFullPermissions"
						:user-has-brand="userHasBrand"
						@load="loadConsentCollectionWidgets"
					/>
				</template>
				<template #1>
					<ConsentCollectionWidgetTranslationsTab
						:user-full-permissions="userFullPermissions"
						:user-has-brand="userHasBrand"
						:assigned-languages="assignedLanguages"
					/>
				</template>
			</TabItems>
		</template>
		<template #footer>
			<PageActionRow>
				<template #actions>
					<SecondaryActionButton @click="backToConsentCollection">
						Back to Consent Collection
					</SecondaryActionButton>
				</template>
			</PageActionRow>
		</template>
	</ConsentCollectionLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ConsentCollectionLayout from '../consent-collection-layout.vue'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import ConsentCollectionWidgetsTab from './consent-collection-widgets-tab.vue'
import ConsentCollectionWidgetTranslationsTab from './consent-collection-widgets-translations-tab.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import { brandOptions } from '../../../../../../shared/state/brands.js'
import { getConsentCollectionWidgets } from '../../../../../../shared/utils/api/consent-collection-widgets.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import { PREF_WIDGETS_AREA_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { CONSENT_COLLECTION } from '../../../../router/route-names.js'
export default {
	components: {
		ConsentCollectionLayout,
		Tabs,
		TabItems,
		ConsentCollectionWidgetsTab,
		ConsentCollectionWidgetTranslationsTab,
		PageActionRow,
		SecondaryActionButton
	},
	props: {
		accessedViaTranslations: Boolean
	},
	data () {
		return {
			tabs: [{ title: 'Widgets', slot: '0' }, { title: 'Widget Translations', slot: '1' }],
			tab: '0',
			consentCollectionWidgets: [],
			assignedLanguages: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(PREF_WIDGETS_AREA_FULL_PERMISSIONS)
		}
	},
	created () {
		this.loadConsentCollectionWidgets()
		this.loadLanguages()
		if (this.accessedViaTranslations) {
			this.tab = '1'
		} else {
			this.tab = '0'
		}
	},
	methods: {
		async loadConsentCollectionWidgets () {
			const { consentCollectionWidgets } = await getConsentCollectionWidgets()
			this.consentCollectionWidgets = consentCollectionWidgets
		},
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		userHasBrand (brandId) {
			return brandOptions.value?.filter(({ value }) => value === brandId).length > 0
		},
		backToConsentCollection () {
			this.$router.push({
				name: CONSENT_COLLECTION
			})
		}
	}
}
</script>
