<template>
	<TransactionTypesLayout>
		<template #content>
			<div class="cassie-vertical-md">
				<FilterBar
					hide-brand-filter
					:search-query.sync="transactionTypesSearchQuery"
					search-query-label="Transaction Type"
					@persistSearchQuery="changeTransactionTypesSearchQuery"
				>
					<template #action>
						<PrimaryActionButton
							v-if="canCreateAndUpdate"
							@click="showManageModal = true"
						>
							<v-icon
								left
								dark
							>
								mdi-plus
							</v-icon>
							Create
						</PrimaryActionButton>
					</template>
				</FilterBar>
				<SectionCard>
					<template #body>
						<DataTable
							:headers="tableHeaders"
							:items="filteredTypes"
							sort-by="createdDate"
							:sort-desc="true"
							@click:row="typeToEdit = $event"
						>
							<template #item.name="{ item }">
								{{ item.name }}
								<Chip
									v-if="item.isSystem"
									class="ml-2 coral-red white--text"
								>
									Cassie Template
								</Chip>
							</template>
							<template #item.action="{ item }">
								<IconButton
									v-if="canCreateAndUpdate && !item.isSystem"
									tooltip-text="Edit Type"
									@click="typeToEdit = item"
								>
									mdi-pencil
								</IconButton>
								<IconButton
									v-else
									tooltip-text="View Type"
									@click="typeToEdit = item"
								>
									mdi-eye
								</IconButton>
								<IconButton
									v-if="canDelete && !item.isSystem"
									tooltip-text="Delete Type"
									@click.stop.prevent="typeToBeRemoved = item"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</template>
				</SectionCard>
				<ConfirmDeleteModal
					v-if="typeToBeRemoved && !typeToBeRemoved.isSystem && canDelete"
					:entity-name="typeToBeRemoved.name"
					entity-type="Transaction Type"
					@close="typeToBeRemoved = null"
					@delete="deleteTransactionType"
				/>
			</div>
			<ManageTransactionTypeModal
				v-if="showManageModal || typeToEdit"
				:type-to-edit="typeToEdit"
				:can-create-and-update="canCreateAndUpdate"
				:security-levels="securityLevels"
				@close="closeManageModal"
				@submit="submitTransactionType"
			/>
		</template>
	</TransactionTypesLayout>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import IconButton from '../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import TransactionTypesLayout from './transaction-types-layout.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import ManageTransactionTypeModal from './manage-transaction-type-modal.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import Chip from '../../../../../shared/components/chip.vue'
import { getTransactionTypes, deleteTransactionType } from '../../../../../shared/utils/api/transaction-types.js'
import { DATE_FORMAT } from '../../../../../shared/utils/date-formatting.js'
import { CAN_CREATE_UPDATE_TRANSACTION_TYPES, CAN_DELETE_TRANSACTION_TYPES } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { transactionTypesSearchQuery, changeTransactionTypesSearchQuery } from '../../../../../shared/state/transaction-types.js'

export default {
	components: {
		TransactionTypesLayout,
		FilterBar,
		ConfirmDeleteModal,
		PrimaryActionButton,
		SectionCard,
		DataTable,
		IconButton,
		Chip,
		ManageTransactionTypeModal
	},
	setup () {
		return {
			transactionTypesSearchQuery,
			changeTransactionTypesSearchQuery,
			showSnackbar
		}
	},
	data () {
		return {
			transactionTypes: [],
			typeToBeRemoved: null,
			editType: null,
			showManageModal: false,
			typeToEdit: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		filteredTypes () {
			return this.typesDateFormated.filter(({ name }) => {
				let check = true
				if (transactionTypesSearchQuery.value) check = name.toLowerCase().includes(transactionTypesSearchQuery.value.toLowerCase())
				return check
			})
		},
		typesDateFormated () {
			return this.transactionTypes.map(tt => {
				return {
					...tt,
					createdDate: format(new Date(tt.createdDate), DATE_FORMAT)
				}
			})
		},
		tableHeaders () {
			return [
				{ value: 'name', text: 'Type Name', width: '30%' },
				{ value: 'securityLevelName', text: 'Security Level', width: '30%' },
				{ value: 'createdDate', text: 'Created Date', width: '30%' },
				{ value: 'action', text: 'Action', width: '10%' }
			]
		},
		canCreateAndUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_TRANSACTION_TYPES)
		},
		canDelete () {
			return this.productAreaPermission(CAN_DELETE_TRANSACTION_TYPES)
		}
	},
	async created () {
		await this.loadTransactionTypes()
	},
	methods: {
		async loadTransactionTypes () {
			const { data: { transactionTypes, securityLevels } } = await getTransactionTypes()
			this.securityLevels = securityLevels
			this.transactionTypes = transactionTypes
		},
		submitTransactionType () {
			this.closeManageModal()
			this.loadTransactionTypes()
		},
		async deleteTransactionType () {
			await deleteTransactionType(this.typeToBeRemoved.id)
			this.showSnackbar('Transaction Type - ' + this.typeToBeRemoved.name + ' has been deleted')
			this.typeToBeRemoved = null
			await this.loadTransactionTypes()
		},
		closeManageModal () {
			this.showManageModal = false
			this.typeToEdit = null
		}
	}
}
</script>
