<template>
	<SectionCard>
		<template #title>
			{{ elementType.cardTitle }}
		</template>
		<template #body>
			<v-row dense>
				<v-col
					cols="6"
					class="text-subtitle-2"
				>
					Default {{ elementType.cardTitle }}
				</v-col>
				<v-col
					cols="6"
					class="text-subtitle-2"
				>
					Translated {{ elementType.cardTitle }}
					<v-tooltip
						right
					>
						<template #activator="{ on }">
							<v-icon
								light
								v-on="on"
							>
								mdi-information
							</v-icon>
						</template>
						<span>
							This can be translated in Components, Headers & Footers, {{ elementType.cardTitle }}s
						</span>
					</v-tooltip>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Textarea
						v-dompurify-html="element.content"
						:test-id="`${element.name}_default_content`"
						class="cassie-disabled-textarea"
					/>
				</v-col>
				<v-col
					cols="6"
					class="d-flex"
				>
					<Textarea
						v-dompurify-html="headerFooterTranslationContent"
						:test-id="`${element.name}_translated_content`"
						class="cassie-disabled-textarea align-self-stretch flex-grow-1"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Textarea from '../../../../../../shared/components/textarea.vue'
export default {
	components: {
		SectionCard,
		Textarea
	},
	props: {
		element: {
			type: Object,
			required: true
		},
		elementType: {
			type: Object,
			required: true
		},
		translatedSchema: {
			type: Object,
			required: true
		}

	},
	computed: {
		headerFooterTranslationContent () {
			return this.translatedSchema[this.element?.name]?.content
		}
	}
}
</script>
