import { computed, ref } from '@vue/composition-api'

const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TEXT_FILTER = ''
const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_STATUS_SELECTED = null
const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_AUTH_TYPE = null
const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_LANGUAGE_SELECTED = null
const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_OVERVIEW = {}
const NO_CONSENT_COLLECTION_PREFERENCE_PAGE_STATUS_TYPE = null

const consentCollectionPreferencePageSearchQueryState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TEXT_FILTER)
const consentCollectionPreferencePageTranslationStatusIdState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_STATUS_SELECTED)
const consentCollectionPreferencePageAuthTypeState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_AUTH_TYPE)
const consentCollectionPreferencePageLanguageSelectedState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_LANGUAGE_SELECTED)
const consentCollectionPreferencePageTranslationOverviewState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_OVERVIEW)
const consentCollectionPreferencePageStatusTypeState = ref(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_STATUS_TYPE)

export const changeConsentCollectionPreferencePageSearchQuery = searchQuery => {
	consentCollectionPreferencePageSearchQueryState.value = searchQuery
}

export const changeConsentCollectionPreferencePageTranslationStatusId = value => {
	consentCollectionPreferencePageTranslationStatusIdState.value = value
}

export const changeConsentCollectionPreferencePageAuthType = authType => {
	consentCollectionPreferencePageAuthTypeState.value = authType
}

export const changeConsentCollectionPreferencePageTranslationLanguageSelected = language => {
	consentCollectionPreferencePageLanguageSelectedState.value = language
}

export const changeConsentCollectionPreferencePageTranslationOverview = payload => {
	consentCollectionPreferencePageTranslationOverviewState.value = payload
}

export const changeConsentCollectionPreferencePageStatusType = statusType => {
	consentCollectionPreferencePageStatusTypeState.value = statusType
}

export const consentCollectionPreferencePageSearchQuery = computed({
	get () {
		return consentCollectionPreferencePageSearchQueryState.value
		// your getter
	},
	set (value) {
		changeConsentCollectionPreferencePageSearchQuery(value)
		// your setter
	}
})

export const consentCollectionPreferencePageTranslationStatusId = computed({
	get () {
		return consentCollectionPreferencePageTranslationStatusIdState.value
		// your getter
	},
	set (value) {
		changeConsentCollectionPreferencePageTranslationStatusId(value)
		// your setter
	}
})

export const consentCollectionPreferencePageAuthType = computed({
	get () {
		return consentCollectionPreferencePageAuthTypeState.value
	},
	set (value) {
		changeConsentCollectionPreferencePageAuthType(value)
	}
})

export const consentCollectionPreferencePageLanguageSelected = computed({
	get () {
		return consentCollectionPreferencePageLanguageSelectedState.value
	},
	set (value) {
		changeConsentCollectionPreferencePageTranslationLanguageSelected(value)
	}
})

export const consentCollectionPreferencePageTranslationOverview = computed(
	() => consentCollectionPreferencePageTranslationOverviewState.value
)

export const consentCollectionPreferencePageStatusType = computed({
	get () {
		return consentCollectionPreferencePageStatusTypeState.value
	},
	set (value) {
		changeConsentCollectionPreferencePageStatusType(value)
	}
})

export const resetConsentCollectionPreferencePageFilters = () => {
	changeConsentCollectionPreferencePageSearchQuery(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TEXT_FILTER)
	changeConsentCollectionPreferencePageTranslationStatusId(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_STATUS_SELECTED)
	changeConsentCollectionPreferencePageAuthType(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_AUTH_TYPE)
	changeConsentCollectionPreferencePageTranslationLanguageSelected(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_LANGUAGE_SELECTED)
	changeConsentCollectionPreferencePageStatusType(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_STATUS_TYPE)
}

export const resetConsentCollectionPreferencePageTranslationOverview = () => {
	changeConsentCollectionPreferencePageTranslationOverview(NO_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION_OVERVIEW)
}
