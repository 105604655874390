<template>
	<div class="cassie-vertical-md pt-2">
		<FilterBar
			:search-query.sync="headerTextSearchQuery"
			search-query-label="Header Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changeHeaderTextSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataSearchQuery="changeMetaDataValueSearchQuery"
			@persistSelectedMetaDataKeyId="changeMetaDataKey"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="showTranslatedOnly"
					:items="showTranslatedOnlyOptions"
					custom-sort
					label="Translation Status"
				/>
				<Dropdown
					v-model="selectedHeaderProduct"
					label="Product"
					:items="headersTypeDropdown"
					custom-sort
					@change="changeHeaderProduct"
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Header Translations
			</template>
			<template #title-action>
				<Dropdown
					:value="selectedLanguageIdOrDefault"
					label="Assigned Language"
					searchable
					:items="assignedLanguages"
					@input="selectedLanguage = $event"
				/>
			</template>
			<template #body>
				<DataTable
					:headers="translationTableHeaders"
					:items="filteredHeaderTranslation"
					no-data-text="No Header Translations exist for the selected Language"
					sort-by="id"
					sort-desc
					@click:row="openTranslationModal"
				>
					<template #item.typeDescription="{ item }">
						{{ headerType(item) }}
					</template>
					<template #item.translationStatus="{ item }">
						{{ item.translatedHtml ? 'Translated' : 'Untranslated' }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							tooltip-text="Open Header Translation"
							@click="openTranslationModal(item)"
						>
							{{ canCreateUpdateHeaders ? 'mdi-pencil': 'mdi-eye' }}
						</IconButton>
						<IconButton
							v-if="item.headerTranslationId && headerFullPermissions"
							tooltip-text="Delete Header Translation"
							@click.stop.prevent="headerTranslationIdToBeRemoved = item.headerTranslationId"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<HeaderFooterTranslationModal
			v-if="showTranslationModal"
			:translation="headerTranslation"
			type="Header"
			@close="closedModal"
		/>
		<ConfirmDeleteModal
			v-if="headerTranslationIdToBeRemoved"
			:entity-name="headerTranslationToBeRemoved"
			entity-type="Header Translation"
			@close="headerTranslationIdToBeRemoved = null"
			@delete="deleteHeaderTranslation"
		/>
	</div>
</template>
<script>
import DataTable from '../../../../../../shared/components/data-table.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import HeaderFooterTranslationModal from '.././header-footer-translation-modal.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import { deleteHeaderTranslation } from '../../../../../../shared/utils/api/headers-and-footers.js'
import { headersAndFootersTypeDropdown, headersAndFootersTypeIdEnumOptions, headersAndFootersTypeIdEnum } from '../../../../../../shared/enums/headers-and-footers.js'
import { sortDropdownWithNullDefault } from '../../../../utils/sorting/sortDropdownWithNullDefault.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { selectedAdminPortalBrandId, changeAdminPortalBrandFilter } from '../../../../../../shared/state/brands.js'
import { headerTextSearchQuery, changeHeaderTextSearchQuery, selectedHeaderProduct, changeHeaderProduct } from '../../../../../../shared/state/headers.js'
import { metaDataValueSearchQuery, selectedMetaDataKey, changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../../shared/state/meta-data.js'

export default {
	components: { SectionCard, DataTable, FilterBar, Dropdown, HeaderFooterTranslationModal, ConfirmDeleteModal, IconButton },
	props: {
		headerTranslations: {
			type: [Array, Object],
			default: () => []
		},
		assignedLanguages: {
			type: Array,
			default: () => []
		},
		translationTableHeaders: {
			type: Array,
			default: () => []
		},
		headerFullPermissions: {
			type: Boolean,
			default: false
		},
		canCreateUpdateHeaders: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			headerTextSearchQuery,
			changeHeaderTextSearchQuery,
			selectedHeaderProduct,
			changeHeaderProduct,
			metaDataValueSearchQuery,
			selectedMetaDataKey,
			changeMetaDataKey,
			changeMetaDataValueSearchQuery
		}
	},
	data () {
		return {
			headersAndFootersTypeDropdown,
			headersAndFootersTypeIdEnumOptions,
			headersAndFootersTypeIdEnum,
			selectedLanguage: null,
			headerTranslation: null,
			showTranslationModal: false,
			headerTranslationIdToBeRemoved: null,
			showTranslatedOnly: null
		}
	},
	computed: {
		selectedLanguageIdOrDefault () {
			if (this.selectedLanguage != null) return this.selectedLanguage
			return this.assignedLanguages[0]?.value
		},
		filteredHeaderTranslation () {
			const filteredTranslations = []
			this.headerTranslations.forEach(translations => {
				filteredTranslations.push(translations.filter(({ name, brandId, languageId, translatedHtml, typeId, metaData }) => {
					return this.checkTranslationFilters(name, brandId, languageId, translatedHtml, typeId, metaData)
				}))
			})
			return filteredTranslations.flat()
		},
		headerTranslationToBeRemoved () {
			return `${this.filteredHeaderTranslation.find(({ headerTranslationId }) => headerTranslationId === this.headerTranslationIdToBeRemoved).name} Translation`
		},
		showTranslatedOnlyOptions () {
			return [
				{ value: null, text: 'View All' },
				{ value: true, text: 'Translated Only' },
				{ value: false, text: 'Untranslated Only' }
			]
		},
		headersTypeDropdown () {
			return headersAndFootersTypeDropdown.filter(({ value }) => value !== headersAndFootersTypeIdEnum.PREFERENCE_PAGE_FOOTER).sort(sortDropdownWithNullDefault)
		}
	},
	methods: {
		reloadTables () {
			this.$emit('load-headers')
		},
		closedModal (successfulOperation) {
			this.showTranslationModal = false
			if (successfulOperation) {
				this.reloadTables()
			}
		},
		async deleteHeaderTranslation () {
			await deleteHeaderTranslation(this.headerTranslationIdToBeRemoved)
			this.headerTranslationIdToBeRemoved = null
			showSnackbar('Header Translation has been deleted')
			this.reloadTables()
		},
		openTranslationModal (headerTranslation) {
			if (!this.canCreateUpdateHeaders && !headerTranslation.translatedHtml) return
			if (!this.canCreateUpdateHeaders) {
				headerTranslation.readOnly = true
			}
			this.headerTranslation = headerTranslation
			this.showTranslationModal = true
		},
		checkTranslationFilters (name, brandId, languageId, translatedHtml, typeId, metaData) {
			let check = true
			check = languageId === this.selectedLanguageIdOrDefault
			if (headerTextSearchQuery.value) check = check && name.toLowerCase().includes(headerTextSearchQuery.value.toLowerCase())
			if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
			if (this.showTranslatedOnly !== null) check = check && (this.showTranslatedOnly && translatedHtml || !this.showTranslatedOnly && !translatedHtml)
			if (selectedHeaderProduct.value !== null) check = check && typeId === selectedHeaderProduct.value
			if (this.selectedMetaDataKey !== null) check = check && metaData.some(meta => meta.externalMetaDataId === this.selectedMetaDataKey)
			if (this.metaDataValueSearchQuery) check = check && metaData.some(meta => meta.externalDataValue.toLowerCase().includes(this.metaDataValueSearchQuery.toLowerCase()))
			return check
		},
		clearFilters () {
			this.showTranslatedOnly = null
			selectedHeaderProduct.value = null
		},
		headerType (item) {
			return this.headersAndFootersTypeIdEnumOptions[item.typeId]
		}
	}
}
</script>
