<template>
	<ValidationForm #default="{ handleSubmit }">
		<PrivacyPoliciesLayout>
			<template #content>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<div class="cassie-vertical-md">
							<div class="d-flex flex-row cassie-horizontal-sm">
								<TextField
									v-model="privacyPolicy.privacyPolicyName"
									:disabled="!userFullPermissions"
									label="Privacy Policy Name *"
									:rules="{ required:true, max:50 }"
									class="cassie-input-width-md"
								/>
								<Dropdown
									v-model="privacyPolicy.brandId"
									:disabled="!userFullPermissions || !clientMultiBranded || singleBrand || isEdit"
									:items="filteredBrandOptions"
									custom-sort
									:label="'Brand*' | useLabels"
									searchable
									:rules="{ required: clientMultiBranded}"
									class="cassie-input-width-md"
								/>
							</div>
							<div class="d-flex flex-row cassie-horizontal-sm">
								<TextField
									v-model="privacyPolicy.externalVersionRef"
									:disabled="!userFullPermissions"
									label="External Version Reference *"
									:rules="{ required:true, max:50 }"
									class="cassie-input-width-md"
								/>
								<TextField
									v-model="privacyPolicy.privacyPolicyUrl"
									:disabled="!userFullPermissions"
									label="Policy URL"
									:rules="{ isUrlwithHttp: true, max: 2000 }"
									class="cassie-input-width-md"
								/>
							</div>
							<div class="d-flex flex-row cassie-horizontal-md">
								<v-switch
									v-if="privacyPolicy.brandId === UNIVERSAL_BRAND "
									v-model="privacyPolicy.isGlobalDefault"
									:disabled="currentGlobalDefault || !userFullPermissions"
									class="v-toggle--reverse"
								>
									<template #label>
										Is the Global Default?
									</template>
								</v-switch>
								<v-switch
									v-model="privacyPolicy.isVisibleInCSPortal"
									:disabled="!userFullPermissions"
									class="v-toggle--reverse"
								>
									<template #label>
										Is Visible in CS Portal?
									</template>
								</v-switch>
								<!-- switch for isCsPortal -->
							</div>
						</div>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="navigateToOverview">
							Back to overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userFullPermissions"
							@click="handleSubmit(savePrivacyPolicyChanges)"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</PrivacyPoliciesLayout>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import PrivacyPoliciesLayout from './privacy-policies-layout.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM, userHasBrand } from '../../../../../shared/state/brands.js'
import { postPrivacyPolicy, putPrivacyPolicy, setGlobalDefaultPrivacyPolicy } from '../../../../../shared/utils/api/privacy-policies.js'
import { PRIVACY_POLICIES_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
export default {
	components: { ValidationForm, PrivacyPoliciesLayout, SectionCard, TextField, Dropdown, PageActionRow, SecondaryActionButton, PrimaryActionButton },
	props: {
		privacyPolicyToEdit: Object
	},
	setup () {
		return {
			brandOptions,
			showSnackbar,
			UNIVERSAL_BRAND,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect
		}
	},
	data () {
		return {
			privacyPolicy: JSON.parse(JSON.stringify(this.privacyPolicyToEdit || {
				privacyPolicyName: '',
				privacyPolicyUrl: null,
				externalVersionRef: '',
				isVisibleInCSPortal: false,
				brandId: defaultBrandInSelect.value,
				isGlobalDefault: false
			})),
			privacyPolicyId: null,
			currentGlobalDefault: false
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		isEdit () {
			return Boolean(this.privacyPolicyToEdit)
		},
		userFullPermissions () {
			if (!this.isEdit) {
				return this.checkPermission(PRIVACY_POLICIES_MODULE_FULL_PERMISSIONS)
			} else return this.checkPermission(PRIVACY_POLICIES_MODULE_FULL_PERMISSIONS) && userHasBrand(this.privacyPolicy.brandId)
		},
		filteredBrandOptions () {
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		}
	},
	created () {
		if (this.privacyPolicyToEdit?.isGlobalDefault) {
			this.currentGlobalDefault = true
		}
	},
	methods: {
		navigateToOverview () {
			this.$router.back()
		},
		async savePrivacyPolicyChanges () {
			const isGlobalDefault = this.privacyPolicy.isGlobalDefault
			delete this.privacyPolicy.isGlobalDefault

			if (this.isEdit) {
				await putPrivacyPolicy(this.privacyPolicy)
				this.privacyPolicyId = this.privacyPolicy.privacyPolicyId
				showSnackbar('Your changes have been saved')
			} else {
				await postPrivacyPolicy(this.privacyPolicy).then(response => {
					this.privacyPolicyId = response.data.newPrivacyPolicyId
				})
				showSnackbar('Privacy Policy has been created successfully')
			}

			if (isGlobalDefault) {
				await setGlobalDefaultPrivacyPolicy({
					privacyPolicyId: this.privacyPolicyId
				})
			}

			this.privacyPolicyId = null
			this.navigateToOverview()
		}
	}
}
</script>
