<template>
	<ValidationForm #default="{ handleSubmit }">
		<ConsentCollectionLayout
			v-if="preferencePageTranslation.preferencePageAuthTranslations"
			:title="pageTitle"
			caption="Manage drag-and-drop preference page translation"
		>
			<template #content>
				<div class="cassie-vertical-md">
					<SectionCard>
						<template #title>
							Details
						</template>
						<template #body>
							<v-row dense>
								<v-col cols="6">
									<TextField
										:value="preferencePageTranslation.preferencePageAuthTranslations.preferencePageName"
										label="Preference Page Name"
										disabled
									/>
								</v-col>
								<v-col cols="6">
									<TextField
										:value="preferencePageTranslation.preferencePageAuthTranslations.brandName"
										label="Brand"
										disabled
									/>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="6">
									<TextField
										:value="preferencePageTranslation.preferencePageAuthTranslations.languageName"
										label="Language"
										disabled
									/>
								</v-col>
							</v-row>
						</template>
					</SectionCard>
					<AuthenticationTranslationsCard
						v-if="preferencePageTranslation.preferencePageAuthTranslations.authTypeId !== AUTHENTICATION_TYPES.DIRECT"
						:user-full-permissions="userFullPermissions"
						:authentication-page-translations.sync="preferencePageTranslation.preferencePageAuthTranslations"
					/>
					<div>
						<Tabs
							v-if="schemaTabs.length > 1"
							:tab.sync="tab"
							:tabs="schemaTabs"
							embedded
							small
						/>
						<TabItems
							:tab="tab"
							:tabs="schemaTabs"
						>
							<div
								v-for="({ key, slot }) in schemaTabs"
								:key="key"
								:slot="slot"
								class="cassie-vertical-md"
							>
								<TranslationManageTab
									v-if="translatedSchema"
									:preference-page-translation="preferencePageTranslation"
									:area="key"
									:user-full-permissions="userFullPermissions"
									:area-schema="preferencePageTranslation.defaultSchema.schema[key]"
									:area-translation-schema="preferencePageTranslation.translatedSchema.schema[key]"
									:translated-schema="translatedSchema"
									@update-translation="updateTranslation"
								/>
							</div>
						</TabItems>
					</div>
				</div>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton
							test-id="manage_preference_page_translation_back_to_overview_button"
							@click="backToOverview"
						>
							Back To Overview
						</SecondaryActionButton>
						<v-spacer />
						<SecondaryActionButton
							test-id="manage_preference_page_translation_preview_button"
							@click="previewTranslation"
						>
							Preview
						</SecondaryActionButton>
						<PrimaryActionButton
							v-if="userFullPermissions"
							class="ml-2"
							test-id="manage_preference_page_translation_finish_button"
							@click="handleSubmit(saveTranslation)"
						>
							{{ isEdit ? 'Save Changes' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ConsentCollectionLayout>
		<TranslationPreviewModal
			v-if="showPreviewModal"
			:channels="channels"
			:extended-preferences="extendedPreferences"
			:language-id="languageId"
			:language-name="preferencePageTranslation.preferencePageAuthTranslations.languageName"
			:name="preferencePageTranslation.preferencePageAuthTranslations.preferencePageName"
			@close="closePreviewModal"
		/>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import ConsentCollectionLayout from '../../consent-collection-layout.vue'
import AuthenticationTranslationsCard from './authentication-translations-card.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import PageActionRow from '../../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import Tabs from '../../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../../shared/components/tab-items.vue'
import TranslationManageTab from '../../translation-manage-tab.vue'
import TranslationPreviewModal from '../../translation-preview-modal.vue'
import { LINKS_AREA_FULL_PERMISSIONS,	LINKS_PAGE_FULL_PERMISSIONS } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { CONSENT_COLLECTION_PREFERENCE_PAGES } from '../../../../../router/route-names.js'
import { AUTHENTICATION_TYPES } from '../preference-page-authentication-types.js'
import { getPreferencePageTranslation, putPreferencePageTranslation } from '../../../../../../../shared/utils/api/preference-pages.js'
import { getPreferenceChannels } from '../../../../../../../shared/utils/api/channels.js'
import { getExtendedPreferences } from '../../../../../../../shared/utils/api/extended-preferences.js'
import { postSchema, putSchema, getTheme, previewSchemaTranslation } from '../../../../../../../shared/utils/api/form-builder.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { brandOptions } from '../../../../../../../shared/state/brands.js'
import { getEnv } from '../../../../../../../shared/utils/runtime-settings.js'
import { defaultVueformBuilderFormat } from '../../../../../../../shared/utils/vueform-builder-utils.js'
export default {
	components: {
		ValidationForm,
		ConsentCollectionLayout,
		AuthenticationTranslationsCard,
		SectionCard,
		TextField,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		Tabs,
		TabItems,
		TranslationManageTab,
		TranslationPreviewModal
	},
	props: {
		id: {
			type: Number,
			required: true
		},
		languageId: {
			type: Number,
			required: true
		}
	},
	setup () {
		const isFormBuilderScriptLoaded = Array.from(document.getElementsByTagName('script')).some(script => script.src === getEnv('VUE_APP_FORMBUILDER_URL'))
		if (!isFormBuilderScriptLoaded) {
			const formBuilderScript = document.createElement('script')
			formBuilderScript.src = getEnv('VUE_APP_FORMBUILDER_URL')
			document.head.appendChild(formBuilderScript)
		}
	},
	data () {
		return {
			AUTHENTICATION_TYPES,
			preferencePageTranslation: {},
			formBuilderThemeGUID: null,
			channels: [],
			extendedPreferences: [],
			tab: '0',
			translatedSchema: {},
			showPreviewModal: false,
			translationSchemaGUID: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		isEdit () {
			return this.preferencePageTranslation?.translatedSchema.formBuilderSchemaId
		},
		pageTitle () {
			return `Manage ${this.preferencePageTranslation?.preferencePageAuthTranslations?.preferencePageName} Translation - ${this.preferencePageTranslation?.preferencePageAuthTranslations?.languageName}`
		},
		userFullPermissions () {
			return (this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)) && this.userHasBrand
		},
		userHasBrand () {
			const brands = brandOptions.value.map(({ value }) => value)
			return brands.includes(this.preferencePageTranslation?.preferencePageAuthTranslations?.brandId)
		},
		schemaTabs () {
			const tabs = []
			const defaultLanguageSchema = this.preferencePageTranslation?.defaultSchema?.schema
			if (defaultLanguageSchema) {
				for (const key in defaultLanguageSchema) {
					if (defaultLanguageSchema[key].length > 0) {
						tabs.push({
							title: this.tabName(key),
							key: key,
							slot: tabs.length.toString()
						})
					}
				}
			}
			return tabs
		}
	},
	async created () {
		await this.getPreferencePageTranslation()
	},
	methods: {
		async getPreferencePageTranslation () {
			const { preferencePageTranslation } = await getPreferencePageTranslation(this.id, this.languageId)
			this.preferencePageTranslation = preferencePageTranslation
			this.translatedSchema = await this.convertTranslatedSchema()
			if (this.isEdit) {
				this.translationSchemaGUID = this.preferencePageTranslation.translatedSchema.formBuilderSchemaId
			}
		},
		tabName (key) {
			switch (key) {
				case 'components':
					return 'Components'
				case 'HTML':
					return 'HTML'
				case 'dataSubjects':
					return 'Data Subject'
				default:
					return key
			}
		},
		convertTranslatedSchema () {
			const translatedSchema = this.preferencePageTranslation.translatedSchema.schema
			const components = translatedSchema.components || []
			const HTML = translatedSchema.HTML || []
			const dataSubjects = translatedSchema.dataSubjects || []

			const translationObj = {}
			components.forEach(component => {
				if (component.name) {
					translationObj[component.name] = component
				}
			})

			HTML.forEach(html => {
				if (html.name) {
					translationObj[html.name] = html
				}
			})

			dataSubjects.forEach(dataSubject => {
				if (dataSubject.name) {
					translationObj[dataSubject.name] = dataSubject
				}
			})
			return translationObj
		},
		updateTranslation (key, property, value) {
			this.$set(this.translatedSchema[key], property, value)
		},
		backToOverview () {
			this.$router.push({
				name: CONSENT_COLLECTION_PREFERENCE_PAGES,
				params: {
					accessedViaTranslations: true
				}
			})
			localStorage.removeItem('vueform-builder')
		},
		nextStep () {
			this.step++
		},
		previousStep () {
			this.step--
		},
		async previewTranslation () {
			if (this.channels.length === 0) {
				const { data: { channels } } = await getPreferenceChannels()
				this.channels = channels
			}
			if (this.extendedPreferences.length === 0) {
				const { extendedPreferences } = await getExtendedPreferences()
				this.extendedPreferences = extendedPreferences
			}
			const builderConfig = { ...defaultVueformBuilderFormat }
			const previewPayload = {
				json: JSON.stringify(this.translatedSchema),
				defaultLanguageSchemaGuid: this.preferencePageTranslation.defaultSchema.formBuilderSchemaId,
				languageId: this.languageId
			}
			const translationPreview = await previewSchemaTranslation(previewPayload).then(response => {
				return response.data.translatedSchemaJson
			})
			builderConfig.schema = JSON.parse(translationPreview)
			const { data } = await getTheme(this.preferencePageTranslation.preferencePageAuthTranslations.formBuilderThemeGUID)
			builderConfig.theme = JSON.parse(data.json)
			localStorage.setItem('vueform-builder', JSON.stringify(builderConfig))
			this.showPreviewModal = true
		},
		closePreviewModal () {
			this.showPreviewModal = false
			localStorage.removeItem('vueform-builder')
		},
		async saveTranslation () {
			await this.saveSchema()
			await this.putPreferencePageTranslation()
			showSnackbar('Preference Page Translation Saved')
			this.backToOverview()
		},
		async putPreferencePageTranslation () {
			const payload = {
				languageId: this.languageId,
				authTypeId: this.preferencePageTranslation.preferencePageAuthTranslations.authTypeId,
				standDakAuthFieldFriendlyName: this.preferencePageTranslation.preferencePageAuthTranslations.authTypeId !== AUTHENTICATION_TYPES.DIRECT
					? this.preferencePageTranslation.preferencePageAuthTranslations.translatedStandDakAuthFieldFriendlyName
					: null,
				verificationSentMessage: this.preferencePageTranslation.preferencePageAuthTranslations.authTypeId !== AUTHENTICATION_TYPES.DIRECT
					? this.preferencePageTranslation.preferencePageAuthTranslations.translatedVerificationSentMessage
					: null,
				verificationAreaText: this.preferencePageTranslation.preferencePageAuthTranslations.authTypeId !== AUTHENTICATION_TYPES.DIRECT
					? this.preferencePageTranslation.preferencePageAuthTranslations.translatedVerificationAreaText
					: null,
				verificationFailedMessage: this.preferencePageTranslation.preferencePageAuthTranslations.authTypeId !== AUTHENTICATION_TYPES.DIRECT
					? this.preferencePageTranslation.preferencePageAuthTranslations.translatedVerificationFailedMessage
					: null,
				translationSchemaGUID: this.translationSchemaGUID
			}
			await putPreferencePageTranslation(this.id, payload)
		},
		async saveSchema () {
			const schemaTranslationPayload = {
				brandId: this.preferencePageTranslation.preferencePageAuthTranslations.brandId,
				json: JSON.stringify(this.translatedSchema),
				isTemplate: false,
				languageId: this.languageId
			}
			if (!this.isEdit) {
				schemaTranslationPayload.defaultLanguageSchemaGUID = this.preferencePageTranslation.defaultSchema.formBuilderSchemaId
				schemaTranslationPayload.languageId = this.languageId
				await postSchema(schemaTranslationPayload).then(response => {
					this.translationSchemaGUID = response.data.id
				})
			} else {
				schemaTranslationPayload.defaultLanguageSchemaGuid = this.preferencePageTranslation.defaultSchema.formBuilderSchemaId
				await putSchema(this.preferencePageTranslation.translatedSchema.formBuilderSchemaId, schemaTranslationPayload)
			}
		}
	}
}
</script>
