<template>
	<SectionCard>
		<template #title>
			Properties
		</template>
		<template #body>
			<TextField
				:value="sourceCodeReplaceValue"
				:disabled="!userFullPermissions"
				label="Source Code Replace Field *"
				:tooltip-text="`
					<p>Input the Source Code replace field text for this Link</p>
					<p>This data will be stored against the Preference update for the Data Subject in question and can be used to determine the source of the transaction if the Link is used in multiple locations</p>
				`"
				class="cassie-input-width-md"
				:rules="validationRules"
				@input="updatePropertyFields({
					...(propertyFields),
					sourceCodeReplaceValue: $event
				})"
			/>
			<TextField
				:value="responseCodeReplaceValue"
				:disabled="!userFullPermissions"
				:label="'Response Channel Replace Field *' | useLabels"
				:tooltip-text="`
					<p>Input the Response Channel replace field text for this Link</p>
					<p>This data will be stored against the Preference update for the Data Subject in question and can be used to determine the avenue that the transaction took place from e.g. Email, Website Link
</p>
				`"
				class="cassie-input-width-md"
				:rules="validationRules"
				@input="updatePropertyFields({
					...(propertyFields),
					responseCodeReplaceValue: $event
				})"
			/>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
export default {
	components: { TextField, SectionCard },
	props: {
		propertyFields: Object,
		brandId: Number,
		userFullPermissions: Boolean
	},
	computed: {
		sourceCodeReplaceValue () {
			return this.propertyFields?.sourceCodeReplaceValue
		},
		responseCodeReplaceValue () {
			return this.propertyFields?.responseCodeReplaceValue
		},
		validationRules () {
			return {
				required: true,
				forbiddenChars: { value: ['/', '\\', '#'] },
				max: 64
			}
		}
	},
	mounted () {
		if (this.sourceCodeReplaceValue && this.responseCodeReplaceValue) return
		const propertyFields = {
			...(this.propertyFields)
		}
		if (!this.sourceCodeReplaceValue) propertyFields.sourceCodeReplaceValue = '%SourceCode%'
		if (!this.responseCodeReplaceValue) propertyFields.responseCodeReplaceValue = '%ResponseChannel%'
		this.updatePropertyFields(propertyFields)
	},
	methods: {
		updatePropertyFields (propertyFields) {
			this.$emit('update:propertyFields', propertyFields)
		}
	}
}
</script>
