<template>
	<ViewLayout>
		<template #header-title>
			{{ 'Cookie Scripts' | useLabels }}
		</template>
		<template #header-caption>
			{{ 'Add and manage cookie scripts' | useLabels }}
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<CookieScriptsTab
						:cookie-scripts="cookieScripts"
						:cookie-categories="cookieCategories"
						:transaction-types="transactionTypes"
						:security-levels="securityLevels"
						:channel-device-copy-actions="channelDeviceCopyActions"
						@load="loadCookieScripts"
					/>
				</template>
				<template #1>
					<CookieScriptsTranslationsTab
						:cookie-scripts="cookieScripts"
						:cookie-categories="cookieCategories"
						@load="loadCookieScripts"
					/>
				</template>
			</TabItems>
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '../../../../../../../shared/layouts/view-layout.vue'
import Tabs from '../../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../../shared/components/tab-items.vue'
import { getCookieScripts } from '../../../../../../../shared/utils/api/cookies/cookie-scripts.js'
import { getCookieCategories } from '../../../../../../../shared/utils/api/cookies/cookie-categories.js'
import CookieScriptsTab from './cookie-scripts-tab.vue'
import CookieScriptsTranslationsTab from './cookie-scripts-translations-tab.vue'
import { getTransactionTypes } from '../../../../../../../shared/utils/api/transaction-types.js'

export default {
	components: {
		ViewLayout,
		Tabs,
		TabItems,
		CookieScriptsTab,
		CookieScriptsTranslationsTab
	},
	data () {
		return {
			tabs: [{ title: 'Cookie Scripts', slot: '0' }, { title: 'Cookie Script Translations', slot: '1' }],
			tab: '0',
			cookieScripts: [],
			cookieCategories: [],
			transactionTypes: [],
			securityLevels: [],
			channelDeviceCopyActions: []
		}
	},
	created () {
		this.loadCookieScripts()
		this.loadCookieCategories()
		this.loadTransactionTypes()
	},
	methods: {
		async loadCookieScripts () {
			const { cookieScripts } = await getCookieScripts()
			this.cookieScripts = cookieScripts
		},
		async loadCookieCategories () {
			const { cookieCategories } = await getCookieCategories()
			this.cookieCategories = cookieCategories
		},
		async loadTransactionTypes () {
			const { data: { transactionTypes, securityLevels, channelDeviceCopyActions } } = await getTransactionTypes()
			this.transactionTypes = transactionTypes
			this.securityLevels = securityLevels
			this.channelDeviceCopyActions = channelDeviceCopyActions
		}
	}
}
</script>
