<template>
	<div>
		<v-row dense>
			<v-col cols="3">
				<b>
					Channel Name:
				</b>
			</v-col>
			<v-col cols="9">
				{{ channel.channelName }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Channel Type:
				</b>
			</v-col>
			<v-col cols="9">
				{{ channel.channelType.typeDescription }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Channel ID:
				</b>
			</v-col>
			<v-col cols="9">
				{{ channel.channelId }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Channel Category:
				</b>
			</v-col>
			<v-col cols="9">
				{{ channel.channelCategory }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Channel Description:
				</b>
			</v-col>
			<v-col cols="9">
				{{ channel.channelDescription }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Brand Name:
				</b>
			</v-col>
			<v-col cols="9">
				{{ channel.brandName }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Expiry Days:
				</b>
			</v-col>
			<v-col cols="9">
				{{ channel.expiryDays }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Eligible for Double Opt In:
				</b>
			</v-col>
			<v-col cols="9">
				{{ channel.channelType.doubleOptInEligible }}
			</v-col>
		</v-row>
		<v-row
			v-if="channel.metaData.length > 0"
			dense
		>
			<v-col cols="3">
				<b>
					Metadata:
				</b>
			</v-col>
			<v-col cols="9">
				<span
					v-for="(metaData, index) in channel.metaData"
					:key="index"
					class="mr-2"
				>
					<v-chip
						class="mb-2"
						small
					>
						<span>
							<strong>
								{{ metaData.metaDataKeyName }}:
							</strong>
							{{ metaData.metaDataValue }}
						</span>
					</v-chip>
				</span>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: {
		channel: {
			type: Object,
			required: true
		}
	}
}
</script>
