<!-- eslint-disable no-useless-escape -->
<!-- eslint-disable max-lines -->
<template>
	<ValidationForm
		#default="{ handleSubmit }"
		class="cassie-vertical-md"
	>
		<ConfirmationPagesLayout
			:assets-links="assetsLinks"
			:read-only="!fullPermissions"
		>
			<template #content>
				<SectionCard>
					<template #title>
						Details
					</template>
					<template #body>
						<v-row dense>
							<v-col :cols="6">
								<TextField
									v-model="confirmationPage.confirmationPageName"
									:disabled="!fullPermissions"
									:label="'Confirmation Page Name *' | useLabels"
									rules="required"
								/>
							</v-col>
							<v-col :cols="6">
								<Dropdown
									v-model="confirmationPage.brandId"
									:disabled="isEdit || !fullPermissions || !clientMultiBranded || singleBrand"
									:label="`Brand*` | useLabels"
									searchable
									class="cassie-input-width-xl"
									:items="filteredBrandOptions"
									custom-sort
									:rules="{ required: clientMultiBranded}"
								/>
							</v-col>
						</v-row>
						<FroalaEditor
							v-if="fullPermissions"
							v-model="confirmationPage.confirmationPageHtml"
							:title="'Confirmation Page Contents *' | useLabels"
							:rules="{ required: true, max: 10000 }"
						/>
						<div
							v-else
						>
							<div>
								<p class="text-subtitle-2 mb-3">
									Confirmation Page Contents
								</p>
							</div>
							<div
								v-dompurify-html="confirmationPage.confirmationPageHtml"
								class="mt-2 cassie-disabled-textarea cassie-input-width-xl rounded-lg"
							/>
						</div>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						Translations
					</template>
					<template #title-action>
						<TextButton
							v-if="!showAddTranslation && availableLanguages.length !== 0 || fullPermissions"
							@click="showTranslationSection"
						>
							<v-icon left>
								mdi-plus
							</v-icon>
							Add Translation
						</TextButton>
					</template>
					<template #body>
						<DataTable
							:headers="pageTranslationHeaders"
							:items="confirmationPageTranslations"
							@click:row="editTranslationClick"
						>
							<template #item.action="{ item }">
								<IconButton
									:tooltip-text="fullPermissions ? 'Edit Translation' : 'View Translation'"
									@click="editTranslationClick(item)"
								>
									{{ fullPermissions ? 'mdi-pencil' : 'mdi-eye' }}
								</IconButton>
								<IconButton
									v-if="fullPermissions"
									tooltip-text="Delete Translation"
									small
									@click.stop="deleteTranslationClick(item)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						Scripts & Assets
					</template>
					<template #body>
						<div class="d-flex flex-row full-width">
							<div class="cassie-input-width-xl">
								<div class="font-weight-bold">
									Javascript <v-tooltip
										slot="append"
										top
									>
										<template #activator="{ on }">
											<v-icon
												light
												v-on="on"
											>
												mdi-information
											</v-icon>
										</template>
										<span>
											{{ 'Upload your script.js file' }}
										</span>
									</v-tooltip>
								</div>
								<v-file-input
									v-model="files.script"
									:disabled="!fullPermissions"
									counter
									show-size
									label="Upload your script.js file"
									accept=".js"
									truncate-length="50"
									@change="readFile($event, 'script')"
								/>
							</div>
							<div class="cassie-input-width-xl">
								<div class="font-weight-bold">
									CSS (style.css)<v-tooltip
										slot="append"
										top
									>
										<template #activator="{ on }">
											<v-icon
												light
												v-on="on"
											>
												mdi-information
											</v-icon>
										</template>
										<span>
											{{ 'Upload your style.css file' }}
										</span>
									</v-tooltip>
								</div>
								<v-file-input
									v-model="files.style"
									:disabled="!fullPermissions"
									counter
									show-size
									size
									label="Upload your style.css file"
									accept=".css"
									truncate-length="50"
									@change="readFile($event, 'css')"
								/>
							</div>
						</div>
						<div class="d-flex flex-row cassie-horizontal-lg mt-4">
							<div class="cassie-input-width-xl">
								<div class="font-weight-bold">
									Images<v-tooltip
										slot="append"
										top
									>
										<template #activator="{ on }">
											<v-icon
												light
												v-on="on"
											>
												mdi-information
											</v-icon>
										</template>
										<span>
											{{ 'Images must be less than 5MB in size. Allowed file formats: jpg, jpeg, png, gif and svg.' }}
										</span>
									</v-tooltip>
								</div>
								<validation-provider
									#default="{ errors }"
									:rules="{size: 2e6}"
									tag="div"
									style="flex: 1 1 auto;"
								>
									<v-file-input
										v-model="files.images"
										:disabled="!fullPermissions"
										counter
										accept="image/jpg, image/jpeg, image/png, image/gif, image/svg+xml"
										multiple
										label="Upload your image files"
										show-size
										:error-messages="errors.length ? [errors[0]] : []"
										truncate-length="50"
									/>
								</validation-provider>
							</div>
							<v-spacer />

							<div class="cassie-input-width-xl">
								<v-spacer />
							</div>
						</div>
						<div class="d-flex flex-row cassie-horizontal-lg mt-4">
							<div class="cassie-input-width-xl">
								<div class="font-weight-bold mb-3">
									Assets: <v-tooltip
										slot="append"
										top
									>
										<template #activator="{ on }">
											<v-icon
												light
												v-on="on"
											>
												mdi-information
											</v-icon>
										</template>
										<span>
											{{ 'Note: Files will be uploaded to the following location(s).' }}
										</span>
									</v-tooltip>
								</div>
								<template v-if="!isEdit">
									<div class="font-weight-bold">
										Javascript:
									</div>
									<p>
										{{ assetsLinks != null ? assetsLinks[0].replace('[ConfirmationPageID]', confirmationPageIdTemplate) : null }}
									</p>
									<div class="font-weight-bold">
										CSS:
									</div>
									<p>
										{{ assetsLinks != null ? assetsLinks[1].replace('[ConfirmationPageID]', confirmationPageIdTemplate) : null }}
									</p>
									<div class="font-weight-bold">
										Images:
									</div>
									<p>
										{{ assetsLinks != null ? assetsLinks[2].replace('[ConfirmationPageID]', confirmationPageIdTemplate) : null }}
									</p>
								</template>
								<DataTable
									v-else
									:headers="assetHeaders"
									:items="confirmationPageAssets"
									@click:row="downloadAsset"
								>
									<template #item.name="{ item }">
										<a
											:href="item.url"
											target="_blank"
											rel="noopener noreferrer"
										>
											{{ item.name }}
										</a>
									</template>
									<template #item.action="{ item }">
										<IconButton
											tooltip-text="Download File"
											@click.stop="downloadAsset(item)"
										>
											mdi-file-download
										</IconButton>
										<IconButton
											tooltip-text="Delete File"
											@click="deleteAsset(item)"
										>
											mdi-trash-can
										</IconButton>
									</template>
								</DataTable>
							</div>
						</div>
					</template>
				</SectionCard>
				<Modal
					v-if="showAddTranslation"
					width="80%"
				>
					<template #modal-title>
						<div class="cassie-vertical-sm">
							{{ editTranslation ? 'Edit ' : 'Add New ' }} Confirmation Page Translation
						</div>
						<v-spacer />
						<IconButton
							@click="cancel"
						>
							mdi-close
						</IconButton>
					</template>
					<template #modal-content>
						<div class="cassie-vertical-md">
							<div
								class="d-flex flex-row cassie-horizontal-sm"
							>
								<Dropdown
									v-if="!editTranslation"
									v-model="selectedLanguage"
									:items="availableLanguages"
									label="Language *"
									searchable
									rules="required"
									item-value="languageId"
									item-text="languageName"
									tooltip-text="Select the language you want to add a translation for."
								/>
								<Dropdown
									v-else
									slot="append"
									v-model="selectedLanguage"
									:items="languages"
									label="Language *"
									searchable
									rules="required"
									item-value="languageId"
									item-text="languageName"
									tooltip-text="Select the language you want to add a translation for."
									:disabled="true"
								/>
								<v-spacer />
								<PrimaryActionButton
									:disabled="!fullPermissions || confirmationPage.confirmationPageHtml == undefined || confirmationPage.confirmationPageHtml === ''"
									@click="bringDefault"
								>
									Import Default Page Contents
								</PrimaryActionButton>
							</div>
							<FroalaEditor
								v-model="confirmationPageTranslationHtml"
								title="Translation Page Contents *"
								:rules="{ required: true, max: 2000 }"
								height="50%"
							/>
						</div>
					</template>
					<template #modal-footer>
						<SecondaryActionButton
							v-if="showAddTranslation"
							@click="cancel"
						>
							Cancel
						</SecondaryActionButton>
						<PrimaryActionButton
							:disabled="!validTranslation || !fullPermissions"
							@click="addTranslationClick"
						>
							{{ !editTranslation ? 'Add' : 'Save' }}
						</PrimaryActionButton>
					</template>
				</Modal>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="navigateToOverview">
							Back to overview
						</SecondaryActionButton>
						<v-spacer />
						<div class="d-flex cassie-horizontal-md">
							<PrimaryActionButton @click="preview">
								Preview
							</PrimaryActionButton>
							<PrimaryActionButton
								v-if="fullPermissions"
								@click="handleSubmit(submit)"
							>
								{{ isEdit ? 'Save' : 'Create' }}
							</PrimaryActionButton>
						</div>
					</template>
				</PageActionRow>
			</template>
		</ConfirmationPagesLayout>
	</ValidationForm>
</template>

<script>
/* eslint-disable max-lines */
/* eslint-disable no-useless-escape */
import axios from 'axios'
import { mapGetters } from 'vuex'
import ConfirmationPagesLayout from './confirmation-pages-layout.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import FroalaEditor from '../../shared/froala-editor.vue'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import downloadFile from '../../../../../shared/mixins/download-file.js'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, waitForLoad, UNIVERSAL_BRAND_ITEM, userHasBrand } from '../../../../../shared/state/brands.js'
import { upsertConfirmationPage, getConfirmationPageAssets, uploadFiles, deleteConfirmationPageAsset } from '../../../../../shared/utils/api/confirmation-page.js'
import { CONFIRMATION_PAGES } from '../../../router/route-names.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { validationObservers } from '../../../../../shared/state/validation-observers.js'
import { getAssignedLanguages } from '../../../../../shared/utils/api/languages.js'
import Modal from '../../../../../shared/components/modal.vue'
const UNIVERSAL_BRAND = 0
export default {
	components: {
		Modal,
		PageActionRow,
		IconButton,
		PrimaryActionButton,
		SecondaryActionButton,
		TextButton,
		ValidationForm,
		Dropdown,
		DataTable,
		FroalaEditor,
		TextField,
		SectionCard,
		ConfirmationPagesLayout
	},
	mixins: [downloadFile],
	props: {
		confirmationPageToEdit: Object,
		assetsLinks: Array,
		userFullPermissions: Boolean
	},
	setup () {
		return {
			brandOptions,
			waitForLoad,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect
		}
	},
	data () {
		return {
			confirmationPage: JSON.parse(JSON.stringify(this.confirmationPageToEdit || {
				confirmationPageName: '',
				confirmationPageHTML: '',
				brandId: defaultBrandInSelect.value,
				translations: []
			})),
			UNIVERSAL_BRAND,
			confirmationPageAssets: [],
			showAddTranslation: false,
			languages: [],
			files: {
				script: null,
				css: null,
				images: []
			},
			previewFiles: {
				script: null,
				css: null,
				images: []
			},
			selectedLanguage: null,
			editTranslation: false,
			confirmationPageTranslationHtml: '',
			pageTranslationHeaders: [
				{
					text: 'Language',
					value: 'languageName',
					width: '45%'
				},
				{
					text: 'HTML',
					value: 'confirmationPageHtml',
					width: '45%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '10%'
				}
			]
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		isEdit () {
			return Boolean(this.confirmationPageToEdit)
		},
		fullPermissions () {
			if (!this.isEdit) return this.userFullPermissions
			else return this.userFullPermissions && userHasBrand(this.confirmationPage.brandId)
		},
		confirmationPageIdTemplate () {
			return this.confirmationPageToEdit ? this.confirmationPageToEdit.confirmationPageId : '[ConfirmationPageID]'
		},
		brandDropdownOptions () {
			const universalBrand = this.brandOptions.find(brand => brand.value === 0)
			if (universalBrand) {
				universalBrand.text = 'Universal'
			}
			return this.brandOptions
		},
		filteredBrandOptions () {
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		},
		scriptAsset () {
			return this.confirmationPageAssets.find(asset => asset.type === 'Scripts')
		},
		styleAsset () {
			return this.confirmationPageAssets.find(asset => asset.type === 'Styles')
		},
		validTranslation () {
			return this.confirmationPageTranslationHtml && this.confirmationPageTranslationHtml.length > 0 && this.selectedLanguage !== null
		},
		availableLanguages () {
			return this.languages.filter(language => !this.confirmationPage.translations.find(translation => translation.languageId === language.languageId))
		},
		confirmationPageTranslations () {
			return this.confirmationPage.translations.map(translation => ({
				...translation,
				confirmationPageHtml: translation.confirmationPageHtml.substring(0, 100) + (translation.confirmationPageHtml.length > 100 ? '...' : ''),
				languageName: this.languages.find(language => language.languageId === translation.languageId)?.languageName ?? 'Archived Language'
			}))
		},
		assetHeaders () {
			const headers = [
				{ value: 'name', text: 'File Name', width: '30%' },
				{ value: 'url', text: 'File Path', width: '55%' },
				{ value: 'action', text: 'Action', width: '15%' }
			]
			return this.fullPermissions ? headers : headers.filter(h => h.value !== 'action')
		}
	},
	async created () {
		await this.waitForLoad()
		if (!this.isEdit) {
			this.confirmationPage.brandId = this.brandOptions[0].value
		}
		if (this.confirmationPageToEdit) {
			await this.getConfirmationPageAssets()
		}
		const { data } = await getAssignedLanguages()
		this.languages = data.map(language => ({
			languageName: language.languageName,
			languageId: language.languageID
		}))
		// We add this to prevent validation observer from triggering changes after Froala editor is populated on page load.
		validationObservers.value[0].flags.changed = false
		validationObservers.value[0].flags.dirty = false
	},
	methods: {
		async getConfirmationPageAssets () {
			const { data } = await getConfirmationPageAssets(this.confirmationPage.confirmationPageId)
			this.confirmationPageAssets = data?.fileUrls ?? []
			if (this.styleAsset) this.previewFiles.css = await this.saveFileContents(this.styleAsset.url)
			if (this.scriptAsset) this.previewFiles.script = await this.saveFileContents(this.scriptAsset.url)
		},
		async saveFileContents (fileLink) {
			const { data } = await axios.get(fileLink)
			return data
		},
		readFile (file, fileType) {
			const reader = new FileReader()
			reader.readAsText(file)
			reader.onload = () => this.storeFileResult(reader.result, fileType)
			this.previewFiles[fileType] = reader.result
		},
		bringDefault () {
			this.confirmationPageTranslationHtml = this.confirmationPage.confirmationPageHtml
		},
		async submit () {
			const formData = new FormData()

			if (this.files.script) {
				formData.append('script', this.files.script)
			}
			if (this.files.style) {
				formData.append('style', this.files.style)
			}
			for (const file of this.files.images) {
				formData.append('images', file)
			}
			const confirmationPageId = await upsertConfirmationPage(this.confirmationPage)
			formData.append('confirmationId', confirmationPageId)
			await uploadFiles(formData)
			this.navigateToOverview()
			showSnackbar(this.isEdit ? 'This confirmation page has been updated' : 'You have created a new confirmation page')
		},
		showTranslationSection () {
			this.editTranslation = false
			this.showAddTranslation = true
		},
		cancel () {
			this.showAddTranslation = false
			this.editTranslation = false
			this.confirmationPageTranslationHtml = ''
			this.selectedLanguage = null
		},
		editTranslationClick (item) {
			this.editTranslation = true
			this.confirmationPageTranslationHtml = this.confirmationPage.translations.find(translation => translation.languageId === item.languageId).confirmationPageHtml
			this.selectedLanguage = item.languageId
			this.showAddTranslation = true
		},
		deleteTranslationClick (item) {
			this.confirmationPage.translations = this.confirmationPage.translations.filter(translation => translation.languageId !== item.languageId)
		},
		addTranslationClick () {
			if (this.editTranslation) {
				this.confirmationPage.translations = this.confirmationPage.translations.map(translation => {
					if (translation.languageId === this.selectedLanguage) {
						return {
							languageId: this.selectedLanguage,
							languageName: this.languages.find(language => language.languageId === this.selectedLanguage).languageName,
							confirmationPageHtml: this.confirmationPageTranslationHtml
						}
					}
					return translation
				})
			} else {
				this.confirmationPage.translations.push({
					languageId: this.selectedLanguage,
					languageName: this.languages.find(language => language.languageId === this.selectedLanguage).languageName,
					confirmationPageHtml: this.confirmationPageTranslationHtml
				})
			}
			this.showAddTranslation = false
			this.confirmationPageTranslationHtml = ''
			this.selectedLanguage = null
		},
		navigateToOverview () {
			this.$router.push({ name: CONFIRMATION_PAGES })
		},
		preview () {
			const win = window.open('', 'printwindow')
			const scriptFile = '<script>' + this.previewFiles.script + '<\/script>'
			const cssFile = '<style>' + this.previewFiles.css + '<\/style>'
			const confirmationPageHtml = this.confirmationPage.confirmationPageHtml.replace('[PLACEHOLDER_JS]', scriptFile).replace('[PLACEHOLDER_CSS]', cssFile)
			win.document.write(`${confirmationPageHtml}`)
		},
		storeFileResult (result, fileType) {
			this.previewFiles[fileType] = result
			return result
		},
		async deleteAsset (item) {
			const formData = new FormData()
			formData.append('ConfirmationPageId', this.confirmationPage.confirmationPageId)
			formData.append('FileName', item.name)
			formData.append('Type', item.type)
			await deleteConfirmationPageAsset(formData).then(() => {
				showSnackbar('Asset deleted successfully')
				this.confirmationPageAssets = this.confirmationPageAssets.filter(asset => asset.name !== item.name)
			})
		},
		downloadAsset (item) {
			this.downloadFile(item.url, item.name)
		}
	}
}
</script>
