<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
	>
		<ViewLayout>
			<template #header-title>
				CS Portal Configuration
			</template>
			<template #header-caption>
				Edit and manage your portal
			</template>
			<template #header-after>
				<Tabs
					:tab.sync="tab"
					:tabs="tabs"
				/>
			</template>
			<template #content>
				<TabItems
					:tab="tab"
					:tabs="tabs"
				>
					<template #general>
						<CsPortalConfigGeneral
							:brand-field-label.sync="configuration.basicConfig.brandFieldLabel"
							:only-show-data-subjects-associated-with-selected-brand.sync="configuration.basicConfig.onlyShowDataSubjectsAssociatedWithSelectedBrand"
							:show-response-channel-for-pref-submissions.sync="configuration.basicConfig.showResponseChannelForPrefSubmissions"
							:response-channel-for-submissions-mandatory.sync="configuration.basicConfig.responseChannelForSubmissionsMandatory"
							:response-channel-field-label.sync="configuration.basicConfig.responseChannelFieldLabel"
							:show-source-code-for-pref-submissions.sync="configuration.basicConfig.showSourceCodeForPrefSubmissions"
							:source-code-for-submissions-mandatory.sync="configuration.basicConfig.sourceCodeForSubmissionsMandatory"
							:source-code-field-label.sync="configuration.basicConfig.sourceCodeFieldLabel"
							:response-channels.sync="configuration.responseChannels"
							:source-codes.sync="configuration.sourceCodes"
							:show-valid-from-and-to-fields-for-pref-submissions.sync="configuration.basicConfig.showValidFromAndToFieldsForPrefSubmissions"
							:can-edit-valid-from-and-to-fields-for-pref-submissions.sync="configuration.basicConfig.canEditValidFromAndToFieldsForPrefSubmissions"
							:data-subjects-with-no-preferences-ticked-by-default.sync="configuration.basicConfig.dataSubjectsWithNoPreferencesTickedByDefault"
							:can-create-cs-portal-users.sync="configuration.basicConfig.canCreateCSPortalUsers"
							:audit-history-sort-newest-to-oldest.sync="configuration.basicConfig.auditHistorySortNewestToOldest"
							:audit-history-expanded-by-default.sync="configuration.basicConfig.auditHistoryExpandedByDefault"
							:can-create-data-subjects.sync="configuration.basicConfig.canCreateDataSubjects"
							:master-can-edit-contact-details.sync="configuration.basicConfig.canEditContactDetails"
							:privacy-policy-mandatory.sync="configuration.basicConfig.privacyPolicyMandatory"
							:master-can-edit-preferences.sync="configuration.basicConfig.canEditPreferences"
							:custom-logout-url.sync="configuration.basicConfig.customLogoutUrl"
							:user-full-permissions="userFullPermissions"
							@update:basicConfig="configuration.basicConfig = $event"
						/>
					</template>
					<template #search-fields>
						<CsPortalConfigSearchFields
							:available-fields="availableFields"
							:search-fields="configuration.dataSubjectSearchFieldsList"
							:user-full-permissions="userFullPermissions"
							@update:searchFields="configuration.dataSubjectSearchFieldsList = $event"
						/>
					</template>
					<template #contact-details-fields>
						<CsPortalConfigContactDetailsFields
							:contact-details-fields.sync="configuration.contactDetailsFields"
							:show-post-code-lookup.sync="configuration.basicConfig.showPostCodeLookup"
							:available-fields="availableFields"
							:user-full-permissions="userFullPermissions"
						/>
					</template>
					<template #preferences>
						<CsPortalConfigPreferences
							:parent-groups="configuration.preferenceGroups.parentGroups"
							:available-fields="availableFields"
							:user-full-permissions="userFullPermissions"
							@update:parentGroups="handleParentGroupsUpdate($event)"
						/>
					</template>
				</TabItems>
			</template>
			<template #footer>
				<PageActionRow v-if="userFullPermissions">
					<template #actions>
						<v-spacer />
						<PrimaryActionButton @click="handleSubmit(save)">
							Save
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
	</ValidationForm>
</template>
<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import TabItems from '../../../../../shared/components/tab-items.vue'
import Tabs from '../../../../../shared/components/tabs.vue'
import CsPortalConfigGeneral from './cs-portal-config-general/cs-portal-config-general.vue'
import { waitForLoad, configuration, searchFieldFilter, showPostcodeLookup } from '../../../../../shared/state/configuration.js'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import CsPortalConfigSearchFields from './cs-portal-config-search-fields.vue'
import CsPortalConfigContactDetailsFields from './cs-portal-config-contact-details-fields.vue'
import CsPortalConfigPreferences from './cs-portal-config-preferences/cs-portal-config-preferences.vue'
import { getStandakFields, putConfigurationApi, getConfigurationApi } from '../../../../../shared/utils/api/configuration.js'
import { CS_ADMINISTRATION_MODULE_FULL_ACCESS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'

export default {
	components: {
		CsPortalConfigPreferences,
		CsPortalConfigContactDetailsFields,
		CsPortalConfigSearchFields,
		PrimaryActionButton,
		PageActionRow,
		CsPortalConfigGeneral,
		Tabs,
		TabItems,
		ViewLayout,
		ValidationForm
	},
	setup () {
		return {
			showSnackbar,
			showPostcodeLookup,
			waitForLoad
		}
	},
	data () {
		return {
			tab: 'general',
			tabs: [
				{ slot: 'general', title: 'General' },
				{ slot: 'search-fields', title: 'Search Fields' },
				{ slot: 'contact-details-fields', title: 'Contact Details Fields' },
				{ slot: 'preferences', title: 'Preferences' }
			],
			configuration: {
				basicConfig: {},
				dataSubjectSearchFieldsList: [],
				preferenceGroups: {},
				headerFooterCombinations: [],
				responseChannels: [],
				sourceCodes: [],
				contactDetailsFields: []
			},
			availableFields: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(CS_ADMINISTRATION_MODULE_FULL_ACCESS)
		}
	},
	async created () {
		await waitForLoad()
		this.configuration = JSON.parse(JSON.stringify(configuration.value))
		const { data: availableFields } = await getStandakFields()
		this.availableFields = availableFields
		const { data: basicConfig } = await getConfigurationApi()
		this.configuration = basicConfig
	},
	methods: {
		searchFieldFilter,
		handleParentGroupsUpdate (parentGroups) {
			this.configuration.preferenceGroups.parentGroups = parentGroups
		},
		async save () {
			if (this.configuration.dataSubjectSearchFieldsList.filter(({ includeInSearchResults }) => includeInSearchResults).length === 0) {
				showSnackbar({ text: 'At least one field must be visible in the search results', color: 'red' })
			} else if (this.configuration.preferenceGroups.parentGroups.length === 0) {
				showSnackbar({ text: 'At least one preference group must be configured', color: 'red' })
			} else {
				await putConfigurationApi(this.configuration)
				await waitForLoad()
				const { data: basicConfig } = await getConfigurationApi()
				this.configuration = basicConfig
				this.showSnackbar('Your changes have been saved')
			}
		}
	}
}
</script>
