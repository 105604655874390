<template>
	<v-simple-table>
		<template #default>
			<thead>
				<tr>
					<th
						scope="col"
						class="text-left"
					>
						Identification Field
					</th>
					<th
						scope="col"
						class="text-left"
					>
						Database Field Name *
					</th>
					<th
						scope="col"
						class="text-left"
					>
						Name in Link *
					</th>
					<th
						scope="col"
						class="text-left"
					>
						Replace value in link *
					</th>
					<th
						scope="col"
						class="text-left"
					>
						Preview value
					</th>
					<th
						scope="col"
						class="text-left"
					>
						Encrypted
					</th>
					<th
						scope="col"
						class="text-left"
					>
						Action
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(linkField, index) in (linkFields || [])"
					:key="linkField.standdakFieldName"
				>
					<td class="text-left">
						<validation-provider
							#default="{ errors }"
							:rules="{
								arrayIsNotEmpty: {
									value: linkFields ? linkFields.filter(({ isMatchField }) => isMatchField) : [],
									ignoreVModel: true
								}
							}"
							mode="passive"
						>
							<v-switch
								:input-value="Boolean(linkField.isMatchField)"
								:error-messages="errors"
								dense
								:hide-details="!errors.length"
								class="ma-0"
								:disabled="!userFullPermissions"
								@change="updateLinkFieldsRow(index, 'isMatchField', $event)"
							/>
						</validation-provider>
					</td>
					<td class="text-left">
						<Dropdown
							:value="linkField.standdakFieldName"
							:items="linkFieldStanddakFieldNames"
							custom-sort
							:disabled="!userFullPermissions"
							searchable
							:label="`Database field name ${index+1}`"
							small
							rules="required"
							@change="updateLinkFieldsRow(index, 'standdakFieldName', $event)"
						/>
					</td>
					<td
						class="text-left"
					>
						<TextField
							:value="linkField.friendlyNameInLink"
							:disabled="!userFullPermissions"
							:label="`Name in link ${index+1}`"
							small
							:rules="{required: true, max: 255, inUseLinkFieldNames: usedNameInLinkFields(linkField)}"
							@input="updateLinkFieldsRow(index, 'friendlyNameInLink', $event)"
						/>
					</td>
					<td
						class="text-left"
					>
						<TextField
							:value="linkField.replaceValueInLink"
							:disabled="!userFullPermissions"
							:label="`Replace value in link ${index+1}`"
							small
							:rules="{required: true, max: 64}"
							@input="updateLinkFieldsRow(index, 'replaceValueInLink', $event)"
						/>
					</td>
					<td>
						<TextField
							:value="linkField.previewValue"
							:disabled="!userFullPermissions"
							:label="`Preview value ${index+1}`"
							small
							:rules="{ max: 255 }"
							@input="updateLinkFieldsRow(index, 'previewValue', $event)"
						/>
					</td>
					<td class="text-left">
						<v-switch
							:input-value="linkField.isEncrypted"
							:disabled="!userFullPermissions"
							dense
							hide-details
							class="ma-0"
							@change="updateLinkFieldsRow(index, 'isEncrypted', $event)"
						/>
					</td>
					<td class="text-left">
						<v-icon
							v-if="linkFields.length > 1 && userFullPermissions"
							small
							@click="onRemoveLinkField(index)"
						>
							mdi-trash-can
						</v-icon>
					</td>
				</tr>
			</tbody>
		</template>
	</v-simple-table>
</template>

<script>
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'

export default {
	components: {
		Dropdown,
		TextField
	},
	props: {
		linkFields: Array,
		availableFields: Array,
		userFullPermissions: Boolean
	},
	computed: {
		linkFieldStanddakFieldNames () {
			return this.availableFields.map(({ text }) => ({
				value: text.toLowerCase(), text: text
			}))
		}
	},
	methods: {
		updateLinkFieldsRow (index, field, value) {
			this.$emit('update-link-fields-row', index, field, value)
		},
		onRemoveLinkField (index) {
			this.$emit('remove-link-field', index)
		},
		usedNameInLinkFields (linkField) {
			const linkFields = this.linkFields?.map(({ friendlyNameInLink, standdakFieldName }) => ({ friendlyNameInLink, standdakFieldName }))
			return linkFields.filter(({ friendlyNameInLink, standdakFieldName }) =>
				friendlyNameInLink === linkField.friendlyNameInLink && standdakFieldName !== linkField.standdakFieldName).map(({ friendlyNameInLink }) => friendlyNameInLink)
				.join(',')
		}
	}
}
</script>
