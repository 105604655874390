<template>
	<StepperItems
		:step="step"
		:steps="steps"
	>
		<template
			v-if="step === 0"
			#0
		>
			<LinkDetailsStep
				:link-name="link.linkName"
				:link-text="link.linkText"
				:is-enabled="link.isEnabled"
				:custom-default-language-id="link.customDefaultLanguageId"
				:authentication-details="link.authenticationDetails"
				:custom-privacy-policies="link.customPrivacyPolicies"
				:link-auth-types="lookUpData.linkAuthTypes"
				:link-type="linkType"
				:brand-id="link.brandId"
				:footer-content-id="link.footerContentId"
				:edit-mode="editMode"
				:prove-confirmation-emails="lookUpData.proveConfirmationEmails"
				:clone-mode="cloneMode"
				:enable-one-click-unsubscribe="link.enableOneClickUnsubscribe"
				:user-full-permissions="userFullPermissions"
				@update:linkName="updateLink('linkName', $event)"
				@update:customDefaultLanguageId="updateLink('customDefaultLanguageId', $event)"
				@update:footerContentId="updateLink('footerContentId', $event)"
				@update:linkText="updateLink('linkText', $event)"
				@update:isEnabled="updateLink('isEnabled', $event)"
				@update:authenticationDetails="updateLink('authenticationDetails', $event)"
				@update:customPrivacyPolicies="updateLink('customPrivacyPolicies', $event)"
				@update:brandId="updateBrandId"
				@update:enableOneClickUnsubscribe="updateLink('enableOneClickUnsubscribe', $event)"
			/>
		</template>
		<template
			v-if="step === 1"
			#1
		>
			<LinkFieldsStep
				:configuration-options="link.configurationOptions"
				:link-fields="link.linkFields"
				:encryption-keys="lookUpData.encryptionKeys"
				:brand-id="link.brandId"
				:user-full-permissions="userFullPermissions"
				:link-type="linkType"
				:contact-fields="lookUpData.contactFields"
				@reload-look-up-data="$emit('reload-look-up-data')"
				@update:configurationOptions="updateLink('configurationOptions', $event)"
				@update:linkFields="updateLink('linkFields', $event)"
			/>
		</template>
		<template
			v-if="step === 2"
			#2
		>
			<LinkPreferencesStep
				v-if="linkType === ACTION_LINK_TYPE"
				:brand-id="link.brandId"
				:action-link-config="link.actionLinkConfig"
				:enforce-double-opt-in="link.actionLinkEnforceDoubleOptIn"
				:user-full-permissions="userFullPermissions"
				@update:actionLinkConfig="updateLink('actionLinkConfig', $event)"
				@update:actionLinkEnforceDoubleOptIn="handleActionLinkEnforceDoubleOptIn"
			/>
			<LinkPreferencePageStep
				v-else-if="linkType === PREFERENCE_PAGE_LINK_TYPE"
				:brand-id="link.brandId"
				:page-contents="pageContents"
				:page-settings="pageSettings"
				:page-preferences="pagePreferences"
				:extended-prefs="extendedPrefs"
				:user-full-permissions="userFullPermissions"
				@update:pageContents="updatePreferencePageLinkConfig('pageContents', $event)"
				@update:pageSettings="updatePreferencePageLinkConfig('pageSettings', $event)"
				@update:pagePreferences="updatePreferencePageLinkConfig('pagePreferences', $event)"
				@update:extendedPrefs="updatePreferencePageLinkConfig('extendedPrefs', $event)"
				@extended-prefs-changed="$emit('extended-prefs-changed')"
			/>
		</template>
		<template
			v-if="step === 3"
			#3
		>
			<LinkPropertiesStep
				v-if="linkType === ACTION_LINK_TYPE"
				:property-fields="link.propertyFields"
				:brand-id="link.brandId"
				@update:propertyFields="updateLink('propertyFields', $event)"
			/>
			<div v-else-if="linkType === PREFERENCE_PAGE_LINK_TYPE">
				<LinkPreferencePagePiiStep
					:contact-details-fields="contactDetailsFields"
					:authentication-details="link.authenticationDetails"
					:link-auth-types="lookUpData.linkAuthTypes"
					:lookup-data-contact-fields="lookUpData.contactFields"
					:user-full-permissions="userFullPermissions"
					@update:contactDetailsFields="updateLink('contactDetailsFields', $event)"
					@update:authenticationDetails="updateLink('authenticationDetails', $event)"
				/>
			</div>
		</template>
		<template
			v-if="step === 4"
			#4
		>
			<LinkOutcomesStep
				v-if="linkType === ACTION_LINK_TYPE"
				:confirmation-pages="lookUpData.confirmationPages"
				:configuration-options="link.configurationOptions"
				:brand-id="link.brandId"
				:custom-error-url="link.customErrorUrl"
				:link-type="linkType"
				:user-full-permissions="userFullPermissions"
				@update:customErrorUrl="updateLink('customErrorUrl', $event)"
				@update:failureType="updateLink('failureType', $event)"
				@update:configurationOptions="updateLink('configurationOptions', $event)"
			/>
			<LinkPropertiesStep
				v-else-if="linkType === PREFERENCE_PAGE_LINK_TYPE"
				:property-fields="link.propertyFields"
				:brand-id="link.brandId"
				:user-full-permissions="userFullPermissions"
				@update:propertyFields="updateLink('propertyFields', $event)"
			/>
		</template>
		<template
			v-if="step === 5"
			#5
		>
			<LinkThemeStep
				v-if="linkType === ACTION_LINK_TYPE"
				:link-brand-id="link.brandId"
				:link-theme-id="link.themeId"
				:link-type="linkType"
				:auth-type="link.authenticationDetails.authTypeId"
				:user-full-permissions="userFullPermissions"
				@update:themeId="updateThemeId"
			/>
			<LinkOutcomesStep
				v-else-if="linkType === PREFERENCE_PAGE_LINK_TYPE"
				:confirmation-pages="lookUpData.confirmationPages"
				:configuration-options="link.configurationOptions"
				:brand-id="link.brandId"
				:custom-error-url="link.customErrorUrl"
				:link-type="linkType"
				:user-full-permissions="userFullPermissions"
				@update:customErrorUrl="updateLink('customErrorUrl', $event)"
				@update:failureType="updateLink('failureType', $event)"
				@update:configurationOptions="updateLink('configurationOptions', $event)"
			/>
		</template>
		<template
			v-if="step === 6"
			#6
		>
			<LinkViewLinkStep
				v-if="linkType === ACTION_LINK_TYPE"
				:link-id="link.linkId || newLinkId"
				:link-type="linkType"
				:assigned-language-items="assignedLanguageItems"
				:user-full-permissions="userFullPermissions"
			/>
			<LinkThemeStep
				v-else-if="PREFERENCE_PAGE_LINK_TYPE"
				:link-brand-id="link.brandId"
				:link-theme-id="link.themeId"
				:link-type="linkType"
				:auth-type="link.authenticationDetails.authTypeId"
				:user-full-permissions="userFullPermissions"
				@update:themeId="updateThemeId"
			/>
		</template>
		<template
			v-if="step === 7 && linkType === PREFERENCE_PAGE_LINK_TYPE"
			#7
		>
			<LinkViewLinkStep
				:link-id="link.linkId || newLinkId"
				:link-type="linkType"
				:assigned-language-items="assignedLanguageItems"
			/>
		</template>
	</StepperItems>
</template>

<script>
import { mapGetters } from 'vuex'
import StepperItems from '../../../../../../shared/components/stepper-items.vue'
import LinkDetailsStep from '../link-details-step.vue'
import LinkFieldsStep from '../link-fields-step.vue'
import LinkPreferencesStep from '../link-preferences-step.vue'
import LinkPreferencePagePiiStep from './link-preference-page-pii-step.vue'
import LinkPropertiesStep from '../link-properties-step.vue'
import LinkOutcomesStep from '../link-outcomes-step.vue'
import LinkViewLinkStep from '../link-view-link-step.vue'
import LinkThemeStep from '../link-theme-step.vue'
import { ACTION_LINK_TYPE, PREFERENCE_PAGE_LINK_TYPE } from '../link-types.js'
import { LINKS_AREA_FULL_PERMISSIONS, LINKS_PAGE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { brandOptions } from '../../../../../../shared/state/brands.js'
import LinkPreferencePageStep from '../link-preference-page-step.vue'
export default {
	components: {
		LinkPreferencePageStep,
		LinkViewLinkStep,
		LinkThemeStep,
		LinkOutcomesStep,
		LinkPropertiesStep,
		LinkPreferencesStep,
		LinkFieldsStep,
		LinkDetailsStep,
		LinkPreferencePagePiiStep,
		StepperItems
	},
	props: {
		step: Number,
		steps: Array,
		link: Object,
		lookUpData: Object,
		linkType: Number,
		embedCode: String,
		newLinkId: Number,
		assignedLanguageItems: {
			type: Array,
			default: () => []
		},
		cloneMode: Boolean
	},
	data () {
		return {
			ACTION_LINK_TYPE,
			PREFERENCE_PAGE_LINK_TYPE
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		preferencePageLinkConfig () {
			return this.link?.preferencePageLinkConfig
		},
		contactDetailsFields () {
			return this.link?.contactDetailsFields
		},
		pageContents () {
			return this.preferencePageLinkConfig?.pageContents
		},
		pageSettings () {
			return this.preferencePageLinkConfig?.pageSettings
		},
		pagePreferences () {
			return this.preferencePageLinkConfig?.pagePreferences || []
		},
		extendedPrefs () {
			return this.preferencePageLinkConfig?.extendedPrefs || []
		},
		editMode () {
			return Boolean(this.link.linkId && !this.cloneMode)
		},
		userFullPermissions () {
			if (!this.editMode && !this.cloneMode) return true
			else return (this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)) && this.userHasBrand
		},
		userHasBrand () {
			const brands = brandOptions.value.map(({ value }) => value)
			return brands.includes(this.link.brandId)
		}
	},
	methods: {
		async clearBrandIdRelatedFields () {
			await this.$nextTick()
			if (this.linkType === ACTION_LINK_TYPE) {
				this.updateLink('actionLinkConfig', {})
			} else if (this.linkType === PREFERENCE_PAGE_LINK_TYPE) {
				this.updatePreferencePageLinkConfig('pagePreferences', [])
			}
		},
		updatePreferencePageLinkConfig (property, value) {
			this.updateLink('preferencePageLinkConfig', {
				...(this.link?.preferencePageLinkConfig),
				[property]: value
			})
		},
		updateLink (property, value) {
			this.$emit('update:link', {
				...this.link,
				[property]: value
			})
		},
		async updateBrandId (value) {
			this.updateLink('brandId', value)
			await this.$nextTick()
			this.clearBrandIdRelatedFields()
		},
		async updateThemeId (value) {
			this.updateLink('themeId', value)
			await this.$nextTick()
		},
		handleActionLinkEnforceDoubleOptIn ({ enforceDoubleOptIn, actionLinkConfig }) {
			const updatedLink = {
				...this.link,
				actionLinkEnforceDoubleOptIn: enforceDoubleOptIn,
				actionLinkConfig: actionLinkConfig
			}
			this.$emit('update:link', updatedLink)
		}
	}

}
</script>
