<template>
	<SectionCard>
		<template #title>
			General Details
		</template>
		<template #body>
			<v-row dense>
				<v-col :cols="6">
					<TextField
						:view-only="securityMatrixView"
						:value="name"
						label="Name *"
						rules="required"
						@input="$emit('update:name', $event)"
					/>
				</v-col>
				<v-col :cols="6">
					<Dropdown
						:view-only="securityMatrixView"
						:value="brandId"
						:items="brandOptions"
						custom-sort
						:label="'Brand*' | useLabels"
						searchable
						rules="required"
						@input="$emit('update:brandId', $event)"
					/>
				</v-col>
			</v-row>
			<TextArea
				:view-only="securityMatrixView"
				:value="description"
				label="Process Description"
				@input="$emit('update:description', $event)"
			/>
			<v-row dense>
				<v-col :cols="6">
					<CitySelector
						:view-only="securityMatrixView"
						:city-id="cityId"
						:country-id="countryId"
						:countries="lookUpData.countries"
						required
						@update:cityId="$emit('update:cityId', $event)"
						@update:countryId="$emit('update:countryId', $event)"
					/>
				</v-col>
				<v-col :cols="6">
					<Dropdown
						:view-only="securityMatrixView"
						:value="ownerId"
						:items="ownerOptions"
						label="Process Owner *"
						rules="required"
						@input="$emit('update:ownerId', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col :cols="6">
					<Dropdown
						:view-only="securityMatrixView"
						label="Is data transferred?"
						:value="dataTransferTypeId"
						:items="dataTransferTypeOptions"
						@input="$emit('update:dataTransferTypeId', $event)"
					/>
				</v-col>
			</v-row>
			<div
				v-if="!securityMatrixView"
				class="d-flex cassie-horizontal-lg"
			>
				<v-radio-group
					:view-only="securityMatrixView"
					:value="isSpecialCategoryData"
					hide-details
					class="mt-0"
					@change="$emit('update:isSpecialCategoryData', $event)"
				>
					<template #label>
						Any special category data?
					</template>
					<div class="d-flex">
						<v-radio
							label="Yes"
							:value="true"
							class="my-0 mr-4"
						/>
						<v-radio
							label="No"
							:value="false"
							class="my-0"
						/>
					</div>
				</v-radio-group>
				<v-radio-group
					:readonly="securityMatrixView"
					:value="isCassieProcess"
					hide-details
					class="mt-0"
					@change="$emit('update:isCassieProcess', $event)"
				>
					<template #label>
						Is this a Cassie process?
					</template>
					<div class="d-flex">
						<v-radio
							label="Yes"
							:value="true"
							class="my-0 mr-4"
						/>
						<v-radio
							label="No"
							:value="false"
							class="my-0"
						/>
					</div>
				</v-radio-group>
				<v-radio-group
					:readonly="securityMatrixView"
					:value="isAutomatedDecisions"
					hide-details
					class="mt-0"
					@change="$emit('update:isAutomatedDecisions', $event)"
				>
					<template #label>
						Any automated decisions?
					</template>
					<div class="d-flex">
						<v-radio
							label="Yes"
							:value="true"
							class="my-0 mr-4"
						/>
						<v-radio
							label="No"
							:value="false"
							class="my-0"
						/>
					</div>
				</v-radio-group>
			</div>
			<v-row v-else>
				<v-col :cols="6">
					<v-simple-table
						dense
						light
					>
						<template #default>
							<thead>
								<tr>
									<th scope="col">
										Any Special Category Data?
									</th>
									<th scope="col">
										Is this a Cassie Process?
									</th>
									<th scope="col">
										Any Automated Decisions
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="text-center">
										<v-icon>
											{{ isSpecialCategoryData ? 'mdi-check' : 'mdi-close' }}
										</v-icon>
									</td>
									<td class="text-center">
										<v-icon>
											{{ isCassieProcess ? 'mdi-check' : 'mdi-close' }}
										</v-icon>
									</td>
									<td class="text-center">
										<v-icon>
											{{ isAutomatedDecisions ? 'mdi-check' : 'mdi-close' }}
										</v-icon>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { brandOptions } from '../../../../../../shared/state/brands.js'
import TextArea from '../../../../../../shared/components/textarea.vue'
import CitySelector from '../city-selector.vue'
export default {
	components: { CitySelector, TextArea, Dropdown, TextField, SectionCard },
	props: {
		securityMatrixView: Boolean,
		name: String,
		description: String,
		brandId: Number,
		countryId: Number,
		cityId: Number,
		ownerId: Number,
		dataTransferTypeId: Number,
		isSpecialCategoryData: Boolean,
		isCassieProcess: Boolean,
		isAutomatedDecisions: Boolean,
		lookUpData: Object
	},
	setup () {
		return {
			brandOptions
		}
	},
	computed: {
		ownerOptions () {
			return this.lookUpData.processOwners.map(({ processOwnerId, processOwnerName }) => ({ value: processOwnerId, text: processOwnerName }))
		},
		dataTransferTypeOptions () {
			return this.lookUpData.dataTransferTypes.map(({ dataTransferTypeId, dataTransferTypeName }) => ({ value: dataTransferTypeId, text: dataTransferTypeName }))
		}
	}
}
</script>
