<template>
	<validation-observer #default="{ handleSubmit, invalid }">
		<Modal width="70%">
			<template #modal-title>
				<template v-if="!userFullPermissions">
					{{ `View Data Export Location` | useLabels }}
				</template>
				<template v-else>
					{{ `${isEdit ? "Edit" : "Create"} Data Export Location` | useLabels }}
				</template>
				<v-spacer />
				<IconButton @click="close">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-horizontal-md d-flex flex-row justify-center pb-4">
					<TextField
						v-model="formData.name"
						:disabled="!userFullPermissions"
						:label="'Export Location Name*' | useLabels"
						class="cassie-input-width-xl"
						:rules="{ required: true, max: 50 }"
					/>
					<v-switch
						v-model="formData.enabled"
						:disabled="!userFullPermissions"
						label="Enabled"
						class="cassie-input-width-sm mt-0"
					/>
				</div>
				<div class="pb-4">
					<TextArea
						v-model="formData.description"
						:disabled="!userFullPermissions"
						:rules="{ required: true, max: 500 }"
						:label="'Description*' | useLabels"
					/>
				</div>
				<v-row class="pb-4">
					<v-col>
						<TextField
							v-model="formData.exportFolder"
							:disabled="!userFullPermissions"
							label="Export Folder"
							class="cassie-input-width-xl"
							:rules="{ max: 200 }"
						/>
					</v-col>
					<v-col>
						<Dropdown
							v-model="formData.systemEmail"
							:disabled="!userFullPermissions"
							:label="'Export Confirmation Email' | useLabels"
							custom-sort
							clearable
							:items="systemEmailItems"
						/>
					</v-col>
				</v-row>
				<div class="cassie-horizontal-md d-flex flex-row pb-4" />
				<v-row class="pb-6">
					<v-col cols="6">
						<TextField
							v-model="state.inputEmail"
							:disabled="!userFullPermissions"
							:label="`Email${formData.systemEmail !== null && formData.systemEmail !== 0 && formData.exportConfirmationEmails.length === 0 ? ' *' : ''}`"
							:rules="{ email: true, requiredEmails: formData.systemEmail !== null && formData.systemEmail !== 0 && formData.exportConfirmationEmails.length === 0 }"
						/>
					</v-col>
					<v-col cols="2">
						<PrimaryActionButton
							:disabled="(!state.inputEmail || invalid) || !userFullPermissions"
							@click="addEmail"
						>
							Add
						</PrimaryActionButton>
					</v-col>
				</v-row>

				<div class="cassie-vertical-md">
					<v-simple-table
						class="pb-6"
						dense
						light
					>
						<template #default>
							<thead>
								<tr>
									<th
										scope="col"
										class="text-left"
									>
										{{ moreThanOneEmail ? 'Addreses' : 'Address' }}
									</th>
									<th
										v-if="userFullPermissions"
										scope="col"
										class="text-left"
									>
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in formData.exportConfirmationEmails"
									:key="item"
								>
									<td>
										{{ item }}
									</td>
									<td v-if="userFullPermissions">
										<IconButton
											:disabled="!userFullPermissions"
											@click="removeEmail(index)"
										>
											mdi-trash-can
										</IconButton>
									</td>
								</tr>
								<tr v-if="formData.exportConfirmationEmails.length == 0">
									<td>
										No emails added
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<div>
						<v-row>
							<v-col
								cols="6"
								class="mb-2"
							>
								<div class="d-flex">
									<TextField
										v-model="formData.textFileMask"
										:disabled="!userFullPermissions"
										class="cassie-input-width-xl"
										label="Text File Mask*"
										:rules="{ required: true, max: 100 }"
									/>
									<v-tooltip
										slot="append"
										top
									>
										<template #activator="{ on }">
											<v-icon
												light
												v-on="on"
											>
												mdi-information
											</v-icon>
										</template>
										<span>
											{{
												`TEXT File Mask the required filename format for the TEXT export file (if you select this option in the export configuration).
											'@NNNNN@' will be replaced by the numeric Export ID and you can include the current date / time information by inserting the following codes into your mask:
											• @YYYY@ = year  • @MO@ = month (e.g. 01 for January) • @DD@ = day of month (e.g. 31)• @HH@ = hour (24-hour format)  @MM@ = minute @SS@ = second`
											}}
										</span>
									</v-tooltip>
								</div>
							</v-col>
						</v-row>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="userFullPermissions"
					:disabled="invalid"
					@click="handleSubmit(save)"
				>
					Save
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
	<!-- end Create Edit Modal -->
</template>
<script>
import { mapGetters } from 'vuex'
import Modal from '../../../../../shared/components/modal.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import TextArea from '../../../../../shared/components/textarea.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import { EXPORTS_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		Dropdown,
		IconButton,
		Modal,
		SecondaryActionButton,
		PrimaryActionButton,
		TextField,
		TextArea
	},
	props: {
		editLocation: {
			type: Object,
			default: () => {}
		},
		systemEmails: {
			type: Array,
			default: () => []
		}
	},
	data () {
		return {
			state: { inputEmail: '' },
			formData: this.editLocation
				? {
					name: this.editLocation.name,
					description: this.editLocation.description,
					enabled: this.editLocation.enabled,
					textFileMask: this.editLocation.fileMask,
					zipFileMask: this.editLocation.zipFileMask,
					exportFolder: this.editLocation.outputFolder,
					exportConfirmationEmails: this.editLocation.emailsToNotify !== '' && this.editLocation.emailsToNotify !== null ? this.editLocation.emailsToNotify.split(';') : [],
					systemEmail: this.editLocation.confirmationEmailId,
					locationId: this.editLocation.locationId
				}
				: {
					name: '',
					enabled: true,
					description: '',
					exportFolder: '',
					systemEmail: null,
					exportConfirmationEmails: [],
					lastTestDate: null,
					lastTestResult: null,
					textFileMask: '@YYYY@_@MO@_@DD@_@HH@@MM@@SS@_EXPORT@NNNNN@.TXT',
					zipFileMask: '@YYYY@_@MO@_@DD@_@HH@@MM@@SS@_EXPORT@NNNNN@.ZIP',
					locationId: null
				}
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		isEdit () {
			return !!this.editLocation
		},
		userFullPermissions () {
			return this.productAreaPermission(EXPORTS_FULL_PERMISSIONS)
		},
		apiCreateModel () {
			return {
				name: this.formData.name,
				description: this.formData.description,
				enabled: this.formData.enabled,
				fileMask: this.formData.textFileMask,
				zipFileMask: this.formData.zipFileMask,
				outputFolder: this.formData.exportFolder,
				emailsToNotify: this.formData.exportConfirmationEmails.join(';'),
				confirmationEmailId: this.formData.systemEmail ? this.formData.systemEmail : 0,
				locationId: this.formData.locationId
			}
		},
		moreThanOneEmail () {
			if (this.formData.exportConfirmationEmails == null) {
				return false
			}
			return this.formData.exportConfirmationEmails.length > 1
		},
		systemEmailItems () {
			const filteredEmails = this.systemEmails.filter(e => e.emailTypeId === 4 || e.emailTypeId === null)
			const systemEmails = [...filteredEmails.map(e => ({ value: e.id, text: e.name })).sort((a, b) => a.text.localeCompare(b.text))]
			return systemEmails
		}
	},
	methods: {
		addEmail () {
			this.formData.exportConfirmationEmails.push(this.state.inputEmail)
			this.state.inputEmail = ''
		},
		removeEmail (index) {
			this.formData.exportConfirmationEmails.splice(index, 1)
		},
		openCreate () {
			this.formData.name = ''
			this.formData.description = ''
			this.formData.enabled = true
			this.formData.textFileMask = ''
			this.formData.zipFileMask = ''
			this.formData.exportFolder = ''
			this.formData.exportConfirmationEmails = []
			this.formData.systemEmail = null
			this.openCreateModal = true
		},
		async save () {
			this.$emit('save', this.apiCreateModel)
		},
		close () {
			this.$emit('close')
		}
	}
}
</script>
