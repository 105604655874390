<template>
	<CookieTemplateLayout
		:header-title="'IAB Text Translations' | useLabels('IAB Text Translations')"
		:header-caption="'Manage the text shared across all IAB banners' | useLabels('Manage the text shared across all IAB banners')"
	>
		<template #content>
			<BannerTextTranslationsTab
				ref="bannerTextTranslationsTab"
				:default-iab-text="iabText"
				@translations-changed="translationsChanged"
			/>
		</template>
		<template #footer>
			<PageActionRow>
				<template #actions>
					<v-spacer />
					<SecondaryActionButton
						:disabled="!changeMadeToTranslations"
						@click="cancelTranslationChanges"
					>
						Cancel
					</SecondaryActionButton>
					<PrimaryActionButton
						class="ml-2"
						:disabled="!changeMadeToTranslations"
						@click="saveTranslation"
					>
						Save
					</PrimaryActionButton>
				</template>
			</PageActionRow>
		</template>
	</CookieTemplateLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import { IAB_TEXT_TRANSLATIONS_FULL_PERMISSIONS } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import BannerTextTranslationsTab from './banner-text-translations-tab.vue'
import PageActionRow from '../../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import { getIabText } from '../../../../../../../shared/utils/api/cookies/cookie-iab-text.js'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
export default {
	components: {
		CookieTemplateLayout,
		BannerTextTranslationsTab,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton
	},
	data () {
		return {
			defaultIabText: null,
			changeMadeToTranslations: false,
			iabText: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(IAB_TEXT_TRANSLATIONS_FULL_PERMISSIONS)
		}
	},
	methods: {
		translationsChanged (changeMade) {
			this.changeMadeToTranslations = changeMade
		},
		saveTranslation () {
			this.$refs.bannerTextTranslationsTab.saveTranslation()
		},
		cancelTranslationChanges () {
			this.$refs.bannerTextTranslationsTab.cancelTranslationChanges()
		},
		async loadIabText () {
			const { data: { bannerText } } = await getIabText('en')
			this.iabText = Object.values(bannerText)
		}
	}
}
</script>
