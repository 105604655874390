<template>
	<SectionCard>
		<template #title>
			Details
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:test-id="fieldId('name')"
						:label="nameFieldLabel"
						:value.sync="details.name"
						reserve-tooltip-space
						:disabled="!userFullPermissions"
						rules="required"
						@input="updateDetails('name', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Dropdown
						:test-id="fieldId('brand')"
						label="Brand*"
						searchable
						:items="brandItems"
						:value.sync="details.brandId"
						reserve-tooltip-space
						custom-sort
						:disabled="isEdit || !clientMultiBranded || singleBrand || !userFullPermissions || isPartialOrComplete"
						rules="required"
						@input="updateDetails('brandId', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Textarea
						:label="descriptionFieldLabel"
						:value.sync="details.description"
						reserve-tooltip-space
						:disabled="!userFullPermissions"
						:test-id="fieldId('description')"
						@input="updateDetails('description', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Dropdown
						:value.sync="details.customDefaultLanguageId"
						label="Default Language *"
						tooltip-text="Set the Default Language that the page will open in if a 'languagecode' parameter is not passed within the Link URL"
						:test-id="`${parentType === 'Widget' ? 'widget' : 'preference_page'}_default_language`"
						rules="required"
						searchable
						:disabled="!userFullPermissions"
						:items="languages"
						@input="updateDetails('customDefaultLanguageId', $event)"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="parentType === 'Widget'"
				dense
			>
				<v-col
					cols="6"
					class="cassie-vertical-md"
				>
					<v-radio-group
						:value="Boolean(details.allowedDomains)"
						hide-details
						class="mt-0"
						:disabled="!userFullPermissions"
						@change="value => updateDetails('allowedDomains', value ? [] : null)"
					>
						<template #label>
							<span class="text-subtitle-1 font-weight-medium">
								Restrict by Domain
								<v-tooltip
									slot="append"
									right
								>
									<template #activator="{ on }">
										<v-icon
											light
											small
											v-on="on"
										>
											mdi-information
										</v-icon>
									</template>
									<span>
										If restricted by domain, this widget can only be deployed on the domains specified. Please include the full domain URL e.g. https://syrenis.com/
									</span>
								</v-tooltip>
							</span>
						</template>
						<div class="d-flex">
							<v-radio
								label="Yes"
								:value="true"
								:data-testid="fieldId('restrict_by_domain_true')"
								class="my-0 mr-4"
							/>
							<v-radio
								label="No"
								:value="false"
								:data-testid="fieldId('restrict_by_domain_false')"
								class="my-0"
							/>
						</div>
					</v-radio-group>
					<OptionSelector
						v-if="details.allowedDomains !== null && userFullPermissions"
						:selected="details.allowedDomains"
						entity-type="Domain"
						is-text
						:rules="{ requiredDomains: details.allowedDomains.length === 0, isUrlwithHttp: true }"
						:test-id="fieldId('allowed_domains_field')"
						@update:selected="updateDetails('allowedDomains', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import Textarea from '../../../../../shared/components/textarea.vue'
import OptionSelector from '../../../../../shared/components/option-selector.vue'
import { brandOptions, UNIVERSAL_BRAND,	UNIVERSAL_BRAND_ITEM, clientMultiBranded, singleBrand } from '../../../../../shared/state/brands.js'
export default {
	components: {
		SectionCard,
		TextField,
		Dropdown,
		Textarea,
		OptionSelector
	},
	props: {
		details: Object,
		isEdit: Boolean,
		parentType: {
			type: String,
			required: true
		},
		userFullPermissions: Boolean,
		isPartialOrComplete: Boolean,
		languages: Array
	},
	setup () {
		return {
			brandOptions,
			clientMultiBranded,
			singleBrand
		}
	},
	computed: {
		nameFieldLabel () {
			return `${this.parentType} Name *`
		},
		descriptionFieldLabel () {
			return `${this.parentType} Description`
		},
		brandItems () {
			// ensures when viewing universal object it displays in dropdown
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		}
	},
	methods: {
		fieldId (field) {
			return `${this.parentType.toLowerCase().replaceAll(' ', '_')}_${field}`
		},
		updateDetails (key, value) {
			this.$emit('update-details', key, value)
		}
	}
}
</script>
