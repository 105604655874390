<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="cookieCategorySearchQuery"
			search-query-label="Cookie Category Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changeCookieCategorySearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedCookieCategoryType"
					label="Cookie Category Type"
					:items="categoryTypeFilterItems"
					custom-sort
					@change="changeCookieCategoryType"
				/>
				<Dropdown
					v-model="selectedTranslationStatus"
					label="Translation Status"
					:items="translationStatusItems"
					custom-sort
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ 'Cookie Category Translations' | useLabels }}
			</template>
			<template #title-action>
				<Dropdown
					:value="selectedLanguageIdOrDefault"
					label="Language"
					searchable
					:items="assignedLanguagesItems"
					@input="selectedLanguageId = $event"
				/>
			</template>
			<template #body>
				<DataTable
					:items="filteredCategoryTranslations"
					:headers="tableHeaders"
					sort-by="id"
					:sort-desc="true"
					@click:row="cookieCategoryTranslationToEdit = $event"
				>
					<template #item.type="{ item }">
						{{ item.isStrictlyCategory ? 'Strictly Necessary' : 'User Consent' }}
					</template>
					<template #item.cookieCategoryTranslated="{ item }">
						{{ getTranslationStatus(item) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userCanCreateUpdate && userHasBrand(item.brandId)"
							tooltip-text="Edit Category Translation"
							@click="cookieCategoryTranslationToEdit = item"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else-if="item.categoryTranslationId"
							tooltip-text="View Category Translation"
							@click="cookieCategoryTranslationToEdit = item"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userCanDelete && item.categoryTranslationId && userHasBrand(item.brandId)"
							tooltip-text="Delete Category Translation"
							@click.stop.prevent="categoryTranslationToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<CookieCategoryTranslationModal
			v-if="cookieCategoryTranslationToEdit"
			:cookie-category-translation="cookieCategoryTranslationToEdit"
			:user-can-create-update="userCanCreateUpdate && userHasBrand(cookieCategoryTranslationToEdit.brandId)"
			@close="cookieCategoryTranslationToEdit = null"
			@submit="$emit('load')"
		/>
		<ConfirmDeleteModal
			v-if="categoryTranslationToRemove"
			:entity-name="categoryTranslationToRemove.cookieCategoryName"
			entity-type="Cookie Category Translation"
			@close="categoryTranslationToRemove = null"
			@delete="removeCategoryTranslation"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import CookieCategoryTranslationModal from './cookie-category-translation-modal.vue'
import ConfirmDeleteModal from '../../../../../../../shared/components/confirm-delete-modal.vue'
import { getAssignedLanguages } from '../../../../../../../shared/utils/api/languages.js'
import { useLabels } from '../../../../../../../shared/state/admin-portal-navigation.js'
import { cookieCategorySearchQuery, changeCookieCategorySearchQuery, changeCookieCategoryType, selectedCookieCategoryType } from '../../../../../../../shared/state/cookie-categories.js'
import { selectedAdminPortalBrandId, changeAdminPortalBrandFilter, userHasBrand } from '../../../../../../../shared/state/brands.js'
import { COOKIE_CATEGORIES_FULL_PERMISSIONS, CAN_CREATE_UPDATE_COOKIE_CATEGORIES, CAN_DELETE_COOKIE_CATEGORIES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { deleteCookieCategoryTranslation } from '../../../../../../../shared/utils/api/cookies/cookie-categories.js'

export default {
	components: {
		FilterBar,
		SectionCard,
		Dropdown,
		DataTable,
		IconButton,
		CookieCategoryTranslationModal,
		ConfirmDeleteModal
	},
	props: {
		cookieCategories: Array
	},
	setup () {
		return {
			userHasBrand,
			useLabels,
			cookieCategorySearchQuery,
			changeCookieCategorySearchQuery,
			changeCookieCategoryType,
			selectedCookieCategoryType,
			selectedAdminPortalBrandId,
			changeAdminPortalBrandFilter
		}
	},
	data () {
		return {
			selectedTranslationStatus: null,
			selectedLanguageId: null,
			assignedLanguages: [],
			cookieCategoryTranslationToEdit: null,
			categoryTranslationToRemove: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		categoryTypeFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Strictly Necessary',
					value: true
				},
				{
					text: 'User Consent',
					value: false
				}
			]
		},
		translationStatusItems () {
			return [
				{ value: null, text: 'View All' },
				{ value: true, text: 'Translated Only' },
				{ value: false, text: 'Untranslated Only' }
			]
		},
		tableHeaders () {
			return [
				{ value: 'id', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'categoryName', text: 'Cookie Category Name', width: '15%' },
				{ value: 'type', text: 'Cookie Category Type', width: '15%' },
				{ value: 'cookieCategoryTranslated', text: 'Translation Status', width: '15%' },
				{ value: 'createdDate', text: 'Created Date', width: '15%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		selectedLanguageIdOrDefault () {
			if (this.selectedLanguageId !== null) return this.selectedLanguageId
			return this.assignedLanguagesItems[0]?.value
		},
		cookieCategoryTranslations () {
			return this.cookieCategories.map(category => {
				const categoryTranslation = category.translations?.find(({ languageId }) => languageId === this.selectedLanguageIdOrDefault)
				const language = this.assignedLanguagesItems.find(({ value }) => value === this.selectedLanguageIdOrDefault)
				return {
					...category,
					translatedCategoryName: categoryTranslation?.translatedCategoryName,
					categoryTranslation: categoryTranslation?.translatedDisplayText,
					categoryTranslationId: categoryTranslation?.categoryTranslationId,
					languageName: language?.text,
					languageId: language?.value
				}
			})
		},
		filteredCategoryTranslations () {
			return this.cookieCategoryTranslations.filter(({ categoryName, brandId, isStrictlyCategory, categoryTranslation, translatedCategoryName }) => {
				let check = true
				if (cookieCategorySearchQuery.value) check = categoryName.toLowerCase().includes(cookieCategorySearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				if (selectedCookieCategoryType.value !== null) check = check && isStrictlyCategory === selectedCookieCategoryType.value
				if (this.selectedTranslationStatus !== null) check = check && (this.selectedTranslationStatus ? Boolean(categoryTranslation) : !categoryTranslation)
				return check
			})
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_CATEGORIES) || this.productAreaPermission(COOKIE_CATEGORIES_FULL_PERMISSIONS)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_COOKIE_CATEGORIES) || this.productAreaPermission(COOKIE_CATEGORIES_FULL_PERMISSIONS)
		}
	},
	created () {
		this.loadLanguages()
	},
	methods: {
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		clearFilters () {
			selectedCookieCategoryType.value = null
			this.selectedTranslationStatus = null
		},
		getTranslationStatus (category) {
			if (category.categoryTranslationId) return 'Translated'
			return 'Untranslated'
		},
		async removeCategoryTranslation () {
			await deleteCookieCategoryTranslation(this.categoryTranslationToRemove.categoryTranslationId)
			this.$emit('load')
			this.categoryTranslationToRemove = null
		}
	}
}
</script>
