<template>
	<SectionCard>
		<template #title>
			Authentication Page Translations
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextArea
						:value="authenticationPageTranslations.defaulttranslatedVerificationAreaText"
						label="Default Authentication Area Text"
						test-id="preference_page_default_verification_area_text"
						disabled
						rows="1"
						auto-grow
						dense
					/>
				</v-col>
				<v-col cols="6">
					<TextArea
						:value.sync="authenticationPageTranslations.translatedVerificationAreaText"
						label="Authentication Area Text Translation"
						test-id="preference_page_verification_area_text"
						:disabled="!userFullPermissions"
						:rules="{ max: 2000 }"
						rows="1"
						auto-grow
						dense
						@input="updateAuthenticationPageTranslations('translatedVerificationAreaText', $event)"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="authenticationPageTranslations.authTypeId === AUTHENTICATION_TYPES.PROVE_EMAIL"
				dense
			>
				<v-col cols="6">
					<TextArea
						:value="authenticationPageTranslations.defaultVerificationSentMessage"
						label="Default Success Message"
						test-id="preference_page_default_verification_sent_message"
						disabled
						rows="1"
						auto-grow
						dense
					/>
				</v-col>
				<v-col
					cols="6"
				>
					<TextArea
						:value.sync="authenticationPageTranslations.translatedVerificationSentMessage"
						label="Success Message Translation"
						test-id="preference_page_verification_sent_message"
						:disabled="!userFullPermissions"
						:rules="{ max: 2000 }"
						rows="1"
						auto-grow
						dense
						@input="updateAuthenticationPageTranslations('translatedVerificationSentMessage', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextArea
						:value="authenticationPageTranslations.defaultVerificationFailedMessage"
						label="Default Failure Message"
						test-id="preference_page_default_verification_failed_message"
						disabled
						rows="1"
						auto-grow
						dense
					/>
				</v-col>
				<v-col
					cols="6"
				>
					<TextArea
						:value.sync="authenticationPageTranslations.translatedVerificationFailedMessage"
						label="Failure Message Translation"
						test-id="preference_page_verification_failed_message"
						:disabled="!userFullPermissions"
						:rules="{ max: 2000 }"
						rows="1"
						auto-grow
						dense
						@input="updateAuthenticationPageTranslations('translatedVerificationFailedMessage', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextArea
						:value="authenticationPageTranslations.defaultStandDakAuthFieldFriendlyName"
						label="Default Value Label Field"
						test-id="preference_page_default_stand_dak_auth_field_friendly_name"
						disabled
						rows="1"
						auto-grow
						dense
					/>
				</v-col>
				<v-col
					cols="6"
				>
					<TextArea
						:value.sync="authenticationPageTranslations.translatedStandDakAuthFieldFriendlyName"
						label="Value Label Field Translation"
						test-id="preference_page_stand_dak_auth_field_friendly_name"
						:rules="{ max: 50 }"
						:disabled="!userFullPermissions"
						rows="1"
						auto-grow
						dense
						@input="updateAuthenticationPageTranslations('translatedStandDakAuthFieldFriendlyName', $event)"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="authenticationPageTranslations.authTypeId === AUTHENTICATION_TYPES.PROVIDE_AND_PROVE_EMAIL"
				dense
			>
				<v-col cols="6">
					<TextArea
						:value="authenticationPageTranslations.defaultVerificationSentMessage"
						label="Default Authentication Email Sent 'Success' Message"
						test-id="preference_page_default_verification_sent_message"
						disabled
						rows="1"
						auto-grow
						dense
					/>
				</v-col>
				<v-col
					cols="6"
				>
					<TextArea
						:value.sync="authenticationPageTranslations.verificationSentMessage"
						label="Authentication Email Sent 'Success' Message Translation"
						test-id="preference_page_verification_sent_message"
						:disabled="!userFullPermissions"
						:rules="{ max: 2000 }"
						rows="1"
						auto-grow
						dense
						@input="updateAuthenticationPageTranslations('verificationSentMessage', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>
<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextArea from '../../../../../../../shared/components/textarea.vue'
import { AUTHENTICATION_TYPES } from '../preference-page-authentication-types.js'

export default {
	components: {
		SectionCard,
		TextArea
	},
	props: {
		authenticationPageTranslations: {
			type: Object,
			required: true
		},
		userFullPermissions: {
			type: Boolean,
			required: true
		}
	},
	data () {
		return {
			AUTHENTICATION_TYPES
		}
	},
	methods: {
		updateAuthenticationPageTranslations (property, value) {
			this.$emit('update:authenticationPageTranslations', {
				...this.authenticationPageTranslations,
				[property]: value
			})
		}
	}
}
</script>
