<template>
	<div class="cassie-vertical-md pt-4">
		<FilterBar
			:search-query.sync="consentCollectionWidgetSearchQuery"
			search-query-label="Widget Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changeConsentCollectionWidgetSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataSearchQuery="changeMetaDataValueSearchQuery"
			@persistSelectedMetaDataKeyId="changeMetaDataKey"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="consentCollectionWidgetStatusType"
					:items="statusFilterItems"
					custom-sort
					label="Status"
					@input="changeConsentCollectionWidgetStatusType($event)"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="$router.push({
						name: MANAGE_CONSENT_COLLECTION_WIDGET,
						params: {
							userFullPermissions: userFullPermissions
						}
					})"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Consent Collection Widgets
			</template>
			<template #body>
				<DataTable
					sort-by="consentCollectionWidgetId"
					sort-desc
					:headers="tableHeaders"
					:items="filteredConsentCollectionWidgets"
					@click:row="onEditClick"
				>
					<template #item.brandName="{ item }">
						{{ getBrandName(item.brandId) }}
					</template>
					<template #item.consentCollectionWidgetName="{ item }">
						{{ item.consentCollectionWidgetName }}
						<v-chip
							v-if="item.status !== COMPLETE_STATUS"
							x-small
							class="ml-2 aqua-blue white--text"
						>
							In Progress
						</v-chip>
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandId)"
							@click="onEditClick(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							@click="onEditClick(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandId)"
							@click.stop.prevent="consentCollectionWidgetToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="consentCollectionWidgetToRemove"
			:entity-name="consentCollectionWidgetToRemove.consentCollectionWidgetName"
			entity-type="Consent Collection Widget"
			@close="consentCollectionWidgetToRemove = null"
			@delete="deleteConsentCollectionWidget"
		/>
	</div>
</template>

<script>
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { metaDataValueSearchQuery, selectedMetaDataKey, changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../../shared/state/meta-data.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId, brandOptions, UNIVERSAL_BRAND } from '../../../../../../shared/state/brands.js'
import {
	changeConsentCollectionWidgetSearchQuery,
	consentCollectionWidgetSearchQuery,
	changeConsentCollectionWidgetStatusType,
	consentCollectionWidgetStatusType
} from '../../../../../../shared/state/consent-collection-widgets.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import {
	MANAGE_CONSENT_COLLECTION_WIDGET
} from '../../../../router/route-names.js'
import { deleteConsentCollectionWidget } from '../../../../../../shared/utils/api/consent-collection-widgets.js'
export default {
	components: {
		SectionCard,
		FilterBar,
		DataTable,
		PrimaryActionButton,
		IconButton,
		ConfirmDeleteModal,
		Dropdown
	},
	props: {
		consentCollectionWidgets: Array,
		userFullPermissions: Boolean,
		userHasBrand: Function
	},
	setup () {
		return {
			metaDataValueSearchQuery,
			selectedMetaDataKey,
			changeMetaDataKey,
			changeMetaDataValueSearchQuery,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeConsentCollectionWidgetSearchQuery,
			consentCollectionWidgetSearchQuery,
			changeConsentCollectionWidgetStatusType,
			consentCollectionWidgetStatusType,
			MANAGE_CONSENT_COLLECTION_WIDGET,
			brandOptions,
			UNIVERSAL_BRAND
		}
	},
	data () {
		const COMPLETE_STATUS = 5
		return {
			consentCollectionWidgetToRemove: null,
			COMPLETE_STATUS
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'ID', value: 'consentCollectionWidgetId', width: '10%' },
				{ text: 'Brand', value: 'brandName', width: '20%' },
				{ text: 'Widget Name', value: 'consentCollectionWidgetName', width: '30%' },
				{ text: 'Created', value: 'createdDate', width: '20%' },
				{ text: 'Action', value: 'action', sortable: false, width: '10%' }
			]
		},
		statusFilterItems () {
			return [
				{ value: null, text: 'View All' },
				{ value: true, text: 'Complete' },
				{ value: false, text: 'In Progress' }
			]
		},
		filteredConsentCollectionWidgets () {
			return this.consentCollectionWidgets.filter(({ consentCollectionWidgetName, brandId, assignedMetadata, complete }) => {
				let check = true
				if (consentCollectionWidgetSearchQuery.value) check = consentCollectionWidgetName.toLowerCase().includes(consentCollectionWidgetSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && selectedAdminPortalBrandId.value === brandId
				if (this.selectedMetaDataKey !== null) check = check && assignedMetadata?.some(meta => meta.externalMetaDataId === this.selectedMetaDataKey)
				if (this.metaDataValueSearchQuery) {
					check = check && assignedMetadata?.some(meta => meta.externalDataValue.toLowerCase().includes(this.metaDataValueSearchQuery.toLowerCase()))
				}
				if (consentCollectionWidgetStatusType.value !== null) check = check && complete === consentCollectionWidgetStatusType.value
				return check
			})
		}
	},
	methods: {
		onEditClick (consentCollectionWidgetToEdit) {
			this.$router.push({
				name: MANAGE_CONSENT_COLLECTION_WIDGET,
				query: {
					id: consentCollectionWidgetToEdit.consentCollectionWidgetId
				}
			})
		},
		async deleteConsentCollectionWidget () {
			await deleteConsentCollectionWidget(this.consentCollectionWidgetToRemove.consentCollectionWidgetId)
			this.consentCollectionWidgetToRemove = null
			this.$emit('load')
			showSnackbar('Consent Collection Widget has been removed.')
		},
		getBrandName (brandId) {
			if (brandId === UNIVERSAL_BRAND) return 'Universal'
			else return brandOptions.value?.find(({ value }) => value === brandId)?.text
		},
		clearFilters () {
			changeConsentCollectionWidgetStatusType(null)
		}
	}
}
</script>
