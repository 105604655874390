import { format } from 'date-fns'
import { DATE_FORMAT } from '../date-formatting.js'
import { configApi } from '../http-client.js'

// Schemas
export const getAllSchemas = () => configApi.get('/api/FormBuilder/Schema').then(({ data }) => ({
	schemas: data.map(schema => ({
		...schema,
		createdDate: format(new Date(schema.createdDate), DATE_FORMAT)
	}))
}))

export const getSchema = schemaId => configApi.get(`/api/FormBuilder/Schema/${schemaId}`)

export const getTranslationSchema = (schemaId, languageId) => configApi.get(`/api/FormBuilder/Schema/${schemaId}/${languageId}`)

export const postSchema = schema => configApi.post('/api/FormBuilder/Schema', schema)

export const putSchema = (schemaGuid, schema) => configApi.put(`/api/FormBuilder/Schema/${schemaGuid}`, schema)

export const previewSchemaTranslation = previewData => configApi.post('/api/FormBuilder/Schema/Translations/preview', previewData)

// Themes
export const getAllThemes = () => configApi.get('/api/FormBuilder/Themes').then(({ data }) => ({
	themes: data.map(theme => ({
		...theme,
		themeName: theme.themeName.trim(),
		createdDate: format(new Date(theme.createdDate), DATE_FORMAT)
	}))
}))

export const getTheme = themeId => configApi.get(`/api/FormBuilder/Themes/${themeId}`)

export const postTheme = theme => configApi.post('/api/FormBuilder/Themes', theme)

export const putTheme = (themeGuid, theme) => configApi.put(`/api/FormBuilder/Themes/${themeGuid}`, theme)
