<template>
	<validation-provider
		#default="{ errors }"
		ref="customValidator"
		:name="fieldName || $props.label"
		:rules="rules"
		:mode="validationMode"
		:custom-messages="customMessages"
		:detect-input="validateField"
		:skip-if-empty="false"
		tag="div"
		:class="tooltipText || reserveTooltipSpace ? 'd-flex flex-row cassie-horizontal-sm' : ''"
	>
		<v-select
			v-if="!searchable"
			ref="select"
			:label="label"
			v-bind="$props"
			:readonly="viewOnly"
			:persistent-placeholder="viewOnly"
			:error-messages="errors.length ? [errors[0]] : []"
			:rules="[]"
			:items="sortedItems"
			:hide-details="!hasRules || !errors.length"
			:class="[small ? 'cassie-dropdown--small' : '', getQaSelectorClass($props)]"
			:menu-props="{
				contentClass: `dropdown-menu cassie-dropdown ${small ? 'cassie-dropdown--small' : '' }`,
				value: menuOpen
			}"
			:data-testid="testId"
			dense
			class="cassie-dropdown"
			v-on="$listeners"
			@click="menuOpen = true"
		>
			<template
				v-if="selectAll"
				#prepend-item
			>
				<slot
					name="prepend-item"
				/>
			</template>
			<template
				v-if="multiple"
				#selection="{ item, index }"
			>
				<span class="v-select__selection">
					<span
						v-if="index === 0"
						class="mr-1"
					>
						{{ itemText ? value[0] : item }}
					</span>
					<span
						v-if="value && index === 1"
						class="grey--text text-caption"
					>
						(+{{ value.length - 1 }} others)
					</span>
				</span>
			</template>
			<template
				v-else-if="$scopedSlots.selection"
				#selection="props"
			>
				<slot
					name="selection"
					v-bind="props"
				/>
			</template>
		</v-select>
		<v-autocomplete
			v-else
			ref="select"
			:label="label"
			v-bind="$props"
			:readonly="viewOnly"
			:persistent-placeholder="viewOnly"
			:error-messages="errors.length ? [errors[0]] : []"
			:rules="[]"
			:items="sortedItems"
			:hide-details="!hasRules || !errors.length"
			:class="[small ? 'cassie-dropdown--small' : '', getQaSelectorClass($props), multiple ? 'cassie-multi-dropdown' : '']"
			:menu-props="{
				contentClass: `dropdown-menu cassie-dropdown ${small ? 'cassie-dropdown--small' : '' }`,
			}"
			data-searchable="true"
			:data-testid="testId"
			dense
			:chips="multiple"
			prepend-inner-icon="mdi-magnify"
			class="cassie-dropdown"
			v-on="$listeners"
		>
			<template
				v-if="selectAll"
				#prepend-item
			>
				<slot
					name="prepend-item"
				/>
			</template>
		</v-autocomplete>
		<v-tooltip
			v-if="tooltipText"
			right
		>
			<template #activator="{ on }">
				<v-icon
					light
					v-on="on"
				>
					mdi-information
				</v-icon>
			</template>
			<div v-dompurify-html="tooltipText" />
		</v-tooltip>
		<div
			v-if="reserveTooltipSpace"
			style="width: 25px;"
		/>
	</validation-provider>
</template>

<script>
import { VSelect } from 'vuetify/lib'
import FieldValidationMixin from '../validation/field-validation-mixin.js'

export default {
	name: 'dropdown',
	extends: VSelect,
	mixins: [FieldValidationMixin],
	props: {
		label: {
			type: String
		},
		viewOnly: {
			type: Boolean,
			default: false
		},
		light: {
			type: Boolean,
			default: true
		},
		outlined: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		validateOnShow: {
			type: Boolean,
			default: false
		},
		tooltipText: {
			type: String
		},
		selectAll: {
			type: Boolean,
			default: false
		},
		customSort: {
			type: Boolean,
			default: false
		},
		reserveTooltipSpace: {
			type: Boolean,
			default: false
		},
		testId: {
			type: String,
			default: 'cassie_dropdown'
		},
		searchable: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			menuOpen: false
		}
	},
	computed: {
		sortedItems () {
			const items = this.$props.items
			if (this.customSort) return items
			else {
				return items?.sort((a, b) => a.text?.localeCompare(b.text))
			}
		}
	},
	mounted () {
		this.validateOnShow && this.$refs.customValidator.validate()
	},
	created () {
		window.addEventListener('scroll', this.closeMenuOnScroll, true)
		window.addEventListener('click', this.closeMenu, true)
	},
	destroyed () {
		window.removeEventListener('scroll', this.closeMenuOnScroll, true)
		window.removeEventListener('click', this.closeMenu, true)
	},
	methods: {
		getQaSelectorClass (props) {
			let trimmedLabel = props.label
			if (trimmedLabel) {
				if (trimmedLabel.includes(' *')) {
					trimmedLabel = trimmedLabel.replace(' *', '')
				}
				trimmedLabel = trimmedLabel.replace(/\s/g, '-').toLowerCase()
			}
			return `qa-dropdown-${trimmedLabel}`
		},
		closeMenuOnScroll (event) {
			if (!this.searchable && this.menuOpen && !event.target.closest('.dropdown-menu')) this.closeMenu(event)
			else if (this.searchable && !event.target.closest('.dropdown-menu')) {
				const dropdownMenu = document.querySelector('.dropdown-menu')
				if (dropdownMenu) {
					dropdownMenu.style.display = 'none'
				}
				this.$refs.select.blur()
			}
		},
		async closeMenu (event) {
			const searchableInputField = event.target.dataset.searchable === 'true'
			if ((!event.target.closest('.dropdown-menu') || !this.multiple) && this.menuOpen && !searchableInputField) {
				this.menuOpen = false
				this.$refs.select.blur()
			}
		}
	}
}
</script>

<style lang="scss">
	.cassie-dropdown {
		.v-select-list .v-list-item .v-list-item__action {
			margin: 5px 10px 0 0 !important;
		}

		&--small {
			.v-input__control .v-input__slot {
				height: 32px !important;
				padding: 0 8px !important;
				min-height: 0 !important;
			}

			.v-select-list .v-list-item {
				min-height: 0 !important;
			}

			.v-select-list .v-list-item .v-list-item__content {
				padding: 4px 0 !important;
			}

			.v-select__selection.v-select__selection {
				font-size: 85% !important;
				margin-left: 6px;
			}

			.v-input__append-inner {
				margin-top: 4px !important;
			}

			.v-input__control .v-input__slot label {
				margin-top: -12px !important;
			}

			.v-label {
				top: 18px !important;
				font-size: 14px !important;
			}

			.v-input__control .v-input__slot label.v-label--active {
				margin: 0 0 0 5px !important;
			}

			.v-label--active {
				-webkit-transform: translateY(-25px) scale(0.65) !important;
				transform: translateY(-25px) scale(0.65) !important;
			}

		}
		.v-select__selections .v-chip {
			height: 24px !important;
			font-size: 12px !important;
		}
	}

	.cassie-multi-dropdown {
		.v-chip {
			margin: 8px !important;
		}
	}
</style>
