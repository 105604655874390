var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cassie-vertical-md"},[_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Preference Page Configuration ")]},proxy:true},{key:"body",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"value":_vm.pageTitle,"label":"Page Title *","tooltip-text":"\n\t\t\t\t\t\t\t<p>The Title of the Preference Page</p>\n\t\t\t\t\t\t\t<p>This text populates the 'page-title' div on the Preference Page</p>\n\t\t\t\t\t\t\t","disabled":!_vm.userFullPermissions,"rules":{required: true, max: 150}},on:{"input":function($event){_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
							{pageTitle: $event}))}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('Dropdown',{attrs:{"value":_vm.dataSubjectIdentifierDisplayField,"label":"Data Subject Identifier Display Field *","tooltip-text":"\n\t\t\t\t\t\t\t<p>Select a field from the Cassie database to display to the Data Subject to show the page is unique to that user</p>\n\t\t\t\t\t\t\t<p>This dynamic field populates the 'custom-identifier' div on the Preference Page</p>\n\t\t\t\t\t\t\t","disabled":!_vm.userFullPermissions,"items":_vm.availableFields,"custom-sort":"","searchable":"","rules":"required"},on:{"input":function($event){_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
							{dataSubjectIdentifierDisplayField: $event}))}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('Dropdown',{attrs:{"value":_vm.headerId,"items":_vm.filteredHeaderOptions,"disabled":!_vm.userFullPermissions,"clearable":"","custom-sort":"","searchable":"","label":"Header","tooltip-text":"\n\t\t\t\t\t\t\t<p>An optional Header on the Preference Page</p>\n\t\t\t\t\t\t\t<p>This text populates the 'page-header' div on the Preference Page</p>\n\t\t\t\t\t\t\t"},on:{"input":function($event){_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
							{headerId: $event}))}}})],1),(_vm.userFullPermissions)?_c('v-col',{staticClass:"cassie-horizontal-sm",attrs:{"cols":"6"}},[_c('TextButton',{on:{"click":function($event){return _vm.openCreateHeaderFooter('Header')}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Create Header ")],1),(_vm.headerId)?_c('TextButton',{on:{"click":function($event){return _vm.openEditHeaderFooterForm('header', _vm.headerId)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")]),_vm._v(" Edit Selected Header ")],1):_vm._e()],1):_vm._e()],1),_c('TextArea',{attrs:{"value":_vm.pageText,"label":"Page Text","tooltip-text":"\n\t\t\t\t\t\t\t<p>The Page Text to be displayed at the top of the Preference Page by default.  This can be used to give context to the data displayed on the page</p>\n\t\t\t\t\t\t\t<p>This text populates the 'page-freetext' div on the Preference Page</p>\n\t\t\t\t\t\t\t","rules":{max: 1000},"disabled":!_vm.userFullPermissions},on:{"input":function($event){_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
					{pageText: $event}))}}}),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"value":_vm.mainPreferencesAreaTitle,"label":"Preferences Area Title *","tooltip-text":"\n\t\t\t\t\t\t\t<p>The Title of the 'Main' Preference area on the Preference Page</p>\n\t\t\t\t\t\t\t<p>This text populates the 'main-group__name' div on the Preference Page</p>\n\t\t\t\t\t\t\t","disabled":!_vm.userFullPermissions,"rules":{ required: true, max: 50 }},on:{"input":function($event){_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
							{mainPreferencesAreaTitle: $event}))}}})],1)],1),_c('TextArea',{attrs:{"value":_vm.mainPreferencesAreaText,"label":"Preferences Area Text","tooltip-text":"\n\t\t\t\t\t\t\t<p> The Preferences Area Text to be displayed at the top of the 'Main' Preference Area on the Preference Page by default.</p>\n\t\t\t\t\t\t\t<p>This text populates the 'main-group__description' div on the Preference Page</p>\n\t\t\t\t\t\t\t","disabled":!_vm.userFullPermissions,"rules":{max: 150}},on:{"input":function($event){_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
					{mainPreferencesAreaText: $event}))}}}),_vm._v(" "),_c('v-row',{staticClass:"align-center",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('Toggle',{staticClass:"ma-0",attrs:{"value":_vm.enableAdditionalSubArea1 || _vm.subPreferenceArea1title,"disabled":_vm.additionalSubAreaUsedInPreferences(_vm.SUB_AREA_1_ID) || !_vm.userFullPermissions,"label":"Enable Additional Sub Area 1","tooltip-text":"\n\t\t\t\t\t\t\t<p>Enable an Additional Sub Area on the Preference Page so that Statements can be displayed in separate sections</p>\n\t\t\t\t\t\t\t<p>This will create a new div on the page named 'sub-group'</p>\n\t\t\t\t\t\t\t","hide-details":"","dense":""},on:{"update:value":function (value) {
							_vm.enableAdditionalSubArea1 = value
							if (!value) {
								_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
									{subPreferenceArea1title: null}))
							}
						}}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.enableAdditionalSubArea1 || _vm.subPreferenceArea1title)?_c('TextField',{attrs:{"value":_vm.subPreferenceArea1title,"disabled":!_vm.userFullPermissions,"label":"Sub Area 1 Title *","tooltip-text":"\n\t\t\t\t\t\t\t<p>The title of the first additional Sub Area on the Preference Page</p>\n\t\t\t\t\t\t\t<p>This text populates the 'sub-group__title' div on the Preference Page</p>\n\t\t\t\t\t\t\t","rules":{required: true, max: 50}},on:{"input":function($event){_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
							{subPreferenceArea1title: $event}))}}}):_vm._e()],1)],1),_c('v-row',{staticClass:"align-center",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('Toggle',{staticClass:"ma-0",attrs:{"value":_vm.enableAdditionalSubArea2 || _vm.subPreferenceArea2title,"disabled":_vm.additionalSubAreaUsedInPreferences(_vm.SUB_AREA_2_ID) || !_vm.userFullPermissions,"label":"Enable Additional Sub Area 2","tooltip-text":"\n\t\t\t\t\t\t\t<p>The title of the second additional Sub Area on the Preference Page.</p>\n\t\t\t\t\t\t\t<p>This text populates the 'sub-group__title' div on the Preference Page</p>\n\t\t\t\t\t\t\t","hide-details":"","dense":""},on:{"update:value":function (value) {
							_vm.enableAdditionalSubArea2 = value
							if (!value) {
								_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
									{subPreferenceArea2title: null}))
							}
						}}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.enableAdditionalSubArea2 || _vm.subPreferenceArea2title)?_c('TextField',{attrs:{"value":_vm.subPreferenceArea2title,"disabled":!_vm.userFullPermissions,"label":"Sub Area 2 Title *","tooltip-text":"\n\t\t\t\t\t\t\t<p>The title of the second additional Sub Area on the Preference Page.</p>\n\t\t\t\t\t\t\t<p>This text populates the 'sub-group__title' div on the Preference Page</p>\n\t\t\t\t\t\t\t","rules":{required: true, max: 50}},on:{"input":function($event){_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
							{subPreferenceArea2title: $event}))}}}):_vm._e()],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('Dropdown',{attrs:{"value":_vm.footerId,"items":_vm.filteredFooterOptions,"custom-sort":"","clearable":"","searchable":"","disabled":!_vm.userFullPermissions,"label":"Preferences Section Footer","tooltip-text":"\n\t\t\t\t\t\t\t<p>The footer which sits at the bottom of the Preferences section.</p>\n\t\t\t\t\t\t\t<p>This text populates the 'footer-text' div on the Preference Page</p>\n\t\t\t\t\t\t\t"},on:{"input":function($event){_vm.updatePageContents(Object.assign({}, (_vm.pageContents),
							{footerId: $event}))}}})],1),(_vm.userFullPermissions)?_c('v-col',{staticClass:"cassie-horizontal-sm",attrs:{"cols":"6"}},[_c('TextButton',{on:{"click":function($event){return _vm.openCreateHeaderFooter('Footer')}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Create Footer ")],1),(_vm.footerId)?_c('TextButton',{on:{"click":function($event){return _vm.openEditHeaderFooterForm('footer', _vm.footerId)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")]),_vm._v(" Edit Selected Footer ")],1):_vm._e()],1):_vm._e()],1)]},proxy:true}])}),_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Behavior ")]},proxy:true},{key:"body",fn:function(){return [_c('Toggle',{staticClass:"ma-0",attrs:{"value":_vm.preferenceOptionsAreTrinary,"disabled":!_vm.userFullPermissions,"hide-details":"","dense":"","label":"Preference answer options are trinary (i.e. Opt-In, Opt-Out & No Change)","tooltip-text":"The Standard 'Opt-In' & 'Opt-Out' preference choices will have a 3rd option named 'No Change' if the Data Subject has made a previous Preference update"},on:{"update:value":function($event){_vm.updatePageSettings(Object.assign({}, (_vm.pageSettings),
					{preferenceOptionsAreTrinary: $event}))}}}),_c('Toggle',{staticClass:"ma-0",attrs:{"value":_vm.hideCurrentPrefData,"disabled":!_vm.userFullPermissions,"hide-details":"","dense":"","label":("\n\t\t\t\t\tDon't load the data subject's current preferences i.e. default all Preferences to <b class='ml-1'>" + (_vm.preferenceOptionsAreTrinary ? ' No Change' : ' Opt-Out') + "</b>\n\t\t\t\t"),"tooltip-text":"\n\t\t\t\t\t\t\t<p>By default, if a Data Subject accesses this Preference Page, their existing Preferences are displayed.  </p>\n\t\t\t\t\t\t\t<p>If enabled, this option will default to 'Opt-Out', but will default to 'No Change' if the trinary options are also enabled</p>\n\t\t\t\t\t\t\t"},on:{"update:value":function($event){_vm.updatePageSettings(Object.assign({}, (_vm.pageSettings),
					{hideCurrentPrefData: $event}))}}}),_c('Toggle',{staticClass:"ma-0",attrs:{"value":_vm.onlyAllowPreferenceOptOuts,"disabled":!_vm.userFullPermissions,"hide-details":"","dense":"","label":"Only allow data subjects to Opt-Out of preferences","tooltip-text":"This option restricts the Data Subject so that they can only 'Opt-Out' of Preferences on this Preference Page"},on:{"update:value":function($event){_vm.updatePageSettings(Object.assign({}, (_vm.pageSettings),
					{onlyAllowPreferenceOptOuts: $event}))}}}),_c('Toggle',{staticClass:"ma-0",attrs:{"value":_vm.showChannelName,"disabled":!_vm.userFullPermissions,"hide-details":"","dense":"","label":_vm._f("useLabels")('Show Channel Name above Statements'),"tooltip-text":"As well as the configured Statement, enabling this option will display the name of the Channel that the Data Subject is making a Preference against"},on:{"update:value":function($event){_vm.updatePageSettings(Object.assign({}, (_vm.pageSettings),
					{showChannelName: $event}))}}})]},proxy:true}])}),_c('LinkPreferencePageStepPreferenceCard',{attrs:{"page-preferences":_vm.pagePreferences,"page-contents":_vm.pageContents,"brand-id":_vm.brandId},on:{"update:pageContents":_vm.updatePageContents,"update:pagePreferences":function($event){return _vm.$emit('update:pagePreferences', $event)}}}),_c('ExtendedPreferencesCard',{attrs:{"preferences":_vm.pagePreferences,"extended-preferences":_vm.extendedPrefs,"parent-type":"Link"},on:{"update:extendedPreferences":function($event){return _vm.$emit('update:extendedPrefs', $event)},"extended-prefs-changed":function($event){return _vm.$emit('extended-prefs-changed')}}}),(_vm.showHeaderFooterForm)?_c('HeaderFooterFormModal',{attrs:{"type-id":_vm.headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS,"form-to-edit":_vm.headerFooterFormData,"selected-brand-id":_vm.brandId,"form-type":_vm.selectedFormType},on:{"updated":_vm.getPreferencePageData,"set-selected-header:headerId":function($event){return _vm.setSelectedHeaderFooter('header', $event)},"set-selected-footer:footerId":function($event){return _vm.setSelectedHeaderFooter('footer', $event)},"close":function () {
			_vm.headerFooterFormData = null
			_vm.showHeaderFooterForm = false
		}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }