<template>
	<validation-observer #default="{ handleSubmit }">
		<Modal width="800px">
			<template #modal-title>
				{{ modalTitle }}
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<TextField
						v-model="externalMetaData.name"
						:disabled="readOnlyPermissions"
						label="Name *"
						rules="required"
					/>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<Dropdown
							v-model="selectedComponentTypes"
							:search-input.sync="searchInput"
							:disabled="readOnlyPermissions"
							label="Component Type"
							:items="availableCassieComponentTypes"
							multiple
							persistent-hint
							select-all
							searchable
							class="cassie-input-width-md"
							@change="updateSelectAllChecked"
						>
							<template
								v-if="externalMetaData.associatedComponentTypes.length !== cassieComponentTypes.length"
								#prepend-item
							>
								<v-list-item
									dense
									@click="toggleSelectAll"
								>
									<v-checkbox
										v-model="selectAllChecked"
										dense
										@click="toggleSelectAll"
									/>
									<v-list-item-title class="pb-2">
										Select All
									</v-list-item-title>
								</v-list-item>
								<v-divider />
							</template>
						</Dropdown>
						<PrimaryActionButton
							:disabled="readOnlyPermissions"
							@click="addComponentTypes"
						>
							Add
						</PrimaryActionButton>
					</div>
					<v-simple-table
						v-if="externalMetaData.associatedComponentTypes"
						dense
						light
					>
						<template #default>
							<thead>
								<tr>
									<th
										class="text-left"
										scope="col"
									>
										Component Type
									</th>
									<th
										class="text-right"
										scope="col"
									>
										{{ readOnlyPermissions ? '' : 'Action' }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(componentTypeId, index) in externalMetaData.associatedComponentTypes"
									:key="index"
								>
									<td>
										{{ getComponentTypeName(componentTypeId) }}
									</td>
									<td class="text-right">
										<IconButton
											v-if="!readOnlyPermissions"
											tooltip-text="Remove Component Type"
											@click="removeComponentType(index)"
										>
											mdi-trash-can
										</IconButton>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<Dropdown
						v-model="selectedMetaDataValueType"
						:disabled="readOnlyPermissions"
						label="Metadata Value Type"
						searchable
						:items="metaDataTypeItems"
						class="cassie-input-width-md"
					/>
					<div
						v-if="selectedMetaDataValueType === SINGLE_SELECT"
						class="cassie-vertical-md"
					>
						<div class="d-flex flex-row cassie-horizontal-sm">
							<TextField
								v-model="selectedDropdownValue"
								:disabled="readOnlyPermissions"
								label="Single Select Value"
								class="cassie-input-width-md"
							/>
							<PrimaryActionButton
								:disabled="!selectedDropdownValue || readOnlyPermissions"
								@click="addDropdownValue"
							>
								Add
							</PrimaryActionButton>
						</div>
						<v-simple-table
							dense
							light
						>
							<template #default>
								<thead>
									<tr>
										<th
											class="text-left"
											scope="col"
										>
											Single Select Value
										</th>
										<th
											class="text-right"
											scope="col"
										>
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(dropdownValue, index) in externalMetaData.dropDownValues"
										:key="index"
									>
										<td>
											{{ dropdownValue.value }}
										</td>
										<td class="text-right">
											<IconButton
												v-if="index !== 0"
												:disabled="readOnlyPermissions"
												tooltip-text="Move Dropdown Value Up"
												@click="moveDropdownValueUp(index)"
											>
												mdi-arrow-up
											</IconButton>
											<IconButton
												v-if="index !== externalMetaData.dropDownValues.length - 1"
												:disabled="readOnlyPermissions"
												tooltip-text="Move Dropdown Value Down"
												@click="moveDropdownValueDown(index)"
											>
												mdi-arrow-down
											</IconButton>
											<IconButton
												v-if="!readOnlyPermissions"
												tooltip-text="Remove Dropdown Value"
												@click="removeDropdownValue(index)"
											>
												mdi-trash-can
											</IconButton>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>
					<Message v-if="isEdit && externalMetaDataToEdit.dropDownValues.length >= 1 && selectedMetaDataValueType === FREE_TEXT">
						Warning - changing the value type to free text will cause previous single select values to be lost.
					</Message>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="!readOnlyPermissions"
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '../../../../../shared/components/modal.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import Message from '../../../../../shared/components/message.vue'
import { updateExternalMetaData, createExternalMetaData } from '../../../../../shared/utils/api/external-meta-data.js'
import { META_DATA_KEYS_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
export default {
	name: 'manage-meta-data-keys-modal',
	components: { PrimaryActionButton, SecondaryActionButton, TextField, Modal, Dropdown, IconButton, Message },
	props: {
		externalMetaDataToEdit: Object,
		cassieComponentTypes: {
			type: Array,
			default: () => []
		}
	},
	data () {
		const FREE_TEXT = 1
		const SINGLE_SELECT = 2
		return {
			FREE_TEXT,
			SINGLE_SELECT,
			selectedComponentTypes: [],
			selectedMetaDataValueType: null,
			selectedDropdownValue: null,
			selectAllChecked: false,
			externalMetaData: JSON.parse(JSON.stringify(this.externalMetaDataToEdit || {
				name: '',
				associatedComponentTypes: [],
				dropDownValues: []
			})),
			searchInput: null
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		isEdit () {
			return Boolean(this.externalMetaDataToEdit)
		},
		modalTitle () {
			return this.isEdit ? `Edit ${useLabels('Metadata Key')}` : `Add ${useLabels('Metadata Key')}`
		},
		readOnlyPermissions () {
			return !this.checkPermission(META_DATA_KEYS_MODULE_FULL_PERMISSIONS)
		},
		availableCassieComponentTypes () {
			return this.cassieComponentTypes.filter(componentType => !this.externalMetaData.associatedComponentTypes?.includes(componentType.value))
		},
		metaDataTypeItems () {
			return [
				{
					text: 'Free Text',
					value: 1
				},
				{
					text: 'Single Select',
					value: 2
				}
			]
		}
	},
	created () {
		if (this.externalMetaData.dropDownValues?.length >= 1) {
			this.selectedMetaDataValueType = this.SINGLE_SELECT
		} else {
			this.selectedMetaDataValueType = this.FREE_TEXT
		}
	},
	methods: {
		close () {
			this.$emit('close')
		},
		addComponentTypes () {
			this.externalMetaData.associatedComponentTypes = [
				...this.externalMetaData.associatedComponentTypes,
				...this.selectedComponentTypes
			]
			this.selectedComponentTypes = []
			this.selectAllChecked = false
			this.searchInput = null
		},
		removeComponentType (index) {
			const associatedComponentTypes = [...this.externalMetaData.associatedComponentTypes]
			this.$delete(associatedComponentTypes, index)
			this.externalMetaData.associatedComponentTypes = associatedComponentTypes
		},
		getComponentTypeName (componentTypeId) {
			return this.cassieComponentTypes.find(({ value }) => value === componentTypeId)?.text
		},
		addDropdownValue () {
			this.externalMetaData.dropDownValues = [
				...this.externalMetaData.dropDownValues,
				{
					value: this.selectedDropdownValue
				}
			]
			this.selectedDropdownValue = null
		},
		moveDropdownValueDown (index) {
			this.move(this.externalMetaData.dropDownValues, index, index + 1)
		},
		moveDropdownValueUp (index) {
			this.move(this.externalMetaData.dropDownValues, index, index - 1)
		},
		removeDropdownValue (index) {
			const dropDownValues = [...this.externalMetaData.dropDownValues]
			this.$delete(dropDownValues, index)
			this.externalMetaData.dropDownValues = dropDownValues
		},
		applySort (array) {
			this.externalMetaData.dropDownValues = array
		},
		move (array, from, to) {
			const arrayCopy = JSON.parse(JSON.stringify(array))
			arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0])
			return this.applySort(arrayCopy)
		},
		toggleSelectAll () {
			if (this.selectedComponentTypes.length === this.availableCassieComponentTypes.length) {
				this.selectAllChecked = false
				this.selectedComponentTypes = []
			} else {
				this.selectAllChecked = true
				if (this.searchInput) {
					this.selectedComponentTypes = this.availableCassieComponentTypes.filter(componentType => componentType.text.toLowerCase().includes(this.searchInput.toLowerCase()))
						.map(componentType => componentType.value)
				} else {
					this.selectedComponentTypes = this.availableCassieComponentTypes.map(componentType => {
						return componentType.value
					})
				}
			}
		},
		updateSelectAllChecked () {
			this.selectAllChecked = !this.selectAllChecked
		},
		async submit () {
			const metaData = {
				externalDataName: this.externalMetaData.name,
				associatedComponentTypes: this.externalMetaData.associatedComponentTypes,
				dropDownValues: this.externalMetaData.dropDownValues
			}
			metaData.dropDownValues.forEach((dropdownValue, index) => {
				dropdownValue.displayOrder = index + 1
			})
			if (!this.isEdit) {
				await createExternalMetaData(metaData)
			} else {
				if (this.externalMetaDataToEdit.dropDownValues.length >= 1 && this.selectedMetaDataValueType === this.FREE_TEXT) {
					metaData.dropDownValues = []
				}
				await updateExternalMetaData(this.externalMetaData.id, metaData)
			}
			this.close()
			this.$emit('submit')
			showSnackbar(this.isEdit ? `You have updated this ${useLabels('metadata key')}` : `You have created a new ${useLabels('metadata key')}`)
		}
	}
}
</script>
