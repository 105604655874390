<template>
	<StepperItems
		:step="step"
		:steps="steps"
	>
		<template
			v-if="step === 0"
			#0
		>
			<IntegrationServiceDetails
				:user-full-permissions="userFullPermissions"
				:is-edit="isEdit"
				:system-integration-items="systemIntegrationItems"
				:name="integrationService.name"
				:brand-id="integrationService.brandId"
				:notes="integrationService.notes"
				:integration-id="integrationService.integrationId"
				@update:name="updateIntegrationService('name', $event)"
				@update:brandId="updateBrandId"
				@update:notes="updateIntegrationService('notes', $event)"
				@update:integrationId="updateIntegrationService('integrationId', $event)"
			/>
		</template>
		<template
			v-if="step === 1"
			#1
		>
			<IntegrationServiceData
				:user-full-permissions="userFullPermissions"
				:is-edit="isEdit"
				:clone-mode="cloneMode"
				:cassie-object-id.sync="integrationService.cassieObjectId"
				:object-id-column="integrationService.objectIdColumn"
				:trigger="integrationService.trigger"
				:reset-incremental-value="integrationService.resetIncrementalValue"
				:update-stand-dak="integrationService.updateStandDak"
				:object-select-list="integrationService.objectSelectList"
				:object-filter-clause="integrationService.objectFilterClause"
				:client-target-object-name="integrationService.clientTargetObjectName"
				:target-system-action="integrationService.targetSystemAction"
				:is-enabled="integrationService.isEnabled"
				:cassie-objects="cassieObjects"
				:data-type-items="dataTypeItems"
				:target-system-action-items="targetSystemActionItems"
				:output-filters="outputFilters"
				:integration-filters="integrationService.filters"
				@update:cassieObjectId="updateIntegrationService('cassieObjectId', $event)"
				@update:objectIdColumn="updateIntegrationService('objectIdColumn', $event)"
				@update:resetIncrementalValue="updateIntegrationService('resetIncrementalValue', $event)"
				@update:updateStandDak="updateIntegrationService('updateStandDak', $event)"
				@update:objectSelectList="updateIntegrationService('objectSelectList', $event)"
				@update:objectFilterClause="updateIntegrationService('objectFilterClause', $event)"
				@update:clientTargetObjectName="updateIntegrationService('clientTargetObjectName', $event)"
				@update:targetSystemAction="updateIntegrationService('targetSystemAction', $event)"
				@update:isEnabled="updateIntegrationService('isEnabled', $event)"
				@update:filters="updateIntegrationService('filters', $event)"
			/>
		</template>
		<template
			v-if="step === 2"
			#2
		>
			<IntegrationServiceConnection
				:user-full-permissions="userFullPermissions"
				:is-edit="isEdit"
				:http-method="integrationService.httpMethod"
				:authentication-url="integrationService.authenticationUrl"
				:request-body-format="integrationService.requestBodyFormat"
				:request-url="integrationService.requestUrl"
				:request-body="integrationService.requestBody"
				:request-throttling-wait-time-m-s="integrationService.requestThrottlingWaitTimeMS"
				:custom-headers="integrationService.customHeaders"
				:http-method-items="httpMethodItems"
				@update:httpMethod="updateIntegrationService('httpMethod', $event)"
				@update:authenticationUrl="updateIntegrationService('authenticationUrl', $event)"
				@update:requestBodyFormat="updateIntegrationService('requestBodyFormat', $event)"
				@update:requestUrl="updateIntegrationService('requestUrl', $event)"
				@update:requestBody="updateIntegrationService('requestBody', $event)"
				@update:requestThrottlingWaitTimeMS="updateIntegrationService('requestThrottlingWaitTimeMS', $event)"
				@update:customHeaders="updateIntegrationService('customHeaders', $event)"
			/>
		</template>
		<template
			v-if="step === 3"
			#3
		>
			<IntegrationServiceConnectionResponse
				:user-full-permissions="userFullPermissions"
				:is-edit="isEdit"
				:response-body-format="integrationService.responseBodyFormat"
				:success-responses="integrationService.successResponses"
				:retry-failed-messages="integrationService.retryFailedMessages"
				@update:responseBodyFormat="updateIntegrationService('responseBodyFormat', $event)"
				@update:successResponses="updateIntegrationService('successResponses', $event)"
				@update:retryFailedMessages="updateIntegrationService('retryFailedMessages', $event)"
			/>
		</template>
	</StepperItems>
</template>

<script>
import { mapGetters } from 'vuex'
import StepperItems from '../../../../../../shared/components/stepper-items.vue'
import IntegrationServiceDetails from './integration-service-details.vue'
import IntegrationServiceConnection from './integration-service-connection.vue'
import IntegrationServiceConnectionResponse from './integration-service-connection-response.vue'
import IntegrationServiceData from './integration-service-data.vue'
import { userHasBrand } from '../../../../../../shared/state/brands.js'
import { CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: { StepperItems, IntegrationServiceDetails, IntegrationServiceConnection, IntegrationServiceConnectionResponse, IntegrationServiceData },
	props: {
		step: Number,
		steps: Array,
		integrationService: Object,
		lookupData: Object,
		systemIntegrationItems: Array,
		cloneMode: Boolean
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			if (this.isEdit) {
				return this.productAreaPermission(CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS) && this.integrationService.isEditable && userHasBrand(this.integrationService.brandId)
			} else {
				return this.productAreaPermission(CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS)
			}
		},
		isEdit () {
			return !!this.integrationService.id && !this.cloneMode
		},
		httpMethodItems () {
			return this.lookupData?.httpMethods
		},
		targetSystemActionItems () {
			return this.lookupData?.targetSystemActions
		},
		urnColumnItems () {
			return this.lookupData?.urnColumns
		},
		cassieObjects () {
			return this.lookupData?.cassieObjects
		},
		dataTypeItems () {
			return this.lookupData?.cassieObjects.map(({ cassieObjectId, cassieObjectName }) => ({
				text: cassieObjectName,
				value: cassieObjectId
			}))
		},
		outputFilters () {
			return this.lookupData?.outputFilters
		}
	},
	methods: {
		async clearBrandIdRelatedFields () {
			await this.$nextTick()
		},
		updateIntegrationService (property, value) {
			this.$emit('update:integrationService', {
				...this.integrationService,
				[property]: value
			})
		},
		async updateBrandId (value) {
			this.updateIntegrationService('brandId', value)
			await this.$nextTick()
			this.clearBrandIdRelatedFields()
		}
	}
}
</script>
