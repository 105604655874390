<template>
	<validation-provider ref="builder">
		<cassie-form-builder
			mode="build"
			:type="type"
			:headers="mappedHeaders"
			:footers="mappedFooters"
			:channels="mappedChannels"
			:extended-preferences="mappedExtendedPreferences"
			:translation-mode="translationMode"
			:read-only="!userFullPermissions"
			class="cassie-builder"
		/>
	</validation-provider>
</template>

<script>
import sortParentAndChildChannelsMixin from '../../../../../shared/mixins/sort-parent-and-child-channels.js'
import { UNIVERSAL_BRAND } from '../../../../../shared/state/brands.js'
export default {
	mixins: [sortParentAndChildChannelsMixin],
	props: {
		type: {
			type: String,
			required: true
		},
		channels: {
			type: Array,
			required: true
		},
		headers: {
			type: Array,
			required: false
		},
		footers: {
			type: Array,
			required: false
		},
		translationMode: {
			type: Boolean,
			default: false
		},
		extendedPreferences: {
			type: Array,
			required: false
		},
		brandId: Number,
		userFullPermissions: Boolean
	},
	computed: {
		mappedHeaders () {
			const headers = this.headers.filter(header => header.brandId === this.brandId || header.brandId === UNIVERSAL_BRAND)
			if (!headers) {
				return []
			}
			headers.sort((a, b) => a.name.localeCompare(b.name))
			return JSON.stringify(headers)
		},
		mappedFooters () {
			const footers = this.footers.filter(footer => footer.brandId === this.brandId || footer.brandId === UNIVERSAL_BRAND)
			if (!footers) {
				return []
			}
			footers.sort((a, b) => a.name.localeCompare(b.name))
			return JSON.stringify(footers)
		},
		mappedChannels () {
			const channels = this.channels.filter(channel => channel.brandId === this.brandId || channel.brandId === UNIVERSAL_BRAND)
			const sortedChannels = this.sortParentAndChildChannels(channels).map(channel => ({
				value: channel.channelId,
				label: channel.originalChannelName,
				selectLabel: channel.channelName,
				statements: channel.statements?.map(statement => ({
					value: statement.statementID,
					label: statement.statementText,
					defaultValue: statement.defaultValue,
					isOptIn: statement.isOptIn
				})).sort((a, b) => a.label.localeCompare(b.label))
			}))
			return JSON.stringify(sortedChannels)
		},
		mappedExtendedPreferences () {
			const mappedExtendedPreferences = this.extendedPreferences?.map(extendedPreference => ({
				extendedPreferenceId: extendedPreference.extendedPreferenceId,
				name: extendedPreference.name,
				dropdownValues: extendedPreference.dropdownValues.map(dropdownValue => ({
					value: dropdownValue.value,
					valueId: dropdownValue.valueId,
					displayOrder: dropdownValue.displayOrder
				})).sort((a, b) => a.displayOrder - b.displayOrder)
			}))
			return JSON.stringify(mappedExtendedPreferences)
		}
	}
}
</script>

<style>
.cassie-builder {
	height: calc(100vh - 298px);
	max-height: calc(100vh - 298px);
}
</style>
