<template>
	<validation-observer #default="{ handleSubmit }">
		<Modal width="80%">
			<template #modal-title>
				{{ isEdit ? 'Edit Preference Subgroup' : 'Create New Preference Subgroup' }}
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<TextField
						v-model="newSubGroupName"
						label="Subgroup Name *"
						:rules="{required: true, max:100}"
					/>
					<TextField
						:value="parentGroupName"
						label="Parent Group"
						view-only
					/>
					<div class="d-flex cassie-horizontal-md">
						<Dropdown
							v-model="selectedChannels"
							:search-input.sync="searchInput"
							:items="mappedAvailableChannels"
							:label="'Select a channel to add' | useLabels"
							multiple
							select-all
							searchable
							style="width: 300px; flex: none;"
							@change="selectChannel"
						>
							<template
								v-if="newChannels.length !== mappedAvailableChannels.length"
								#prepend-item
							>
								<v-list-item
									dense
									@click="toggleSelectAll"
								>
									<v-checkbox
										v-model="selectAllChecked"
										dense
										@click="toggleSelectAll"
									/>
									<v-list-item-title class="pb-2">
										Select All
									</v-list-item-title>
								</v-list-item>
								<v-divider />
							</template>
						</Dropdown>
						<PrimaryActionButton
							@click="addChannels"
						>
							Add
						</PrimaryActionButton>
					</div>
					<div class="text-subtitle-1 font-weight-medium">
						Associated {{ 'Channels' | useLabels }}
					</div>
					<DataTable
						:items="newChannels"
						:headers="tableHeaders"
						:disable-sort="true"
						item-key="channelId"
					>
						<template #item.action="{ index }">
							<IconButton
								v-if="index !== 0"
								@click="moveChannelUp(index)"
							>
								mdi-arrow-up
							</IconButton>
							<IconButton
								v-else
								small-icon
								small
								disabled
							/>
							<IconButton
								v-if="index !== newChannels.length - 1"
								@click="moveChannelDown(index)"
							>
								mdi-arrow-down
							</IconButton>
							<IconButton
								v-else
								small-icon
								small
								disabled
							/>
							<IconButton
								:tooltip-text="'Remove Channel' | useLabels"
								@click="removeChannel(index)"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<v-spacer />
				<PrimaryActionButton @click="handleSubmit(submit)">
					{{ isEdit ? 'Save' : 'Create' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>
<script>
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { getPreferenceChannels } from '../../../../../../shared/utils/api/channels.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'
export default {
	components: { Dropdown, DataTable, IconButton, Modal, TextField, SecondaryActionButton, PrimaryActionButton },
	props: {
		subGroupName: String,
		subGroupIndex: Number,
		parentGroupIndex: Number,
		parentGroupName: String,
		allSelectedChannels: {
			type: Array,
			default: () => []
		},
		channels: {
			type: Array,
			default: () => []
		},
		move: Function
	},
	data () {
		return {
			newSubGroupName: this.subGroupName,
			newChannels: this.channels,
			selectedChannelId: null,
			selectedChannels: [],
			selectAllChecked: false,
			selectedStandDakField: null,
			selectedStandDakFields: [],
			availableChannels: [],
			searchInput: null
		}
	},
	computed: {
		mappedAvailableChannels () {
			const selectedChannels = [...this.newChannels, ...this.allSelectedChannels]
			return this.availableChannels
				.filter(channel => selectedChannels.every(({ channelId }) => channel.channelId !== channelId))
				.map(({ channelName, channelId }) => ({ value: channelId, text: channelName }))
		},
		isEdit () {
			return !!this.subGroupName
		},
		tableHeaders () {
			return [
				{
					text: useLabels('Channel Name'),
					value: 'channelName',
					width: '80%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '15%'
				}
			]
		}
	},
	async created () {
		const { data: { channels } } = await getPreferenceChannels()
		this.availableChannels = channels
	},
	methods: {
		removeChannel (index) {
			this.$delete(this.newChannels, index)
		},
		removeStandDakField (index) {
			this.$delete(this.selectedStandDakFields, index)
		},
		moveChannelDown (index) {
			this.newChannels = this.move(this.newChannels, index, index + 1)
		},
		moveChannelUp (index) {
			this.newChannels = this.move(this.newChannels, index, index - 1)
		},
		addChannels () {
			this.selectedChannels.forEach(selectedChannelId => {
				const matchingChannel = this.availableChannels.find(({ channelId }) => channelId === selectedChannelId)
				const newChannel = {
					brandId: matchingChannel.brandId,
					brandName: matchingChannel.brandName,
					canBeAdded: true,
					channelId: matchingChannel.channelId,
					channelName: matchingChannel.channelName,
					display: true,
					displayOrderInSubGroup: this.newChannels.length,
					extendedPrefs: [],
					hideNoPref: false,
					id: 0,
					requiredStandDakFields: null,
					subGroupId: 0
				}
				this.newChannels = [...this.newChannels, newChannel]
			})
			this.selectedChannels = []
			this.selectAllChecked = false
		},
		addStandDakField () {
			this.selectedStandDakFields = [...this.selectedStandDakFields, this.selectedStandDakField]
			this.selectedStandDakField = null
			this.searchInput = null
			this.selectAllChecked = false
		},
		close () {
			this.$emit('close')
		},
		selectChannel () {
			this.selectAllChecked = !this.selectAllChecked
		},
		toggleSelectAll () {
			if (this.selectAllChecked) {
				this.selectAllChecked = false
				this.selectedChannels = []
			} else {
				this.selectAllChecked = true
				if (this.searchInput) {
					this.selectedChannels = this.mappedAvailableChannels.filter(channel => channel.text.toLowerCase().includes(this.searchInput.toLowerCase()))
						.map(channel => channel.value)
				} else {
					this.selectedChannels = this.mappedAvailableChannels.map(channel => {
						return channel.value
					})
				}
			}
		},
		submit () {
			this.$emit(this.isEdit ? 'edit' : 'add', this.parentGroupIndex, this.newSubGroupName, this.newChannels, this.subGroupIndex)
			this.close()
		}
	}
}
</script>
