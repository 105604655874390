<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="850px">
			<template #modal-title>
				{{ isEdit ? 'Edit' : 'Create' }} Cookie Script
				<v-spacer />
				<IconButton @click="$emit('close')">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="6">
							<TextField
								v-model="cookieScript.scriptName"
								:disabled="!userFullPermissions"
								label="Name *"
								:rules="{required: true, max: 120}"
							/>
						</v-col>
						<v-col cols="6">
							<Dropdown
								v-model="cookieScript.brandId"
								:disabled="!userFullPermissions || isEdit || accessedViaCookieBannerConfiguration || !clientMultiBranded || singleBrand"
								:items="brandItems"
								custom-sort
								label="Brand*"
								searchable
								:rules="{ required: clientMultiBranded}"
								@change="updateParentCookieCategory(cookieScript.type)"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<Dropdown
								v-model="cookieScript.type"
								label="Type *"
								:disabled="
									isEdit ||
										selectedCategoryIsStrictlyNecessary ||
										!userFullPermissions
								"
								:items="typeItems"
								rules="required"
								@change="updateParentCookieCategory($event)"
							/>
						</v-col>
						<v-col cols="6">
							<Dropdown
								v-model="cookieScript.parentCookieCategoryId"
								label="Cookie Category *"
								:items="categoryItems"
								searchable
								class="cassie-input-width-xl"
								item-disabled="disabled"
								:disabled="
									isEdit ||
										!userFullPermissions ||
										accessedViaCookieBannerConfiguration ||
										cookieScript.type === 'Essential'"
								rules="required"
							/>
						</v-col>
					</v-row>
					<span class="text-subtitle-1 font-weight-medium">
						Cookie Script Settings
					</span>
					<v-divider />
					<v-row dense>
						<v-col cols="6">
							<Toggle
								:value.sync="cookieScript.isRunFirst"
								label="Run First? *"
								tooltip-text="Enable if you wish for this script to be executed before the Essential scripts (if the user opts in)."
								:disabled="!userFullPermissions"
							/>
						</v-col>
						<v-col cols="6">
							<Toggle
								:value.sync="cookieScript.gpcEnabled"
								label="Global Privacy Control *"
								tooltip-text="Enable if you wish for this script to detect GPC signals from user's browsers."
								:disabled="!userFullPermissions"
							/>
						</v-col>
					</v-row>
					<template v-if="enableTransactionTypes">
						<span class="text-subtitle-1 font-weight-medium">
							Cookie Script Transaction Settings
						</span>
						<v-divider />
						<v-row
							dense
						>
							<v-col cols="6">
								<Dropdown
									v-model="cookieScript.transactionTypeId"
									label="Transaction Type *"
									:items="transactionTypeItems"
									class="cassie-input-width-xl"
									item-disabled="disabled"
									:disabled="
										!userFullPermissions"
									rules="required"
								/>
							</v-col>
							<v-col cols="6">
								<Toggle
									v-if="transactionTypeAccessible"
									:value.sync="cookieScript.overrideUniversalConsent"
									:label="universalConsentInfo.label"
									:tooltip-text="universalConsentInfo.tooltip"
									:disabled="!userFullPermissions"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<Toggle
									:value.sync="overrideSecurityLevel"
									label="Override Security Level?"
									:tooltip-text="securityLevelTooltip"
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col cols="6">
								<Dropdown
									v-if="overrideSecurityLevel"
									v-model="cookieScript.overrideSecurityLevelId"
									label="Security Level *"
									:items="overrideSecurityLevelItems"
									class="cassie-input-width-xl"
									item-disabled="disabled"
									:disabled="!userFullPermissions"
									rules="required"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="6">
								<Toggle
									:value.sync="copyFromDeviceToDataSubject"
									label="Copy from Device to Data Subject?"
									tooltip-text="Enabling the ability to Copy from Device to Data Subject allows for Consent & Preferences captured at a Device Level to be copied over to any associated Person Records."
									:disabled="!userFullPermissions"
								/>
							</v-col>
							<v-col cols="6">
								<Dropdown
									v-if="copyFromDeviceToDataSubject"
									v-model="cookieScript.deviceCopyActionId"
									label="Copy Action *"
									:items="copyActionItems"
									class="cassie-input-width-xl"
									item-disabled="disabled"
									:disabled="!userFullPermissions"
									rules="required"
								/>
							</v-col>
						</v-row>
					</template>
					<span class="text-subtitle-1 font-weight-medium">
						Google Consent Mode
					</span>
					<v-divider />
					<v-row dense>
						<v-col cols="6">
							<Toggle
								:value.sync="cookieScript.gtmEnabled"
								label="Google Consent Mode integration"
								tooltip-text="Enabling Google Consent mode integration will append a google tag manager consent property to the cookie script. This will be referenced within the Cassie CMP Tag Template within Google Tag manager."
								:disabled="!userFullPermissions"
								@update:value="resetGtmConsentType($event)"
							/>
						</v-col>
						<v-col cols="6">
							<Dropdown
								v-model="cookieScript.gtmConsentTypeId"
								label="Consent Type"
								:items="gtmConsentTypes"
								:disabled="!userFullPermissions || !cookieScript.gtmEnabled"
								tooltip-text="Google Tag Manager consent types can be used to control tag firing in Google Tag Manager, selecting a consent type will attribute that consent type to the cookie script."
								custom-sort
							/>
						</v-col>
					</v-row>
					<span class="text-subtitle-1 font-weight-medium">
						Cookie Script Deployment
					</span>
					<v-divider />
					<v-row dense>
						<v-col cols="12">
							<Froala
								v-if="userFullPermissions"
								v-model="cookieScript.displayText"
								title="Display Text *"
								:rules="{required: true, max: 2000}"
							/>
							<div
								v-else
								v-dompurify-html="cookieScript.displayText"
								class="mt-2 cassie-disabled-textarea rounded-lg"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<TextField
								v-model="cookieScript.cookieInfoUrl"
								label="Information URL"
								class="cassie-input-width-xl"
								:disabled="!userFullPermissions"
								tooltip-text="Add a link to a web page that contains more information about this script."
								rules="isLink"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<OptionSelector
								v-if="userFullPermissions"
								:selected="cookieScript.browserCookieNames"
								entity-type="Cookies In Script"
								is-text
								@update:selected="updateCookiesInScript($event)"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<TextField
								v-model.number="cookieScript.scriptExpiryInDays"
								type="number"
								:disabled="!userFullPermissions"
								label="Script Expiry (in days)"
								tooltip-text="Specify how long the cookie(s) will be persisted for in the user's browser (in days)."
								rules="numeric"
							/>
						</v-col>
						<v-col cols="6">
							<TextField
								v-model.number="cookieScript.consentExpiryInDays"
								type="number"
								:disabled="!userFullPermissions"
								label="Consent Expiry (in days)"
								tooltip-text="Specify how long the consent given for this cookie script is valid for (in days)."
								rules="numeric"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col :cols="cookieScript.scriptPlacementTypeId === BODY_SCRIPT_PLACEMENT ? 6 : 12">
							<Dropdown
								v-model="cookieScript.scriptPlacementTypeId"
								label="Script Placement"
								:disabled="!userFullPermissions"
								custom-sort
								:items="placementAreaItems"
							/>
						</v-col>
						<v-col
							v-if="bodyScriptPlacement"
							cols="6"
						>
							<TextField
								v-model="cookieScript.optInDivBody"
								:disabled="!userFullPermissions"
								label="Body Opt-in div id"
								tooltip-text="The Id of the HTML div where the opt-in script will be appended to."
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Textarea
								v-if="headScriptPlacement"
								v-model="cookieScript.optInScript"
								:disabled="!userFullPermissions"
								no-resize
								label="Head Opt-in Script"
								tooltip-text="The script that will be appended to the head of the page and executed, if the user opts-in."
							/>
							<Textarea
								v-else
								v-model="cookieScript.optInScriptBody"
								:disabled="!userFullPermissions"
								no-resize
								label="Body Opt-in Script"
								tooltip-text="The script that will be executed, if the user opts-in."
							/>
						</v-col>
					</v-row>
					<v-row
						v-if="bodyScriptPlacement"
						dense
					>
						<v-col cols="12">
							<TextField
								v-model="cookieScript.optOutDivBody"
								:disabled="!userFullPermissions"
								label="Body Opt-out div id"
								tooltip-text="The Id of the HTML div where the opt-out script will be appended to."
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col
							v-if="headScriptPlacement"
							cols="12"
						>
							<Textarea
								v-model="cookieScript.optOutScript"
								:disabled="!userFullPermissions"
								no-resize
								label="Head Opt-out Script"
								tooltip-text="The script that will be appended to the head of the page and executed, if the user opts-out."
							/>
						</v-col>
						<v-col
							v-else
							cols="12"
						>
							<Textarea
								v-model="cookieScript.optOutScriptBody"
								:disabled="!userFullPermissions"
								no-resize
								label="Body Opt-out Script"
								tooltip-text="The script that will be executed, if the user opts-out."
							/>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>
<script>
/* eslint-disable max-lines */
import Modal from '../../../../../../../shared/components/modal.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import Textarea from '../../../../../../../shared/components/textarea.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import OptionSelector from '../../../../../../../shared/components/option-selector.vue'
import Froala from '../../../../shared/froala-editor.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM } from '../../../../../../../shared/state/brands.js'
import { createCookieScript, editCookieScript, getGtmConsentTypes } from '../../../../../../../shared/utils/api/cookies/cookie-scripts.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { scriptTagDefault } from './cookie-scripts-defaults.js'
import { enableTransactionTypes } from '../../../../../../../shared/state/config-keys.js'

export default {
	components: {
		Modal,
		ValidationForm,
		TextField,
		Dropdown,
		Textarea,
		Toggle,
		OptionSelector,
		IconButton,
		Froala,
		SecondaryActionButton,
		PrimaryActionButton
	},
	props: {
		cookieScriptToEdit: Object,
		cookieCategories: Array,
		cookieCategoryId: Number,
		userFullPermissions: Boolean,
		accessedViaCookieBannerConfiguration: {
			type: Boolean,
			default: false
		},
		cookieCollectionBrandId: Number,
		gtmCookieScripts: Array,
		transactionTypes: Array,
		securityLevels: Array,
		channelDeviceCopyActions: Array
	},
	setup () {
		return {
			brandOptions,
			showSnackbar,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect,
			enableTransactionTypes
		}
	},
	data () {
		const HEAD_SCRIPT_PLACEMENT = 1
		const BODY_SCRIPT_PLACEMENT = 2
		const ESSENTIAL_SCRIPT_TYPE = 'Essential'
		return {
			cookieScript: JSON.parse(JSON.stringify(this.cookieScriptToEdit || {
				scriptName: null,
				brandId: this.defaultBrandInSelect,
				type: null,
				parentCookieCategoryId: null,
				isRunFirst: false,
				gpcEnabled: false,
				displayText: null,
				cookieInfoUrl: null,
				browserCookieNames: [],
				scriptExpiryInDays: 0,
				consentExpiryInDays: 0,
				scriptPlacementTypeId: HEAD_SCRIPT_PLACEMENT,
				optInDivBody: null,
				optInScript: scriptTagDefault,
				optInScriptBody: scriptTagDefault,
				optOutDivBody: null,
				optOutScript: scriptTagDefault,
				optOutScriptBody: scriptTagDefault,
				gtmEnabled: false,
				gtmConsentTypeId: null
			})),
			BODY_SCRIPT_PLACEMENT,
			HEAD_SCRIPT_PLACEMENT,
			ESSENTIAL_SCRIPT_TYPE,
			selectedCategoryIsStrictlyNecessary: false,
			gtmConsentTypes: [],
			overrideSecurityLevel: false,
			copyFromDeviceToDataSubject: false
		}
	},
	computed: {
		isEdit () {
			return !!this.cookieScriptToEdit
		},
		brandItems () {
		// ensures when viewing universal object it displays in dropdown
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)
			if (containsUniversal) {
				return brandOptions.value
			} else {
				return !this.isEdit
					? brandOptions.value
					: [
						UNIVERSAL_BRAND_ITEM,
						...brandOptions.value
					]
			}
		},
		typeItems () {
			let types = [
				'Essential', 'Legitimate Interest', 'User Consent'
			]
			if (this.accessedViaCookieBannerConfiguration && !this.selectedCategoryIsStrictlyNecessary) {
				types = types.filter(type => type !== 'Essential')
			}
			return types
		},
		placementAreaItems () {
			return [
				{
					text: '<head>',
					value: this.HEAD_SCRIPT_PLACEMENT
				},
				{
					text: '<body>',
					value: this.BODY_SCRIPT_PLACEMENT
				}
			]
		},
		headScriptPlacement () {
			return this.cookieScript.scriptPlacementTypeId === this.HEAD_SCRIPT_PLACEMENT
		},
		bodyScriptPlacement () {
			return this.cookieScript.scriptPlacementTypeId === this.BODY_SCRIPT_PLACEMENT
		},
		categoryItems () {
			const categories = this.cookieCategories.map(({ id, categoryName, isStrictlyCategory, brandId, brandName }) => ({
				text: brandName != null ? categoryName + ' - ' + brandName : categoryName,
				value: id,
				isStrictlyCategory: isStrictlyCategory,
				brandId: brandId,
				brandName: brandName
			}))
			const categoriesByBrand = this.cookieScript.brandId !== 0 ? categories.filter(({ brandId }) => brandId === this.cookieScript.brandId || brandId === 0) : categories
			if (this.cookieScript.type === this.ESSENTIAL_SCRIPT_TYPE) {
				return categoriesByBrand
			} else {
				return categoriesByBrand.filter(({ isStrictlyCategory }) => !isStrictlyCategory)
			}
		},
		transactionTypeItems () {
			const transactionTypes = this.transactionTypes?.map(({ id, name, securityLevelName }) => ({
				text: name,
				value: id,
				accessible: securityLevelName.toLowerCase() === 'accessible'
			}))
			return transactionTypes
		},
		transactionTypeAccessible () {
			const transactionType = this.transactionTypes.find(type => type.id === this.cookieScript.transactionTypeId)
			return (transactionType?.securityLevelName.toLowerCase() || '') === 'accessible'
		},
		universalConsentInfo () {
			const transactionType = this.transactionTypes.find(type => type.id === this.cookieScript.transactionTypeId)
			let label = ''
			let tooltip = ''
			if (transactionType) {
				label = transactionType.enableUniversalConsent
					? 'Override Universal Consent'
					: 'Allow Universal Consent'
				tooltip = transactionType.enableUniversalConsent
					? 'This chosen Transaction Type has Universal Consent enabled. Use this toggle to override this functionality for this particular Channel'
					: 'This chosen Transaction Type has Universal Consent disabled. Use this toggle to allow this functionality for this particular Channel'
			}
			return { label, tooltip }
		},
		overrideSecurityLevelItems () {
			return this.securityLevels.map(({ id, name }) => ({
				text: name,
				value: id
			}))
		},
		copyActionItems () {
			return this.channelDeviceCopyActions.map(({ channelDeviceCopyActionId, actionName }) => ({
				text: actionName,
				value: channelDeviceCopyActionId
			}))
		},
		selectedSecurityLevel () {
			const transactionType = this.transactionTypes.find(type => type.id === this.cookieScript.transactionTypeId)
			return transactionType?.securityLevelName
		},
		securityLevelTooltip () {
			if (!this.selectedSecurityLevel) {
				return 'Enabling this toggle will allow the Security Level to be managed within the Channel.'
			} else {
				return `This Transaction Type's Security Level is set to ${this.selectedSecurityLevel}. Enabling this toggle will allow the Security Level to be managed within the Channel.`
			}
		}
	},
	created () {
		if (this.accessedViaCookieBannerConfiguration && !this.isEdit) {
			const cookieCategoryIsStrictlyNecessary = this.cookieCategories.find(({ id }) => id === this.cookieCategoryId)?.isStrictlyCategory
			this.selectedCategoryIsStrictlyNecessary = cookieCategoryIsStrictlyNecessary
			if (cookieCategoryIsStrictlyNecessary) {
				this.cookieScript.type = this.ESSENTIAL_SCRIPT_TYPE
			}
			this.cookieScript.parentCookieCategoryId = this.cookieCategoryId
			this.cookieScript.brandId = this.cookieCollectionBrandId
		}
		this.getGtmConsentTypes()
		this.overrideSecurityLevel = this.cookieScript.overrideSecurityLevelId !== null && this.cookieScript.overrideSecurityLevelId !== undefined
		this.copyFromDeviceToDataSubject = this.cookieScript.deviceCopyActionId !== null && this.cookieScript.deviceCopyActionId !== undefined
		if (this.cookieScript.optInScript === '' || !this.cookieScript.optInScript) this.cookieScript.optInScript = scriptTagDefault
		if (this.cookieScript.optInScriptBody === '' || !this.cookieScript.optInScriptBody) this.cookieScript.optInScriptBody = scriptTagDefault
		if (this.cookieScript.optOutScript === '' || !this.cookieScript.optOutScript) this.cookieScript.optOutScript = scriptTagDefault
		if (this.cookieScript.optOutScriptBody === '' || !this.cookieScript.optOutScriptBody) this.cookieScript.optOutScriptBody = scriptTagDefault
	},
	methods: {
		async getGtmConsentTypes () {
			const { gtmConsentTypes } = await getGtmConsentTypes()
			this.gtmConsentTypes = gtmConsentTypes
		},
		resetGtmConsentType (gtmEnabled) {
			if (!gtmEnabled) {
				this.cookieScript.gtmConsentTypeId = null
			}
		},
		updateParentCookieCategory (value) {
			const selectedBrandId = this.cookieScript.brandId ?? 0
			if (value === this.ESSENTIAL_SCRIPT_TYPE) {
				this.cookieScript.parentCookieCategoryId = this.cookieCategories.find(({ isStrictlyCategory, brandId }) => isStrictlyCategory && brandId === selectedBrandId)?.id
			}
		},
		updateCookiesInScript (value) {
			this.cookieScript.browserCookieNames = value
		},
		async submit () {
			const cookieScript = { ...this.cookieScript }
			if (cookieScript.overrideUniversalConsent == null && this.transactionTypeAccessible) cookieScript.overrideUniversalConsent = false
			cookieScript.overrideSecurityLevelId = this.overrideSecurityLevel ? cookieScript.overrideSecurityLevelId : null
			cookieScript.deviceCopyActionId = this.copyFromDeviceToDataSubject ? cookieScript.deviceCopyActionId : null
			cookieScript.scriptDescription = cookieScript.scriptName
			cookieScript.browserCookieNames = this.cookieScript.browserCookieNames.join(',')
			cookieScript.isStrictlyNecessary = cookieScript.type === 'Essential'
			cookieScript.isLegitimateInterest = cookieScript.type === 'Legitimate Interest'
			if (cookieScript.optInScript === scriptTagDefault) cookieScript.optInScript = ''
			if (cookieScript.optInScriptBody === scriptTagDefault) cookieScript.optInScriptBody = ''
			if (cookieScript.optOutScript === scriptTagDefault) cookieScript.optOutScript = ''
			if (cookieScript.optOutScriptBody === scriptTagDefault) cookieScript.optOutScriptBody = ''
			if (!this.isEdit) {
				await createCookieScript(cookieScript).then(response => {
					this.$emit('submit')
					this.$emit('get-new-script-id', response.data.newCookieScriptId)
				})
				showSnackbar('Cookie Script created successfully')
			} else {
				await editCookieScript(cookieScript)
				showSnackbar('Cookie Script updated successfully')
			}
			this.$emit('submit')
		}
	}
}
</script>
