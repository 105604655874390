<template>
	<SectionCard>
		<template #title>
			{{ elementType.cardTitle }}
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						label="Default Label"
						disabled
						:test-id="`${element.name}_default_label`"
						:value="element.label"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Label"
						:value.sync="translatedPiiLabel"
						:disabled="!userFullPermissions"
						:test-id="`${element.name}_translated_label`"
						@input="updateTranslation(element.name, 'label', $event)"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="element.info"
				dense
			>
				<v-col cols="6">
					<TextField
						label="Default Tooltip"
						disabled
						:test-id="`${element.name}_default_tooltip`"
						:value="element.info"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Tooltip"
						:value.sync="translatedPiiTooltip"
						:disabled="!userFullPermissions"
						:test-id="`${element.name}_translated_tooltip`"
						@input="updateTranslation(element.name, 'info', $event)"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="element.description"
				dense
			>
				<v-col cols="6">
					<TextField
						label="Default Description"
						disabled
						:test-id="`${element.name}_default_description`"
						:value="element.description"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Description"
						:value.sync="translatedPiiDescription"
						:disabled="!userFullPermissions"
						:test-id="`${element.name}_translated_description`"
						@input="updateTranslation(element.name, 'description', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
export default {
	components: {
		SectionCard,
		TextField
	},
	props: {
		element: {
			type: Object,
			required: true
		},
		elementType: {
			type: Object,
			required: true
		},
		translatedSchema: {
			type: Object,
			required: true
		},
		userFullPermissions: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		translatedPiiLabel () {
			return this.translatedSchema[this.element?.name]?.label
		},
		translatedPiiTooltip () {
			return this.translatedSchema[this.element?.name]?.info
		},
		translatedPiiDescription () {
			return this.translatedSchema[this.element?.name]?.description
		}
	},
	methods: {
		updateTranslation (key, property, value) {
			this.$emit('update-translation', key, property, value)
		}
	}
}
</script>
