export const defaultVueformBuilderFormat = {
	schema: {},
	theme: {
		primary: '#6200ee',
		'primary-darker': '#5000cc',
		'color-on-primary': '#ffffff',
		danger: '#b00020',
		'danger-lighter': '#f9e5e8',
		success: '#4caf50',
		'success-lighter': '#e8f5e9',
		'ring-width': '0px',
		'ring-color': '#673ab766',
		'link-color': 'var(--vf-primary)',
		'link-decoration': 'inherit',
		'gray-50': '#fafafa',
		'gray-100': '#f5f5f5',
		'gray-200': '#eeeeee',
		'gray-300': '#e0e0e0',
		'gray-400': '#bdbdbd',
		'gray-500': '#9e9e9e',
		'gray-600': '#757575',
		'gray-700': '#616161',
		'gray-800': '#424242',
		'gray-900': '#212121',
		'dark-50': '#fafafa',
		'dark-100': '#f5f5f5',
		'dark-200': '#eeeeee',
		'dark-300': '#e0e0e0',
		'dark-400': '#bdbdbd',
		'dark-500': '#9e9e9e',
		'dark-600': '#757575',
		'dark-700': '#616161',
		'dark-800': '#424242',
		'dark-900': '#212121',
		'font-size': '1rem',
		'font-size-sm': '0.875rem',
		'font-size-lg': '1rem',
		'font-size-small': '0.875rem',
		'font-size-small-sm': '0.75rem',
		'font-size-small-lg': '0.875rem',
		'font-size-h1': '2.125rem',
		'font-size-h1-sm': '2.125rem',
		'font-size-h1-lg': '2.125rem',
		'font-size-h2': '1.875rem',
		'font-size-h2-sm': '1.875rem',
		'font-size-h2-lg': '1.875rem',
		'font-size-h3': '1.5rem',
		'font-size-h3-sm': '1.5rem',
		'font-size-h3-lg': '1.5rem',
		'font-size-h4': '1.25rem',
		'font-size-h4-sm': '1.25rem',
		'font-size-h4-lg': '1.25rem',
		'font-size-h1-mobile': '1.5rem',
		'font-size-h1-mobile-sm': '1.5rem',
		'font-size-h1-mobile-lg': '1.5rem',
		'font-size-h2-mobile': '1.25rem',
		'font-size-h2-mobile-sm': '1.25rem',
		'font-size-h2-mobile-lg': '1.25rem',
		'font-size-h3-mobile': '1.125rem',
		'font-size-h3-mobile-sm': '1.125rem',
		'font-size-h3-mobile-lg': '1.125rem',
		'font-size-h4-mobile': '1rem',
		'font-size-h4-mobile-sm': '1rem',
		'font-size-h4-mobile-lg': '1rem',
		'font-size-blockquote': '1rem',
		'font-size-blockquote-sm': '0.875rem',
		'font-size-blockquote-lg': '1rem',
		'line-height': '1.5rem',
		'line-height-sm': '1.25rem',
		'line-height-lg': '1.5rem',
		'line-height-small': '1.25rem',
		'line-height-small-sm': '1.125rem',
		'line-height-small-lg': '1.25rem',
		'line-height-headings': '1.2',
		'line-height-headings-sm': '1.2rem',
		'line-height-headings-lg': '1.2rem',
		'line-height-blockquote': '1.5rem',
		'line-height-blockquote-sm': '1.25rem',
		'line-height-blockquote-lg': '1.5rem',
		'letter-spacing': '0px',
		'letter-spacing-sm': '0px',
		'letter-spacing-lg': '0px',
		'letter-spacing-small': '0px',
		'letter-spacing-small-sm': '0px',
		'letter-spacing-small-lg': '0px',
		'letter-spacing-headings': '0px',
		'letter-spacing-headings-sm': '0px',
		'letter-spacing-headings-lg': '0px',
		'letter-spacing-blockquote': '0px',
		'letter-spacing-blockquote-sm': '0px',
		'letter-spacing-blockquote-lg': '0px',
		gutter: '1rem',
		'gutter-sm': '0.5rem',
		'gutter-lg': '1rem',
		'min-height-input': '3rem',
		'min-height-input-sm': '2.125rem',
		'min-height-input-lg': '3.5rem',
		'py-input': '0.75rem',
		'py-input-sm': '0.375rem',
		'py-input-lg': '1rem',
		'px-input': '1rem',
		'px-input-sm': '0.625rem',
		'px-input-lg': '1rem',
		'py-btn': '0.375rem',
		'py-btn-sm': '0.5rem',
		'py-btn-lg': '0.5rem',
		'px-btn': '1rem',
		'px-btn-sm': '1rem',
		'px-btn-lg': '1rem',
		'py-btn-small': '0.28125rem',
		'py-btn-small-sm': '0.375rem',
		'py-btn-small-lg': '0.375rem',
		'px-btn-small': '0.75rem',
		'px-btn-small-sm': '0.75rem',
		'px-btn-small-lg': '0.75rem',
		'py-group-tabs': '0.75rem',
		'py-group-tabs-sm': '0.375rem',
		'py-group-tabs-lg': '1rem',
		'px-group-tabs': '1rem',
		'px-group-tabs-sm': '0.625rem',
		'px-group-tabs-lg': '1rem',
		'py-group-blocks': '1rem',
		'py-group-blocks-sm': '0.75rem',
		'py-group-blocks-lg': '1.25rem',
		'px-group-blocks': '1.25rem',
		'px-group-blocks-sm': '1rem',
		'px-group-blocks-lg': '1.5rem',
		'py-tag': '0.1875rem',
		'py-tag-sm': '0.125rem',
		'py-tag-lg': '0.1875rem',
		'px-tag': '0.675rem',
		'px-tag-sm': '0.5rem',
		'px-tag-lg': '0.75rem',
		'py-slider-tooltip': '0.25rem',
		'py-slider-tooltip-sm': '0.1875rem',
		'py-slider-tooltip-lg': '0.3125rem',
		'px-slider-tooltip': '0.5rem',
		'px-slider-tooltip-sm': '0.375rem',
		'px-slider-tooltip-lg': '0.625rem',
		'py-blockquote': '0.25rem',
		'py-blockquote-sm': '0.25rem',
		'py-blockquote-lg': '0.25rem',
		'px-blockquote': '0.75rem',
		'px-blockquote-sm': '0.75rem',
		'px-blockquote-lg': '0.75rem',
		'py-hr': '0.25rem',
		'space-addon': '0px',
		'space-addon-sm': '0px',
		'space-addon-lg': '0px',
		'space-checkbox': '0.5rem',
		'space-checkbox-sm': '0.5rem',
		'space-checkbox-lg': '0.625rem',
		'space-tags': '0.1875rem',
		'space-tags-sm': '0.1875rem',
		'space-tags-lg': '0.1875rem',
		'space-static-tag-1': '1rem',
		'space-static-tag-2': '2rem',
		'space-static-tag-3': '3rem',
		'floating-top': '0.75rem',
		'floating-top-sm': '0rem',
		'floating-top-lg': '0.875rem',
		'bg-input': 'var(--vf-gray-100)',
		'bg-input-hover': '#ececec',
		'bg-input-focus': '#dcdcdc',
		'bg-input-danger': 'var(--vf-gray-100)',
		'bg-input-success': 'var(--vf-gray-100)',
		'bg-checkbox': 'var(--vf-gray-100)',
		'bg-checkbox-hover': '#ececec',
		'bg-checkbox-focus': '#dcdcdc',
		'bg-checkbox-danger': 'var(--vf-gray-100)',
		'bg-checkbox-success': 'var(--vf-gray-100)',
		'bg-disabled': 'var(--vf-gray-50)',
		'bg-selected': '#1118270d',
		'bg-passive': 'var(--vf-gray-300)',
		'bg-icon': 'var(--vf-gray-700)',
		'bg-danger': 'var(--vf-danger-lighter)',
		'bg-success': 'var(--vf-success-lighter)',
		'bg-tag': 'var(--vf-primary)',
		'bg-slider-handle': 'var(--vf-primary)',
		'bg-toggle-handle': '#ffffff',
		'bg-date-head': 'var(--vf-gray-100)',
		'bg-addon': 'transparent',
		'bg-btn': 'var(--vf-primary)',
		'bg-btn-danger': 'var(--vf-danger)',
		'bg-btn-secondary': 'var(--vf-gray-200)',
		'color-input': 'var(--vf-gray-900)',
		'color-input-focus': 'var(--vf-gray-900)',
		'color-input-hover': 'var(--vf-gray-900)',
		'color-input-danger': 'var(--vf-gray-900)',
		'color-input-success': 'var(--vf-gray-900)',
		'color-disabled': '#afafaf',
		'color-placeholder': '#00000099',
		'color-passive': 'var(--vf-gray-700)',
		'color-muted': '#00000099',
		'color-floating': '#00000099',
		'color-floating-focus': 'var(--vf-primary)',
		'color-floating-success': 'var(--vf-success)',
		'color-floating-danger': 'var(--vf-danger)',
		'color-danger': 'var(--vf-danger)',
		'color-success': 'var(--vf-success)',
		'color-tag': 'var(--vf-color-on-primary)',
		'color-addon': 'inherit',
		'color-date-head': 'var(--vf-gray-700)',
		'color-btn': 'var(--vf-color-on-primary)',
		'color-btn-danger': '#ffffff',
		'color-btn-secondary': 'var(--vf-gray-700)',
		'border-color-input': 'var(--vf-gray-600)',
		'border-color-input-focus': 'var(--vf-primary)',
		'border-color-input-hover': 'var(--vf-gray-600)',
		'border-color-input-danger': 'var(--vf-danger)',
		'border-color-input-success': 'var(--vf-gray-600)',
		'border-color-checkbox': 'var(--vf-gray-600)',
		'border-color-checkbox-focus': 'var(--vf-primary)',
		'border-color-checkbox-hover': 'var(--vf-gray-600)',
		'border-color-checkbox-danger': 'var(--vf-danger)',
		'border-color-checkbox-success': 'var(--vf-gray-600)',
		'border-color-checked': 'var(--vf-primary)',
		'border-color-passive': 'var(--vf-gray-300)',
		'border-color-slider-tooltip': 'var(--vf-primary)',
		'border-color-tag': 'var(--vf-primary)',
		'border-color-btn': 'var(--vf-primary)',
		'border-color-btn-danger': 'var(--vf-danger)',
		'border-color-btn-secondary': 'var(--vf-gray-200)',
		'border-color-blockquote': 'var(--vf-gray-300)',
		'border-color-hr': 'var(--vf-gray-400)',
		'border-width-input-t': '0px',
		'border-width-input-r': '0px',
		'border-width-input-b': '1px',
		'border-width-input-l': '0px',
		'border-width-radio-t': '2px',
		'border-width-radio-r': '2px',
		'border-width-radio-b': '2px',
		'border-width-radio-l': '2px',
		'border-width-checkbox-t': '2px',
		'border-width-checkbox-r': '2px',
		'border-width-checkbox-b': '2px',
		'border-width-checkbox-l': '2px',
		'border-width-dropdown': '0px',
		'border-width-toggle': '0.25rem',
		'border-width-btn': '1px',
		'border-width-tag': '1px',
		'border-width-blockquote': '3px',
		'shadow-input': '0px 0px 0px 0px rgba(0,0,0,0)',
		'shadow-input-hover': '0px 0px 0px 0px rgba(0,0,0,0)',
		'shadow-input-focus': '0px 0px 0px 0px rgba(0,0,0,0)',
		'shadow-handles': '0px 0px 0px 0px rgba(0,0,0,0)',
		'shadow-handles-hover': '0px 0px 0px 9px rgba(0,0,0,0.15)',
		'shadow-handles-focus': '0px 0px 0px 9px rgba(0,0,0,0.15)',
		'shadow-btn': '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
		'shadow-dropdown': '0 4px 6px 0 rgb(32 33 36 / 28%)',
		'radius-input': '0.25rem 0.25rem 0rem 0rem',
		'radius-input-sm': '0.25rem 0.25rem 0rem 0rem',
		'radius-input-lg': '0.25rem 0.25rem 0rem 0rem',
		'radius-btn': '0.25rem',
		'radius-btn-sm': '0.25rem',
		'radius-btn-lg': '0.25rem',
		'radius-small': '0.125rem',
		'radius-small-sm': '0.125rem',
		'radius-small-lg': '0.125rem',
		'radius-large': '0.5rem 0.5rem 0rem 0rem',
		'radius-large-sm': '0.5rem 0.5rem 0rem 0rem',
		'radius-large-lg': '0.5rem 0.5rem 0rem 0rem',
		'radius-tag': '999px',
		'radius-tag-sm': '999px',
		'radius-tag-lg': '999px',
		'radius-checkbox': '0.25rem',
		'radius-checkbox-sm': '0.25rem',
		'radius-checkbox-lg': '0.25rem',
		'radius-slider': '1rem',
		'radius-slider-sm': '1rem',
		'radius-slider-lg': '1rem',
		'radius-image': '0.25rem 0.25rem 0rem 0rem',
		'radius-image-sm': '0.25rem 0.25rem 0rem 0rem',
		'radius-image-lg': '0.25rem 0.25rem 0rem 0rem',
		'radius-gallery': '0.25rem 0.25rem 0rem 0rem',
		'radius-gallery-sm': '0.25rem 0.25rem 0rem 0rem',
		'radius-gallery-lg': '0.25rem 0.25rem 0rem 0rem',
		'checkbox-size': '1rem',
		'checkbox-size-sm': '0.9375rem',
		'checkbox-size-lg': '1.125rem',
		'gallery-size': '6rem',
		'gallery-size-sm': '5rem',
		'gallery-size-lg': '7rem',
		'toggle-width': '3rem',
		'toggle-width-sm': '2.75rem',
		'toggle-width-lg': '3rem',
		'toggle-height': '1rem',
		'toggle-height-sm': '1.125rem',
		'toggle-height-lg': '1.25rem',
		'slider-height': '0.375rem',
		'slider-height-sm': '0.3125rem',
		'slider-height-lg': '0.4375rem',
		'slider-height-vertical': '20rem',
		'slider-height-vertical-sm': '20rem',
		'slider-height-vertical-lg': '20rem',
		'slider-handle-size': '1.25rem',
		'slider-handle-size-sm': '1.25rem',
		'slider-handle-size-lg': '1.4375rem',
		'slider-tooltip-distance': '0.625rem',
		'slider-tooltip-distance-sm': '0.625rem',
		'slider-tooltip-distance-lg': '0.625rem',
		'slider-tooltip-arrow-size': '0.375rem',
		'slider-tooltip-arrow-size-sm': '0.375rem',
		'slider-tooltip-arrow-size-lg': '0.375rem'
	},
	form: {
		nesting: true
	},
	export: {
		output: 'inline',
		api: 'options',
		theme: 'none'
	},
	builder: {
		name: 'MyForm'
	}
}

export const schemaHasPreferencesWithUnassignedChannelOrStatement = json => {
	if (typeof json !== 'object' || json === null) {
		return false
	}

	for (const key in json) {
		if (json.hasOwnProperty(key)) {
			const item = json[key]
			if (typeof item === 'object' && item.type === 'preference') {
				if (!item.channel || !item.statement) {
					return true
				}
				if (schemaHasPreferencesWithUnassignedChannelOrStatement(item)) {
					return true
				}
			}
		}
	}
	return false
}

export const schemaContainsPii = json => {
	if (typeof json !== 'object' || json === null) {
		return false
	}

	for (const key in json) {
		if (json.hasOwnProperty(key)) {
			if (key === 'dataSubject' && (json[key] === true)) {
				return true
			}
			if (typeof json[key] === 'object' && schemaContainsPii(json[key])) {
				return true
			}
		}
	}
	return false
}

export const schemaHasUnassignedHeaderOrFooter = json => {
	if (typeof json !== 'object' || json === null) {
		return false
	}

	for (const key in json) {
		if (json.hasOwnProperty(key)) {
			const item = json[key]
			if (typeof item === 'object' && !!item.name) {
				if ((item.name === 'cassieHeader' || item.name === 'cassieFooter') && !item.id) {
					return true
				}
				if (schemaHasUnassignedHeaderOrFooter(item)) {
					return true
				}
			}
		}
	}
	return false
}
