<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Settings
			</template>
			<template #body>
				<v-row
					dense
				>
					<v-col cols="6">
						<TextField
							:value="divId"
							label="Div ID"
							tooltip-text="The ID of the HTML DIV component on your web page where the Profile will be displayed"
							@input="updateDivId($event)"
						/>
					</v-col>
					<v-col cols="6">
						<Dropdown
							v-model="selectedAccessKey"
							:items="accessKeyItems"
							label="Access Key"
							tooltip-text="
								<p>Select an Embedded Consent Collection Access Key to allow the profile to access Cassie from your website</p>
								<p>New Access Keys can be created in the Access Keys module under the Embedded Consent Collection tab</p>
							"
							@input="getEmbedCode"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							v-model="selectedLanguage"
							label="Language"
							searchable
							tooltip-text="Select the Language that the Profile will display each Cassie Component in"
							:items="languageItems"
							@input="getEmbedCode"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<SectionCard v-if="embedCode">
			<template
				v-if="!enableCassieCloudEmbedCode"
				#title
			>
				<span>
					Embed Code: {{ selectedLanguageName }}
					<v-tooltip
						slot="append"
						top
					>
						<template #activator="{ on }">
							<v-icon
								light
								v-on="on"
							>
								mdi-information
							</v-icon>
						</template>
						<span>
							This embed code uses the domain of the page it is hosted on for the resource location, you must configure your DNS for this to work.  Please speak to your Account Manager for more details.
						</span>
					</v-tooltip>
				</span>
			</template>
			<template
				v-else
				#title
			>
				Embed Code: {{ selectedLanguageName }}
			</template>
			<template
				v-if="enableCassieCloudEmbedCode"
				#title-action
			>
				<Toggle
					:value.sync="dynamicEmbedCode"
					label="Dynamic Embed Code"
					tooltip-text="This will enable the embed code to use the domain of the hosting page for resource location, only enable this if your DNS configuration is complete for the hosting domain"
					@update:value="getEmbedCode"
				/>
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="10">
						<p class="mb-0">
							1 - On your web form, you need to create a div with a unique ID, as shown below (where we have pre-populated the value with the specified above). The content of this div must be left blank, this will load and display the Progressive Profling data.
						</p>
					</v-col>
					<v-spacer />
					<TextButton @click="copyToClipboard(divIdCode)">
						Copy
					</TextButton>
				</v-row>
				<v-row dense>
					<v-col
						style="font-family:'Monaco', monospace"
						cols="12"
					>
						<Prism
							:code="divIdCode"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="8">
						2 - Place this script in the header tag of your site
					</v-col>
					<v-col
						cols="4"
						class="text-right"
					>
						<TextButton
							@click="copyToClipboard(embedCode)"
						>
							Copy
						</TextButton>
						<TextButton
							v-if="dynamicEmbedCode"
							class="ml-2"
							@click="copyToClipboard(minifiedDynamicEmbedCode)"
						>
							Copy Minified
						</TextButton>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col
						style="font-family:'Monaco', monospace"
						cols="12"
					>
						<Prism
							:code="embedCode"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import Prism from 'vue-prism-component'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../../shared/components/toggle.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import TextButton from '../../../../../../shared/components/text-button.vue'
import { getPreferenceWidgetAccessKeys } from '../../../../../../shared/utils/api/access-keys.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import { getProgressiveProfilingEmbedCode } from '../../../../../../shared/utils/api/progressive-profiling.js'
import copyToClipboard from '../../../../../../shared/mixins/copy-to-clipboard.js'

export default {
	components: {
		SectionCard,
		Dropdown,
		Toggle,
		Prism,
		TextField,
		TextButton
	},
	mixins: [copyToClipboard],
	props: {
		progressiveProfile: Object,
		enableCassieCloudEmbedCode: Boolean
	},
	data () {
		return {
			domain: null,
			embedCode: '',
			accessKeys: [],
			assignedLanguages: [],
			selectedLanguage: 0,
			selectedAccessKey: null,
			dynamicEmbedCode: false,
			divId: '',
			minifiedDynamicEmbedCode: ''
		}
	},
	computed: {
		accessKeyItems () {
			const accessKeys = this.accessKeys.map(({ brandId, accessKey, accessKeyName }) => ({
				brandId,
				text: `${accessKeyName} - ${accessKey}`,
				value: accessKey.toUpperCase()
			}))
			return accessKeys.filter(({ brandId }) => brandId === this.progressiveProfile.brandId || brandId === 0)
		},
		selectedLanguageName () {
			return this.assignedLanguages.find(({ languageID }) => languageID === this.selectedLanguage)?.languageName
		},
		selectedLanguageCode () {
			return this.selectedLanguage === 0 ? '' : this.assignedLanguages.find(({ languageID }) => languageID === this.selectedLanguage)?.languageCode
		},
		languageItems () {
			return this.assignedLanguages.map(({ languageID, languageName }) => ({ value: languageID, text: languageName }))
		},
		divIdCode () {
			if (!this.divId) {
				return '<div id=""></div>'
			} else {
				return `<div id="${this.divId}"></div>`
			}
		}
	},
	async created () {
		await this.loadAccessKeys()
		await this.loadAssignedLanguages()
	},
	methods: {
		async loadAccessKeys () {
			const { preferenceWidgetAccessKeys } = await getPreferenceWidgetAccessKeys()
			this.accessKeys = preferenceWidgetAccessKeys
		},
		async loadAssignedLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		async getEmbedCode () {
			if (!this.selectedAccessKey) return
			// If EnableCassieCloudEmbedCode configkey is false, it is always dynamic embed code
			if (!this.enableCassieCloudEmbedCode) {
				this.dynamicEmbedCode = true
			}
			const { data } = await getProgressiveProfilingEmbedCode(
				this.progressiveProfile.id,
				this.dynamicEmbedCode,
				this.selectedAccessKey
			)
			this.embedCode = this.formatEmbedCode(data.embedCode)
			if (this.dynamicEmbedCode) {
				this.minifiedDynamicEmbedCode = this.formatEmbedCode(data.minifiedDynamicEmbedCode)
			}
		},
		formatEmbedCode (embedCode) {
			embedCode = embedCode.replace('[divId]', this.divId)
			embedCode = embedCode.replace('[languageCode]', this.selectedLanguageCode)
			return embedCode
		},
		updateDivId (value) {
			this.embedCode = this.embedCode.replace(`divId: "${this.divId}"`, `divId: "${value}"`)
			this.minifiedDynamicEmbedCode = this.embedCode.replace(`divId: "${this.divId}"`, `divId: "${value}"`)
			this.divId = value
		}
	}
}
</script>
