<template>
	<SectionCard>
		<template #title>
			Cookie Banner Templates
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<div class="cassie-vertical-md">
						<Dropdown
							:value.sync="cookieBanner.cookieCollectionTemplateId"
							:disabled="!userCanCreateUpdate"
							:items="collectionTemplateItems"
							searchable
							label="Cookie Collection Template *"
							rules="required"
							@input="selectCollectionTemplate($event)"
						/>
						<div v-if="cookieBanner.cookieCollectionTemplateId && cookieCollectionDescription">
							{{ cookieCollectionDescription }}
						</div>
					</div>
				</v-col>
				<v-col cols="6">
					<TextButton
						v-if="userCanCreateUpdate"
						@click="$emit('show-cookie-collection-manage-screen')"
					>
						<v-icon>
							mdi-plus
						</v-icon>
						Create
					</TextButton>
					<TextButton
						v-if="cookieBanner.cookieCollectionTemplateId && userCanCreateUpdate"
						@click="$emit('open-edit-collection-template-screen', cookieBanner.cookieCollectionTemplateId)"
					>
						<v-icon>
							mdi-pencil
						</v-icon>
						Edit Selected Collection Template
					</TextButton>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<div class="cassie-vertical-md">
						<Dropdown
							:value.sync="cookieBanner.cookieTextTemplateId"
							:disabled="!userCanCreateUpdate"
							:items="textTemplateItems"
							searchable
							label="Cookie Text Template *"
							rules="required"
							@input="updateBanner('cookieTextTemplateId', $event)"
						/>
						<div v-if="cookieBanner.cookieTextTemplateId && cookieTextDescription">
							{{ cookieTextDescription }}
						</div>
					</div>
				</v-col>
				<v-col cols="6">
					<TextButton
						v-if="userCanCreateUpdate"
						@click="$emit('show-cookie-text-manage-screen')"
					>
						<v-icon>
							mdi-plus
						</v-icon>
						Create
					</TextButton>
					<TextButton
						v-if="cookieBanner.cookieTextTemplateId && userCanCreateUpdate"
						@click="$emit('open-edit-text-template-screen', cookieBanner.cookieTextTemplateId)"
					>
						<v-icon>
							mdi-pencil
						</v-icon>
						Edit Selected Text Template
					</TextButton>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<div class="cassie-vertical-md">
						<Dropdown
							:value.sync="cookieBanner.cookieAppearanceTemplateId"
							:disabled="!userCanCreateUpdate"
							:items="appearanceTemplateItems"
							searchable
							label="Cookie Appearance Template *"
							rules="required"
							@input="updateBanner('cookieAppearanceTemplateId', $event)"
						/>
						<div v-if="cookieBanner.cookieAppearanceTemplateId && cookieAppearanceDescription">
							{{ cookieAppearanceDescription }}
						</div>
					</div>
				</v-col>
				<v-col cols="6">
					<TextButton
						v-if="userCanCreateUpdate"
						@click="$emit('show-cookie-appearance-manage-screen')"
					>
						<v-icon>
							mdi-plus
						</v-icon>
						Create
					</TextButton>
					<TextButton
						v-if="cookieBanner.cookieAppearanceTemplateId && userCanCreateUpdate"
						@click="$emit('open-edit-appearance-template-screen', cookieBanner.cookieAppearanceTemplateId)"
					>
						<v-icon>
							mdi-pencil
						</v-icon>
						Edit Selected Appearance Template
					</TextButton>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>
<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import TextButton from '../../../../../../../shared/components/text-button.vue'
import { UNIVERSAL_BRAND } from '../../../../../../../shared/state/brands.js'

export default {
	components: {
		SectionCard,
		Dropdown,
		TextButton
	},
	props: {
		cookieCollectionTemplates: {
			type: Array,
			default: () => []
		},
		cookieTextTemplates: {
			type: Array,
			default: () => []
		},
		cookieAppearanceTemplates: {
			type: Array,
			default: () => []
		},
		cookieBanner: Object,
		updateBanner: Function,
		isEdit: {
			type: Boolean,
			default: false
		},
		cloneMode: {
			type: Boolean,
			default: false
		},
		userCanCreateUpdate: Boolean
	},
	data () {
		return {
			selectedCookieScript: null,
			selectedCookieName: null
		}
	},
	computed: {
		collectionTemplateItems () {
			const collectionTemplateItems = this.cookieCollectionTemplates.map(({ name, id, brandId }) => ({
				text: name,
				value: id,
				brandId
			})).sort((a, b) => a.text.localeCompare(b.text))
			return this.cookieBanner.brandId === UNIVERSAL_BRAND
				? collectionTemplateItems
				: collectionTemplateItems.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.cookieBanner.brandId)
		},
		textTemplateItems () {
			const textTemplateItems = this.cookieTextTemplates.map(({ name, id, brandId }) => ({
				text: name,
				value: id,
				brandId
			})).sort((a, b) => a.text.localeCompare(b.text))
			return this.cookieBanner.brandId === UNIVERSAL_BRAND
				? textTemplateItems
				: textTemplateItems.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.cookieBanner.brandId)
		},
		appearanceTemplateItems () {
			const appearanceTemplateItems = this.cookieAppearanceTemplates.map(({ name, id, brandId }) => ({
				text: name,
				value: id,
				brandId
			})).sort((a, b) => a.text.localeCompare(b.text))
			return this.cookieBanner.brandId === UNIVERSAL_BRAND
				? appearanceTemplateItems
				: appearanceTemplateItems.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.cookieBanner.brandId)
		},
		cookieCollectionDescription () {
			return this.cookieCollectionTemplates.find(({ id }) => id === this.cookieBanner.cookieCollectionTemplateId)?.description
		},
		cookieTextDescription () {
			return this.cookieTextTemplates.find(({ id }) => id === this.cookieBanner.cookieTextTemplateId)?.description
		},
		cookieAppearanceDescription () {
			return this.cookieAppearanceTemplates.find(({ id }) => id === this.cookieBanner.cookieAppearanceTemplateId)?.description
		},
		templatesCardTitle () {
			return !this.cookieBanner.enableCis ? 'Cookie Banner Templates' : 'Cookie Text and Appearance Template'
		}
	},
	methods: {
		async selectCollectionTemplate (collectionTemplateId) {
			await this.updateBanner('cookieCollectionTemplateId', collectionTemplateId)
			const collectionTemplate = this.cookieCollectionTemplates.find(({ id }) => id === collectionTemplateId)
			await this.updateBanner('enableCis', collectionTemplate.crossDomainConsent)
			await this.updateBanner('allowedDomainsValue', !collectionTemplate.crossDomainConsent)
		}
	}
}
</script>
