<template>
	<ConsentCollectionLayout
		title="Drag-and-Drop Preference Pages"
		caption="Add, edit and manage your drap-and-drop preference pages"
	>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<PreferencePagesTab
						:preference-pages="preferencePages"
						:user-full-permissions="userFullPermissions"
						:user-has-brand="userHasBrand"
						:assigned-languages="assignedLanguages"
						@load="loadPreferencePages"
					/>
				</template>
				<template #1>
					<PreferencePageTranslationsTab
						:user-full-permissions="userFullPermissions"
						:user-has-brand="userHasBrand"
						:assigned-languages="assignedLanguages"
						@load="loadPreferencePages"
					/>
				</template>
			</TabItems>
		</template>
		<template #footer>
			<PageActionRow>
				<template #actions>
					<SecondaryActionButton @click="backToConsentCollection">
						Back to Consent Collection
					</SecondaryActionButton>
				</template>
			</PageActionRow>
		</template>
	</ConsentCollectionLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import ConsentCollectionLayout from '../consent-collection-layout.vue'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import PreferencePagesTab from './preference-pages-tab.vue'
import PreferencePageTranslationsTab from './preference-page-translations-tab.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import { brandOptions } from '../../../../../../shared/state/brands.js'
import { LINKS_AREA_FULL_PERMISSIONS, LINKS_PAGE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { getPreferencePages } from '../../../../../../shared/utils/api/preference-pages.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import { CONSENT_COLLECTION } from '../../../../router/route-names.js'

export default {
	components: {
		ConsentCollectionLayout,
		Tabs,
		TabItems,
		PreferencePagesTab,
		PreferencePageTranslationsTab,
		PageActionRow,
		SecondaryActionButton
	},
	props: {
		accessedViaTranslations: Boolean
	},
	data () {
		return {
			tabs: [{ title: 'Preference Pages', slot: '0' }, { title: 'Preference Page Translations', slot: '1' }],
			tab: '0',
			preferencePages: [],
			assignedLanguages: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)
		}
	},
	created () {
		this.loadPreferencePages()
		this.loadLanguages()
		if (this.accessedViaTranslations) {
			this.tab = '1'
		} else {
			this.tab = '0'
		}
	},
	methods: {
		async loadPreferencePages () {
			const { preferencePages } = await getPreferencePages()
			this.preferencePages = preferencePages
		},
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		userHasBrand (brandId) {
			return brandOptions.value?.filter(({ value }) => value === brandId).length > 0
		},
		backToConsentCollection () {
			this.$router.push({
				name: CONSENT_COLLECTION
			})
		}
	}
}
</script>
