var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{class:{
		'rounded-b-0': _vm.embedded,
	},attrs:{"value":_vm.tab,"height":_vm.embedded ? undefined : 70,"left":_vm.embedded,"grow":!_vm.embedded,"dark":_vm.embedded && !_vm.small},on:{"change":function($event){return _vm.$emit('update:tab', $event)}}},_vm._l((_vm.tabs),function(ref){
	var title = ref.title;
	var slot = ref.slot;
	var tabDisabled = ref.disabled;
return _c('v-tab',{key:slot,staticClass:"text-capitalize",class:{
			'px-10 py-5': _vm.embedded,
		},attrs:{"disabled":_vm.disabled || tabDisabled,"href":("#" + slot),"active-class":("" + (_vm.embedded ? '' : 'blue lighten-5 blue--text'))}},[_vm._v(" "+_vm._s(title)+" ")])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }