<template>
	<div
		v-if="translatedExtendedPreferences"
		class="ml-4"
	>
		<v-row
			v-for="extendedPreference in extendedPreferences"
			:key="extendedPreference.extendedPreferenceName"
			dense
		>
			<v-col
				cols="12"
				class="text-subtitle-2"
			>
				Extended Preference: {{ extendedPreference.extendedPreferenceName }}
				<v-tooltip
					right
				>
					<template #activator="{ on }">
						<v-icon
							light
							v-on="on"
						>
							mdi-information
						</v-icon>
					</template>
					<span>
						Dropdown Values relating to this Extended Preference can be translated in Components, Extended Preferences
					</span>
				</v-tooltip>
			</v-col>
			<template v-if="extendedPreference.showOnPage">
				<v-col cols="6">
					<TextField
						label="Default Label"
						disabled
						:value="extendedPreference.label"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Label"
						:disabled="!userFullPermissions"
						:value="getTranslatedExtendedPreferenceProperty(extendedPreference.extendedPreferenceName, 'label')"
						@input="updateExtendedPreference(extendedPreference.extendedPreferenceName, 'label', $event)"
					/>
				</v-col>
			</template>
			<template v-else>
				<v-col cols="6">
					<TextField
						label="Hidden on Page"
						disabled
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Hidden on Page"
						disabled
					/>
				</v-col>
			</template>
			<v-row
				v-if="extendedPreference.type === 'text'"
				class="mt-2 px-1"
				dense
			>
				<v-col cols="6">
					<TextField
						label="Default Value"
						:value="extendedPreference.default"
						disabled
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Default Value"
						:disabled="!userFullPermissions"
						:value="getTranslatedExtendedPreferenceProperty(extendedPreference.extendedPreferenceName, 'default')"
						@input="updateExtendedPreference(extendedPreference.extendedPreferenceName, 'default', $event)"
					/>
				</v-col>
			</v-row>
		</v-row>
	</div>
</template>

<script>
import TextField from '../../../../../../shared/components/text-field.vue'
export default {
	components: {
		TextField
	},
	props: {
		userFullPermissions: {
			type: Boolean,
			required: true
		},
		extendedPreferences: {
			type: Array,
			required: true
		},
		translatedExtendedPreferences: {
			type: Array,
			required: true
		}
	},
	methods: {
		getTranslatedExtendedPreferenceProperty (extendedPreferenceName, property) {
			const extendedPreference = this.translatedExtendedPreferences?.find(
				translatedExtendedPreference => translatedExtendedPreference?.extendedPreferenceName === extendedPreferenceName
			)
			return extendedPreference[property]
		},
		updateExtendedPreference (extendedPreferenceName, property, value) {
			const extendedPreferences = this.extendedPreferences
			const translatedExtendedPreference = this.translatedExtendedPreferences?.find(
				translatedExtendedPreference => translatedExtendedPreference?.extendedPreferenceName === extendedPreferenceName
			)
			translatedExtendedPreference[property] = value

			const extendedPreferencesIndex = this.translatedExtendedPreferences.findIndex(
				translatedExtendedPreference => translatedExtendedPreference?.extendedPreferenceName === extendedPreferenceName
			)

			this.$set(extendedPreferences, extendedPreferencesIndex, translatedExtendedPreference)
			this.$emit('update-extended-preferences', this.translatedExtendedPreferences)
		}
	}
}
</script>
