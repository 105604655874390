<template>
	<ProductRolesLayout	:product-id="productIdToView">
		<template #content>
			<FilterBar
				:search-query.sync="productRoleTextSearchQuery"
				search-query-label="Role Name"
				hide-brand-filter
				@persistSearchQuery="changeProductRoleTextSearchQuery"
			>
				<template #action>
					<PrimaryActionButton
						v-if="canCreateAndEdit"
						@click="onCreateClick"
					>
						<v-icon
							left
							dark
						>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</template>
			</FilterBar>
			<SectionCard>
				<template #title>
					User Roles
				</template>
				<template #body>
					<DataTable
						:items="filteredProductRoles"
						:headers="tableHeaders"
						sort-by="roleId"
						sort-desc
						@click:row="onEditClick"
					>
						<template #item.name="{ item }">
							{{ item.name }}
							<Chip
								v-if="item.isSystem"
								class="ml-2 coral-red white--text"
							>
								Cassie Template
							</Chip>
						</template>
						<template #item.action="{ item }">
							<IconButton
								v-if="!item.isSystem && canCreateAndEdit"
								tooltip-text="Edit Role"
								@click="onEditClick(item)"
							>
								mdi-pencil
							</IconButton>
							<IconButton
								v-if="item.isSystem || !canCreateAndEdit"
								tooltip-text="View Role"
								@click="onViewClick(item)"
							>
								mdi-eye
							</IconButton>
							<IconButton
								v-if="!item.activeUserCount && !item.isSystem && canDelete"
								tooltip-text="Delete Role"
								@click.stop.prevent="onRemoveClick(item)"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<Modal
				v-if="roleIdToBeRemoved"
				width="500px"
			>
				<template #modal-title>
					Delete {{ roleNameToBeRemoved }}
				</template>
				<template #modal-content>
					Are you sure you want to delete this User Role? <br>
					You will not be able to recover it if you confirm its deletion.
				</template>
				<template #modal-footer>
					<SecondaryActionButton @click="roleIdToBeRemoved = null">
						Close
					</SecondaryActionButton>
					<DeleteButton @click="deleteProductRole">
						Delete
					</DeleteButton>
				</template>
			</Modal>
		</template>
	</ProductRolesLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import ProductRolesLayout from './product-roles-layout.vue'
import FilterBar from '../filter-bar.vue'
import DataTable from '../data-table.vue'
import PrimaryActionButton from '../primary-action-button.vue'
import IconButton from '../icon-button.vue'
import SecondaryActionButton from '../secondary-action-button.vue'
import Modal from '../modal.vue'
import DeleteButton from '../delete-button.vue'
import SectionCard from '../section-card.vue'
import Chip from '../chip.vue'
import {
	CS_PORTAL_PRODUCT_ROLES_CREATE_ROLE,
	CS_PORTAL_PRODUCT_ROLES_EDIT_ROLE,
	ADMIN_PORTAL_PRODUCT_ROLES_CREATE_ROLE,
	ADMIN_PORTAL_PRODUCT_ROLES_EDIT_ROLE
} from '../../router/route-names.js'
import { deleteProductRole, getProductRoles } from '../../utils/api/product-roles.js'
import { showSnackbar } from '../../state/snackbar.js'
import {
	changeProductRoleTextSearchQuery,
	productRoleTextSearchQuery
} from '../../state/product-roles.js'
import {
	ADMINISTRATION_MODULE_FULL_PERMISSIONS,
	CAN_CREATE_AND_EDIT_ADMIN_PORTAL_PRODUCT_ROLES,
	CAN_DELETE_ADMIN_PORTAL_PRODUCT_ROLES,
	CS_ADMINISTRATION_MODULE_FULL_ACCESS,
	CAN_CREATE_AND_EDIT_CS_PORTAL_PRODUCT_ROLES,
	CAN_DELETE_CS_PORTAL_PRODUCT_ROLES,
	CS_PRODUCT_ROLES_MODULE_FULL_PERMISSIONS
} from '../../permissions/admin-portal-permissions.js'
import { CREATE_UPDATE_CSPORTAL_USERS } from '../../permissions/cs-portal-permissions.js'
export default {
	components: { SectionCard, FilterBar, DeleteButton, Modal, SecondaryActionButton, PrimaryActionButton, DataTable, ProductRolesLayout, IconButton, Chip },
	props: {
		productIdToView: Number,
		loggedInProductId: Number
	},
	setup () {
		return {
			showSnackbar,
			changeProductRoleTextSearchQuery,
			productRoleTextSearchQuery
		}
	},
	data () {
		return {
			productRoles: [],
			roleIdToBeRemoved: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'hasPermission']),
		tableHeaders () {
			return [
				{
					value: 'roleId',
					text: 'ID',
					width: '10%'
				},
				{
					value: 'name',
					text: 'Role Name',
					width: '70%'
				},
				{
					value: 'activeUserCount',
					text: 'No. of Members',
					width: '10%'
				},
				{
					value: 'action',
					text: 'Action',
					width: '10%',
					sortable: false
				}
			]
		},
		filteredProductRoles () {
			return this.productRoles.filter(({ name }) => !productRoleTextSearchQuery.value || name.toLowerCase().includes(productRoleTextSearchQuery.value.toLowerCase()))
		},
		roleNameToBeRemoved () {
			return this.productRoles.find(({ roleId }) => roleId === this.roleIdToBeRemoved)?.name
		},
		canCreateAndEdit () {
			if (this.loggedInProductId === 1) {
				if (this.productIdToView === 1) {
					return this.productAreaPermission(ADMINISTRATION_MODULE_FULL_PERMISSIONS) ||
						this.productAreaPermission(CAN_CREATE_AND_EDIT_ADMIN_PORTAL_PRODUCT_ROLES)
				}	else {
					return this.productAreaPermission(CAN_CREATE_AND_EDIT_CS_PORTAL_PRODUCT_ROLES) ||
						this.productAreaPermission(CS_ADMINISTRATION_MODULE_FULL_ACCESS) ||
						this.productAreaPermission(CS_PRODUCT_ROLES_MODULE_FULL_PERMISSIONS)
				}
			}	else {
				return this.hasPermission(CREATE_UPDATE_CSPORTAL_USERS)
			}
		},
		canDelete () {
			if (this.loggedInProductId === 1) {
				if (this.productIdToView === 1) {
					return this.productAreaPermission(ADMINISTRATION_MODULE_FULL_PERMISSIONS) ||
						this.productAreaPermission(CAN_DELETE_ADMIN_PORTAL_PRODUCT_ROLES)
				} else {
					return this.productAreaPermission(CAN_DELETE_CS_PORTAL_PRODUCT_ROLES) ||
						this.productAreaPermission(CS_ADMINISTRATION_MODULE_FULL_ACCESS) ||
						this.productAreaPermission(CS_PRODUCT_ROLES_MODULE_FULL_PERMISSIONS)
				}
			}	else {
				return this.hasPermission(CREATE_UPDATE_CSPORTAL_USERS)
			}
		}
	},
	async created () {
		if (this.productIdToView === 1 || this.productIdToView === 2) {
			this.getProductRoles(this.productIdToView)
		}
	},
	methods: {
		async getProductRoles (productIdToView) {
			const { data: productRoles } = await getProductRoles(productIdToView)
			this.productRoles = productRoles
		},
		onViewClick ({ roleId }) {
			this.$router.push({
				name: this.productIdToView === 1 ? ADMIN_PORTAL_PRODUCT_ROLES_EDIT_ROLE : CS_PORTAL_PRODUCT_ROLES_EDIT_ROLE,
				query: {
					roleId,
					productIdToView: this.productIdToView,
					loggedInProductId: this.loggedInProductId
				}
			})
		},
		onEditClick ({ roleId }) {
			this.$router.push({
				name: this.productIdToView === 1 ? ADMIN_PORTAL_PRODUCT_ROLES_EDIT_ROLE : CS_PORTAL_PRODUCT_ROLES_EDIT_ROLE,
				query: {
					roleId,
					productIdToView: this.productIdToView,
					loggedInProductId: this.loggedInProductId
				}
			})
		},
		onCreateClick () {
			this.$router.push({
				name: this.productIdToView === 1 ? ADMIN_PORTAL_PRODUCT_ROLES_CREATE_ROLE : CS_PORTAL_PRODUCT_ROLES_CREATE_ROLE,
				query: {
					productIdToView: this.productIdToView,
					loggedInProductId: this.loggedInProductId
				}
			})
		},
		async onRemoveClick ({ roleId }) {
			this.roleIdToBeRemoved = roleId
		},
		async deleteProductRole () {
			await deleteProductRole(this.roleIdToBeRemoved)
			this.getProductRoles(this.productIdToView)
			this.roleIdToBeRemoved = null
			this.showSnackbar('This role has been deleted')
		}
	}
}
</script>
