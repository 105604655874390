<template>
	<SectionCard>
		<template #title>
			{{ elementType.cardTitle }}
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						label="Default Channel"
						disabled
						:test-id="`${element.name}_default_channelName`"
						:value="element.channelName"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Channel"
						disabled
						:test-id="`${element.name}_translated_channelName`"
						tooltip-text="This can be translated in Components, Channels"
						:value.sync="translatedChannelName"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextField
						label="Default Statement"
						disabled
						:test-id="`${element.name}_default_statementText`"
						:value="element.statementText"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Statement"
						disabled
						:test-id="`${element.name}_translated_statementText`"
						tooltip-text="This can be translated in Components, Statements & Groups, Statements"
						:value.sync="translatedStatementText"
					/>
				</v-col>
			</v-row>
			<ExtendedPreferencesSection
				v-if="hasExtendedPreferences"
				:user-full-permissions="userFullPermissions"
				:extended-preferences.sync="element.extendedPreferences"
				:translated-extended-preferences.sync="translatedExtendedPreferences"
				@update-extended-preferences="updateTranslation(element.name, 'extendedPreferences', $event)"
			/>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import ExtendedPreferencesSection from './extended-preferences-section.vue'
export default {
	components: {
		SectionCard,
		TextField,
		ExtendedPreferencesSection
	},
	props: {
		element: {
			type: Object,
			required: true
		},
		elementType: {
			type: Object,
			required: true
		},
		translatedSchema: {
			type: Object,
			required: true
		},
		userFullPermissions: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		translatedChannelName () {
			return this.translatedSchema[this.element?.name]?.channelName
		},
		translatedStatementText () {
			return this.translatedSchema[this.element?.name]?.statementText
		},
		translatedExtendedPreferences () {
			return this.translatedSchema[this.element?.name]?.extendedPreferences ?? []
		},
		hasExtendedPreferences () {
			return this.element.extendedPreferences?.length > 0 && this.translatedExtendedPreferences?.length > 0
		}
	},
	methods: {
		updateTranslation (key, property, value) {
			this.$emit('update-translation', key, property, value)
		}
	}
}
</script>
