<template>
	<validation-observer #default="{ handleSubmit, valid }">
		<Modal
			width="1000px"
		>
			<template #modal-title>
				{{ 'Manage Source Codes' | useLabels }}
			</template>
			<template #modal-title-action>
				<TextButton
					:disabled="!valid"
					@click="addSourceCode"
				>
					<v-icon>
						mdi-plus
					</v-icon>
					{{ 'Add Source Code' | useLabels }}
				</TextButton>
			</template>
			<template #modal-content>
				<v-simple-table dense>
					<template #default>
						<thead>
							<tr>
								<th
									class="text-left"
									style="width: 30%;"
									scope="col"
								>
									{{ 'Source Code' | useLabels }}
								</th>
								<th
									class="text-left"
									style="width: 30%;"
									scope="col"
								>
									{{ 'Brand' | useLabels }}
								</th>
								<th
									class="text-left"
									style="width: 30%;"
									scope="col"
								>
									Default?
								</th>
								<th
									class="text-right"
									style="width: 10%;"
									scope="col"
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(sourceCode, index) in newSourceCodes"
								:key="index"
							>
								<td>
									<TextField
										v-model="sourceCode.sourceCodeName"
										small
										:label="'Source code *' | useLabels"
										class="py-2"
										:rules="{required: true, max:50}"
									/>
								</td>
								<td>
									<Dropdown
										v-model="sourceCode.brandId"
										:items="brandOptions"
										custom-sort
										small
										class="py-2"
										rules="required"
										:label="'Brand*' | useLabels"
										searchable
									/>
								</td>
								<td>
									<v-switch
										v-model="sourceCode.isDefault"
										:disabled="sourceCode.brandId == null"
										label="Is Default"
										class="mt-0"
										hide-details
										@change="handleDefaultChange(index)"
									/>
								</td>
								<td class="text-right">
									<IconButton
										small
										small-icon
										@click="removeSourceCode(index)"
									>
										mdi-trash-can
									</IconButton>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton @click="handleSubmit(submit)">
					Confirm
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import TextButton from '../../../../../../shared/components/text-button.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { brandOptions } from '../../../../../../shared/state/brands.js'
export default {
	components: { IconButton, Dropdown, TextField, PrimaryActionButton, SecondaryActionButton, Modal, TextButton },
	props: {
		sourceCodes: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			brandOptions
		}
	},
	data () {
		return {
			newSourceCodes: this.sourceCodes
		}
	},
	methods: {
		close () {
			this.$emit('close')
		},
		removeSourceCode (index) {
			this.newSourceCodes.splice(index, 1)
		},
		handleDefaultChange (index) {
			const thisSourceCode = this.newSourceCodes[index]
			if (thisSourceCode.isDefault) {
				this.newSourceCodes.filter(x => x.brandId === thisSourceCode.brandId).forEach((sourceCode, i) => {
					if (i !== index) {
						sourceCode.isDefault = false
					}
				})
			}
		},
		addSourceCode () {
			this.newSourceCodes = [...this.newSourceCodes, {
				sourceCodeName: null,
				brandId: null,
				isDefault: false
			}]
		},
		submit () {
			this.$emit('update:sourceCodes', this.newSourceCodes)
			this.close()
		}
	}
}
</script>
