<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="linkTranslationSearchQuery"
			:search-query-label="parentName"
			hide-brand-filter
			@persistSearchQuery="changeLinkTranslationSearchQuery"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					:value="linkTranslationsStatusFilter"
					label="Status"
					:items="translationStatusItems"
					custom-sort
					@input="changeLinkTranslationsStatusFilter($event)"
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ cardHeading }}
			</template>
			<template #title-action>
				<Dropdown
					:value="linksTranslationLanguageSelected"
					label="Language"
					searchable
					:items="assignedLanguagesItems"
					@input="changeLinksTranslationLanguageSelected($event)"
				/>
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredLinkTranslations"
					sort-by="linkId"
					sort-desc
					@click:row="manageTranslation"
				>
					<template #item.linkType="{ item }">
						{{ getLinkType(item) }}
					</template>
					<template #item.status="{ item }">
						{{ getTranslationStatus(item) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							tooltip-text="Manage Translation"
							@click="manageTranslation(item)"
						>
							{{ userFullPermissions && userHasBrand(item.brandId) ? 'mdi-pencil' : 'mdi-eye' }}
						</IconButton>
						<IconButton
							v-if="displayDeleteIcon(item) && userHasBrand(item.brandId)"
							tooltip-text="Delete Translation"
							@click.stop.prevent="linkTranslationToDelete = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="linkTranslationToDelete"
			:entity-name="`${linkTranslationToDelete.linkName} Translation`"
			entity-type="Link Translation"
			@close="linkTranslationToDelete = null"
			@delete="deleteLinkTranslation"
		/>
	</div>
</template>

<script>
import { format } from 'date-fns'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import {
	changeLinkTranslationSearchQuery,
	linkTranslationSearchQuery,
	linksTranslationLanguageSelected,
	changeLinksTranslationLanguageSelected,
	changeLinkTranslationsStatusFilter,
	linkTranslationsStatusFilter
} from '../../../../../../shared/state/links.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import { MANAGE_LINK_TRANSLATION } from '../../../../router/route-names.js'
import { DATE_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import { deleteLinkTranslation } from '../../../../../../shared/utils/api/admin-portal/links/links.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { userHasBrand } from '../../../../../../shared/state/brands.js'
import {
	CONFIRM_VALUE_AUTHENTICATION_TYPE,
	DIRECT_AUTHENTICATION_TYPE,
	PROVIDE_EMAIL_AUTHENTICATION_TYPE
} from '../link-authentication-types.js'
import { PREFERENCE_PAGE_LINK_TYPE } from '../link-types.js'
export default {
	components: {
		SectionCard,
		DataTable,
		FilterBar,
		Dropdown,
		IconButton,
		ConfirmDeleteModal
	},
	props: {
		preferencePageLinks: {
			type: Array,
			default: () => []
		},
		actionLinks: {
			type: Array,
			default: () => []
		},
		publicPortalLinks: {
			type: Array,
			default: () => []
		},
		linkTypes: {
			type: Array,
			default: () => []
		},
		userFullPermissions: Boolean,
		userReadOnly: Boolean
	},
	setup () {
		return {
			userHasBrand,
			showSnackbar,
			changeLinkTranslationSearchQuery,
			linkTranslationSearchQuery,
			linksTranslationLanguageSelected,
			changeLinksTranslationLanguageSelected,
			changeLinkTranslationsStatusFilter,
			linkTranslationsStatusFilter
		}
	},
	data () {
		return {
			assignedLanguages: [],
			selectedTranslationStatus: null,
			linkTranslationToDelete: null,
			NO_TRANSLATION: 1,
			PARTIALLY_TRANSLATED: 2,
			FULLY_TRANSLATED: 3
		}
	},
	computed: {
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		tableHeaders () {
			return [
				{ text: 'ID', value: 'linkId' },
				{ text: 'Brand', value: 'brandName' },
				{ text: this.parentName, value: 'linkName' },
				{ text: 'Status', value: 'amountOfFieldsTranslated' },
				{ text: 'Date Created', value: 'formattedCreatedDate' },
				{ text: 'Action', value: 'action', sortable: false }
			]
		},
		parentName () {
			if (this.preferencePageLinks.length) return 'Preference Page Name'
			else if (this.actionLinks.length) return 'Action Link Name'
			else if (this.publicPortalLinks.length) return 'Public Portal Link Name'
			else return ''
		},
		cardHeading () {
			if (this.preferencePageLinks.length) return 'Preference Page Translations'
			else if (this.actionLinks.length) return 'Action Link Translations'
			else if (this.publicPortalLinks.length) return 'Public Portal Link Translations'
			else return ''
		},
		linkTranslationItems () {
			const linkTranslations = [
				...this.preferencePageLinks,
				...this.actionLinks.filter(({ authenticationDetails }) => authenticationDetails?.authTypeId !== DIRECT_AUTHENTICATION_TYPE),
				...this.publicPortalLinks.filter(({ authenticationDetails }) => authenticationDetails?.authTypeId !== DIRECT_AUTHENTICATION_TYPE)
			].map(link => {
				const linkTranslation = link.linkTranslations?.find(({ languageId }) => languageId === linksTranslationLanguageSelected.value)
				const language = this.assignedLanguagesItems.find(({ value }) => value === linksTranslationLanguageSelected.value)
				const preferencePageTranslation = link.preferencePageTranslations?.find(({ languageId }) => languageId === linksTranslationLanguageSelected.value)
				return {
					...link,
					linkTranslationId: linkTranslation?.linkTranslationId,
					translatedVerificationAreaText: linkTranslation?.verificationAreaText,
					translatedVerificationFailedMessage: linkTranslation?.verificationFailedMessage,
					translatedVerificationSentMessage: linkTranslation?.verificationSentMessage,
					translatedStandDakAuthFieldFriendlyName: linkTranslation?.standDakAuthFieldFriendlyName,
					translatedPageTitle: preferencePageTranslation?.pageTitle,
					translatedPageText: preferencePageTranslation?.pageText,
					translatedGroupName: preferencePageTranslation?.groupName,
					translatedGroupDescription: preferencePageTranslation?.groupDescription,
					translatedSubGroupLeft: preferencePageTranslation?.subGroupLeft,
					translatedSubGroupRight: preferencePageTranslation?.subGroupRight,
					languageId: language?.value,
					languageName: language?.text,
					translationStatus: this.getAmountOfFieldsTranslated(
						this.linkTranslationFields(linkTranslation, preferencePageTranslation, link.linkTypeId),
						link.authenticationDetails?.authTypeId,
						true
					),
					amountOfFieldsTranslated: this.getAmountOfFieldsTranslated(
						this.linkTranslationFields(linkTranslation, preferencePageTranslation, link.linkTypeId),
						link.authenticationDetails?.authTypeId,
						false
					),
					extendedPreferenceTranslations: link.preferencePageLinkConfig?.extendedPrefs?.map((extendedPref, index) => ({
						index,
						preferenceUpdatePageStatementsId: extendedPref.preferenceUpdatePageStatementsID,
						pupsepId: extendedPref.pupsepId,
						languageId: language?.value,
						languageName: language?.text,
						extendedPreferenceKeyId: extendedPref.keyId,
						keyName: extendedPref.keyName,
						channelName: extendedPref.channelName,
						defaultFieldLabel: extendedPref.keyLabel,
						extendedPreferenceKeyLabel: extendedPref.translations?.find(({ languageId }) => languageId === language?.value)?.keyLabel
					})),
					createdDate: linkTranslation?.createdDate ? format(new Date(linkTranslation?.createdDate), DATE_FORMAT) : null
				}
			})

			return linkTranslations
		},
		filteredLinkTranslations () {
			return this.linkTranslationItems.filter(({ linkName, translationStatus }) => {
				let check = true
				if (linkTranslationSearchQuery.value) check = linkName.toLowerCase().includes(linkTranslationSearchQuery.value.toLowerCase())
				if (linkTranslationsStatusFilter.value) check = check && translationStatus === linkTranslationsStatusFilter.value
				return check
			})
		},
		translationStatusItems () {
			return [
				{ text: 'View All', value: null },
				{ text: 'No Translation', value: this.NO_TRANSLATION },
				{ text: 'Partially Translated', value: this.PARTIALLY_TRANSLATED },
				{ text: 'Translated', value: this.FULLY_TRANSLATED }
			]
		}
	},
	async created () {
		await this.loadLanguages()
		if (!linksTranslationLanguageSelected.value) {
			changeLinksTranslationLanguageSelected(this.assignedLanguagesItems[0]?.value)
		}
	},
	methods: {
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		manageTranslation (link) {
			this.$router.push({
				name: MANAGE_LINK_TRANSLATION,
				query: {
					linkType: link.linkTypeId
				},
				params: {
					userFullPermissions: this.userFullPermissions && userHasBrand(link.brandId),
					linkAuthType: link.authenticationDetails?.authTypeId,
					language: this.assignedLanguagesItems.find(({ value }) => value === linksTranslationLanguageSelected.value),
					linkTranslationToEdit: link
				}
			})
		},
		async deleteLinkTranslation () {
			await deleteLinkTranslation(this.linkTranslationToDelete.linkId, this.linkTranslationToDelete.languageId)
			this.linkTranslationToDelete = null
			showSnackbar('Link Translation Deleted')
			this.$emit('load-links')
		},
		linkTranslationFields (linkTranslation, preferencePageTranslation, linkTypeId) {
			const linkTranslationAuthFields = {
				translatedVerificationAreaText: linkTranslation?.verificationAreaText ?? null,
				translatedVerificationFailedMessage: linkTranslation?.verificationFailedMessage ?? null,
				translatedVerificationSentMessage: linkTranslation?.verificationSentMessage ?? null,
				translatedStandDakAuthFieldFriendlyName: linkTranslation?.standDakAuthFieldFriendlyName ?? null
			}

			const preferencePageFields = {
				translatedPageTitle: preferencePageTranslation?.pageTitle ?? null,
				translatedPageText: preferencePageTranslation?.pageText ?? null,
				translatedGroupName: preferencePageTranslation?.groupName ?? null,
				translatedGroupDescription: preferencePageTranslation?.groupDescription ?? null,
				translatedSubGroupLeft: preferencePageTranslation?.subGroupLeft ?? null,
				translatedSubGroupRight: preferencePageTranslation?.subGroupRight ?? null
			}

			if (linkTypeId === PREFERENCE_PAGE_LINK_TYPE) {
				return {
					...linkTranslationAuthFields,
					...preferencePageFields
				}
			} else {
				return linkTranslationAuthFields
			}
		},
		getTranslationStatus (totalAmountOfFields, translatedFields) {
			if (translatedFields === 0) return this.NO_TRANSLATION
			else if (translatedFields === totalAmountOfFields) return this.FULLY_TRANSLATED
			else return this.PARTIALLY_TRANSLATED
		},
		getAmountOfFieldsTranslated (link, authTypeId, returnAsStatus) {
			const linkTranslation = link

			// we need to remove the fields that are not relevant to the link auth type
			// if auth type is direct remove all auth fields
			if (authTypeId === DIRECT_AUTHENTICATION_TYPE) {
				delete linkTranslation.translatedStandDakAuthFieldFriendlyName
				delete linkTranslation.translatedVerificationAreaText
				delete linkTranslation.translatedVerificationFailedMessage
				delete linkTranslation.translatedVerificationSentMessage
			}
			// remove success message if auth type is confirm or provide email
			if (authTypeId === CONFIRM_VALUE_AUTHENTICATION_TYPE || authTypeId === PROVIDE_EMAIL_AUTHENTICATION_TYPE) {
				delete linkTranslation.translatedVerificationSentMessage
			}
			// get length of allfields and those that are not null
			const linkTranslationFields = Object.values(linkTranslation)
			const totalAmountOfFields = linkTranslationFields.length
			const translatedFields = linkTranslationFields.filter(field => field !== null).length
			return !returnAsStatus
				? `${translatedFields} / ${totalAmountOfFields} Field${this.displayPluralFieldsTranslated(translatedFields)} Translated`
				: this.getTranslationStatus(totalAmountOfFields, translatedFields)
		},
		displayPluralFieldsTranslated (translatedFields) {
			return translatedFields === 1 ? '' : 's'
		},
		getLinkType (link) {
			return this.linkTypes.find(({ linkTypeId }) => linkTypeId === link.linkTypeId)?.linkTypeName
		},
		clearFilters () {
			changeLinkTranslationsStatusFilter(null)
		},
		displayDeleteIcon (link) {
			return link.linkTranslationId &&
				this.userFullPermissions &&
				link.customDefaultLanguageId !== linksTranslationLanguageSelected.value &&
				link.translationStatus !== this.NO_TRANSLATION
		}
	}
}
</script>
