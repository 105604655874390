<template>
	<div>
		<SectionCard collapsible>
			<template #title>
				{{ getCategoryName(cookieCategory) }}
			</template>
			<template #title-action />
			<template #subtitle>
				<div
					v-dompurify-html="getCategoryDisplayText(cookieCategory)"
				/>
			</template>
			<template #body>
				<v-row
					v-if="userCanCreateUpdate"
					dense
				>
					<v-col cols="6">
						<Dropdown
							v-model="selectedScriptIds"
							:search-input.sync="searchInput"
							:label="`${getCategoryName(cookieCategory)} Cookie Scripts *`"
							multiple
							select-all
							searchable
							chips
							:items="availableCookieScriptItems"
							@change="updateSelectAllChecked"
						>
							<template
								v-if="showSelectAll"
								#prepend-item
							>
								<v-list-item
									dense
									@click="toggleSelectAll"
								>
									<v-checkbox
										v-model="selectAllChecked"
										dense
										@click="toggleSelectAll"
									/>
									<v-list-item-title class="pb-2">
										Select All
									</v-list-item-title>
								</v-list-item>
								<v-divider />
							</template>
						</Dropdown>
					</v-col>
					<v-col cols="3">
						<PrimaryActionButton
							:disabled="!selectedScriptIds.length"
							@click="addScripts"
						>
							Add
						</PrimaryActionButton>
					</v-col>
					<v-col
						class="d-flex justify-end"
						cols="3"
					>
						<TextButton
							:disabled="cookieCollectionTemplate.brandId === null"
							@click="showManageModal = true"
						>
							<v-icon>
								mdi-plus
							</v-icon>
							Create Script
						</TextButton>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<ValidationProvider
							#default="{ errors }"
							ref="cookieScriptValidationProvider"
							rules="atLeastOneCookieScript"
							vid="categoryCookieScripts"
						>
							<DataTable
								:headers="tableHeaders"
								:items="categoryCookieScripts"
							>
								<template #item.cookieScriptName="{ item }">
									{{ getCookieScriptName(item) }}
								</template>
								<template #item.isRunFirst="{ item }">
									<v-icon small>
										{{ getIsRunFirst(item) ? 'mdi-check' : 'mdi-close' }}
									</v-icon>
								</template>
								<template #item.gpcEnabled="{ item }">
									<v-icon small>
										{{ getGpcEnabled(item) ? 'mdi-check' : 'mdi-close' }}
									</v-icon>
								</template>
								<template #item.type="{ item }">
									{{ getCookieScriptType(item) }}
								</template>
								<template #item.displayOrder="{ index }">
									{{ index + 1 }}
								</template>
								<template
									v-if="userCanCreateUpdate"
									#item.action="{ item, index }"
								>
									<IconButton
										tooltip-text="Edit Script"
										@click="editCookieScript(item)"
									>
										mdi-pencil
									</IconButton>
									<IconButton
										v-if="index !== 0"
										@click="moveScriptUp(index, item)"
									>
										mdi-arrow-up
									</IconButton>
									<IconButton
										v-if="index !== categoryCookieScripts.length - 1"
										@click="moveScriptDown(index, item)"
									>
										mdi-arrow-down
									</IconButton>
									<IconButton
										@click="deleteScript(item)"
									>
										mdi-trash-can
									</IconButton>
								</template>
							</DataTable>
							<span
								v-if="errors.length"
								class="error-message"
							>
								{{ errors[0] }}
							</span>
						</ValidationProvider>
					</v-col>
				</v-row>
			</template>
		</SectionCard>

		<CookieScriptModal
			v-if="showManageModal || cookieScriptToEdit"
			:cookie-script-to-edit="cookieScriptToEdit"
			:cookie-category-id="getCategoryId(cookieCategory)"
			:cookie-categories="cookieCategories"
			:transaction-types="transactionTypes"
			:security-levels="securityLevels"
			:channel-device-copy-actions="channelDeviceCopyActions"
			accessed-via-cookie-banner-configuration
			:cookie-collection-brand-id="cookieCollectionTemplate.brandId"
			:user-full-permissions="true"
			:gtm-cookie-scripts="gtmCookieScripts"
			@get-new-script-id="addNewScriptToCollection"
			@close="closeModal"
			@submit="submitCookieScript"
		/>
	</div>
</template>

<script>
import { extend, ValidationProvider } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import TextButton from '../../../../../../../shared/components/text-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import CookieScriptModal from '../cookie-scripts/cookie-script-modal.vue'
import { getCookieScript } from '../../../../../../../shared/utils/api/cookies/cookie-scripts.js'
import { validationObservers } from '../../../../../../../shared/state/validation-observers.js'

extend('atLeastOneCookieScript', {
	...required,
	validate (value) {
		return value && value.length > 0
	},
	message: 'Please select at least one cookie script.'
})

export default {
	components: {
		SectionCard,
		TextButton,
		Dropdown,
		PrimaryActionButton,
		DataTable,
		IconButton,
		CookieScriptModal,
		ValidationProvider
	},
	props: {
		cookieCollectionTemplate: Object,
		cookieCategoryScripts: Array,
		cookieCategories: Array,
		transactionTypes: Array,
		securityLevels: Array,
		channelDeviceCopyActions: Array,
		allCookieScripts: Array,
		cookieCategory: Object,
		userCanCreateUpdate: Boolean
	},
	data () {
		return {
			showManageModal: false,
			cookieScriptToEdit: null,
			selectedScriptIds: [],
			selectAllChecked: false,
			searchInput: null
		}
	},
	computed: {
		availableCookieScriptItems () {
			const existingCookieScripts = this.categoryCookieScripts.map(({ id }) => id)
			return this.cookieCategoryScripts.filter(({ value }) => !existingCookieScripts.includes(value)).sort((a, b) => a.text.localeCompare(b.text))
		},
		tableHeaders () {
			return [
				{ text: 'ID', value: 'id' },
				{ text: 'Cookie Script Name', value: 'cookieScriptName' },
				{ text: 'Run First?', value: 'isRunFirst' },
				{ text: 'GPC', value: 'gpcEnabled' },
				{ text: 'Type', value: 'type' },
				{ text: 'Display Order', value: 'displayOrder' },
				{ text: 'Action', value: 'action' }
			]
		},
		categoryCookieScripts () {
			return this.cookieCollectionTemplate?.cookieScripts?.filter(script => script?.categoryId === this.cookieCategory?.categoryId)
		},
		strictlyScripts () {
			const scripts = this.cookieCollectionTemplate.cookieScripts.map(script => ({
				...script,
				isStrictlyNecessary: this.strictlyNecessaryScript(script.id)
			})).filter(({ isStrictlyNecessary }) => isStrictlyNecessary)
			return scripts
		},
		showSelectAll () {
			return this.availableCookieScriptItems.length > 1
		},
		gtmCookieScripts () {
			return this.allCookieScripts.filter(({ gtmEnabled }) => gtmEnabled).map(cookieScript => {
				return {
					cookieCategoryId: cookieScript.cookieCategoryId,
					cookieScriptId: cookieScript.cookieScriptId,
					gtmConsentTypeId: cookieScript.gtmConsentTypeId,
					cookieCategoryName: cookieScript.cookieCategoryName
				}
			})
		}
	},
	watch: {
		categoryCookieScripts: 'validateCategoryScripts'
	},
	methods: {
		addScripts () {
			const selectedScriptIds = this.selectedScriptIds.map(script => ({
				id: script,
				categoryId: this.cookieCategory.categoryId
			}))
			this.$emit('update:cookieScripts', [
				...this.cookieCollectionTemplate.cookieScripts,
				...selectedScriptIds
			])
			this.selectAllChecked = false
			this.searchInput = null
			this.selectAllChecked = false
			// solves issue where we try to assign run order before the cookie scripts have been emitted
			setTimeout(() => {
				selectedScriptIds.forEach(script => {
					this.assignRunOrder(script)
				})
			}, 0)
		},
		validateCategoryScripts () {
			validationObservers.value[0].errors.categoryCookieScripts = []
			this.$refs.cookieScriptValidationProvider?.validate(this.categoryCookieScripts)
		},
		addNewScriptToCollection (newScriptId) {
			const newScript = {
				id: newScriptId,
				categoryId: this.cookieCategory.categoryId
			}
			this.$emit('update:cookieScripts', [
				...this.cookieCollectionTemplate.cookieScripts,
				{
					id: newScriptId,
					categoryId: this.cookieCategory.categoryId
				}
			])
			this.assignRunOrder(newScript)
		},
		getCategoryId (category) {
			return category?.categoryId
		},
		getCategoryName (category) {
			return category?.categoryName
		},
		getCategoryDisplayText (category) {
			return category?.displayText
		},
		getCookieScriptName ({ id }) {
			const matchedScript = this.allCookieScripts.find(script => script.id === id)
			return `${matchedScript?.cookieScriptName} - ${matchedScript?.brandName}`
		},
		getIsRunFirst ({ id }) {
			return this.allCookieScripts.find(script => script.id === id)?.isRunFirst
		},
		getGpcEnabled ({ id }) {
			return this.allCookieScripts.find(script => script.id === id)?.gpcEnabled
		},
		getCookieScriptType ({ id }) {
			return this.allCookieScripts.find(script => script.id === id)?.cookieScriptType
		},
		moveScriptDown (index, { id: scriptId }) {
			const cookieScriptCategoryId = this.allCookieScripts.find(({ id }) => id === scriptId)?.cookieCategoryId
			this.move(this.categoryCookieScripts, index, index + 1, cookieScriptCategoryId)
		},
		moveScriptUp (index, { id: scriptId }) {
			const cookieScriptCategoryId = this.allCookieScripts.find(({ id }) => id === scriptId)?.cookieCategoryId
			this.move(this.categoryCookieScripts, index, index - 1, cookieScriptCategoryId)
		},
		applySort (array) {
			// const allOtherScripts
			this.$emit('update:cookieScripts', array)
		},
		move (array, from, to, cookieScriptCategoryId) {
			// find item category id and + 1 the index, then find the next item with that category id and - 1 the index
			const cookieScriptsNotInSelectedCategory = this.cookieCollectionTemplate.cookieScripts.filter(({ categoryId }) => categoryId !== cookieScriptCategoryId)
			const arrayCopy = JSON.parse(JSON.stringify(array))
			arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0])
			return this.applySort([
				...arrayCopy,
				...cookieScriptsNotInSelectedCategory
			])
		},
		assignRunOrder (script) {
			const runOrderScripts = this.cookieCollectionTemplate.runOrderScripts
			let insertAtIndex = 0
			if (this.strictlyNecessaryScript(script.id) && this.runFirstScript(script.id)) {
				runOrderScripts.splice(insertAtIndex, 0, script)
				this.$emit('update:runOrderScripts', runOrderScripts)
			} else if (this.strictlyNecessaryScript(script.id) && !this.runFirstScript(script.id)) {
				insertAtIndex = this.strictlyScripts.length
				runOrderScripts.splice(insertAtIndex, 0, script)
				this.$emit('update:runOrderScripts', runOrderScripts)
			} else if (!this.strictlyNecessaryScript(script.id) && this.runFirstScript(script.id)) {
				insertAtIndex = this.strictlyScripts.length
				runOrderScripts.splice(insertAtIndex, 0, script)
				this.$emit('update:runOrderScripts', runOrderScripts)
			} else {
				runOrderScripts.push(script)
				this.$emit('update:runOrderScripts', runOrderScripts)
			}
			this.selectedScriptIds = []
		},
		// editCookieScript (script) {
		// },
		deleteScript ({ id }) {
			const deletedScriptIndex = this.cookieCollectionTemplate.cookieScripts.findIndex(script => script.id === id)
			const cookieScripts = this.cookieCollectionTemplate.cookieScripts
			const runOrderScripts = this.cookieCollectionTemplate.runOrderScripts
			const deletedRunOrderScriptIndex = this.cookieCollectionTemplate.runOrderScripts.findIndex(script => script.id === id)
			this.$delete(cookieScripts, deletedScriptIndex)
			this.$delete(runOrderScripts, deletedRunOrderScriptIndex)
			this.$emit('update:cookieScripts', cookieScripts)
			this.$emit('update:runOrderScripts', runOrderScripts)
		},
		strictlyNecessaryScript (scriptId) {
			return this.allCookieScripts.find(({ id }) => id === scriptId)?.cookieScriptType === 'Essential'
		},
		runFirstScript (scriptId) {
			return this.allCookieScripts.find(({ id }) => id === scriptId)?.isRunFirst
		},
		toggleSelectAll () {
			if (this.selectAllChecked) {
				this.selectAllChecked = false
				this.selectedScriptIds = []
			} else {
				this.selectAllChecked = true
				if (this.searchInput) {
					this.selectedScriptIds = this.availableCookieScriptItems.filter(script => script.text.toLowerCase().includes(this.searchInput.toLowerCase())).map(script => script.value)
				} else {
					this.selectedScriptIds = this.availableCookieScriptItems.map(script => {
						return script.value
					})
				}
			}
		},
		updateSelectAllChecked () {
			const filteredItems = this.searchInput
				? this.availableCookieScriptItems.filter(script => script.text.toLowerCase().includes(this.searchInput.toLowerCase()))
				: this.availableCookieScriptItems

			this.selectAllChecked = this.selectedScriptIds.length === filteredItems.length
		},
		closeModal () {
			this.showManageModal = false
			this.cookieScriptToEdit = null
		},
		async editCookieScript (script) {
			const cookieScriptChannelId = this.allCookieScripts.find(({ id }) => id === script.id)?.cookieScriptChannelId
			const cookieScriptToEdit = await getCookieScript(cookieScriptChannelId)
			this.cookieScriptToEdit = cookieScriptToEdit
		},
		submitCookieScript () {
			this.closeModal()
			this.$emit('load-cookie-scripts')
			// add check to add into run order table when creating new script
		}
	}
}

</script>
<style lang="scss">
.error-message {
	color: red;
}
</style>
