<template>
	<SectionCard>
		<template #title>
			{{ chart.title }}
		</template>
		<template #body>
			<component
				:is="chart.chartType"
				v-if="chartHasData"
				:chart-data="chart.chartData"
				:chart-options="chart.chartOptions"
			/>
			<template v-else>
				<p>
					No data available for selected filters.
				</p>
			</template>
		</template>
	</SectionCard>
</template>
<script>

import SectionCard from '../../../../../../shared/components/section-card.vue'
import LineChart from '../../../../../../shared/components/chart/line-chart.vue'
import BarChart from '../../../../../../shared/components/chart/bar-chart.vue'
import RadarChart from '../../../../../../shared/components/chart/radar-chart.vue'
import PolarChart from '../../../../../../shared/components/chart/polar-chart.vue'
import PieChart from '../../../../../../shared/components/chart/pie-chart.vue'
import DoughnutChart from '../../../../../../shared/components/chart/doughnut-chart.vue'

export default {
	components: {
		BarChart,
		LineChart,
		RadarChart,
		PolarChart,
		PieChart,
		DoughnutChart,
		SectionCard
	},
	props: {
		items: Array,
		selected: Function,
		chart: Object
	},
	data () {
		return {
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false
			}
		}
	},
	computed: {
		chartHasData () {
			return this.chart.chartData.datasets.length
		}
	}
}
</script>
