<template>
	<div class="cassie-vertical-md">
		<ProgressiveProfilePreviewCard
			:header="primaryWidgetHeader"
			:footer="primaryWidgetFooter"
			:statements="statements"
			:colour-scheme="progressiveProfile.colourSchemeJson"
		/>
		<SectionCard>
			<template #title>
				Banner
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<RadioGroup
							radio-group-label="Colour Scheme *"
							:radio-group-value.sync="colourSchemeIdentifier"
							:radios="COLOUR_SCHEME_RADIOS"
							:disabled="!userCanCreateAndUpdate"
							@update:radioGroupValue="selectColourScheme($event)"
						/>
						<div class="mt-2">
							<ColourPicker
								v-if="isCustomScheme"
								:elements.sync="customColourSchemeButtons"
								:disabled="!userCanCreateAndUpdate"
								@update-element-changed="
									updateColourChanged"
							/>
						</div>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Behaviors
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							:value.sync="progressiveProfile.executionOptionId"
							:disabled="!userCanCreateAndUpdate"
							:items="executionOptions"
							label="Execution Type *"
							rules="required"
							:tooltip-text="executionTypeTooltipText"
							@input="updateProgressiveProfile('executionOptionId', $event)"
						/>
					</v-col>
					<v-col
						v-if="showTimeOnPageField"
						cols="6"
					>
						<TextField
							:value.sync="progressiveProfile.timeOnPageInSeconds"
							:disabled="!userCanCreateAndUpdate"
							label="Time on Page (In Seconds)"
							type="number"
							tooltip-text="Specify the length of time that the profile will wait until being displayed on your webpage"
							@input="updateProgressiveProfile('timeOnPageInSeconds', $event)"
						/>
					</v-col>
					<v-col
						v-if="actionBasedSelected"
						cols="6"
					>
						<TextField
							:value.sync="progressiveProfile.cssSelector"
							:disabled="!userCanCreateAndUpdate"
							label="Webpage Class or ID"
							tooltip-text="
							<p>Specify the Class or ID of the object in your webpage which will prompt the profile</p>
							<p>Class fields should be prefixed with '.' e.g. '.class_button'</p>
							<p>ID fields should be prefixed with '#' e.g. '#id_button'</p>
							"
							type="string"
							@input="updateProgressiveProfile('cssSelector', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							:value.sync="progressiveProfile.displayTypeId"
							:disabled="!userCanCreateAndUpdate"
							:items="displayTypes"
							label="Display Type"
							rules="required"
							tooltip-text="The Display Type determines how this Profile will render on the page this is deployed to"
							@input="updateProgressiveProfile('displayTypeId', $event)"
						/>
					</v-col>
					<v-col cols="6">
						<Dropdown
							:value.sync="progressiveProfile.profileBannerLocationId"
							:disabled="!userCanCreateAndUpdate"
							:items="bannerLocations"
							label="Location On Page *"
							rules="required"
							tooltip-text="The location on the webpage where the banner will load"
							@input="
								updateProgressiveProfile('profileBannerLocationId', $event)"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<Toggle
							:value.sync="progressiveProfile.restrictToOneWidgetPerVisit"
							:disabled="!userCanCreateAndUpdate"
							tooltip-text="If enabled, this will prompt the end user with only one of the configured Embedded Consent Collections each time the profile is activated"
							label="Limit to one response per visit"
							@input="updateProgressiveProfile('restrictToOneWidgetPerVisit', $event)"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
	</div>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../../shared/components/toggle.vue'
import RadioGroup from '../../../../../../shared/components/radio-group.vue'
import ColourPicker from '../../../../../../shared/components/colour-picker.vue'
import ProgressiveProfilePreviewCard from './progressive-profile-preview-card.vue'
import { EXECUTION_OPTIONS } from './execution-options.js'
import { COLOUR_SCHEME_RADIOS, COLOUR_SCHEME_IDENTIFIERS, COLOUR_SCHEMES, CUSTOM_COLOUR_SCHEME } from './profile-appearance.js'
export default {
	components: {
		SectionCard,
		RadioGroup,
		ColourPicker,
		TextField,
		Dropdown,
		Toggle,
		ProgressiveProfilePreviewCard
	},
	props: {
		progressiveProfile: Object,
		userCanCreateAndUpdate: Boolean,
		executionOptions: Array,
		bannerLocations: Array,
		displayTypes: Array,
		preferenceWidgets: Array,
		headers: Array,
		footers: Array,
		channels: Array
	},
	setup () {
		return {
			COLOUR_SCHEME_RADIOS,
			CUSTOM_COLOUR_SCHEME,
			COLOUR_SCHEME_IDENTIFIERS
		}
	},
	data () {
		return {
			colourSchemeIdentifier: 0,
			customColourSchemeButtons: CUSTOM_COLOUR_SCHEME
		}
	},
	computed: {
		isCustomScheme () {
			return this.colourSchemeIdentifier === COLOUR_SCHEME_IDENTIFIERS.CUSTOM
		},
		showTimeOnPageField () {
			return this.progressiveProfile.executionOptionId === EXECUTION_OPTIONS.TIME_BASED_DISPLAY
		},
		executionTypeTooltipText () {
			if (this.progressiveProfile.executionOptionId === EXECUTION_OPTIONS.IMMEDIATE_DISPLAY) {
				return 'This will display the profile immediately on page load once the Cassie Cookie Banner has been interacted with'
			} else if (this.progressiveProfile.executionOptionId === EXECUTION_OPTIONS.TIME_BASED_DISPLAY) {
				return 'This will display the profile after a specified amount of time'
			} else if (this.progressiveProfile.executionOptionId === EXECUTION_OPTIONS.ACTION_BASED_DISPLAY) {
				return 'The profile will be displayed after the end user has interacted with the Class or ID configured'
			} else return 'Select when you would like this profile to load on page'
		},
		actionBasedSelected () {
			return this.progressiveProfile.executionOptionId === EXECUTION_OPTIONS.ACTION_BASED_DISPLAY
		},
		primaryWidget () {
			return this.preferenceWidgets.find(widget => widget.preferenceWidgetId === this.progressiveProfile.primaryWidgetId)
		},
		primaryWidgetHeader () {
			return this.headers.find(header => header.id === this.primaryWidget.headerId)?.html
		},
		primaryWidgetFooter () {
			return this.footers.find(footer => footer.id === this.primaryWidget.footerId)?.html
		},
		colourScheme () {
			return this.progressiveProfile.colourSchemeJson ?? COLOUR_SCHEMES.LIGHT
		},
		statements () {
			return this.primaryWidget.statementGroups.map(group => {
				return group.statements[0].statementText
			}).flat()
		}
	},
	created () {
		this.colourSchemeIdentifier = this.progressiveProfile.colourSchemeJson.Identifier
		if (this.isCustomScheme) {
			this.customColourSchemeButtons.forEach(element => {
				element.color = this.progressiveProfile.colourSchemeJson[element.id]
			})
		}
	},
	methods: {
		selectColourScheme (value) {
			if (value === COLOUR_SCHEME_IDENTIFIERS.DARK) {
				this.updateProgressiveProfile('colourSchemeJson', COLOUR_SCHEMES.DARK)
			} else if (value === COLOUR_SCHEME_IDENTIFIERS.LIGHT) {
				this.updateProgressiveProfile('colourSchemeJson', COLOUR_SCHEMES.LIGHT)
			} else {
				this.updateProgressiveProfile('colourSchemeJson', COLOUR_SCHEMES.CUSTOM)
			}
		},
		updateColourChanged (element) {
			const colourSchemeJson = this.progressiveProfile.colourSchemeJson
			colourSchemeJson[element.id] = element.colour
			this.updateProgressiveProfile('colourSchemeJson', colourSchemeJson)
		},
		updateProgressiveProfile (property, value) {
			this.$emit('update:progressiveProfile', {
				...this.progressiveProfile,
				[property]: value
			})
		}
	}
}
</script>
