<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="cassie-vertical-md"
	>
		<AdminPortalApiUsersLayout>
			<template #content>
				<SectionCard>
					<template #title>
						{{ isEdit ? 'Edit' : 'Create New' }} API User
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<TextField
									v-model="apiUser.username"
									label="Username *"
									rules="required"
									:readonly="!fullPermission"
								/>
							</v-col>
							<v-col v-if="isEdit">
								<SecondaryActionButton
									v-if="fullPermission"
									@click="showPasswordFields = !showPasswordFields"
								>
									change password
								</SecondaryActionButton>
							</v-col>
						</v-row>
						<div v-if="showPasswordFields && fullPermission">
							<v-row dense>
								<v-col cols="6">
									<PasswordField
										:id="String(Math.random())"
										v-model="apiUser.password"
										:rules="{ password: true }"
										label="New Password *"
										field-name="New Password"
									/>
								</v-col>
								<v-col cols="6">
									<PasswordField
										:id="String(Math.random())"
										v-model="apiUser.confirmPassword"
										:rules="{ password: true, confirmed: 'New Password' }"
										label="Confirm New Password"
									/>
								</v-col>
								<p class="mt-2 ml-2 text-caption">
									Passwords must be at least 12 characters, have at least one uppercase character, one number and one special character.
								</p>
							</v-row>
						</div>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						API Key
					</template>
					<template #body>
						<OptionSelector
							:selected.sync="apiUser.apiKeyIds"
							:options="apiKeyOptions"
							dropdown-label="API Key *"
							entity-type="API Key"
							searchable
							class="cassie-input-width-md"
							:disabled="!fullPermission"
							required
						/>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<div class="d-flex">
					<SecondaryActionButton
						class="mr-4"
						@click="navigateToOverview"
					>
						Back to Overview
					</SecondaryActionButton>
					<v-spacer />
					<PrimaryActionButton
						v-if="isEdit && fullPermission"
						@click="handleSubmit(saveUser)"
					>
						Save
					</PrimaryActionButton>
					<PrimaryActionButton
						v-else-if="!isEdit && fullPermission"
						@click="handleSubmit(createUser)"
					>
						Create
					</PrimaryActionButton>
				</div>
			</template>
		</AdminPortalApiUsersLayout>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import { ADMIN_PORTAL_API_USERS } from '../../../../../router/route-names.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import AdminPortalApiUsersLayout from './admin-portal-api-users-layout.vue'
import OptionSelector from '../../../../../../../shared/components/option-selector.vue'
import { upsertApiUser } from '../../../../../../../shared/utils/api/api-users.js'
import { getApiKeys } from '../../../../../../../shared/utils/api/api-keys.js'
import TextField from '../../../../../../../shared/components/text-field.vue'
import PasswordField from '../../../../../../../shared/components/password-field.vue'
import { SYSTEM_USERS_MODULE_FULL_PERMISSIONS } from '../../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		TextField,
		PasswordField,
		OptionSelector,
		AdminPortalApiUsersLayout,
		PrimaryActionButton,
		SecondaryActionButton,
		ValidationForm,
		SectionCard
	},
	props: {
		apiUserToEdit: Object
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			apiUser: JSON.parse(JSON.stringify(this.apiUserToEdit || {
				username: '',
				apiKeyIds: [],
				password: null,
				confirmPassword: null
			})),
			apiKeyOptions: [],
			showPasswordFields: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		isEdit () {
			return Boolean(this.apiUserToEdit)
		},
		fullPermission () {
			return this.productAreaPermission(SYSTEM_USERS_MODULE_FULL_PERMISSIONS)
		}
	},
	async created () {
		const { data: apiKeys } = await getApiKeys()
		this.apiKeyOptions = apiKeys.map(({ apiKeyId, apiKeyName }) => ({ value: apiKeyId, text: apiKeyName }))
		if (!this.isEdit) {
			this.showPasswordFields = true
		}
	},
	methods: {
		navigateToOverview () {
			this.$router.push({ name: ADMIN_PORTAL_API_USERS })
		},
		async saveUser () {
			await upsertApiUser(this.apiUser)
			this.navigateToOverview()
			showSnackbar('This API User has been saved')
		},
		async createUser () {
			await upsertApiUser(this.apiUser)
			this.navigateToOverview()
			showSnackbar('This API User has been created')
		}
	}
}
</script>
