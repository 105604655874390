<template>
	<div class="cassie-vertical-md pt-4">
		<FilterBar
			:search-query.sync="consentCollectionPreferencePageSearchQuery"
			search-query-label="Preference Page Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changeConsentCollectionPreferencePageSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="consentCollectionPreferencePageTranslationStatusId"
					:items="translationStatusItems"
					custom-sort
					label="Status"
					@input="changeConsentCollectionPreferencePageTranslationStatusId($event)"
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Preference Page Translations
			</template>
			<template #title-action>
				<Dropdown
					:value="consentCollectionPreferencePageLanguageSelected"
					label="Language"
					searchable
					:items="assignedLanguagesItems"
					@input="changeLanguage($event)"
				/>
			</template>
			<template #body>
				<DataTable
					sort-desc
					sort-by="linkID"
					:headers="tableHeaders"
					:items="filteredTranslations"
					@click:row="onEditClick"
				>
					<template #item.translated="{ item }">
						{{ item.translated ? TRANSLATED : NO_TRANSLATION }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandID)"
							tooltip-text="Edit Preference Page Translation"
							@click="onEditClick(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Preference Page Translation"
							@click="onEditClick(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="shouldShowPreview(item)"
							tooltip-text="Preview link"
							@click.stop="onPreviewClick(item)"
						>
							mdi-open-in-app
						</IconButton>
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandID) && item.translated"
							tooltip-text="Delete Preference Page Translation"
							@click.stop.prevent="preferencePageTranslationToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="preferencePageTranslationToRemove"
			:entity-name="preferencePageTranslationToRemove.name"
			entity-type="Preference Page Translation"
			@close="preferencePageTranslationToRemove = null"
			@delete="deletePreferencePageTranslation"
		/>
	</div>
</template>
<script>
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../shared/state/brands.js'
import { getPreferencePageTranslations, deletePreferencePageTranslation } from '../../../../../../shared/utils/api/preference-pages.js'
import {
	changeConsentCollectionPreferencePageSearchQuery,
	consentCollectionPreferencePageSearchQuery,
	changeConsentCollectionPreferencePageTranslationStatusId,
	consentCollectionPreferencePageTranslationStatusId,
	changeConsentCollectionPreferencePageTranslationLanguageSelected,
	consentCollectionPreferencePageLanguageSelected
} from '../../../../../../shared/state/consent-collection-preference-pages.js'
import { MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION } from '../../../../router/route-names.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { encryptParameters } from '../../../../../../shared/utils/api/admin-portal/links/links.js'
import previewLinkMixin from '../../../../../../shared/mixins/preview-link.js'

export default {
	components: {
		SectionCard,
		FilterBar,
		DataTable,
		Dropdown,
		IconButton,
		ConfirmDeleteModal
	},
	mixins: [previewLinkMixin],
	props: {
		userFullPermissions: Boolean,
		userHasBrand: Function,
		assignedLanguages: Array
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeConsentCollectionPreferencePageSearchQuery,
			consentCollectionPreferencePageSearchQuery,
			changeConsentCollectionPreferencePageTranslationStatusId,
			consentCollectionPreferencePageTranslationStatusId,
			changeConsentCollectionPreferencePageTranslationLanguageSelected,
			consentCollectionPreferencePageLanguageSelected,
			MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION
		}
	},
	data () {
		return {
			NO_TRANSLATION: 'Untranslated',
			TRANSLATED: 'Translated',
			preferencePageTranslationToRemove: null,
			preferencePageTranslations: []
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'ID', value: 'linkID', width: '10%' },
				{ text: 'Brand', value: 'brandName', width: '20%' },
				{ text: 'Preference Page Name', value: 'name', width: '20%' },
				{ text: 'Status', value: 'translated', width: '20%' },
				{ text: 'Last Updated', value: 'lastUpdated', width: '20%' },
				{ text: 'Action', value: 'action', sortable: false, width: '10%' }
			]
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		filteredTranslations () {
			return this.preferencePageTranslations.filter(({ name, translated, brandID }) => {
				let check = true
				if (consentCollectionPreferencePageSearchQuery.value) check = name.toLowerCase().includes(consentCollectionPreferencePageSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandID === selectedAdminPortalBrandId.value
				if (consentCollectionPreferencePageTranslationStatusId.value) check = check && translated === consentCollectionPreferencePageTranslationStatusId.value
				return check
			})
		},
		translationStatusItems () {
			return [
				{ value: null, text: 'View All' },
				{ value: true, text: this.TRANSLATED },
				{ value: false, text: this.NO_TRANSLATION }
			]
		}
	},
	async created () {
		this.loadPreferencePageTranslations(consentCollectionPreferencePageLanguageSelected.value
			? consentCollectionPreferencePageLanguageSelected.value
			: this.assignedLanguagesItems[0]?.value ?? 0)
	},
	methods: {
		clearFilters () {
			changeConsentCollectionPreferencePageTranslationStatusId(null)
		},
		async changeLanguage (languageId) {
			await this.loadPreferencePageTranslations(languageId)
		},
		async loadPreferencePageTranslations (languageId) {
			changeConsentCollectionPreferencePageTranslationLanguageSelected(languageId)
			const { preferencePageTranslations } = await getPreferencePageTranslations(languageId)
			this.preferencePageTranslations = preferencePageTranslations
		},
		onEditClick (item) {
			this.$router.push({
				name: MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE_TRANSLATION,
				query: {
					id: item.linkID,
					languageId: consentCollectionPreferencePageLanguageSelected.value
				}
			})
		},
		shouldShowPreview (prefPage) {
			return prefPage.translated && !(prefPage.linkFields?.find(
				field => !field.previewValue || field.previewValue === ''
			) ?? null)
		},
		async onPreviewClick (prefPage) {
			const tempString = this.getLinkHref(prefPage)
			await this.previewLink(tempString, prefPage)
		},
		async getEncryptedData (encryptedParams, prefPage) {
			return await encryptParameters({
				encryptionKeyId: prefPage.encryptionKeyId,
				linkParams: encryptedParams
			})
		},
		getLinkHref (prefPage) {
			return new DOMParser()
				.parseFromString(prefPage.embedLink, 'text/html')
				.getElementsByTagName('a')[0].href
		},
		async deletePreferencePageTranslation () {
			await deletePreferencePageTranslation(this.preferencePageTranslationToRemove.linkID, consentCollectionPreferencePageLanguageSelected.value)
			this.preferencePageTranslationToRemove = null
			await this.loadPreferencePageTranslations(consentCollectionPreferencePageLanguageSelected.value)
			showSnackbar('Preference Page Translation has been removed.')
		}
	}
}
</script>
