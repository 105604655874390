<template>
	<SectionCard>
		<template #title>
			Details
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:value="preferenceWidget.preferenceWidgetName"
						label="Embedded Consent Collection Name *"
						rules="required"
						:disabled="!userFullPermissions"
						@input="updatePreferenceWidget('preferenceWidgetName', $event)"
					/>
				</v-col>
				<v-col cols="6">
					<Dropdown
						:value="preferenceWidget.brandId"
						:items="brandOptionItems"
						custom-sort
						:label="'Brand*' | useLabels"
						searchable
						:rules="{ required: clientMultiBranded}"
						:disabled="editMode || !userFullPermissions || !clientMultiBranded || singleBrand"
						@input="updatePreferenceWidget('brandId', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<Textarea
						:value="preferenceWidget.preferenceWidgetDescription"
						label="Embedded Consent Collection Description *"
						rules="required"
						:disabled="!userFullPermissions"
						no-resize
						@input="updatePreferenceWidget('preferenceWidgetDescription', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Dropdown
						:value="preferenceWidget.headerId"
						:items="brandFilteredHeaders"
						label="Header"
						searchable
						:disabled="!userFullPermissions"
						clearable
						@input="updatePreferenceWidget('headerId', $event)"
					/>
				</v-col>
				<v-col cols="6">
					<TextButton
						:disabled="!userFullPermissions"
						@click="openCreateHeaderFooterForm('Header')"
					>
						<v-icon>
							mdi-plus
						</v-icon>
						Create
					</TextButton>
					<TextButton
						:disabled="!preferenceWidget.headerId || !userFullPermissions"
						@click="openEditHeaderFooterForm('Header')"
					>
						<v-icon>
							mdi-pencil
						</v-icon>
						Edit Selected Header
					</TextButton>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Dropdown
						:value="preferenceWidget.footerId"
						:items="brandFilteredFooters"
						label="Footer"
						searchable
						:disabled="!userFullPermissions"
						clearable
						@input="updatePreferenceWidget('footerId', $event)"
					/>
				</v-col>
				<v-col cols="6">
					<TextButton
						:disabled="!userFullPermissions"
						@click="openCreateHeaderFooterForm('Footer')"
					>
						<v-icon>
							mdi-plus
						</v-icon>
						Create
					</TextButton>
					<TextButton
						:disabled="!preferenceWidget.footerId || !userFullPermissions"
						@click="openEditHeaderFooterForm('Footer')"
					>
						<v-icon>
							mdi-pencil
						</v-icon>
						Edit Selected Footer
					</TextButton>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Dropdown
						:value="preferenceWidget.confirmationEmailId"
						:items="brandFilteredSystemEmails"
						label="Confirmation Email"
						searchable
						clearable
						:disabled="!userFullPermissions"
						@input="updatePreferenceWidget('confirmationEmailId', $event)"
					/>
				</v-col>
			</v-row>
			<v-radio-group
				:value="Boolean(preferenceWidget.allowedDomains)"
				hide-details
				class="mt-0"
				:disabled="!userFullPermissions"
				@change="value => updatePreferenceWidget('allowedDomains', value ? [] : undefined)"
			>
				<template #label>
					<span>
						Restrict by Domain
						<v-tooltip
							slot="append"
							top
						>
							<template #activator="{ on }">
								<v-icon
									light
									small
									v-on="on"
								>
									mdi-information
								</v-icon>
							</template>
							<span>
								If restricted by domain, this Embedded Consent Collection can only be deployed on the domains specified.  Please include the full domain URL e.g. https://syrenis.com/
							</span>
						</v-tooltip>
					</span>
				</template>
				<div class="d-flex">
					<v-radio
						label="Yes"
						:value="true"
						class="my-0 mr-4"
					/>
					<v-radio
						label="No"
						:value="false"
						class="my-0"
					/>
				</div>
			</v-radio-group>
			<OptionSelector
				v-if="preferenceWidget.allowedDomains && userFullPermissions"
				:selected="preferenceWidget.allowedDomains"
				entity-type="Domain"
				is-text
				@update:selected="updatePreferenceWidget('allowedDomains', $event)"
			/>
			<HeaderFooterFormModal
				v-if="showHeaderFooterForm"
				:type-id="headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS"
				:form-to-edit="headerFooterFormData"
				:form-type="selectedFormType"
				:selected-brand-id="preferenceWidget.brandId"
				@updated="$emit('load-headers-footers')"
				@set-selected-header:headerId="updatePreferenceWidget('headerId', $event)"
				@set-selected-footer:footerId="updatePreferenceWidget('footerId', $event)"
				@close="() => {
					headerFooterFormData = null
					showHeaderFooterForm = false
				}"
			/>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Textarea from '../../../../../shared/components/textarea.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import { getConfirmationPages } from '../../../../../shared/utils/api/confirmation-page.js'
import { getSystemEmails } from '../../../../../shared/utils/api/system-emails.js'
import { defaultBrandInSelect, singleBrand, brandOptions, clientMultiBranded, UNIVERSAL_BRAND } from '../../../../../shared/state/brands.js'
import OptionSelector from '../../../../../shared/components/option-selector.vue'
import { headersAndFootersTypeIdEnum } from '../../../../../shared/enums/headers-and-footers.js'
import HeaderFooterFormModal from '../../shared/header-footer-form-modal.vue'

export default {
	components: { HeaderFooterFormModal, OptionSelector, TextButton, Dropdown, TextField, Textarea, SectionCard },
	props: {
		preferenceWidget: Object,
		updatePreferenceWidget: Function,
		userFullPermissions: Boolean,
		headers: {
			type: Array,
			default: () => []
		},
		footers: {
			type: Array,
			default: () => []
		},
		editMode: Boolean
	},
	setup () {
		return {
			brandOptions,
			UNIVERSAL_BRAND,
			clientMultiBranded,
			defaultBrandInSelect,
			singleBrand
		}
	},
	data () {
		return {
			confirmationPages: [],
			systemEmails: [],
			headerFooterFormData: null,
			showHeaderFooterForm: false,
			headersAndFootersTypeIdEnum,
			selectedFormType: null
		}
	},
	computed: {
		brandFilteredHeaders () {
			const headerOptions = this.headers.map(({ id, name, brandName, brandId }) => {
				if (clientMultiBranded.value) {
					return {
						value: id,
						text: `${name} - ${brandName}`,
						brandId: brandId
					}
				} else {
					return {
						value: id,
						text: name,
						brandId: brandId
					}
				}
			})

			if (this.preferenceWidget.brandId === UNIVERSAL_BRAND) {
				return headerOptions.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				return headerOptions.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.preferenceWidget.brandId)
			}
		},
		brandFilteredFooters () {
			const footerOptions = this.footers.map(({ id, name, brandName, brandId }) => {
				if (clientMultiBranded.value) {
					return {
						value: id,
						text: `${name} - ${brandName}`,
						brandId: brandId
					}
				} else {
					return {
						value: id,
						text: name,
						brandId: brandId
					}
				}
			})
			if (this.preferenceWidget.brandId === UNIVERSAL_BRAND) {
				return footerOptions.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				return footerOptions.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.preferenceWidget.brandId)
			}
		},
		brandFilteredConfirmationPages () {
			if (!this.confirmationPages) {
				return []
			}
			return this.confirmationPages.filter(({ brandId }) => brandId === UNIVERSAL_BRAND || brandId === this.preferenceWidget.brandId)
		},
		brandFilteredSystemEmails () {
			if (!this.systemEmails) {
				return []
			}
			const emailTypeFilter = emailTypeId => emailTypeId === 3 || emailTypeId === null
			const brandsFilter = brandId => brandId === UNIVERSAL_BRAND || brandId === this.preferenceWidget.brandId
			const filteredSystemEmails = this.systemEmails.filter(({ brandId, emailTypeId }) => brandsFilter(brandId) && emailTypeFilter(emailTypeId))

			return filteredSystemEmails.map(systemEmail => ({
				...systemEmail,
				text: systemEmail.systemEmailName,
				value: systemEmail.systemEmailId
			}))
		},
		brandOptionItems () {
			const universalAccess = Boolean(brandOptions.value.find(
				brand => brand.value === 0
			))
			const universal = {
				text: 'Universal',
				value: 0
			}
			return this.editMode && !universalAccess ? [universal, ...brandOptions.value] : brandOptions.value
		}
	},
	async created () {
		const [
			{ data: confirmationPages },
			{ data }
		] = await Promise.all([
			getConfirmationPages(),
			getSystemEmails()
		])
		this.confirmationPages = confirmationPages
		this.systemEmails = data.systemEmails
	},
	methods: {
		openCreateHeaderFooterForm (formType) {
			this.showHeaderFooterForm = true
			this.selectedFormType = formType
		},
		openEditHeaderFooterForm (type) {
			this.showHeaderFooterForm = true
			this.selectedFormType = type
			if (type === 'Header') {
				this.headerFooterFormData = this.headers.find(({ id }) => id === this.preferenceWidget.headerId)
				this.headerFooterFormData.brand = this.headerFooterFormData.brandId
			} else if (type === 'Footer') {
				this.headerFooterFormData = this.footers.find(({ id }) => id === this.preferenceWidget.footerId)
				this.headerFooterFormData.brand = this.headerFooterFormData.brandId
			}
		}
	}
}
</script>
