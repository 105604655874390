<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="cookieCategorySearchQuery"
			search-query-label="Cookie Category Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changeCookieCategorySearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedCookieCategoryType"
					label="Cookie Category Type"
					:items="categoryTypeFilterItems"
					custom-sort
					@change="changeCookieCategoryType"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="showManageModal = true"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Cookie Categories
			</template>
			<template #body>
				<DataTable
					:items="filteredCategories"
					:headers="tableHeaders"
					sort-by="id"
					:sort-desc="true"
					@click:row="cookieCategoryToEdit = $event"
				>
					<template #item.type="{ item }">
						{{ item.isStrictlyCategory ? 'Strictly Necessary' : 'User Consent' }}
					</template>
					<template #item.usage="{ item }">
						{{ returnUsageCount(item) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="userCanCreateUpdate && userHasBrand(item.brandId)"
							tooltip-text="Edit Category"
							@click="cookieCategoryToEdit = item"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Category"
							@click="cookieCategoryToEdit = item"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userCanDelete && !item.countOfAssociatedCookieBanners && !item.countOfAssociatedCookieScripts && item.isStrictlyCategory === false && userHasBrand(item.brandId)"
							tooltip-text="Delete Category"
							@click.stop.prevent="categoryToRemove = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<CookieCategoryModal
			v-if="showManageModal || cookieCategoryToEdit"
			:cookie-category-to-edit="cookieCategoryToEdit"
			:user-full-permissions="cookieCategoryToEdit ? userCanCreateUpdate && userHasBrand(cookieCategoryToEdit.brandId) : userCanCreateUpdate"
			@close="closeModal"
			@submit="submitCookieCategory"
		/>
		<ConfirmDeleteModal
			v-if="categoryToRemove"
			:entity-name="categoryToRemove.categoryName"
			entity-type="Cookie Category"
			@close="categoryToRemove = null"
			@delete="deleteCategory"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import CookieCategoryModal from './cookie-category-modal.vue'
import ConfirmDeleteModal from '../../../../../../../shared/components/confirm-delete-modal.vue'
import { useLabels } from '../../../../../../../shared/state/admin-portal-navigation.js'
import { CAN_CREATE_UPDATE_COOKIE_CATEGORIES, CAN_DELETE_COOKIE_CATEGORIES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { deleteCookieCategory } from '../../../../../../../shared/utils/api/cookies/cookie-categories.js'
import { cookieCategorySearchQuery, changeCookieCategorySearchQuery, changeCookieCategoryType, selectedCookieCategoryType } from '../../../../../../../shared/state/cookie-categories.js'
import { selectedAdminPortalBrandId, changeAdminPortalBrandFilter, userHasBrand } from '../../../../../../../shared/state/brands.js'
export default {
	components: {
		DataTable,
		FilterBar,
		PrimaryActionButton,
		SectionCard,
		IconButton,
		Dropdown,
		CookieCategoryModal,
		ConfirmDeleteModal
	},
	props: {
		cookieCategories: Array
	},
	setup () {
		return {
			userHasBrand,
			useLabels,
			cookieCategorySearchQuery,
			changeCookieCategorySearchQuery,
			changeCookieCategoryType,
			selectedCookieCategoryType,
			selectedAdminPortalBrandId,
			changeAdminPortalBrandFilter
		}
	},
	data () {
		return {
			showManageModal: false,
			cookieCategoryToEdit: null,
			categoryToRemove: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		categoryTypeFilterItems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Strictly Necessary',
					value: true
				},
				{
					text: 'User Consent',
					value: false
				}
			]
		},
		tableHeaders () {
			return [
				{ value: 'id', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'categoryName', text: 'Cookie Category Name', width: '15%' },
				{ value: 'type', text: 'Cookie Category Type', width: '15%' },
				{ value: 'usage', text: 'Usage', width: '20%' },
				{ value: 'createdDate', text: 'Created Date', width: '15%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		},
		filteredCategories () {
			return this.cookieCategories.filter(({ categoryName, brandId, isStrictlyCategory }) => {
				let check = true
				if (cookieCategorySearchQuery.value) check = categoryName.toLowerCase().includes(cookieCategorySearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				if (selectedCookieCategoryType.value !== null) check = check && isStrictlyCategory === selectedCookieCategoryType.value
				return check
			})
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_CATEGORIES)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_COOKIE_CATEGORIES)
		}
	},
	methods: {
		returnUsageCount (category) {
			return `${category.countOfAssociatedCookieBanners} Cookie Banner${category.countOfAssociatedCookieBanners === 1 ? '' : 's'}
			/ ${category.countOfAssociatedCookieScripts} Cookie Script${category.countOfAssociatedCookieScripts === 1 ? '' : 's'}`
		},
		clearFilters () {
			selectedCookieCategoryType.value = null
		},
		closeModal () {
			this.showManageModal = false
			this.cookieCategoryToEdit = null
		},
		submitCookieCategory () {
			this.closeModal()
			this.$emit('load')
		},
		async deleteCategory () {
			await deleteCookieCategory(this.categoryToRemove.id)
			this.categoryToRemove = null
			this.$emit('load')
		}
	}
}
</script>
