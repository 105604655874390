var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ThemesLayout',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"cassie-vertical-md"},[_c('FilterBar',{attrs:{"hide-brand-filter":true,"search-query":_vm.searchQuery,"search-query-label":"Theme Name"},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event}},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.canCreateAndUpdate)?_c('PrimaryActionButton',{on:{"click":_vm.onCreateClick}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create ")],1):_vm._e()]},proxy:true}])}),_c('SectionCard',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('DataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredThemes,"sort-by":"themeId","sort-desc":true},on:{"click:row":_vm.onEditClick},scopedSlots:_vm._u([{key:"item.themeName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.themeName)+" "),(item.cassieDefault)?_c('Chip',{staticClass:"ml-2 coral-red white--text"},[_vm._v(" Cassie Template ")]):_vm._e()]}},{key:"item.cassieDefault",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cassieDefault ? 'Default' : 'Custom')+" ")]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isActive ? 'Yes' : 'No')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.canCreateAndUpdate && !item.cassieDefault && !item.readOnly)?_c('IconButton',{attrs:{"tooltip-text":"Edit Theme"},on:{"click":function($event){return _vm.onEditClick(item)}}},[_vm._v(" mdi-pencil ")]):_c('IconButton',{attrs:{"tooltip-text":"View Theme"},on:{"click":function($event){return _vm.onEditClick(item)}}},[_vm._v(" mdi-eye ")]),(item.usedBy.length > 0)?_c('IconButton',{attrs:{"tooltip-text":"View Theme Mapping"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.themeMappingToView = item}}},[_vm._v(" mdi-relation-one-to-many ")]):_vm._e(),(!item.cassieDefault && !item.isActive && !item.readOnly && _vm.canDelete)?_c('IconButton',{attrs:{"tooltip-text":"Delete Theme"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onRemoveThemeClick(item)}}},[_vm._v(" mdi-trash-can ")]):_vm._e()]}}])})]},proxy:true}])}),(_vm.themeToBeRemoved && !_vm.themeToBeRemoved.readOnly && _vm.canDelete)?_c('ConfirmDeleteModal',{attrs:{"entity-name":_vm.themeToBeRemoved.themeName,"entity-type":"Theme"},on:{"close":function($event){_vm.themeToBeRemoved = null},"delete":_vm.deleteTheme}}):_vm._e()],1),(_vm.showManageModal)?_c('ManageThemeModal',{attrs:{"theme-to-edit":_vm.themeToEdit,"can-create-and-update":_vm.canCreateAndUpdate},on:{"create":_vm.createTheme,"update":_vm.updateTheme,"close":_vm.closeManageModal}}):_vm._e(),(_vm.themeMappingToView)?_c('ThemeMappingModal',{attrs:{"theme":_vm.themeMappingToView},on:{"close":_vm.closeThemeMappingModal}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }