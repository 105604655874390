<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="publicPortalLinkSearchQuery"
			search-query-label="Public Portal Link Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changePublicPortalLinkSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="linkAuthType"
					:items="authTypeFilter"
					custom-sort
					label="Authentication Type"
					@input="changeLinkAuthType($event)"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="createPublicPortalLink"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Public Portal Links
			</template>
			<template #subtitle>
				These links are used to take a data subject to their Public Portal.
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredPublicPortalLinks"
					sort-by="linkId"
					sort-desc
					@click:row="onEditLink"
				>
					<template #item.action="{ item }">
						<IconButton
							v-if="userFullPermissions && ((item.brandId === 0 && userHasUniversalBrand) || item.brandId !== 0)"
							tooltip-text="Edit Public Portal Link"
							@click="onEditLink(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else-if="userReadOnly || (item.brandId === 0 && !userHasUniversalBrand)"
							tooltip-text="View Public Portal Link"
							@click="onEditLink(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							tooltip-text="View Embed Code"
							@click.stop.prevent="onViewLink(item)"
						>
							mdi-link
						</IconButton>
						<IconButton
							v-if="userFullPermissions && ((item.brandId === 0 && userHasUniversalBrand) || item.brandId !== 0)"
							tooltip-text="Delete Public Portal Link"
							@click.stop.prevent="onRemoveLink(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<RemoveLinkModal
			v-if="linkToBeRemoved"
			v-bind="linkToBeRemoved"
			@close="linkToBeRemoved = null"
			@deleted="loadLinks"
		/>
		<ViewLinkModal
			v-if="linkToView"
			v-bind="linkToView"
			@close="linkToView = null"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import ViewLinkModal from '../view-link-modal.vue'
import RemoveLinkModal from '../remove-link-modal.vue'
import { changePublicPortalLinkSearchQuery, publicPortalLinkSearchQuery, changeLinkAuthType, linkAuthType } from '../../../../../../shared/state/links.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../shared/state/brands.js'
import { MANAGE_PUBLIC_PORTAL_LINK } from '../../../../router/route-names.js'
import { PUBLIC_PORTAL_LINK_TYPE } from '../link-types.js'
import { CONFIRM_VALUE_AUTHENTICATION_TYPE, DIRECT_AUTHENTICATION_TYPE } from '../link-authentication-types.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'

export default {
	components: { RemoveLinkModal, ViewLinkModal, DataTable, PrimaryActionButton, SectionCard, FilterBar, Dropdown, IconButton },
	props: {
		publicPortalLinks: {
			type: Array,
			default: () => []
		},
		lookUpData: {
			type: Object,
			default: () => {}
		},
		userFullPermissions: {
			type: Boolean,
			default: () => false
		},
		userReadOnly: {
			type: Boolean,
			default: () => false
		}
	},
	setup () {
		return {
			changePublicPortalLinkSearchQuery,
			publicPortalLinkSearchQuery,
			changeLinkAuthType,
			linkAuthType,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId
		}
	},
	data () {
		return {
			tableHeaders: [
				{ text: 'ID', value: 'linkId', width: '8%' },
				{ text: useLabels('Brand'), value: 'brandName', width: '13%' },
				{ text: 'Public Portal Link Name', value: 'linkName', width: '20%' },
				{ text: 'Authentication Type', value: 'authenticationType', width: '15%' },
				{ text: 'Public Portal Landing Page', value: 'publicPortalLandingPage', width: '20%' },
				{ text: 'Created Date', value: 'formattedCreatedDate', width: '11%' },
				{ text: 'Action', value: 'action', width: '13%' }
			],
			linkToBeRemoved: null,
			linkToView: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		filteredPublicPortalLinks () {
			// need to add type in here when available
			return this.publicPortalLinks.filter(({ linkName, brandId, authenticationDetails }) => {
				let check = true
				if (publicPortalLinkSearchQuery.value) check = linkName.toLowerCase().includes(publicPortalLinkSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				if (linkAuthType.value !== null) check = check && authenticationDetails.authTypeId === linkAuthType.value
				return check
			})
		},
		filteredLinkAuthTypes () {
			return this.lookUpData?.linkAuthTypes?.filter(({ linkAuthTypeId }) => [DIRECT_AUTHENTICATION_TYPE, CONFIRM_VALUE_AUTHENTICATION_TYPE].includes(linkAuthTypeId)) || []
		},
		authTypeFilter () {
			return [
				{ value: null, text: 'View All' },
				...this.filteredLinkAuthTypes ?? [].map(
					({ linkAuthTypeId, linkAuthTypeDescription }) => ({ value: linkAuthTypeId, text: linkAuthTypeDescription })
				).sort((a, b) => a.text.localeCompare(b.text))
			]
		}
	},
	methods: {
		onEditLink (link) {
			this.$router.push({
				name: MANAGE_PUBLIC_PORTAL_LINK,
				params: {
					linkToEdit: link
				},
				query: {
					linkType: PUBLIC_PORTAL_LINK_TYPE
				}
			})
		},
		onViewLink (link) {
			this.linkToView = link
		},
		onRemoveLink (link) {
			this.linkToBeRemoved = link
		},
		createPublicPortalLink () {
			this.$router.push({
				name: MANAGE_PUBLIC_PORTAL_LINK,
				query: {
					linkType: PUBLIC_PORTAL_LINK_TYPE
				}
			})
		},
		clearFilters () {
			changeLinkAuthType(null)
		},
		loadLinks () {
			this.$emit('load-links')
		}
	}
}
</script>
