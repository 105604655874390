<template>
	<!-- eslint-disable max-lines-->
	<ValidationForm
		#default="{ handleSubmit }"
	>
		<Modal width="800px">
			<template #modal-title>
				{{ modalTitle }}
				<v-spacer />
				<IconButton
					@click="$emit('close')"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col
							cols="6"
						>
							<TextField
								v-model="dataExport.name"
								label="Name *"
								:disabled="!userFullPermissions"
								:rules="{required: true, max: 50}"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Textarea
								v-model="dataExport.description"
								label="Description *"
								:disabled="!userFullPermissions"
								:rules="{required: true, max: 500}"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Dropdown
								v-model="dataExport.enabled"
								label="Status"
								:disabled="!userFullPermissions"
								:items="statusItems"
							/>
						</v-col>
					</v-row>
					<span class="text-subtitle-2">
						File Options
					</span>
					<v-divider />
					<v-row dense>
						<v-col cols="12">
							<Dropdown
								v-model="dataExport.compressionOptions"
								label="File Compression Option *"
								:disabled="!userFullPermissions"
								custom-sort
								:items="compressionOptionItems"
								rules="required"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Toggle
								:value.sync="dataExport.disableEmptyFileCreation"
								:disabled="!userFullPermissions"
								label="Disable empty export file creation"
								tooltip-text="If no data is found on a given Export schedule, this option can stop the creation of an empty file."
							/>
						</v-col>
					</v-row>
					<div v-if="dataExport.disableEmptyFileCreation">
						<v-row class="pb-4">
							<v-col cols="8">
								<Dropdown
									v-model="dataExport.noFileCreatedConfirmationEmailID"
									label="No File Created Email Notification"
									:disabled="!userFullPermissions"
									custom-sort
									clearable
									:items="systemEmailItems"
									tooltip-text="Send a Cassie System Email if the Export schedule found no data and no file was created."
								/>
							</v-col>
						</v-row>
						<validation-observer #default="{ invalid }">
							<v-row dense>
								<v-col cols="6">
									<TextField
										v-model="state.inputEmail"
										:disabled="!userFullPermissions"
										:label="`Email${dataExport.noFileCreatedConfirmationEmailID !== null && dataExport.noFileCreatedConfirmationEmailID !== 0 && selectedEmails.length === 0 ? ' *' : ''}`"
										:rules="{ email: true, requiredEmails: dataExport.noFileCreatedConfirmationEmailID !== null && dataExport.noFileCreatedConfirmationEmailID !== 0 && selectedEmails.length === 0 }"
									/>
								</v-col>
								<v-col cols="2">
									<PrimaryActionButton
										:disabled="(!state.inputEmail || invalid) || !userFullPermissions"
										@click="addEmail"
									>
										Add
									</PrimaryActionButton>
								</v-col>
							</v-row>
						</validation-observer>
						<div class="cassie-vertical-md">
							<v-simple-table
								class="pb-6"
								dense
								light
							>
								<template #default>
									<thead>
										<tr>
											<th
												scope="col"
												class="text-left"
											>
												{{ moreThanOneEmail ? 'Addreses' : 'Address' }}
											</th>
											<th
												v-if="userFullPermissions"
												scope="col"
												class="text-left"
											>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item, index) in selectedEmails"
											:key="item"
										>
											<td>
												{{ item }}
											</td>
											<td v-if="userFullPermissions">
												<IconButton
													:disabled="!userFullPermissions"
													@click="removeEmail(index)"
												>
													mdi-trash-can
												</IconButton>
											</td>
										</tr>
										<tr v-if="selectedEmails.length == 0">
											<td>
												No emails added
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</div>
					</div>
					<span class="text-subtitle-2">
						Header Row Options
					</span>
					<v-divider />
					<v-row dense>
						<v-col cols="4">
							<Toggle
								:value.sync="dataExport.includeHeaderRow"
								:disabled="!userFullPermissions"
								label="Include Header Row"
							/>
						</v-col>
						<v-col cols="4">
							<Toggle
								:value.sync="dataExport.includeTitleRow"
								:disabled="!userFullPermissions"
								label="Include Title Row"
							/>
						</v-col>
					</v-row>
					<span class="text-subtitle-2">
						Formatting Options
					</span>
					<v-divider />
					<v-row dense>
						<v-col cols="4">
							<Dropdown
								v-model="dataExport.delimiter"
								label="Delimiter *"
								:disabled="!userFullPermissions"
								:items="delimiterItems"
							/>
						</v-col>
						<v-col cols="4">
							<Dropdown
								v-model="dataExport.textQualifier"
								label="Text Qualifier"
								:disabled="!userFullPermissions"
								:items="textQualifierItems"
							/>
						</v-col>
						<v-col cols="4">
							<Dropdown
								v-model="dataExport.rowTerminator"
								label="Row Terminiator *"
								:disabled="!userFullPermissions"
								:items="rowTerminatorItems"
								rules="required"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<TextField
								v-model="dataExport.lfReplaceText"
								label="LF Replace Text"
								:disabled="!userFullPermissions"
								:rules="{max: 10, required: true}"
							/>
						</v-col>
						<v-col cols="4">
							<TextField
								v-model="dataExport.crReplaceText"
								label="CR Replace Text"
								:disabled="!userFullPermissions"
								:rules="{max: 10, required: true}"
							/>
						</v-col>
					</v-row>
					<span class="text-subtitle-2">
						Date/Time Formatting Options
					</span>
					<v-divider />
					<v-row dense>
						<v-col cols="4">
							<Dropdown
								v-model="dataExport.dateFormatId"
								label="Date Structure *"
								:disabled="!userFullPermissions"
								:items="dateStructureItems"
								rules="required"
							/>
						</v-col>
						<v-col cols="4">
							<Dropdown
								v-model="dataExport.dateSeparator"
								label="Date Separator *"
								:disabled="!userFullPermissions"
								:items="dateSeparatorItems"
								rules="required"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<Toggle
								:value.sync="dataExport.includeTime"
								:disabled="!userFullPermissions"
								label="Include Time"
							/>
						</v-col>
						<v-col cols="4">
							<Toggle
								:value.sync="dataExport.includeLeadingZeroesInDate"
								:disabled="!userFullPermissions"
								label="Show Leading Zeros"
							/>
						</v-col>
						<v-col
							v-if="dataExport.includeTime"
							cols="4"
						>
							<Dropdown
								v-model="dataExport.hourFormat24"
								label="Time Format"
								:disabled="!userFullPermissions"
								:items="timeFormatItems"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="4">
							<span class="text-subtitle-2">
								Date/Time Preview:
							</span>
						</v-col>
						<v-col cols="4">
							<div>
								{{ dateFormatPreview }}
							</div>
							<div>
								{{ dateTimePreview }}
							</div>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="handleSubmit(submit)"
				>
					{{ modalActionButtonText }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
/* eslint-disable max-lines */
import { format } from 'date-fns'
import Modal from '../../../../../shared/components/modal.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import Textarea from '../../../../../shared/components/textarea.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import { getDataExportHelperValues, postDataExport, putDataExport, getDataExportDesign, createDataExportDesign, scheduleDataExport } from '../../../../../shared/utils/api/data-exports.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { getServerTimezoneInfo } from '../../../../../shared/utils/api/server-timezone.js'
import { getSystemFromEmails } from '../../../../../shared/utils/api/system-emails.js'
export default {
	components: {
		Modal,
		Dropdown,
		TextField,
		Toggle,
		Textarea,
		ValidationForm,
		IconButton,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		cloneMode: {
			type: Boolean,
			default: false
		},
		cloneSubjectExportId: {
			type: Number,
			default: null
		},
		dataExportToEdit: {
			type: Object,
			default: () => ({})
		},
		userFullPermissions: Boolean,
		userReadOnly: Boolean
	},
	data () {
		return {
			dataExport: JSON.parse(JSON.stringify(this.dataExportToEdit || {
				name: '',
				description: '',
				includeHeaderRow: false,
				includeTitleRow: false,
				delimiter: null,
				textQualifier: null,
				rowTerminator: null,
				lfReplaceText: '\\n',
				crReplaceText: '\\r',
				dateFormat: '',
				dateFormatId: null,
				dateSeparator: '',
				includeTime: false,
				includeLeadingZeroesInDate: false,
				hourFormat24: true,
				compressionOptions: 0,
				enabled: false,
				disableEmptyFileCreation: false,
				noFileCreatedConfirmationEmailID: null,
				noFileCreatedEmailsToNotify: null
			})),
			formHelperData: null,
			design: {
				fullExport: true,
				incrementalField: '',
				dataObjectId: null,
				design:
				[{
					fieldName: '',
					fieldOrder: null,
					fieldAlias: '',
					dataType: '',
					dataSize: null,
					filter: '',
					sortOrder: '',
					sortDirection: '',
					legacyFilter: '',
					displayFilter: ''
				}]
			},
			serverTimeInfo: {
				currentServerTime: null,
				serverTimezone: null,
				utcOffsent: null
			},
			state: {
				inputEmail: ''
			},
			systemEmails: [],
			selectedEmails: []
		}
	},
	computed: {
		isEdit () {
			return this.dataExportToEdit?.id && !this.cloneMode
		},
		modalTitle () {
			return `${this.isEdit ? 'Edit' : 'Create'} Data Export`
		},
		modalActionButtonText () {
			return this.isEdit ? 'Save' : 'Create'
		},
		delimiterItems () {
			return this.formHelperData?.delimiters.map(delimiter => ({
				text: delimiter.delimiter,
				value: delimiter.character
			})) || []
		},
		textQualifierItems () {
			return this.formHelperData?.textQualifiers.map(textQualifier => ({
				text: textQualifier.textQualifier,
				value: textQualifier.character
			})) || []
		},
		rowTerminatorItems () {
			return this.formHelperData?.rowTerminators || []
		},
		dateStructureItems () {
			return this.formHelperData?.dateStructures || []
		},
		dateSeparatorItems () {
			return this.formHelperData?.dateSeparators.map(dateSeparator => ({
				text: dateSeparator.dateSeparator,
				value: dateSeparator.character
			})) || []
		},
		compressionOptionItems () {
			return this.formHelperData?.compressionOptions || []
		},
		statusItems () {
			return [
				{
					text: 'Enabled',
					value: true
				},
				{
					text: 'Disabled',
					value: false
				}
			]
		},
		timeFormatItems () {
			return this.formHelperData?.timeFormats || []
		},
		dateFormatPreview () {
			const dateStructure = this.dateStructureItems.find(element => element.value === this.dataExport.dateFormatId)?.text
			const dateSeparator = this.dataExport.dateSeparator
			if (dateStructure && dateSeparator) return dateStructure.replace(/\//g, dateSeparator)
			else return ''
		},
		timeFormatPreview () {
			if (!this.dataExport.includeTime) return ''
			const timeFormat12Hour = false
			const timeFormat24Hour = true
			if (this.dataExport.hourFormat24 === timeFormat12Hour) return 'hh:mm aaa'
			else if (this.dataExport.hourFormat24 === timeFormat24Hour) return 'HH:mm'
			return ''
		},
		dateTimePreview () {
			const dateTimeNow = new Date()
			const dateFormat = this.dateFormatPreview.toLowerCase().replace(/m/g, 'M')
			const preview = this.dateFormatPreview ? format(dateTimeNow, `${dateFormat} ${this.timeFormatPreview}`) : ''
			if (!this.dataExport.includeLeadingZeroesInDate) {
				return preview.replace(/(^|\D)0+([1-9]+)/g, '$1$2')
			}
			return preview
		},
		systemEmailItems () {
			const filteredEmails = this.systemEmails.filter(e => e.emailTypeId === 4 || e.emailTypeId === null)
			const systemEmails = [...filteredEmails.map(e => ({ value: e.id, text: e.name })).sort((a, b) => a.text.localeCompare(b.text))]
			return systemEmails
		},
		moreThanOneEmail () {
			if (this.selectedEmails == null) {
				return false
			}
			return this.selectedEmails.length > 1
		}
	},
	async created () {
		await this.getDataExportHelperValues()
		this.systemEmails = await getSystemFromEmails()
		if (!this.isEdit && !this.cloneMode) {
			this.setDefaultValues()
		}
		this.selectedEmails = this.dataExport.noFileCreatedEmailsToNotify ? this.dataExport.noFileCreatedEmailsToNotify.split(';') : []
	},
	methods: {
		async getDataExportHelperValues () {
			const { data } = await getDataExportHelperValues()
			this.formHelperData = data
		},
		setDefaultValues () {
			const defaultTextQualifier = this.textQualifierItems.find(textQualifier => textQualifier.value === '"').value
			const defaultRowTerminator = this.rowTerminatorItems.find(rowTerminator => rowTerminator.value === 'Newline').value
			this.dataExport.delimiter = this.delimiterItems[0].value
			this.dataExport.textQualifier = defaultTextQualifier
			this.dataExport.rowTerminator = defaultRowTerminator
		},
		async submit () {
			const dateStructure = this.dateStructureItems.find(element => element.value === this.dataExport.dateFormatId)
			let saveErrors = ''
			const dataExport = {
				name: this.dataExport.name,
				description: this.dataExport.description,
				includeHeaderRow: this.dataExport.includeHeaderRow,
				includeTitleRow: this.dataExport.includeTitleRow,
				delimiter: this.dataExport.delimiter,
				textQualifier: this.dataExport.textQualifier ?? '',
				rowTerminator: this.dataExport.rowTerminator,
				lfReplaceText: this.dataExport.lfReplaceText,
				crReplaceText: this.dataExport.crReplaceText,
				dateFormat: dateStructure.text,
				dateFormatId: dateStructure.value,
				dateSeparator: this.dataExport.dateSeparator,
				includeTime: this.dataExport.includeTime,
				includeLeadingZeroesInDate: this.dataExport.includeLeadingZeroesInDate,
				HourFormat24: this.dataExport.hourFormat24,
				compressionOptions: this.dataExport.compressionOptions,
				enabled: this.dataExport.enabled,
				disableEmptyFileCreation: this.dataExport.disableEmptyFileCreation,
				noFileCreatedConfirmationEmailID: this.dataExport.noFileCreatedConfirmationEmailID ? this.dataExport.noFileCreatedConfirmationEmailID : 0,
				noFileCreatedEmailsToNotify: this.selectedEmails.length > 0 ? this.selectedEmails.join(';') : null
			}
			if (this.cloneMode && !this.isEdit) {
				// Create a new export as per the selected fields
				const postExportResponse = await postDataExport(dataExport)
				const newExportId = postExportResponse.data.newDataExportId
				// Get the design of the export you cloned from
				await this.getExportDesign()
				const designToSubmit = {
					...this.design,
					exportId: newExportId
				}
				// Save that design to the new export
				await createDataExportDesign(designToSubmit)
					.catch(() => {
						saveErrors = 'Failed to clone export design'
					})
				// Save the schedule of the export you cloned from
				const timezoneResponse = await getServerTimezoneInfo()
				this.serverTimeInfo = timezoneResponse.data
				if (this.dataExport.locationId) {
					const scheduledExport = {
						id: newExportId,
						locationId: this.dataExport.locationId,
						frequency: this.dataExport.frequency,
						dayOfWeek: this.dataExport.dayOfWeek,
						nextRunDate: this.adjustNextRunDate(this.dataExport.nextRunDate),
						startDateFilter: this.dataExport.startDateFilter > this.serverTimeInfo.currentServerTime ? this.dataExport.startDateFilter : this.serverTimeInfo.currentServerTime,
						endDateFilter: null,
						pushFileToClient: this.dataExport.pushFileToClient
					}
					await scheduleDataExport(scheduledExport)
						.catch(({ response }) => {
							saveErrors = saveErrors === '' ? 'Failed to clone export schedule' : saveErrors + ' and schedule'
						})
				}
				if (saveErrors !== '') {
					showSnackbar({ text: saveErrors, color: 'red' })
				} else {
					showSnackbar(`Data export created successfully`)
				}
			} else	if (this.isEdit) {
				dataExport.id = this.dataExport.id
				await putDataExport(dataExport)
				showSnackbar(`Data export updated successfully`)
			} else {
				await postDataExport(dataExport)
				showSnackbar(`Data export created successfully`)
			}
			this.$emit('close')
		},
		async getExportDesign () {
			const { data: design } = await getDataExportDesign(this.cloneSubjectExportId)
			this.design.design = design.design.map(field => {
				return {
					fieldName: field.fieldName,
					fieldOrder: field.fieldOrder,
					fieldAlias: field.fieldAlias,
					dataType: field.dataType,
					dataSize: field.dataSize,
					filter: field.filter,
					sortOrder: field.sortOrder,
					sortDirection: field.sortDirection,
					displayFilter: field.legacyFilter
				}
			})
			this.design = design
			this.design.design = design.design.filter(
				field => field.fieldOrder > 0).sort(
				(a, b) => a.fieldOrder - b.fieldOrder).concat(
				design.design.filter(
					field => field.fieldOrder === 0))
			this.selectedDataObject = this.design.dataObjectId
			this.incrementFields = design.design.filter((field, index) => field.dataType === 'datetime' || index === 0).map(field => { return { value: field.fieldName, text: field.fieldName } })
			this.sortField1 = {
				fieldName: this.design.design.filter(field => field.sortOrder === 1)[0]?.fieldName || '',
				sortDirection: this.design.design.filter(field => field.sortOrder === 1)[0]?.sortDirection || 'asc'
			}
			this.sortField2 = {
				fieldName: this.design.design.filter(field => field.sortOrder === 2)[0]?.fieldName || '',
				sortDirection: this.design.design.filter(field => field.sortOrder === 2)[0]?.sortDirection || 'asc'
			}
			this.sortField3 = {
				fieldName: this.design.design.filter(field => field.sortOrder === 3)[0]?.fieldName || '',
				sortDirection: this.design.design.filter(field => field.sortOrder === 3)[0]?.sortDirection || 'asc'
			}
		},
		adjustNextRunDate (currentNextRunDate) {
			if (currentNextRunDate > this.serverTimeInfo.currentServerTime) {
				return currentNextRunDate
			}
			if (this.dataExport.frequency === 'Daily') {
				return new Date(currentNextRunDate.setDate(currentNextRunDate.getDate() + 1))
			}
			if (this.dataExport.frequency === 'Weekly') {
				return new Date(currentNextRunDate.setDate(currentNextRunDate.getDate() + 7))
			}
			if (this.dataExport.frequency === 'Monthly') {
				return new Date(currentNextRunDate.setMonth(currentNextRunDate.getMonth() + 1))
			}
			if (this.dataExport.frequency === 'Quarterly') {
				return new Date(currentNextRunDate.setMonth(currentNextRunDate.getMonth() + 3))
			}
			return this.serverTimeInfo.currentServerTime
		},
		addEmail () {
			const alreadyAdded = this.selectedEmails.find(email => email === this.state.inputEmail)
			if (alreadyAdded) {
				showSnackbar({ color: 'red', text: 'This address has already been added' })
				return
			}
			this.selectedEmails.push(this.state.inputEmail)
			this.state.inputEmail = ''
		},
		removeEmail (index) {
			this.selectedEmails.splice(index, 1)
		}
	}
}

</script>
