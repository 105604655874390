var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ConsentCollectionLayout',{attrs:{"title":"Consent Collection Methods","caption":"From here you can create, edit and manage your consent collection methods"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',[(_vm.userCanSeePreferencePagesAndActionLinks && _vm.customPreferencePagesEnabled)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6","xl":"4"}},[_c('OverviewCard',{attrs:{"height":"350px","card-title":"Custom Preference Pages","user-can-create-and-update":_vm.userCanCreateAndUpdatePreferencePagesAndActionLinks},on:{"overview":function($event){return _vm.$router.push({ name: _vm.PREFERENCE_PAGE_LINKS })},"create":function($event){return _vm.$router.push({
						name: _vm.MANAGE_PREFERENCE_LINK,
						query: {
							linkType: _vm.PREFERENCE_PAGE_LINK_TYPE
						},
						params: {
							accessedViaHub: true
						}
					})}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" Build your own Preference Pages fully controlled by your CSS ")]},proxy:true},{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":require("../../../assets/images/original-colours/Custom-Preference-Pages.svg"),"alt":"Custom Preference Page","max-height":"100%","max-width":"270px","height":"100%"}})]},proxy:true}],null,false,807993783)})],1):_vm._e(),(_vm.userCanSeePreferencePagesAndActionLinks && _vm.dragAndDropPreferencePagesEnabled)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6","xl":"4"}},[_c('OverviewCard',{attrs:{"height":"350px","card-title":"Drag-and-Drop Preference Pages","user-can-create-and-update":_vm.userCanCreateAndUpdatePreferencePagesAndActionLinks},on:{"overview":function($event){return _vm.$router.push({ name: _vm.CONSENT_COLLECTION_PREFERENCE_PAGES })},"create":function($event){return _vm.$router.push({
						name: _vm.MANAGE_CONSENT_COLLECTION_PREFERENCE_PAGE,
						params: {
							userFullPermissions: _vm.userCanCreateAndUpdatePreferencePagesAndActionLinks,
							accessedViaHub: true
						}
					})}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" Build your own Preference Pages with our no-code editor ")]},proxy:true},{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":require("../../../assets/images/original-colours/Drag-and-Drop-Preference-Pages.svg"),"alt":"Preference Page","max-height":"100%","max-width":"270px","height":"100%"}})]},proxy:true}],null,false,3278756582)})],1):_vm._e(),(_vm.userCanSeeWidgets && _vm.embeddedConsentCollectionEnabled)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6","xl":"4"}},[_c('OverviewCard',{attrs:{"height":"350px","card-title":"Embedded Consent Collection","user-can-create-and-update":_vm.userCanCreateAndUpdateWidgets},on:{"overview":function($event){return _vm.$router.push({ name: _vm.PREFERENCE_WIDGETS })},"create":function($event){return _vm.$router.push({
						name: _vm.MANAGE_PREFERENCE_WIDGET,
						params: {
							accessedViaHub: true,
							userFullPermissions: _vm.userCanCreateAndUpdateWidgets
						}
					})}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" Build Consent Collection Points to embed within your existing forms ")]},proxy:true},{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":require("../../../assets/images/original-colours/Embedded-Consent-Collection.svg"),"alt":"Embedded Consent Collection","max-height":"100%","max-width":"270px","height":"100%"}})]},proxy:true}],null,false,3235511588)})],1):_vm._e(),(_vm.userCanSeeWidgets && _vm.consentCollectionWidgetEnabled)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6","xl":"4"}},[_c('OverviewCard',{attrs:{"height":"350px","card-title":"Consent Collection Widget","user-can-create-and-update":_vm.userCanCreateAndUpdateWidgets},on:{"overview":function($event){return _vm.$router.push({ name: _vm.CONSENT_COLLECTION_WIDGETS })},"create":function($event){return _vm.$router.push({
						name: _vm.MANAGE_CONSENT_COLLECTION_WIDGET,
						params: {
							userFullPermissions: _vm.userCanCreateAndUpdateWidgets,
							accessedViaHub: true
						}
					})}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" Build Consent Collection Widgets that can be applied to your existing pages ")]},proxy:true},{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":require("../../../assets/images/original-colours/Consent-Collection-Widget.svg"),"alt":"Consent Collection Widget","max-height":"100%","max-width":"270px","height":"100%"}})]},proxy:true}],null,false,1265552872)})],1):_vm._e(),(_vm.userCanSeePreferencePagesAndActionLinks)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6","xl":"4"}},[_c('OverviewCard',{attrs:{"height":"350px","card-title":"Action Links","user-can-create-and-update":_vm.userCanCreateAndUpdatePreferencePagesAndActionLinks},on:{"overview":function($event){return _vm.$router.push({ name: _vm.ACTION_LINKS })},"create":function($event){return _vm.$router.push({
						name: _vm.MANAGE_PREFERENCE_LINK,
						query: {
							linkType: _vm.ACTION_LINK_TYPE
						},
						params: {
							accessedViaHub: true
						}
					})}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" Create links that set your customers consents and preferences ")]},proxy:true},{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":require("../../../assets/images/original-colours/Action-Links.svg"),"alt":"Action Links","max-height":"100%","max-width":"270px","height":"100%"}})]},proxy:true}],null,false,684090638)})],1):_vm._e(),(_vm.userCanSeeProgressiveProfiling)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6","xl":"4"}},[_c('OverviewCard',{attrs:{"height":"350px","card-title":"Progressive Profiling","user-can-create-and-update":_vm.userCanCreateAndUpdateProgressiveProfiling},on:{"overview":function($event){return _vm.$router.push({ name: _vm.PROGRESSIVE_PROFILING })},"create":function($event){return _vm.$router.push({
						name: _vm.MANAGE_PROGRESSIVE_PROFILE,
						params: {
							userCanCreateAndUpdate: _vm.userCanCreateAndUpdateProgressiveProfiling,
							accessedViaHub: true
						}
					})}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" Create pop-ups (e.g. questions, surveys or polls) to build anonymous preference profiles ")]},proxy:true},{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":require("../../../assets/images/original-colours/Progressive-Profiling.svg"),"alt":"Progressive Profiling","max-height":"100%","max-width":"270px","height":"100%"}})]},proxy:true}],null,false,3675416873)})],1):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }