<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Web Page Details
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<TextField
							v-model="widgetDivId"
							label="Widget DIV ID"
						/>
					</v-col>
					<v-col cols="6">
						<Dropdown
							v-model="accessKey"
							label="Access Key"
							:items="accessKeyItems"
						/>
					</v-col>
				</v-row>
				<p>
					The ID of the HTML DIV component on your web page where the Embedded Consent Collection will be displayed.
				</p>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							v-if="showTranslations"
							v-model="language"
							label="Language"
							searchable
							:items="availableLanguages"
							custom-sort
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<SectionCard v-if="enableCassieCloudEmbedCode">
			<template #title>
				Embedded Consent Collection Code
			</template>
			<template #title-action>
				<Toggle
					label="Dynamic Embed Code"
					:value.sync="dynamicEmbedCode"
					tooltip-text="This will enable the embed code to use the domain of the hosting page for resource location, only enable this if your DNS configuration is complete for the hosting domain."
				/>
			</template>
			<template #body>
				<v-row>
					<v-col cols="10">
						<p class="mb-0">
							1 - On your web form, you need to create a div with a unique ID, as shown below (where we have pre-populated the value with the specified above). The content of this div must be left blank - it will be loaded with your form {{ 'statements' | useLabels }} from Cassie.
						</p>
					</v-col>
					<v-spacer />
					<TextButton @click="copyCode(widgetEmbedCode1)">
						Copy
					</TextButton>
				</v-row>
				<Prism
					language="javascript"
					:code="widgetEmbedCode1"
				/>
				<v-row>
					<v-col cols="10">
						<p class="mb-0">
							2 - In the DOM Ready event e.g. $(document).ready of your web form, you should initiate the call to the GetWidget() function, passing the following parameters
						</p>
					</v-col>
					<v-spacer />
					<TextButton
						v-if="dynamicEmbedCode"
						@click="copyCode(widgetEmbedCode2Dynamic)"
					>
						Copy
					</TextButton>
					<TextButton
						v-else
						@click="copyCode(widgetEmbedCode2)"
					>
						Copy
					</TextButton>
				</v-row>
				<Prism
					v-if="dynamicEmbedCode"
					:code="widgetEmbedCode2Dynamic"
					language="javascript"
				/>
				<Prism
					v-else
					:code="widgetEmbedCode2"
					language="javascript"
				/>
				<v-expansion-panels>
					<v-expansion-panel>
						<v-expansion-panel-header>
							Variables
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<strong>
								FormID
							</strong> – The ID of this
							<br>
							<strong>
								AccessKey
							</strong> – The Access Key to use for this widget
							<br>
							<strong>
								DivID
							</strong> – This is the ID of the blank div you created on your web form (above)
							<br>
							<strong>
								WidgetType
							</strong> – A code representing the type of widget required
							<br>
							(Optional) <strong>
								LanguageCode
							</strong> – A code to change the language of the widget (if a language has been selected above)
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</template>
		</SectionCard>
		<SectionCard v-else>
			<template #title>
				<span>Embedded Consent Collection Code
					<v-tooltip
						slot="append"
						top
					>
						<template #activator="{ on }">
							<v-icon
								light
								v-on="on"
							>
								mdi-information
							</v-icon>
						</template>
						<span>
							This embed code uses the domain of the page it is hosted on for the resource location, you must configure your DNS for this to work.  Please speak to your Account Manager for more details.
						</span>
					</v-tooltip>
				</span>
			</template>
			<template #body>
				<v-row>
					<v-col cols="10">
						<p class="mb-0">
							1 - On your web form, you need to create a div with a unique ID, as shown below (where we have pre-populated the value with the specified above). The content of this div must be left blank - it will be loaded with your form {{ 'statements' | useLabels }} from Cassie.
						</p>
					</v-col>
					<v-spacer />
					<TextButton @click="copyCode(widgetEmbedCode1)">
						Copy
					</TextButton>
				</v-row>
				<Prism
					language="javascript"
					:code="widgetEmbedCode1"
				/>
				<v-row>
					<v-col cols="10">
						<p class="mb-0">
							2 - In the DOM Ready event e.g. $(document).ready of your web form, you should initiate the call to the GetWidget() function, passing the following parameters
						</p>
					</v-col>
					<v-spacer />
					<TextButton
						@click="copyCode(widgetEmbedCode2Dynamic)"
					>
						Copy
					</TextButton>
				</v-row>
				<Prism
					:code="widgetEmbedCode2Dynamic"
					language="javascript"
				/>
				<v-expansion-panels>
					<v-expansion-panel>
						<v-expansion-panel-header>
							Variables
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<strong>
								FormID
							</strong> – The ID of this
							<br>
							<strong>
								AccessKey
							</strong> – The Access Key to use for this widget
							<br>
							<strong>
								DivID
							</strong> – This is the ID of the blank div you created on your web form (above)
							<br>
							<strong>
								WidgetType
							</strong> – A code representing the type of widget required
							<br>
							(Optional) <strong>
								LanguageCode
							</strong> – A code to change the language of the widget (if a language has been selected above)
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</template>
		</SectionCard>
		<PreferenceWidgetSubmissionCodeCard :submission-code="submissionCode" />
	</div>
</template>

<script>
import Prism from 'vue-prism-component'
import SectionCard from '../../../../../shared/components/section-card.vue'
import { submissionCode, widgetEmbedCode2, widgetEmbedCode2Dynamic } from './widget-embed-code-templates.js'
import { getEnv } from '../../../../../shared/utils/runtime-settings.js'
import PreferenceWidgetSubmissionCodeCard from './preference-widget-submission-code-card.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import { getPreferenceWidgetAccessKeys } from '../../../../../shared/utils/api/access-keys.js'
import { waitForLoad, licenseId } from '../../../../../shared/state/config-keys.js'
import { getStatementGroups } from '../../../../../shared/utils/api/statement-groups.js'
import { getPreferenceChannels } from '../../../../../shared/utils/api/channels.js'

const widgetUrl = getEnv('VUE_APP_WIDGET_URL')
const widgetApiSubmit = getEnv('VUE_APP_WIDGETSAPI_SUBMIT')

export default {
	components: { TextField, Dropdown, PreferenceWidgetSubmissionCodeCard, SectionCard, TextButton, Toggle, Prism },
	props: {
		preferenceWidget: {
			type: Object,
			default: () => ({})
		},
		brandId: Number,
		optionAppearance: Number,
		enableCassieCloudEmbedCode: Boolean
	},
	setup () {
		return {
			waitForLoad,
			licenseId
		}
	},
	data () {
		return {
			language: null,
			widgetDivId: null,
			accessKey: null,
			accessKeys: [],
			channels: [],
			dynamicEmbedCode: false
		}
	},
	computed: {
		submissionCode () {
			return submissionCode
				.replace('$preferenceWidgetId', String(this.preferenceWidget.preferenceWidgetId || ''))
				.replace('$accessKey', this.licenseId)
				.replace('$preferenceWidgetDivId', this.widgetDivId || 'PrefWidget')
				.replace('$widgetApiSubmit', widgetApiSubmit || '')
		},
		widgetEmbedCode1 () {
			if (!this.widgetDivId) {
				return '<div id="PrefWidget"></div>'
			} else {
				return `<div id="${this.widgetDivId}"></div>`
			}
		},
		widgetEmbedCode2 () {
			return widgetEmbedCode2
				.replace('$preferenceWidgetId', String(this.preferenceWidget.preferenceWidgetId || ''))
				.replace('$accessKey', this.accessKey || this.licenseId)
				.replace('$preferenceWidgetDivId', this.widgetDivId || 'PrefWidget')
				.replace('$licenceKey', this.licenseId || '')
				.replace('$language', this.selectedLanguage?.languageCode || '')
				.replace('$widgetUrl', widgetUrl || '')
				.replace('$optionAppearance', this.optionAppearance === 1 ? '00' : '10')
		},
		widgetEmbedCode2Dynamic () {
			const dynamicWidgetUrl = widgetUrl.replace('cassiecloud.com', `\${rootDomain}`)
			return widgetEmbedCode2Dynamic
				.replace('$preferenceWidgetId', String(this.preferenceWidget.preferenceWidgetId || ''))
				.replace('$accessKey', this.accessKey || this.licenseId)
				.replace('$preferenceWidgetDivId', this.widgetDivId || 'PrefWidget')
				.replace('$licenceKey', this.licenseId || '')
				.replace('$language', this.selectedLanguage?.languageCode || '')
				.replace(/\$widgetUrl/g, widgetUrl || '')
				.replace('$optionAppearance', this.optionAppearance === 1 ? '00' : '10')
				.replace('$dynamicWidgetUrl', dynamicWidgetUrl)
		},
		accessKeyItems () {
			const accessKeys = this.accessKeys.map(({ brandId, accessKey, accessKeyName }) => ({
				brandId,
				text: `${accessKeyName} - ${accessKey}`,
				value: accessKey.toUpperCase()
			}))

			return accessKeys.filter(({ brandId }) => brandId === this.brandId || brandId === 0)
		},
		statements () {
			const allStatements = this.channels.map(channel => {
				return channel.statements
			}).flat()
			const prefWidgetStatements = this.preferenceWidget.statementGroups.map(statementGroup => {
				return {
					...statementGroup.statements[0],
					statementGroupId: statementGroup.statementGroupID
				}
			})
			const statements = prefWidgetStatements.map(statement => ({
				...statement,
				statementText: allStatements.find(allStatement => allStatement.statementID === statement.statementID || allStatement.statementID === statement.statementId)
					?.liveStatements.find(({ languageId }) => languageId === this.selectedLanguageId)?.statementText || statement.statementText,
				liveStatements: allStatements.find(allStatement => allStatement.statementID === statement.statementID || allStatement.statementID === statement.statementId)?.liveStatements
			}))
			return statements
		},
		availableLanguages () {
			let statementTranslations = this.statements?.map(statement => ({
				...statement
			}))
			statementTranslations = statementTranslations.filter(({ liveStatements }) => liveStatements?.length >= 1).map(statement => {
				return statement.liveStatements
			}).flat()
			const availableTranslationOptions = []
			statementTranslations.forEach(statementTranslation => {
				if (statementTranslation.languageId) {
					availableTranslationOptions.push({
						value: statementTranslation.languageId,
						text: statementTranslation.languageName,
						languageCode: statementTranslation.languageCode
					})
				}
			})
			return [{ value: 0, text: 'Default' }, ...availableTranslationOptions.sort((a, b) =>
				a.text.localeCompare(b.text)
			)]
		},
		showTranslations () {
			return this.availableLanguages.length > 1 // More than just Default
		},
		selectedLanguage () {
			return this.availableLanguages.find(({ value }) => value === this.language)
		}
	},
	async created () {
		await waitForLoad()
		const { preferenceWidgetAccessKeys } = await getPreferenceWidgetAccessKeys()
		this.accessKeys = preferenceWidgetAccessKeys
		const [
			{ data: { channels } }
		] = await Promise.all([
			getPreferenceChannels(),
			getStatementGroups()
		])
		this.channels = channels
	},
	methods: {
		copyCode (snippet) {
			navigator.clipboard.writeText(snippet)
		}
	}
}
</script>
