	<!-- eslint-disable max-lines-->
<template>
	<ValidationForm
		#default="{ handleSubmit }"
		tag="div"
		class="cassie-vertical-md"
	>
		<ViewLayout>
			<template #header-title>
				{{ pageTitle }}
			</template>
			<template #header-caption>
				{{ 'Add, edit and manage channel statements.' | useLabels }}
			</template>
			<template #header-after>
				<slot name="header-after" />
			</template>
			<template #content>
				<SectionCard>
					<template #title>
						Live {{ 'Statement' | useLabels }} Details
					</template>
					<template
						v-if="isEdit && liveStatementCreatedBy"
						#title-action
					>
						{{ 'Created By ' + liveStatementCreatedBy }}
					</template>
					<template #body>
						<v-row
							v-if="!showStatementDrafts"
							dense
						>
							<v-col cols="3">
								<v-radio-group
									v-model="statement.defaultValue"
									hide-details
									class="mt-0"
									:disabled="universalStatementNotUser"
								>
									<template #label>
										Selected by default?
									</template>
									<div class="d-flex">
										<v-radio
											label="Yes"
											:value="true"
											class="my-0 mr-4"
										/>
										<v-radio
											label="No"
											:value="false"
											class="my-0"
										/>
									</div>
								</v-radio-group>
							</v-col>
							<v-col cols="3">
								<v-radio-group
									v-model="statement.isOptIn"
									hide-details
									class="mt-0"
									:disabled="isEdit || universalStatementNotUser"
								>
									<template #label>
										Opt in/out
									</template>
									<div class="d-flex">
										<v-radio
											label="Opt In"
											:value="true"
											class="my-0 mr-4"
										/>
										<v-radio
											label="Opt Out"
											:value="false"
											class="my-0"
										/>
									</div>
								</v-radio-group>
							</v-col>
						</v-row>
						<span v-if="showStatementDrafts && !statement.liveStatementText">
							No live Version
						</span>
						<v-row
							v-else
							dense
						>
							<v-col cols="12">
								<Textarea
									v-model="statement.liveStatementText"
									:label="'Live Statement' | useLabels"
									:view-only="showStatementDrafts || universalStatementNotUser"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard v-if="showStatementDrafts">
					<template #title>
						{{ 'Draft Statement Details' | useLabels }}
					</template>
					<template #title-action>
						{{ statementIdPresent && draftStatementEditedBy && !!statement.defaultLanguageDraftStatement ? 'Last Edited By ' + draftStatementEditedBy + ', ' + getDraftStatementStatus : getDraftStatementStatus }}
					</template>
					<template #body>
						<v-row
							dense
						>
							<v-col cols="3">
								<v-radio-group
									v-model="statement.defaultValue"
									hide-details
									class="mt-0"
									:disabled="disableRadioGroups"
								>
									<template #label>
										Selected by default?
									</template>
									<div class="d-flex">
										<v-radio
											label="Yes"
											:value="true"
											class="my-0 mr-4"
										/>
										<v-radio
											label="No"
											:value="false"
											class="my-0"
										/>
									</div>
								</v-radio-group>
							</v-col>
							<v-col cols="3">
								<v-radio-group
									v-model="statement.isOptIn"
									hide-details
									class="mt-0"
									:disabled="disableRadioGroups"
								>
									<template #label>
										Opt in/out
									</template>
									<div class="d-flex">
										<v-radio
											label="Opt In"
											:value="true"
											class="my-0 mr-4"
										/>
										<v-radio
											label="Opt Out"
											:value="false"
											class="my-0"
										/>
									</div>
								</v-radio-group>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<Textarea
									v-model="statement.draftStatementText"
									:view-only="!canCreateDraftStatements || (!displaySaveAndSubmitButton && !displaySubmitButton)"
									:label="'Draft Statement Details' | useLabels"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col
								cols="6"
								class="cassie-horizontal-sm"
							>
								<DeleteButton
									v-if="displayAbandonButton && canCreateDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.ABANDON)"
								>
									Abandon
								</DeleteButton>
								<DeleteButton
									v-if="displayRejectDraftButton && canApproveDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.REJECT_DRAFT)"
								>
									Reject Draft
								</DeleteButton>
								<DeleteButton
									v-if="displayRejectApprovalButton && canPublishDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.REJECT_APPROVAL)"
								>
									Reject Approval
								</DeleteButton>
								<DeleteButton
									v-if="displayCancelPublishButton && canPublishDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.CANCEL_PUBLISH)"
								>
									Cancel Publish
								</DeleteButton>
							</v-col>
							<v-col
								cols="6"
								class="text-right cassie-horizontal-sm d-flex flex-row"
							>
								<v-spacer />
								<PrimaryActionButton
									v-if="displaySaveDraftButton && canCreateDraftStatements"
									@click="handleSubmit(saveStatement)"
								>
									Save
								</PrimaryActionButton>
								<PrimaryActionButton
									v-if="displaySaveAndSubmitButton && canCreateDraftStatements"
									@click="handleSubmit(postStatementAndAction)"
								>
									Save and Submit
								</PrimaryActionButton>
								<PrimaryActionButton
									v-if="displaySubmitButton && canCreateDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.SUBMIT)"
								>
									Submit for Approval
								</PrimaryActionButton>
								<PrimaryActionButton
									v-if="displayApproveButton && canApproveDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.APPROVE)"
								>
									Approve
								</PrimaryActionButton>
								<PrimaryActionButton
									v-if="displayPublishButton && canPublishDraftStatements"
									@click="putStatementAction(STATEMENT_ACTIONS.PUBLISH)"
								>
									Publish Now
								</PrimaryActionButton>
								<PrimaryActionButton
									v-if="displayPublishButton && canPublishDraftStatements"

									@click=" showPublishOnModal=true"
								>
									Schedule
								</PrimaryActionButton>
							</v-col>
						</v-row>
						<PublishOnModal
							v-if="showPublishOnModal"
							@close="() =>
								showPublishOnModal = false
							"
							@handleClick="(date) => putStatementAction(STATEMENT_ACTIONS.PUBLISH_ON, date)"
						/>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						{{ 'Archived Statement Versions' | useLabels }}
					</template>
					<template #body>
						<DataTable
							:headers="tableHeaders"
							:items="statement.archivedStatements"
						/>
					</template>
				</SectionCard>
				<MetadataCard
					module-type="Statement"
					:assigned-meta-data="statement.metaData"
					:read-only="universalStatementNotUser || !metadataFullPermissions"
					:component-type-id="COMPONENT_TYPE.STATEMENTS"
					@update:metadata="updateMetadata($event)"
				/>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							Back to overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="!showStatementDrafts && canCreateDraftStatements && !universalStatementNotUser"
							@click="handleSubmit(saveStatement)"
						>
							{{ isEdit ? 'Save' : 'Create' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
	</ValidationForm>
</template>
<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { DATE_FORMAT, DATE_TIME_FORMAT_NO_SECONDS } from '../../../../../../shared/utils/date-formatting.js'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import MetadataCard from '../../../shared/metadata-card.vue'
import Textarea from '../../../../../../shared/components/textarea.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import DeleteButton from '../../../../../../shared/components/delete-button.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import PublishOnModal from '../../../shared/publish-on-modal.vue'
import { showStatementDrafts } from '../../../../../../shared/state/config-keys.js'
import { COMPONENT_TYPE } from '../../../../../../shared/state/meta-data.js'
import { userHasBrand } from '../../../../../../shared/state/brands.js'
import { putStatementAction, postStatementAndAction, putStatement, postStatement } from '../../../../../../shared/utils/api/statements.js'
import { createExternalMetaDataAssignment, deleteExternalMetaDataAssignment } from '../../../../../../shared/utils/api/external-meta-data.js'
import { STATEMENTS } from '../../../../router/route-names.js'
import { STATEMENT_ACTIONS, STATEMENT_STATUS } from '../statement-enums.js'
import { CAN_CREATE_DRAFT_STATEMENTS, CAN_APPROVE_DRAFT_STATEMENTS, CAN_PUBLISH_DRAFT_STATEMENTS, META_DATA_KEYS_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'
export default {
	components: {
		ValidationForm,
		ViewLayout,
		SectionCard,
		Textarea,
		DataTable,
		MetadataCard,
		PrimaryActionButton,
		SecondaryActionButton,
		DeleteButton,
		PageActionRow,
		PublishOnModal
	},
	props: {
		statementToEdit: Object,
		channelName: String,
		channelId: Number,
		channelStatements: Array,
		index: Number,
		accessedVia: String
	},
	setup () {
		return {
			showStatementDrafts,
			COMPONENT_TYPE
		}
	},
	data () {
		const ACCESSED_VIA_STATEMENTS = 'statements'
		const ACCESSED_VIA_CHANNEL = 'channel'
		return {
			ACCESSED_VIA_STATEMENTS,
			ACCESSED_VIA_CHANNEL,
			STATEMENT_ACTIONS,
			STATEMENT_STATUS,
			statement: JSON.parse(JSON.stringify(this.statementToEdit || {
				liveStatementText: '',
				draftStatementText: '',
				defaultValue: false,
				isOptIn: true,
				metaData: []
			})),
			showPublishOnModal: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		isEdit () {
			return !!this.statementToEdit
		},
		statementIdPresent () {
			return !!this.statementToEdit?.statementId
		},
		pageTitle () {
			if (this.universalStatementNotUser) {
				return `${this.channelName} - View Statement`
			}
			return `${this.channelName} - ${useLabels(!this.isEdit ? 'Add Statement' : 'Edit Statement')}`
		},
		canCreateDraftStatements () {
			return this.productAreaPermission(CAN_CREATE_DRAFT_STATEMENTS) && (userHasBrand(this.statement.brandID) || !this.isEdit)
		},
		canApproveDraftStatements () {
			return this.productAreaPermission(CAN_APPROVE_DRAFT_STATEMENTS) && userHasBrand(this.statement.brandID)
		},
		canPublishDraftStatements () {
			return this.productAreaPermission(CAN_PUBLISH_DRAFT_STATEMENTS) && userHasBrand(this.statement.brandID)
		},
		metadataFullPermissions () {
			return this.productAreaPermission(META_DATA_KEYS_MODULE_FULL_PERMISSIONS) && userHasBrand(this.statement.brandID)
		},
		newChannel () {
			return !this.channelId && this.accessedVia === this.ACCESSED_VIA_CHANNEL
		},
		getDraftStatementStatus () {
			if (this.statement.defaultLanguageDraftStatement == null) return ''
			if (this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.NEW) return 'Current Status: New'
			if (this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.SUBMITTED) return 'Current Status: Submitted'
			if (this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.APPROVED) return 'Current Status: Approved'
			if (this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.AWAITING_PUBLISH) return 'Current Status: Live on ' + format(new Date(this.statement.defaultLanguageDraftStatement.queuedActionDateTime), DATE_TIME_FORMAT_NO_SECONDS)
			else return ''
		},
		tableHeaders () {
			return [
				{ text: useLabels('Statement Text'), value: 'statementText', width: '60%' },
				{ text: 'Archived By', value: 'archivedByName', width: '20%' },
				{ text: 'Archive Date', value: 'archivedOn', width: '20%' }
			]
		},
		displaySaveDraftButton () {
			return (!this.statement.defaultLanguageDraftStatement || this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.NEW) && !this.universalStatementNotUser
		},
		displaySaveAndSubmitButton () {
			return !this.statement.defaultLanguageDraftStatement && !this.universalStatementNotUser
		},
		displaySubmitButton () {
			return this.statement.defaultLanguageDraftStatement?.draftState === STATEMENT_STATUS.NEW && !this.universalStatementNotUser
		},
		displayAbandonButton () {
			return this.statement.defaultLanguageDraftStatement && this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.NEW && !this.universalStatementNotUser
		},
		displayRejectDraftButton () {
			return this.statement.defaultLanguageDraftStatement && this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.SUBMITTED &&
				!this.universalStatementNotUser
		},
		displayRejectApprovalButton () {
			return this.statement.defaultLanguageDraftStatement && this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.APPROVED &&
				!this.universalStatementNotUser
		},
		displayCancelPublishButton () {
			return this.statement.defaultLanguageDraftStatement && this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.AWAITING_PUBLISH &&
				!this.universalStatementNotUser
		},
		displayApproveButton () {
			return this.statement.defaultLanguageDraftStatement && this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.SUBMITTED &&
				!this.universalStatementNotUser
		},
		displayPublishButton () {
			return this.statement.defaultLanguageDraftStatement &&
				(this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.APPROVED ||
					this.statement.defaultLanguageDraftStatement.draftState === STATEMENT_STATUS.AWAITING_PUBLISH) &&
				!this.universalStatementNotUser
		},
		disableRadioGroups () {
			return (this.statement.liveStatementText != null && this.statement.liveStatementText !== '') || (this.displaySubmitButton === false && this.displaySaveAndSubmitButton === false)	||
				!this.canCreateDraftStatements || this.universalStatementNotUser
		},
		universalStatementNotUser () {
			return this.statement.brandID === 0 && !this.userHasUniversalBrand
		},
		liveStatementCreatedBy () {
			if (isNaN(new Date(this.statement?.liveStatements[0]?.createdDate).getTime())) {
				return ''
			}
			return this.statement?.liveStatements[0]?.createdByName + ', ' + format(new Date(this.statement?.liveStatements[0]?.createdDate), DATE_FORMAT)
		},
		draftStatementEditedBy () {
			if (isNaN(new Date(this.statement?.defaultLanguageDraftStatement?.lastActionDate).getTime())) {
				return ''
			}
			return this.statement?.defaultLanguageDraftStatement?.lastActionByName + ', ' + format(new Date(this.statement?.defaultLanguageDraftStatement?.lastActionDate), DATE_FORMAT)
		}
	},
	created () {
		this.scrollToTop()
	},
	methods: {
		updateMetadata (assignedMetadata) {
			this.statement.metaData = assignedMetadata
			if (this.statement.statementId) {
				this.createMetadataAssignment()
			}
		},
		scrollToTop () {
			if (this.accessedVia === this.ACCESSED_VIA_CHANNEL) document.querySelector('#manage_channel_statement_container').scrollTo(0, 0)
		},
		backToOverview () {
			this.scrollToTop()
			if (this.accessedVia === this.ACCESSED_VIA_CHANNEL) {
				this.$emit('close')
				this.$emit('load-channel-statements')
			} else {
				this.$router.push({ name: STATEMENTS })
			}
		},
		async putStatementAction (actionId, autoQueueDateTime) {
			let statementAction = {
				svid: this.statement.defaultLanguageDraftStatement.svid,
				action: actionId
			}
			if (actionId === STATEMENT_ACTIONS.SUBMIT) {
				await this.putStatement(false)
			}
			if (actionId === STATEMENT_ACTIONS.PUBLISH_ON) {
				statementAction = {
					svid: this.statement.defaultLanguageDraftStatement.svid,
					action: actionId,
					autoQueueDateTime: autoQueueDateTime
				}
			}
			await putStatementAction(statementAction)
			this.backToOverview()
		},
		async postStatementAndAction () {
			const statementAndAction = {
				channelId: !this.statement.statementId ? this.channelId : null,
				statementID: this.statement.statementId ? this.statement.statementId : null,
				statementText: this.statement.draftStatementText,
				isOptIn: this.statement.isOptIn,
				defaultValue: this.statement.defaultValue,
				externalValues: this.statement.metaData?.map(metaData => ({
					externalMetaDataKeyId: metaData.metaDataKeyId,
					externalFieldValue: metaData.metaDataValue
				})),
				actionId: STATEMENT_ACTIONS.SUBMIT
			}
			if (!this.newChannel) {
				let statementId = 0
				await postStatementAndAction(statementAndAction).then(response => {
					statementId = response.data.affectedStatementId
				})
				this.updateEditChannelNewStatementAdded(statementId)
			} else {
				const lastNegativeId = this.channelStatements.reduce((max, statement) => statement.statementID < 0 ? Math.min(max, statement.statementID) : max, 0)
				const channelStatement = {
					statementID: lastNegativeId - 1,
					statementText: statementAndAction.statementText,
					draftStatementText: statementAndAction.statementText,
					isOptIn: statementAndAction.isOptIn,
					defaultValue: statementAndAction.defaultValue,
					metaData: this.statement.metaData,
					externalValues: statementAndAction.externalValues,
					status: 'Draft Submitted',
					actionId: STATEMENT_ACTIONS.SUBMIT
				}
				if (!this.isEdit) {
					this.updateChannelStatements([
						...(this.channelStatements),
						channelStatement
					])
				} else {
					const currentStatements = this.channelStatements
					this.$set(currentStatements, this.index, channelStatement)
					this.updateChannelStatements(currentStatements)
				}
			}
			this.backToOverview()
		},
		async createMetadataAssignment () {
			await deleteExternalMetaDataAssignment(this.statement.statementId, 7)
			await createExternalMetaDataAssignment(this.statement.metaData, this.statement.statementId, 7)
		},
		saveStatement () {
			if (!showStatementDrafts.value && !this.isEdit || !this.statement.statementId) {
				this.postStatement()
			} else {
				this.putStatement(true)
			}
		},
		async postStatement () {
			const statement = {
				channelId: this.channelId,
				statementText: showStatementDrafts.value ? this.statement.draftStatementText : this.statement.liveStatementText,
				isOptIn: this.statement.isOptIn,
				defaultValue: this.statement.defaultValue,
				externalValues: this.statement.metaData?.map(metaData => ({
					externalMetaDataKeyId: metaData.metaDataKeyId,
					externalFieldValue: metaData.metaDataValue
				}))
			}
			if (!this.newChannel) {
				let statementId = 0
				await postStatement(statement).then(response => {
					statementId = response.data.affectedStatementId
				})
				this.updateEditChannelNewStatementAdded(statementId)
			} else {
				const lastNegativeId = this.channelStatements.reduce((max, statement) => statement.statementID < 0 ? Math.min(max, statement.statementID) : max, 0)
				const channelStatement = {
					statementID: lastNegativeId - 1,
					statementText: statement.statementText,
					draftStatementText: statement.statementText,
					isOptIn: statement.isOptIn,
					defaultValue: statement.defaultValue,
					metaData: this.statement.metaData,
					externalValues: statement.externalValues,
					status: 'Draft New',
					actionId: null
				}

				if (!this.isEdit) {
					this.updateChannelStatements([
						...(this.channelStatements),
						channelStatement
					])
				} else {
					const currentStatements = this.channelStatements
					this.$set(currentStatements, this.index, channelStatement)
					this.updateChannelStatements(currentStatements)
				}
			}
			this.backToOverview()
		},
		async putStatement (goBackToOverview) {
			const statement = {
				statementId: this.statement.statementId,
				statementText: showStatementDrafts.value ? this.statement.draftStatementText : this.statement.liveStatementText,
				isOptIn: this.statement.isOptIn,
				defaultValue: this.statement.defaultValue,
				externalValues: this.statement.metaData?.map(metaData => ({
					externalMetaDataKeyId: metaData.metaDataKeyId,
					externalFieldValue: metaData.metaDataValue
				}))
			}
			await putStatement(statement)
			if (goBackToOverview) {
				this.backToOverview()
			}
		},
		updateChannelStatements (statements) {
			this.$emit('update:statements', statements)
		},
		updateEditChannelNewStatementAdded (statementId) {
			this.$emit('update:edit-channel-new-statement-added', statementId)
		}
	}
}
</script>
