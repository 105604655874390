<template>
	<SectionCard>
		<template #title>
			{{ elementType.cardTitle }}
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						label="Default Button Label"
						disabled
						:test-id="`${element.name}_default_button_label`"
						:value="element.buttonLabel"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						label="Translated Button Label"
						:value.sync="translatedButtonLabel"
						:disabled="!userFullPermissions"
						:test-id="`${element.name}_button_label_translation`"
						@input="updateTranslation(element.name, 'buttonLabel', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
export default {
	components: {
		SectionCard,
		TextField
	},
	props: {
		element: {
			type: Object,
			required: true
		},
		elementType: {
			type: Object,
			required: true
		},
		translatedSchema: {
			type: Object,
			required: true
		},
		userFullPermissions: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		translatedButtonLabel () {
			return this.translatedSchema[this.element?.name]?.buttonLabel
		}
	},
	methods: {
		updateTranslation (key, property, value) {
			this.$emit('update-translation', key, property, value)
		}
	}
}
</script>
