<template>
	<ManageInterationServiceLayout
		:current-step.sync="currentStep"
		:steps.sync="steps"
		:is-edit="isEdit"
		:clone-mode="cloneMode"
		:back-button-action="goBackToOverview"
		:finish-button-action="finish"
		:integration-service-has-changed="integrationServiceHasChanged"
		:brand-id="isEdit ? integrationServiceToEdit.brandId : userBrands[0]"
	>
		<template #content>
			<IntegrationService
				:integration-service.sync="integrationService"
				:lookup-data="lookupData"
				:system-integration-items="systemIntegrationItems"
				:step="currentStep"
				:steps="steps"
				:look-up-data="lookUpData"
				:clone-mode="cloneMode"
				@reload-look-up-data="loadIntegrationServices"
			/>
		</template>
	</ManageInterationServiceLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import IntegrationService from './integration-service.vue'
import { CONNECTOR_SERVICE } from '../../../../router/route-names.js'
import ManageInterationServiceLayout from './manage-integration-service-layout.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { brandOptions, defaultBrandInSelect } from '../../../../../../shared/state/brands.js'
import { updatePrivacyPolicies } from '../../../../../../shared/state/privacy-policies.js'
import { postIntegrationService, putIntegrationService } from '../../../../../../shared/utils/api/connector-service.js'

export default {
	components: { ManageInterationServiceLayout, IntegrationService },
	props: {
		integrationServiceToEdit: Object,
		lookupData: Object,
		systemIntegrationItems: Array,
		cloneMode: Boolean
	},
	setup () {
		return {
			brandOptions,
			updatePrivacyPolicies
		}
	},
	data () {
		return {
			currentStep: 0,
			steps: [
				{ slot: 0, title: 'Details', stepNumber: '01', stepComplete: false },
				{ slot: 1, title: 'Data', stepNumber: '02', stepComplete: false },
				{ slot: 2, title: 'Connection', stepNumber: '03', stepComplete: false },
				{ slot: 3, title: 'Connection Response', stepNumber: '04', stepComplete: false }
			],
			integrationService: JSON.parse(JSON.stringify(this.integrationServiceToEdit || {
				name: null,
				brandId: defaultBrandInSelect.value,
				integrationId: null,
				notes: null,
				isEnabled: false,
				httpMethod: null,
				authenticationUrl: null,
				requestUrl: null,
				requestBody: null,
				requestThrottlingWaitTimeMS: 0,
				customHeaders: [],
				successResponses: [],
				cassieObjectId: null,
				objectIdColumn: null,
				objectClientUrnColumn: null,
				trigger: 'Insert',
				resetIncrementalValue: false,
				updateStandDak: false,
				objectSelectList: [],
				objectFilterClause: null,
				clientTargetObjectName: null,
				targetSystemAction: 'Insert',
				filters: []
			})),
			lookUpData: {}
		}
	},
	computed: {
		...mapGetters('auth', ['userBrands']),
		isEdit () {
			return !!this.integrationServiceToEdit && !this.cloneMode
		},
		integrationServiceHasChanged () {
			return JSON.stringify(this.integrationServiceToEdit) !== JSON.stringify(this.integrationService)
		}
	},
	created () {
		if (this.cloneMode) {
			this.integrationService.name += ' (clone)'
			this.integrationService.isEditable = true
			this.integrationService.cloneFromId = this.integrationService.id
			delete this.integrationService.id
		}
	},
	methods: {
		goBackToOverview () {
			this.$router.push({
				name: CONNECTOR_SERVICE,
				params: {
					accessedVia: 'integration-services'
				}
			})
		},
		async finish () {
			const integrationService = JSON.parse(JSON.stringify(this.integrationService))
			integrationService.objectSelectList = integrationService.objectSelectList.join(',')
			if (!this.isEdit || this.cloneMode) {
				await postIntegrationService(integrationService)
			} else {
				await putIntegrationService(integrationService)
			}
			showSnackbar(`This integration service has been ${this.isEdit ? 'updated' : 'added'}`)
			this.goBackToOverview()
		},
		loadIntegrationServices () {
		}
	}
}
</script>
