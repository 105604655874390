<template>
	<SectionCard>
		<template #title>
			Privacy Policy
		</template>
		<template #title-action>
			<Toggle
				class="mr-2"
				:disabled="!userFullPermissions"
				:value.sync="customPrivacyPolicyEnabled"
				label-left
				label="Enabled"
			/>
		</template>
		<template #body>
			<p>
				Enabling this option will allow you to specify Privacy Policy and Version for submissions made using this Embedded Consent Collection depending on the language that it is being displayed to the user in.
			</p>
			<template v-if="customPrivacyPolicyEnabled">
				<div class="cassie-vertical-md">
					<div class="cassie-horizontal-md d-flex flex-row">
						<Dropdown
							v-model="selectedLanguageId"
							label="Language*"
							class="cassie-input-width-md"
							:items="filteredAssignedLanguages"
							searchable
							:disabled="!userFullPermissions"
						/>
						<Dropdown
							v-model="selectedPrivacyPolicy"
							label="Privacy Policy*"
							:items="brandFilteredActivePrivacyPolicies"
							custom-sort
							searchable
							:disabled="(!brandId && brandId !== 0) || !userFullPermissions || filteredAssignedLanguages.length === 0"
							return-object
							class="cassie-input-width-md"
							@input="getLatestVersion()"
						/>
					</div>
					<div class="cassie-horizontal-md d-flex flex-row">
						<Dropdown
							v-if="selectedPrivacyPolicy"
							v-model="selectedPrivacyPolicyVersion"
							:items="versionOptions"
							:disabled="!userFullPermissions || filteredAssignedLanguages.length === 0"
							return-object
							label="Version*"
							class="cassie-input-width-md"
						/>
						<PrimaryActionButton
							:disabled="!userFullPermissions || filteredAssignedLanguages.length === 0 || !allowAddPrivacyPolicy"
							@click="addPrivacyPolicy"
						>
							Add
						</PrimaryActionButton>
					</div>
					<DataTable
						:headers="tableHeaders"
						:items="customPrivacyPolicies"
						hide-default-footer
					>
						<template #item.action="{ index }">
							<v-icon
								v-if="userFullPermissions"
								small
								@click="removeCustomPrivacyPolicy(index)"
							>
								mdi-trash-can
							</v-icon>
						</template>
					</DataTable>
				</div>
			</template>
		</template>
	</SectionCard>
</template>

<script>

import SectionCard from '../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import { getAssignedLanguages } from '../../../../../shared/utils/api/languages.js'
import { activePrivacyPolicies, privacyPolicies } from '../../../../../shared/state/privacy-policies.js'
const UNIVERSAL_BRAND = 0

export default {
	components: { SectionCard, Dropdown, PrimaryActionButton, DataTable, Toggle },
	props: {
		preferenceWidget: Object,
		updatePreferenceWidget: Function,
		userFullPermissions: Boolean,
		editMode: Boolean
	},
	setup () {
		return {
			privacyPolicies,
			activePrivacyPolicies
		}
	},
	data () {
		return {
			customPrivacyPolicyEnabled: false,
			assignedLanguages: [],
			selectedLanguageId: null,
			selectedPrivacyPolicy: null
		}
	},
	computed: {
		filteredAssignedLanguages () {
			const filteredLanguages = this.assignedLanguages.filter(x => !this.customPrivacyPolicies.some(y => y.languageId === x.value))
			return filteredLanguages
		},
		customPrivacyPolicies () {
			return this.preferenceWidget.customPrivacyPolicies ? this.preferenceWidget.customPrivacyPolicies : []
		},
		tableHeaders () {
			return [
				{ value: 'languageName', text: 'Language', width: '15%' },
				{ value: 'privacyPolicyId', text: 'Privacy Policy ID', width: '10%' },
				{ value: 'privacyPolicyName', text: 'Privacy Policy Name', width: '20%' },
				{ value: 'privacyPolicyVersionId', text: 'Cassie Version ID', width: '25%' },
				{ value: 'privacyPolicyExternalVersionRef', text: 'Client Version Identifier', width: '10%' },
				{ value: 'action', text: 'Action', width: '10%' }
			]
		},
		versionOptions () {
			const selectedPrivacyPolicyVersions = privacyPolicies.value.find(({ privacyPolicyId }) => privacyPolicyId === this.selectedPrivacyPolicy.value).versions.map(version => ({
				...version,
				value: version.externalVersionRef,
				text: version.externalVersionRef
			}))
			const useLatestVersion = {
				value: 'Always Use Latest Version',
				text: 'Always Use Latest Version'
			}
			return [
				useLatestVersion,
				...selectedPrivacyPolicyVersions
			]
		},
		brandFilteredActivePrivacyPolicies () {
			let filteredPrivacyPolicies = []

			if (this.brandId === UNIVERSAL_BRAND) {
				filteredPrivacyPolicies = activePrivacyPolicies.value.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				filteredPrivacyPolicies = activePrivacyPolicies.value.filter(({ brandId }) => brandId === this.brandId || brandId === UNIVERSAL_BRAND)
			}
			return filteredPrivacyPolicies
		},
		brandId () {
			return this.preferenceWidget.brandId
		},
		allowAddPrivacyPolicy () {
			return this.selectedLanguageId !== null && this.selectedPrivacyPolicy !== null && this.selectedPrivacyPolicyVersion !== null
		}
	},
	async created () {
		const { data: assignedLanguages } = await getAssignedLanguages()
		this.assignedLanguages = assignedLanguages.map(({ languageID, languageName }) => ({ value: languageID, text: languageName }))
		if (this.customPrivacyPolicies.length) this.customPrivacyPolicyEnabled = true
	},
	methods: {
		updatePrivacyPolicies (customPrivacyPolicies) {
			this.updatePreferenceWidget('customPrivacyPolicies', customPrivacyPolicies)
		},
		getLatestVersion () {
			this.selectedPrivacyPolicyVersion = this.versionOptions[0]
		},
		addPrivacyPolicy () {
			const language = this.assignedLanguages.find(({ value }) => value === this.selectedLanguageId)
			this.updatePrivacyPolicies([
				...this.customPrivacyPolicies,
				{
					languageId: language.value,
					languageName: language.text,
					privacyPolicyId: this.selectedPrivacyPolicy.value,
					privacyPolicyName: this.selectedPrivacyPolicy.text,
					privacyPolicyVersionId: this.selectedPrivacyPolicyVersion.versionId || 'Always Use Latest Version',
					privacyPolicyExternalVersionRef: this.selectedPrivacyPolicyVersion.externalVersionRef
				}
			])
			this.selectedLanguageId = null
			this.selectedPrivacyPolicy = null
			this.selectedPrivacyPolicyVersion = null
		},
		removeCustomPrivacyPolicy (index) {
			const customPrivacyPolicies = [...this.customPrivacyPolicies]
			this.$delete(customPrivacyPolicies, index)
			this.updatePreferenceWidget('customPrivacyPolicies', customPrivacyPolicies)
		}
	}
}
</script>
