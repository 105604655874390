<template>
	<validation-observer #default="{ handleSubmit }">
		<Modal
			width="600px"
		>
			<template #modal-title>
				{{ isEdit ? 'Edit' : 'Add' }} Language
				<v-spacer />
				<IconButton @click="$emit('close')">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<Dropdown
						v-if="!isEdit"
						v-model="optionTranslation.languageLookupId"
						:disabled="userFullPermissions"
						:items="languages"
						searchable
						label="Language *"
						rules="required"
						item-value="languageLookupId"
						item-text="languageName"
					/>
					<TextField
						v-model="optionTranslation.languageCode"
						:disabled="userFullPermissions"
						rules="required"
						:placeholder="calculateSuggestions"
						label="Language Code"
					/>
					<TextField
						v-model="optionTranslation.languageName"
						:disabled="userFullPermissions"
						label="Language Name"
						rules="required"
					/>
					<v-radio-group
						v-model="optionTranslation.enabledInPublicPortal"
						:disabled="userFullPermissions"
						hide-details
						class="mt-0"
					>
						<template #label>
							Is enabled in the Public Portal?
						</template>
						<div class="d-flex">
							<v-radio
								:disabled="userFullPermissions"
								label="Yes"
								:value="true"
								class="my-0 mr-4"
							/>
							<v-radio
								:disabled="userFullPermissions"
								label="No"
								:value="false"
								class="my-0"
							/>
						</div>
					</v-radio-group>
					<v-radio-group
						v-model="optionTranslation.allowPrefPageAutoTranslation"
						hide-details
						class="mt-0"
					>
						<template #label>
							Allow Auto Translation On Preference Link Pages?
						</template>
						<div class="d-flex">
							<v-radio
								:disabled="userFullPermissions"
								label="Yes"
								:value="true"
								class="my-0 mr-4"
							/>
							<v-radio
								:disabled="userFullPermissions"
								label="No"
								:value="false"
								class="my-0"
							/>
						</div>
					</v-radio-group>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="!userFullPermissions"
					@click="handleSubmit(submit)"
				>
					{{ isEdit ? 'Save' : 'Create' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>
<script>
import Modal from '../../../../../../shared/components/modal.vue'
import { createLanguage, updateLanguage } from '../../../../../../shared/utils/api/languages.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import TextField from '../../../../../../shared/components/text-field.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
export default {
	components: { PrimaryActionButton, SecondaryActionButton, TextField, Modal, Dropdown, IconButton },
	props: {
		optionTranslationToEdit: Object,
		userFullPermissions: {
			type: Boolean,
			default: false
		},
		languages: {
			type: Array,
			default: () => []
		},
		isEdit: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			optionTranslation: JSON.parse(JSON.stringify(this.optionTranslationToEdit)),
			showAddModal: false
		}
	},
	computed: {
		calculateSuggestions () {
			return this.languages.find(({ languageLookupId }) => languageLookupId === this.optionTranslation.languageLookupId)
				?.googleLanguageCode ?? ''
		}
	},
	methods: {
		async submit () {
			const {
				allowPrefPageAutoTranslation, enabledInPublicPortal,
				languageCode, languageLookupId, languageID, languageName
			} = this.optionTranslation
			if (this.languages.includes(languageCode)) {
				showSnackbar({ text: `Language code '${languageCode} already exists.`, color: 'error' })
				return
			}
			if (this.isEdit) {
				await updateLanguage(languageID,
					languageName.replace(' (Default)', ''),
					languageCode,
					enabledInPublicPortal,
					allowPrefPageAutoTranslation)
				if (languageID === 0) {
					// Reload Default Language
					this.defaultLanguage = languageName
				}
			} else {
				await createLanguage(languageLookupId,
					languageName,
					languageCode,
					enabledInPublicPortal,
					allowPrefPageAutoTranslation)
			}
			showSnackbar(`${languageName} has been ${this.isEdit ? 'updated' : 'created'}`)
			this.$emit('saved')
			this.$emit('close')
		}
	}
}
</script>
