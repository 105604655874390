<template>
	<ValidationForm #default="{ handleSubmit }">
		<ViewLayout>
			<template #header-title>
				{{ systemEmail.systemEmailName + ` Translation (` + systemEmail.languageName +`)` }}
			</template>
			<template #header-caption>
				Add, edit and manage system email translations
			</template>
			<template #content>
				<SectionCard>
					<template #title>
						{{ systemEmail.systemEmailName + ` Default Wording` }}
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<TextField
									v-model="systemEmail.systemEmailSubject"
									label="Default Subject"
									disabled
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<div>
									<p class="text-subtitle-2 mb-3">
										Default Email Contents
									</p>
								</div>
								<div
									v-dompurify-html="systemEmail.systemEmailHtml"
									class="mt-2 cassie-disabled-textarea cassie-input-width-xl rounded-lg"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
				<SectionCard>
					<template #title>
						System Email Translation
					</template>
					<template #body>
						<v-row dense>
							<v-col cols="6">
								<TextField
									v-model="systemEmail.translatedEmailSubject"
									:disabled="!userFullPermissions"
									label="Translated Email Subject"
								/>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<FroalaEditor
									v-if="userFullPermissions"
									v-model="systemEmail.translatedEmailHTML"
									title="Translated Email Contents"
									:rules="{ required: true, max: 2000 }"
								/>
								<div
									v-dompurify-html="systemEmail.translatedEmailHTML"
									class="mt-2 cassie-disabled-textarea cassie-input-width-xl rounded-lg"
								/>
							</v-col>
						</v-row>
					</template>
				</SectionCard>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							Back to Overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userFullPermissions"
							@click="handleSubmit(submit)"
						>
							{{ !isEdit ? 'Create' : 'Save' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
	</ValidationForm>
</template>
<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import PageActionRow from '../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { SYSTEM_EMAILS } from '../../../router/route-names.js'
import { SYSTEM_EMAILS_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { userHasBrand } from '../../../../../shared/state/brands.js'
import FroalaEditor from '../../shared/froala-editor.vue'
import { upsertSystemEmailTranslation } from '../../../../../shared/utils/api/system-emails.js'

export default {
	components: {
		ViewLayout,
		ValidationForm,
		SectionCard,
		TextField,
		PageActionRow,
		SecondaryActionButton,
		PrimaryActionButton,
		FroalaEditor
	},
	props: {
		systemEmailToTranslate: Object
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			systemEmail: null
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		userFullPermissions () {
			return this.checkPermission(SYSTEM_EMAILS_MODULE_FULL_PERMISSIONS) && userHasBrand(this.systemEmail.brandId)
		},
		isEdit () {
			return this.systemEmail.translated === 'Yes'
		}
	},
	created () {
		this.systemEmail = this.systemEmailToTranslate
	},
	methods: {
		backToOverview () {
			this.$router.push({
				name: SYSTEM_EMAILS,
				params: {
					accessedViaTranslation: true,
					previouslySelectedLanguage: this.systemEmail.languageId
				}
			})
		},
		async submit () {
			const emailTranslation = {
				emailTranslationId: this.systemEmail.systemEmailTranslationID,
				emailId: this.systemEmail.systemEmailId,
				languageId: this.systemEmail.languageId,
				EmailSubject: this.systemEmail.translatedEmailSubject,
				EmailHTML: this.systemEmail.translatedEmailHTML
			}
			await upsertSystemEmailTranslation(emailTranslation)
			showSnackbar('System email translation has been saved')
			this.backToOverview()
		}
	}
}
</script>
