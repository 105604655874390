<template>
	<div>
		<v-row
			v-for="element in areaSchema"
			:key="element.name"
		>
			<v-col cols="12">
				<component
					:is="elementFieldsMap[getElementType(element)].componentName"
					v-if="translatedSchema"
					:element="element"
					:element-type="elementFieldsMap[getElementType(element)]"
					:translated-schema.sync="translatedSchema"
					:user-full-permissions="userFullPermissions"
					@update-translation="updateTranslation"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { elementFieldsMap } from './element-type-cards/element-fields-map.js'
import HeaderFooterElement from './element-type-cards/header-footer-element.vue'
import ButtonElement from './element-type-cards/button-element.vue'
import SimplePreferenceElement from './element-type-cards/simple-preference-element.vue'
import RadioPreferenceElement from './element-type-cards/radio-preference-element.vue'
import StaticImageElement from './element-type-cards/static-image-element.vue'
import StaticTextElement from './element-type-cards/static-text-element.vue'
import PiiElement from './element-type-cards/pii-element.vue'

export default {
	components: {
		HeaderFooterElement,
		ButtonElement,
		SimplePreferenceElement,
		RadioPreferenceElement,
		StaticImageElement,
		StaticTextElement,
		PiiElement
	},
	props: {
		preferencePageTranslation: Object,
		area: String,
		areaSchema: Array,
		areaTranslationSchema: Array,
		translatedSchema: Object,
		userFullPermissions: {
			type: Boolean,
			required: true
		}
	},
	setup () {
		return {
			elementFieldsMap
		}
	},
	methods: {
		getElementType (element) {
			const elementName = element.name.split('_')[0]
			return elementName
		},
		updateTranslation (key, property, value) {
			this.$emit('update-translation', key, property, value)
		}
	}
}
</script>
