import axios from 'axios'
import { loadingSpinner } from '../state/loading-spinner.js'
import { showSnackbar } from '../state/snackbar.js'

const instanceConfig = {
	headers: {
		'Access-Control-Allow-Origin': '*'
	}
}

const configApiInstance = axios.create(instanceConfig)

const transactionalApiInstance = axios.create(instanceConfig)

let config = {
	unauthorizedHandler: () => {},
	loginUrl: '',
	logoutUrl: '',
	otpLoginUrl: ''
}

const setHttpClientConfig = newConfig => {
	config = {
		...config,
		...newConfig
	}
}

const showSpinner = async (method, instance, ...args) => {
	loadingSpinner.value = true
	let response
	try {
		if (args[0] === 'mock') {
			response = await new Promise(resolve => {
				setTimeout(() => {
					const { response: responseMock } = args[1]
					resolve(responseMock)
				}, 250)
			})
		} else {
			response = await instance[method](...args)
		}
	} catch (error) {
		loadingSpinner.value = false
		if (args[0] === '/api/authorize/adminportaluserrefreshtoken' || args[0] === '/api/authorize/adminportaluserlogout') {
			showSnackbar({ text: 'Your session has expired, please log in again http-client.js', color: 'red' })
		} else if (error.response?.data?.errors?.length && error.response.data.errors.length > 0) {
			showSnackbar({ text: `${error.response.data.errors[0]}`, color: 'red' })
		} else {
			const errorMessage = error.response?.data
			const errorToDisplay = Array.isArray(errorMessage) ? errorMessage.join('\r\n') : errorMessage
			if (errorToDisplay) {
				showSnackbar({ text: errorToDisplay, color: 'red' })
			}
		}
		throw error
	}
	loadingSpinner.value = false
	return response
}

const createInstance = instance => {
	const responseHandler = function (response) {
		if (response.code === 204) {
			response.data = null
		}

		return response
	}
	const responseErrorHandler = function (error) {
		if (!error.response?.config || (error.response.status && +error.response.status !== 401)) {
			return Promise.reject(error)
		}

		if (config.loginUrl && error.response.config.url.endsWith(config.loginUrl) || config.logoutUrl && error.response.config.url.endsWith(config.logoutUrl)) {
			return Promise.reject(error)
		}
		// if we call the otp endpoint and it fails, we don't want to redirect to the login page
		if (config.otpLoginUrl && !error.response.config.url.endsWith(config.otpLoginUrl)) {
			config.unauthorizedHandler(error.response.config.url)
		}
		return Promise.reject(error)
	}

	instance.interceptors.response.use(responseHandler, responseErrorHandler)

	return {
		get: (...args) => showSpinner('get', instance, ...args),
		post: (...args) => showSpinner('post', instance, ...args),
		put: (...args) => showSpinner('put', instance, ...args),
		delete: (...args) => showSpinner('delete', instance, ...args),
		instance
	}
}

const configApi = createInstance(configApiInstance)
const refresherApi = axios.create(configApiInstance)
const transactionalApi = createInstance(transactionalApiInstance)

const setBearerToken = token => {
	configApi.instance.defaults.headers.common.Authorization = token ? 'Bearer ' + token : ''
	transactionalApi.instance.defaults.headers.common.Authorization = token ? 'Bearer ' + token : ''
	refresherApi.defaults.headers.common.Authorization = token ? 'Bearer ' + token : ''
}

export { configApi, transactionalApi, refresherApi, setBearerToken, setHttpClientConfig }
